import * as tslib_1 from "tslib";
import { OverlayContainer } from '@angular/cdk/overlay';
import { AfterViewInit, ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { DialogService, DropFilesAction } from 'app/modules/editor/components/dialogs';
import { ProjectService } from 'app/modules/editor/components/project';
import { ActionMode, ActionSource } from 'app/modules/editor/model/actionmode';
import { CursorType } from 'app/modules/editor/model/paper';
import { bugsnagClient } from 'app/modules/editor/scripts/bugsnag';
import { DestroyableMixin } from 'app/modules/editor/scripts/mixins';
import { ActionModeService, ClipboardService, FileImportService, LayerTimelineService, ShortcutService, ThemeService, } from 'app/modules/editor/services';
import { Duration, SnackBarService } from 'app/modules/editor/services/snackbar.service';
import { Store } from 'app/modules/editor/store';
import { getActionMode, getActionModeHover } from 'app/modules/editor/store/actionmode/selectors';
import { isWorkspaceDirty } from 'app/modules/editor/store/common/selectors';
import { getCursorType } from 'app/modules/editor/store/paper/selectors';
import { ResetWorkspace } from 'app/modules/editor/store/reset/actions';
import * as erd from 'element-resize-detector';
import { environment } from 'environments/environment';
import * as $ from 'jquery';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';
var IS_DEV_BUILD = !environment.production;
var ELEMENT_RESIZE_DETECTOR = erd({ strategy: 'scroll' });
var STORAGE_KEY_FIRST_TIME_USER = 'storage_key_first_time_user';
var RootComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RootComponent, _super);
    function RootComponent(snackBarService, fileImportService, store, actionModeService, shortcutService, demoService, dialogService, clipboardService, layerTimelineService, themeService, overlayContainer) {
        var _this = _super.call(this) || this;
        _this.snackBarService = snackBarService;
        _this.fileImportService = fileImportService;
        _this.store = store;
        _this.actionModeService = actionModeService;
        _this.shortcutService = shortcutService;
        _this.demoService = demoService;
        _this.dialogService = dialogService;
        _this.clipboardService = clipboardService;
        _this.layerTimelineService = layerTimelineService;
        _this.themeService = themeService;
        _this.overlayContainer = overlayContainer;
        _this.ACTION_SOURCE_FROM = ActionSource.From;
        _this.ACTION_SOURCE_ANIMATED = ActionSource.Animated;
        _this.ACTION_SOURCE_TO = ActionSource.To;
        _this.IS_BETA = environment.beta;
        _this.displayBoundsSubject = new BehaviorSubject({ w: 1, h: 1 });
        return _this;
    }
    RootComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.shortcutService.init();
        this.clipboardService.init();
        this.registerSubscription(this.themeService.asObservable().subscribe(function (t) {
            var isDark = t.themeType === 'dark';
            _this.isDarkThemeHostBinding = isDark;
            var classList = _this.overlayContainer.getContainerElement().classList;
            if (isDark) {
                classList.add('ss-dark-theme');
            }
            else {
                classList.remove('ss-dark-theme');
            }
        }));
        $(window).on('beforeunload', function (event) {
            var isDirty;
            _this.store
                .select(isWorkspaceDirty)
                .pipe(first())
                .subscribe(function (dirty) { return (isDirty = dirty); });
            if (isDirty && !IS_DEV_BUILD) {
                return "You've made changes but haven't saved. Are you sure you want to navigate away?";
            }
            return undefined;
        });
        var displaySize$ = this.displayBoundsSubject.asObservable().pipe(distinctUntilChanged(function (s1, s2) {
            return s1.w === s2.w && s1.h === s2.h;
        }));
        this.isActionMode$ = this.store
            .select(getActionMode)
            .pipe(map(function (mode) { return mode !== ActionMode.None; }));
        this.canvasBounds$ = combineLatest(displaySize$, this.isActionMode$).pipe(map(function (_a) {
            var _b = _a[0], w = _b.w, h = _b.h, shouldShowThreeCanvases = _a[1];
            return { w: w / (shouldShowThreeCanvases ? 3 : 1), h: h };
        }));
        this.cursorClassName$ = combineLatest(this.store.select(getCursorType), this.store.select(getActionMode), this.store.select(getActionModeHover)).pipe(map(function (_a) {
            var cursorType = _a[0], mode = _a[1], hover = _a[2];
            if (mode === ActionMode.SplitCommands || mode === ActionMode.SplitSubPaths) {
                return CursorType.Pen;
            }
            else if (hover) {
                return CursorType.Pointer;
            }
            return cursorType || CursorType.Default;
        }), map(function (cursorType) { return "cursor-" + cursorType; }));
    };
    RootComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.isMobile()) {
            this.$displayContainer = $(this.displayContainerRef.nativeElement);
            ELEMENT_RESIZE_DETECTOR.listenTo(this.$displayContainer.get(0), function () {
                var w = _this.$displayContainer.width();
                var h = _this.$displayContainer.height();
                _this.displayBoundsSubject.next({ w: w, h: h });
            });
        }
        if ('serviceWorker' in navigator) {
            var isFirstTimeUser = window.localStorage.getItem(STORAGE_KEY_FIRST_TIME_USER);
            if (!isFirstTimeUser) {
                window.localStorage.setItem(STORAGE_KEY_FIRST_TIME_USER, 'true');
                setTimeout(function () {
                    _this.snackBarService.show('Ready to work offline', 'Dismiss', Duration.Long);
                });
            }
        }
        var projectUrl = getUrlParameter('project');
        if (projectUrl) {
            this.demoService
                .getProject(projectUrl)
                .then(function (_a) {
                var vectorLayer = _a.vectorLayer, animation = _a.animation, hiddenLayerIds = _a.hiddenLayerIds;
                _this.store.dispatch(new ResetWorkspace(vectorLayer, animation, hiddenLayerIds));
            })
                .catch(function (e) {
                _this.snackBarService.show("There was a problem loading the Shape Shifter project", 'Dismiss', Duration.Long);
            });
        }
    };
    RootComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        if (!this.isMobile()) {
            ELEMENT_RESIZE_DETECTOR.removeAllListeners(this.$displayContainer.get(0));
        }
        this.shortcutService.destroy();
        this.clipboardService.destroy();
        $(window).unbind('beforeunload');
    };
    // Called by the DropTargetDirective.
    RootComponent.prototype.onDropFiles = function (fileList) {
        var _this = this;
        if (this.actionModeService.isActionMode()) {
            // TODO: make action mode automatically exit when layers/blocks are added in other parts of the app
            bugsnagClient.notify('Attempt to import files while in action mode', {
                severity: 'warning',
            });
            return;
        }
        if (!fileList || !fileList.length) {
            return;
        }
        var files = [];
        // tslint:disable-next-line: prefer-for-of
        for (var i = 0; i < fileList.length; i++) {
            files.push(fileList[i]);
        }
        var type = files[0].type;
        if (!files.every(function (file) { return file.type === type; })) {
            // TODO: handle attempts to import different types of files better
            return;
        }
        if (type === 'application/json' || files[0].name.match(/\.shapeshifter$/)) {
            // TODO: Show a dialog here as well?
            this.fileImportService.import(fileList, true /* resetWorkspace */);
            return;
        }
        this.dialogService.dropFiles().subscribe(function (action) {
            if (action === DropFilesAction.AddToWorkspace) {
                _this.fileImportService.import(fileList);
            }
            else if (action === DropFilesAction.ResetWorkspace) {
                _this.fileImportService.import(fileList, true /* resetWorkspace */);
            }
        });
    };
    RootComponent.prototype.onClick = function (event) {
        var actionMode = this.actionModeService.getActionMode();
        if (actionMode === ActionMode.None) {
            this.layerTimelineService.clearSelections();
        }
        else if (actionMode === ActionMode.Selection) {
            this.actionModeService.setSelections([]);
        }
        else {
            this.actionModeService.setActionMode(ActionMode.Selection);
        }
    };
    RootComponent.prototype.isMobile = function () {
        return window.navigator.userAgent.includes('Mobile');
    };
    return RootComponent;
}(DestroyableMixin()));
export { RootComponent };
function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
