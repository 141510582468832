import { MathUtil } from 'app/modules/editor/scripts/common';
import * as _ from 'lodash';
var SnapBounds = /** @class */ (function () {
    function SnapBounds() {
        var snapPoints = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            snapPoints[_i] = arguments[_i];
        }
        this.snapPoints = snapPoints;
        this.left = _.minBy(snapPoints, function (p) { return p.x; }).x;
        this.top = _.minBy(snapPoints, function (p) { return p.y; }).y;
        this.right = _.maxBy(snapPoints, function (p) { return p.x; }).x;
        this.bottom = _.maxBy(snapPoints, function (p) { return p.y; }).y;
        this.width = this.right - this.left;
        this.height = this.bottom - this.top;
    }
    /** Computes the minimum distance between two snap bounds. */
    SnapBounds.prototype.distance = function (sb) {
        var _a = this, l1 = _a.left, t1 = _a.top, r1 = _a.right, b1 = _a.bottom;
        var l2 = sb.left, t2 = sb.top, r2 = sb.right, b2 = sb.bottom;
        var left = r2 < l1;
        var top = b1 < t2;
        var right = r1 < l2;
        var bottom = b2 < t1;
        var line;
        if (top && left) {
            line = { from: { x: l1, y: b1 }, to: { x: r2, y: t2 } };
        }
        else if (left && bottom) {
            line = { from: { x: l1, y: t1 }, to: { x: r2, y: b2 } };
        }
        else if (bottom && right) {
            line = { from: { x: r1, y: t1 }, to: { x: l2, y: b2 } };
        }
        else if (right && top) {
            line = { from: { x: r1, y: b1 }, to: { x: l2, y: t2 } };
        }
        else if (left) {
            line = { from: { x: r2, y: t1 }, to: { x: l1, y: t1 } };
        }
        else if (right) {
            line = { from: { x: r1, y: t1 }, to: { x: l2, y: t1 } };
        }
        else if (bottom) {
            line = { from: { x: l1, y: b2 }, to: { x: l1, y: t1 } };
        }
        else if (top) {
            line = { from: { x: l1, y: b1 }, to: { x: l1, y: t2 } };
        }
        else {
            // TODO: handle this case better? (it implies the bounds intersect)
            line = { from: { x: l1, y: t1 }, to: { x: l1, y: t1 } };
        }
        return { line: line, dist: MathUtil.distance(line.from, line.to) };
    };
    return SnapBounds;
}());
export { SnapBounds };
