import * as tslib_1 from "tslib";
import { ClipPathLayer, GroupLayer, LayerUtil, PathLayer, } from 'app/modules/editor/model/layers';
import { CursorType } from 'app/modules/editor/model/paper';
import { MathUtil, Matrix } from 'app/modules/editor/scripts/common';
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { PaperUtil, SnapUtil } from 'app/modules/editor/scripts/paper/util';
import * as paper from 'paper';
/**
 * A gesture that performs selection, move, and clone operations
 * on one or more items.
 *
 * Preconditions:
 * - The user is in default mode.
 * - The user hit an item in the previous mousedown event.
 */
var SelectDragCloneItemsGesture = /** @class */ (function (_super) {
    tslib_1.__extends(SelectDragCloneItemsGesture, _super);
    function SelectDragCloneItemsGesture(ps, hitLayerId) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.hitLayerId = hitLayerId;
        _this.pl = paper.project.activeLayer;
        _this.isDragging = false;
        return _this;
    }
    // @Override
    SelectDragCloneItemsGesture.prototype.onMouseDown = function (event) {
        // Clear the current hover layer, if it exists.
        this.ps.setHoveredLayerId(undefined);
        var selectedLayers = new Set(this.ps.getSelectedLayerIds());
        if (!event.modifiers.shift && !selectedLayers.has(this.hitLayerId)) {
            // If shift isn't pressed and the hit layer isn't already selected,
            // then clear any existing selections.
            selectedLayers.clear();
        }
        // Select the hit item.
        selectedLayers.add(this.hitLayerId);
        this.ps.setSelectedLayerIds(selectedLayers);
        // Save a copy of the initial vector layer so that we can make changes
        // to it as we drag.
        this.initialVectorLayer = this.ps.getVectorLayer();
    };
    // @Override
    SelectDragCloneItemsGesture.prototype.onMouseDrag = function (event) {
        var _this = this;
        if (!this.isDragging) {
            this.isDragging = true;
            if (event.modifiers.alt) {
                // TODO: clone the selected items
            }
            this.ps.setCursorType(CursorType.Grabbing);
        }
        var newVl = this.initialVectorLayer.clone();
        newVl = this.dragItems(newVl, event.downPoint, event.point, event.modifiers.shift);
        this.ps.setVectorLayer(newVl);
        // TODO: this could be WAY more efficient (no need to drag/snap things twice)
        var snapInfo = this.buildSnapInfo();
        if (snapInfo) {
            var projSnapDelta = new paper.Point(snapInfo.projSnapDelta);
            if (!projSnapDelta.isZero()) {
                newVl = this.dragItems(newVl, event.downPoint, event.downPoint.add(projSnapDelta));
                this.ps.setVectorLayer(newVl);
            }
            var updatedSnapInfo = this.buildSnapInfo();
            this.ps.setSnapGuideInfo({
                guides: updatedSnapInfo.guides.map(function (g) { return _this.projToVpLine(g); }),
                rulers: updatedSnapInfo.rulers.map(function (r) { return _this.projToVpLine(r); }),
            });
        }
        else {
            this.ps.setSnapGuideInfo(undefined);
        }
    };
    // TODO: dragging a parent and child simultaneously doesn't work
    SelectDragCloneItemsGesture.prototype.dragItems = function (newVl, projDownPoint, projPoint, shouldSnapDelta) {
        var _this = this;
        if (shouldSnapDelta === void 0) { shouldSnapDelta = false; }
        Array.from(this.ps.getSelectedLayerIds()).forEach(function (layerId) {
            var item = _this.pl.findItemByLayerId(layerId);
            var localDown = item.globalToLocal(projDownPoint).transform(item.matrix);
            var localCurr = item.globalToLocal(projPoint).transform(item.matrix);
            var localDelta = localCurr.subtract(localDown);
            var localFinalDelta = shouldSnapDelta
                ? new paper.Point(MathUtil.snapVectorToAngle(localDelta, 90))
                : localDelta;
            newVl = dragItem(newVl, layerId, localFinalDelta);
        });
        return newVl;
    };
    // TODO: reuse this code with ScaleItemsGesture
    SelectDragCloneItemsGesture.prototype.buildSnapInfo = function () {
        var _this = this;
        var selectedLayerIds = this.ps.getSelectedLayerIds();
        if (!selectedLayerIds.size) {
            return undefined;
        }
        var draggedItems = Array.from(selectedLayerIds).map(function (id) { return _this.pl.findItemByLayerId(id); });
        var parent = draggedItems[0].parent;
        if (!draggedItems.every(function (item) { return item.parent === parent; })) {
            // TODO: copy the behavior used in Sketch
            console.warn('All snapped items must share the same parent item.');
            return undefined;
        }
        var siblingItems = parent.children.filter(function (i) { return !draggedItems.includes(i); });
        var isParentVectorLayer = parent.data.id === this.initialVectorLayer.id;
        if (!siblingItems.length && !isParentVectorLayer) {
            return undefined;
        }
        // Perform the snap test.
        var siblingSnapPointsTable = siblingItems.map(function (item) { return toSnapPoints([item]); });
        if (isParentVectorLayer) {
            var _a = this.initialVectorLayer, width = _a.width, height = _a.height;
            var topLeft = new paper.Point(0, 0);
            var bottomRight = parent.localToGlobal(new paper.Point(width, height));
            var center = bottomRight.divide(2);
            siblingSnapPointsTable.push([topLeft, center, bottomRight]);
        }
        return SnapUtil.computeSnapInfo(toSnapPoints(draggedItems), siblingSnapPointsTable);
    };
    // @Override
    SelectDragCloneItemsGesture.prototype.onMouseUp = function (event) {
        this.ps.setSnapGuideInfo(undefined);
        this.ps.setCursorType(CursorType.Default);
    };
    SelectDragCloneItemsGesture.prototype.projToVpLine = function (_a) {
        var from = _a.from, to = _a.to;
        return {
            from: this.pl.globalToLocal(new paper.Point(from)),
            to: this.pl.globalToLocal(new paper.Point(to)),
        };
    };
    return SelectDragCloneItemsGesture;
}(Gesture));
export { SelectDragCloneItemsGesture };
// TODO: should we bake transforms into children (to be consistent with scale items gesture?)
function dragItem(newVl, layerId, localDelta) {
    var initialLayer = newVl.findLayerById(layerId);
    var x = localDelta.x, y = localDelta.y;
    if (initialLayer instanceof PathLayer || initialLayer instanceof ClipPathLayer) {
        var replacementLayer = initialLayer.clone();
        replacementLayer.pathData = initialLayer.pathData.transform(Matrix.translation(x, y));
        newVl = LayerUtil.replaceLayer(newVl, layerId, replacementLayer);
    }
    else if (initialLayer instanceof GroupLayer) {
        var replacementLayer = initialLayer.clone();
        replacementLayer.translateX += x;
        replacementLayer.translateY += y;
        newVl = LayerUtil.replaceLayer(newVl, layerId, replacementLayer);
    }
    return newVl;
}
function toSnapPoints(items) {
    var _a = PaperUtil.computeBounds(items), topLeft = _a.topLeft, center = _a.center, bottomRight = _a.bottomRight;
    return [topLeft, center, bottomRight];
}
