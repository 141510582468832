import { AnimationBlock } from 'app/modules/editor/model/timeline';
import { bugsnagClient } from 'app/modules/editor/scripts/bugsnag';
import { SvgLoader, VectorDrawableLoader } from 'app/modules/editor/scripts/import';
import * as $ from 'jquery';
import { ActionModeService } from './actionmode.service';
import { LayerTimelineService } from './layertimeline.service';
import { PlaybackService } from './playback.service';
import * as i0 from "@angular/core";
import * as i1 from "./layertimeline.service";
import * as i2 from "./playback.service";
import * as i3 from "./actionmode.service";
var ClipboardService = /** @class */ (function () {
    function ClipboardService(layerTimelineService, playbackService, actionModeService) {
        this.layerTimelineService = layerTimelineService;
        this.playbackService = playbackService;
        this.actionModeService = actionModeService;
    }
    ClipboardService.prototype.init = function () {
        var _this = this;
        var cutCopyHandlerFn = function (event, shouldCut) {
            if (document.activeElement.matches('input')) {
                return true;
            }
            var blocks = _this.layerTimelineService.getSelectedBlocks().map(function (b) { return b.toJSON(); });
            if (!blocks.length) {
                return false;
            }
            var clipboardData = event.originalEvent.clipboardData;
            clipboardData.setData('text/plain', JSON.stringify({ blocks: blocks }, undefined, 2));
            if (shouldCut) {
                _this.layerTimelineService.deleteSelectedModels();
            }
            return false;
        };
        var pasteHandlerFn = function (event) {
            if (_this.actionModeService.isActionMode()) {
                // TODO: make action mode automatically exit when layers/blocks are added in other parts of the app
                bugsnagClient.notify('Attempt to import files while in action mode', {
                    severity: 'warning',
                });
                return false;
            }
            if (document.activeElement.matches('input')) {
                return true;
            }
            var clipboardData = event.originalEvent.clipboardData;
            var str = clipboardData.getData('text');
            var existingVl = _this.layerTimelineService.getVectorLayer();
            if (str.match(/<\/svg>\s*$/)) {
                // Paste SVG.
                ga('send', 'event', 'paste', 'svg');
                SvgLoader.loadVectorLayerFromSvgString(str, function (name) { return !!existingVl.findLayerByName(name); })
                    .then(function (vl) { return _this.layerTimelineService.importLayers([vl]); })
                    .catch(function () { return console.warn('failed to import SVG'); });
            }
            else if (str.match(/<\/vector>\s*$/)) {
                // Paste VD.
                ga('send', 'event', 'paste', 'vd');
                var importedVl = VectorDrawableLoader.loadVectorLayerFromXmlString(str, function (name) { return !!existingVl.findLayerByName(name); });
                if (importedVl) {
                    _this.layerTimelineService.importLayers([importedVl]);
                }
            }
            else if (str.match(/\}\s*$/)) {
                var parsed = void 0;
                try {
                    parsed = JSON.parse(str);
                }
                catch (e) {
                    console.error("Couldn't parse JSON: " + str);
                    return false;
                }
                if (parsed.blocks) {
                    ga('send', 'event', 'paste', 'json.blocks');
                    _this.layerTimelineService.addBlocks(parsed.blocks.map(function (b) {
                        var block = AnimationBlock.from(b);
                        var layerId = block.layerId, propertyName = block.propertyName, fromValue = block.fromValue, toValue = block.toValue, interpolator = block.interpolator, startTime = block.startTime, endTime = block.endTime;
                        var duration = endTime - startTime;
                        return {
                            layerId: layerId,
                            propertyName: propertyName,
                            fromValue: fromValue,
                            toValue: toValue,
                            currentTime: _this.playbackService.getCurrentTime(),
                            duration: duration,
                            interpolator: interpolator,
                        };
                    }), false);
                }
                else {
                    ga('send', 'event', 'paste', 'json.unknown');
                }
                return false;
            }
            return false;
        };
        var cutHandler = function (event) { return cutCopyHandlerFn(event, true); };
        var copyHandler = function (event) { return cutCopyHandlerFn(event, false); };
        var pasteHandler = pasteHandlerFn;
        $(window)
            .on('cut', cutHandler)
            .on('copy', copyHandler)
            .on('paste', pasteHandler);
    };
    ClipboardService.prototype.destroy = function () {
        $(window)
            .unbind('cut')
            .unbind('copy')
            .unbind('paste');
    };
    ClipboardService.ngInjectableDef = i0.defineInjectable({ factory: function ClipboardService_Factory() { return new ClipboardService(i0.inject(i1.LayerTimelineService), i0.inject(i2.PlaybackService), i0.inject(i3.ActionModeService)); }, token: ClipboardService, providedIn: "root" });
    return ClipboardService;
}());
export { ClipboardService };
