import { CommandBuilder } from 'app/modules/editor/model/paths/Command';
import { MathUtil } from 'app/modules/editor/scripts/common';
import * as BezierJs from 'bezier-js';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { LineCalculator } from './LineCalculator';
import { PointCalculator } from './PointCalculator';
/**
 * A simple typed wrapper class around the amazing bezier-js library.
 */
var BezierCalculator = /** @class */ (function () {
    function BezierCalculator(id, svgChar) {
        var points = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            points[_i - 2] = arguments[_i];
        }
        this.id = id;
        this.svgChar = svgChar;
        this.points = points;
        // Don't initialize variables lazily for dev builds (to avoid
        // ngrx-store-freeze crashes).
        if (!environment.production) {
            this.getPathLength();
            this.getBoundingBox();
        }
    }
    Object.defineProperty(BezierCalculator.prototype, "bezierJs", {
        get: function () {
            if (this.bezierJs_ === undefined) {
                this.bezierJs_ = new BezierJs(this.points);
            }
            return this.bezierJs_;
        },
        enumerable: true,
        configurable: true
    });
    BezierCalculator.prototype.getPointAtLength = function (distance) {
        return this.bezierJs.get(this.findTimeByDistance(distance / this.getPathLength()));
    };
    BezierCalculator.prototype.getPathLength = function () {
        if (this.length === undefined) {
            this.length = this.bezierJs.length();
        }
        return this.length;
    };
    BezierCalculator.prototype.project = function (point) {
        // Create a new bezier curve for dev builds to avoid ngrx-store-freeze crashes.
        var bezierJs = !environment.production ? new BezierJs(this.points) : this.bezierJs;
        var _a = bezierJs.project(point), x = _a.x, y = _a.y, t = _a.t, d = _a.d;
        return { x: x, y: y, t: t, d: d };
    };
    BezierCalculator.prototype.split = function (t1, t2) {
        if (t1 === t2) {
            return new PointCalculator(this.id, this.svgChar, this.bezierJs.get(t1));
        }
        var points = this.bezierJs.split(t1, t2).points;
        var uniquePoints = _.uniqWith(points, MathUtil.arePointsEqual);
        if (uniquePoints.length === 2) {
            return new LineCalculator(this.id, this.svgChar, _.first(points), _.last(points));
        }
        return new (BezierCalculator.bind.apply(BezierCalculator, [void 0, this.id, this.svgChar].concat(points)))();
    };
    BezierCalculator.prototype.convert = function (svgChar) {
        if (svgChar === undefined) {
            throw new Error('Attempt to convert an undefined svgChar');
        }
        if (this.svgChar === 'Q' && svgChar === 'C') {
            var qcp0 = this.points[0];
            var qcp1 = this.points[1];
            var qcp2 = this.points[2];
            var ccp0 = qcp0;
            var ccp1 = {
                x: qcp0.x + (2 / 3) * (qcp1.x - qcp0.x),
                y: qcp0.y + (2 / 3) * (qcp1.y - qcp0.y),
            };
            var ccp2 = {
                x: qcp2.x + (2 / 3) * (qcp1.x - qcp2.x),
                y: qcp2.y + (2 / 3) * (qcp1.y - qcp2.y),
            };
            var ccp3 = qcp2;
            return new BezierCalculator(this.id, svgChar, ccp0, ccp1, ccp2, ccp3);
        }
        return new (BezierCalculator.bind.apply(BezierCalculator, [void 0, this.id, svgChar].concat(this.points)))();
    };
    BezierCalculator.prototype.findTimeByDistance = function (distance) {
        if (distance < 0 || distance > 1) {
            console.warn('distance must be a number between 0 and 1.');
        }
        if (distance === 0 || distance === 1) {
            return distance;
        }
        var originalDistance = distance;
        var epsilon = 0.001;
        var maxDepth = -100;
        var lowToHighRatio = distance / (1 - distance);
        var step = -2;
        while (step > maxDepth) {
            var split = this.bezierJs.split(distance);
            var low = split.left.length();
            var high = split.right.length();
            var diff = low - lowToHighRatio * high;
            if (Math.abs(diff) < epsilon) {
                // We found a satisfactory midpoint t value.
                break;
            }
            // Jump half the t-distance in the direction of the bias.
            step = step - 1;
            distance += (diff > 0 ? -1 : 1) * Math.pow(2, step);
        }
        if (step === maxDepth) {
            // TODO: handle degenerate curves!!!!!
            console.warn('Could not find the midpoint for: ', this.svgChar + " " + this.points.toString());
            return originalDistance;
        }
        return distance;
    };
    BezierCalculator.prototype.toCommand = function () {
        return new CommandBuilder(this.svgChar, this.points.slice()).setId(this.id).build();
    };
    BezierCalculator.prototype.getBoundingBox = function () {
        if (this.bbox === undefined) {
            var bbox = this.bezierJs.bbox();
            this.bbox = {
                x: { min: bbox.x.min, max: bbox.x.max },
                y: { min: bbox.y.min, max: bbox.y.max },
            };
        }
        return this.bbox;
    };
    BezierCalculator.prototype.intersects = function (line) {
        if (MathUtil.arePointsEqual(_.first(this.points), _.last(this.points))) {
            // Points can't be intersected.
            return [];
        }
        return this.bezierJs.intersects(line);
    };
    return BezierCalculator;
}());
export { BezierCalculator };
