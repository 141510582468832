import * as tslib_1 from "tslib";
import * as _ from 'lodash';
export function CanvasLayoutMixin(Base) {
    if (Base === void 0) { Base = /** @class */ (function () {
        function class_1() {
        }
        return class_1;
    }()); }
    return /** @class */ (function (_super) {
        tslib_1.__extends(class_2, _super);
        function class_2() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.bounds = { w: 24, h: 24 };
            _this.viewport = { w: 24, h: 24 };
            _this.zoom = 1;
            _this.translation = { tx: 0, ty: 0 };
            return _this;
        }
        Object.defineProperty(class_2.prototype, "cssScale", {
            /**
             * The 'cssScale' represents the number of CSS pixels per SVG viewport pixel.
             */
            get: function () {
                var _a = this.getViewport(), vWidth = _a.w, vHeight = _a.h;
                var _b = this.getBounds(), bWidth = _b.w, bHeight = _b.h;
                var vectorAspectRatio = vWidth / vHeight;
                var containerAspectRatio = bWidth / bHeight;
                if (vectorAspectRatio > containerAspectRatio) {
                    return bWidth / vWidth;
                }
                else {
                    return bHeight / vHeight;
                }
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(class_2.prototype, "attrScale", {
            /**
             * The 'attrScale' represents the number of physical pixels per SVG viewport pixel.
             */
            get: function () {
                return this.cssScale * devicePixelRatio;
            },
            enumerable: true,
            configurable: true
        });
        class_2.prototype.getBounds = function () {
            return this.bounds;
        };
        class_2.prototype.getViewport = function () {
            return this.viewport;
        };
        class_2.prototype.getZoom = function () {
            return this.zoom;
        };
        class_2.prototype.getTranslation = function () {
            return this.translation;
        };
        class_2.prototype.setDimensions = function (bounds, viewport) {
            if (!_.isEqual(this.bounds, bounds) || !_.isEqual(this.viewport, viewport)) {
                this.bounds = bounds;
                this.viewport = viewport;
                this.onDimensionsChanged(this.bounds, this.viewport);
            }
        };
        class_2.prototype.onDimensionsChanged = function (bounds, viewport) { };
        class_2.prototype.setZoomPan = function (zoom, translation) {
            if (this.zoom !== zoom || !_.isEqual(this.translation, translation)) {
                this.zoom = zoom;
                this.translation = translation;
                this.onZoomPanChanged(zoom, translation);
            }
        };
        class_2.prototype.onZoomPanChanged = function (zoom, translation) { };
        return class_2;
    }(Base));
}
