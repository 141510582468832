import * as tslib_1 from "tslib";
import { LayerUtil } from 'app/modules/editor/model/layers';
import { Path } from 'app/modules/editor/model/paths';
import { TransformUtil } from 'app/modules/editor/scripts/common';
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { PaperUtil } from 'app/modules/editor/scripts/paper/util';
import * as paper from 'paper';
/**
 * A gesture that performs transform operations.
 *
 * Preconditions:
 * - The user is in default mode.
 * - One or more paths are selected.
 * - A mouse down event occurred on a selection bounds handle.
 *
 * TODO: finish this
 * TODO: fix crash that can occur when 3+ points are on same axis
 * TODO: could this work with generic items (not just paths)?
 * TODO: we need to also filter out non-empty groups (see PaperLayer.ts)
 */
var TransformPathsGesture = /** @class */ (function (_super) {
    tslib_1.__extends(TransformPathsGesture, _super);
    function TransformPathsGesture(ps, selectionBoundsRaster) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.selectionBoundsRaster = selectionBoundsRaster;
        _this.pl = paper.project.activeLayer;
        return _this;
    }
    // @Override
    TransformPathsGesture.prototype.onMouseDown = function (event) {
        var _this = this;
        this.ps.setHoveredLayerId(undefined);
        this.selectedItems = Array.from(this.ps.getSelectedLayerIds()).map(function (id) { return _this.pl.findItemByLayerId(id); });
        var invertedPaperLayerMatrix = this.pl.matrix.inverted();
        this.localToVpItemMatrices = this.selectedItems.map(function (item) {
            // Compute the matrices to directly transform during drag events.
            return item.globalMatrix.prepended(invertedPaperLayerMatrix).inverted();
        });
        this.vpBounds = PaperUtil.transformRectangle(PaperUtil.computeBounds(this.selectedItems), this.pl.matrix.inverted());
        this.vpDownPoint = this.vpBounds[this.selectionBoundsRaster.pivotType];
        this.vpPoint = this.vpDownPoint;
        this.initialVectorLayer = this.ps.getVectorLayer();
    };
    // @Override
    TransformPathsGesture.prototype.onMouseDrag = function (event) {
        this.vpPoint = this.pl.globalToLocal(event.point);
        this.processEvent(event);
    };
    // @Override
    TransformPathsGesture.prototype.onKeyDown = function (event) {
        this.processKeyEvent(event);
    };
    // @Override
    TransformPathsGesture.prototype.onKeyUp = function (event) {
        this.processKeyEvent(event);
    };
    TransformPathsGesture.prototype.processKeyEvent = function (event) {
        if (event.key === 'command') {
            this.processEvent(event);
        }
    };
    TransformPathsGesture.prototype.processEvent = function (event) {
        var _this = this;
        if (!this.vpPoint) {
            return;
        }
        var sourcePoints = [
            this.vpBounds.topLeft,
            this.vpBounds.topRight,
            this.vpBounds.bottomRight,
            this.vpBounds.bottomLeft,
        ].map(function (_a) {
            var x = _a.x, y = _a.y;
            return [x, y];
        });
        var targetPoints = sourcePoints.slice();
        var vpPoint = [this.vpPoint.x, this.vpPoint.y];
        switch (this.selectionBoundsRaster.pivotType) {
            case 'topLeft':
                targetPoints[0] = vpPoint;
                break;
            case 'topRight':
                targetPoints[1] = vpPoint;
                break;
            case 'bottomRight':
                targetPoints[2] = vpPoint;
                break;
            case 'bottomLeft':
                targetPoints[3] = vpPoint;
                break;
        }
        var distortFn = TransformUtil.distort(sourcePoints, targetPoints);
        var newVl = this.initialVectorLayer.clone();
        this.selectedItems.forEach(function (item, index) {
            // TODO: make this stuff works for groups as well
            var path = item.clone();
            var localToViewportMatrix = _this.localToVpItemMatrices[index];
            var pathDistortFn = function (point) {
                point = localToViewportMatrix.transform(point);
                var intermediatePoint = distortFn([point.x, point.y]);
                point = new paper.Point(intermediatePoint[0], intermediatePoint[1]);
                point = localToViewportMatrix.inverted().transform(point);
                return point;
            };
            path.segments.forEach(function (segment) {
                if (segment.handleIn) {
                    segment.handleIn = pathDistortFn(segment.point.add(segment.handleIn)).subtract(segment.point);
                }
                if (segment.handleOut) {
                    segment.handleOut = pathDistortFn(segment.point.add(segment.handleOut)).subtract(segment.point);
                }
                segment.point = pathDistortFn(segment.point);
            });
            var newPl = newVl.findLayerById(item.data.id).clone();
            newPl.pathData = new Path(path.pathData);
            newVl = LayerUtil.replaceLayer(newVl, item.data.id, newPl);
        });
        this.ps.setVectorLayer(newVl);
    };
    return TransformPathsGesture;
}(Gesture));
export { TransformPathsGesture };
