import * as tslib_1 from "tslib";
import { NameProperty, NumberProperty, Property, } from 'app/modules/editor/model/properties';
import * as _ from 'lodash';
import { AnimationBlock } from './AnimationBlock';
/**
 * An animation represents a collection of layer property tweens for a VectorLayer.
 * An animation has an ID and a duration, as well as a list of AnimationBlocks that
 * each target a single layer in the vector. An animation is structured as an
 * AnimatedVectorDrawable, with the targets being AnimationBlocks.
 */
var Animation = /** @class */ (function () {
    function Animation(obj) {
        if (obj === void 0) { obj = {}; }
        this.id = obj.id || _.uniqueId();
        this.name = obj.name || 'anim';
        this.blocks = (obj.blocks || []).map(function (block) { return AnimationBlock.from(block); });
        this.duration = obj.duration || 300;
    }
    Animation_1 = Animation;
    Animation.prototype.clone = function () {
        var clone = new Animation_1(this);
        clone.blocks = clone.blocks.slice();
        return clone;
    };
    Animation.prototype.toJSON = function () {
        return {
            id: this.id,
            name: this.name,
            duration: this.duration,
            blocks: this.blocks.map(function (block) { return block.toJSON(); }),
        };
    };
    var Animation_1;
    Animation = Animation_1 = tslib_1.__decorate([
        Property.register(new NameProperty('name'), new NumberProperty('duration', { min: 100, max: 60000 })),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], Animation);
    return Animation;
}());
export { Animation };
