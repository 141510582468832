import * as tslib_1 from "tslib";
import { INTERPOLATORS } from 'app/modules/editor/model/interpolators';
import { ModelUtil } from 'app/modules/editor/scripts/common';
import * as _ from 'lodash';
var DEFAULT_LAYER_PROPERTY_STATE = {
    activeBlock: undefined,
    interpolatedValue: false,
};
/**
 * A simple class that takes a VectorLayer and an animation and outputs a new
 * rendered VectorLayer given a specific time.
 */
var AnimationRenderer = /** @class */ (function () {
    function AnimationRenderer(originalVectorLayer, activeAnimation) {
        var _this = this;
        // Keys are layerIds and values are RenderedData objects.
        this.animDataByLayer = {};
        // TODO: technically this could be more performant if we only cloned the affected layers
        this.renderedVectorLayer = originalVectorLayer.deepClone();
        var animDataByLayer = ModelUtil.getOrderedBlocksByPropertyByLayer(activeAnimation);
        Object.keys(animDataByLayer).forEach(function (layerId) {
            _this.animDataByLayer[layerId] = {
                originalLayer: originalVectorLayer.findLayerById(layerId),
                renderedLayer: _this.renderedVectorLayer.findLayerById(layerId),
                orderedBlocks: animDataByLayer[layerId],
            };
        });
        this.setCurrentTime(0);
    }
    /**
     * Returns a rendered vector layer given a specific time. The time must be
     * non-negative and must be less than the animation's duration. The returned
     * vector layer should not be mutated externally, as it will be cached and
     * returned on subsequent time frames.
     */
    AnimationRenderer.prototype.setCurrentTime = function (timeMillis) {
        var _this = this;
        Object.keys(this.animDataByLayer).forEach(function (layerId) {
            var animData = _this.animDataByLayer[layerId];
            animData.cachedState = animData.cachedState || {};
            Object.keys(animData.orderedBlocks).forEach(function (propertyName) {
                var blocks = animData.orderedBlocks[propertyName];
                var _ar = tslib_1.__assign({}, DEFAULT_LAYER_PROPERTY_STATE);
                // Compute the rendered value at the given time.
                var property = animData.originalLayer.animatableProperties.get(propertyName);
                var value = animData.originalLayer[propertyName];
                var _loop_1 = function (block) {
                    if (timeMillis < block.startTime) {
                        return "break";
                    }
                    if (timeMillis < block.endTime) {
                        var f = (timeMillis - block.startTime) / (block.endTime - block.startTime);
                        // TODO: this is a bit hacky... no need to perform a search every time.
                        var interpolatorFn = _.find(INTERPOLATORS, function (i) { return i.value === block.interpolator; })
                            .interpolateFn;
                        value = property.interpolateValue(block.fromValue, block.toValue, interpolatorFn(f));
                        _ar.activeBlock = block;
                        _ar.interpolatedValue = true;
                        return "break";
                    }
                    value = block.toValue;
                    _ar.activeBlock = block;
                };
                for (var _i = 0, blocks_1 = blocks; _i < blocks_1.length; _i++) {
                    var block = blocks_1[_i];
                    var state_1 = _loop_1(block);
                    if (state_1 === "break")
                        break;
                }
                animData.renderedLayer[propertyName] = value;
                // Cached data.
                animData.cachedState[propertyName] =
                    animData.cachedState[propertyName] || {};
                animData.cachedState[propertyName] = _ar;
            });
        });
        return this.renderedVectorLayer;
    };
    return AnimationRenderer;
}());
export { AnimationRenderer };
