import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit, } from '@angular/core';
import { Animation } from 'app/modules/editor/model/timeline';
import { Dragger } from 'app/modules/editor/scripts/dragger';
import { DestroyableMixin } from 'app/modules/editor/scripts/mixins';
import { ShortcutService, ThemeService } from 'app/modules/editor/services';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';
import { TIMELINE_ANIMATION_PADDING } from './constants';
var HEADER_HEIGHT = 40;
var GRID_INTERVALS_MS = [10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000, 30000, 60000];
var LayerTimelineGridDirective = /** @class */ (function (_super) {
    tslib_1.__extends(LayerTimelineGridDirective, _super);
    function LayerTimelineGridDirective(elementRef, themeService) {
        var _this = _super.call(this) || this;
        _this.themeService = themeService;
        _this.scrub = new EventEmitter();
        _this.canvas = elementRef.nativeElement;
        _this.$canvas = $(_this.canvas);
        return _this;
    }
    LayerTimelineGridDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.registerSubscription(this.themeService
            .asObservable()
            .pipe(filter(function (t) { return !t.isInitialPageLoad; }))
            .subscribe(function (t) { return _this.redraw(); }));
    };
    Object.defineProperty(LayerTimelineGridDirective.prototype, "horizZoom", {
        get: function () {
            return this.horizZoom_;
        },
        set: function (horizZoom) {
            if (this.horizZoom_ !== horizZoom) {
                this.horizZoom_ = horizZoom;
                this.redraw();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LayerTimelineGridDirective.prototype, "currentTime", {
        get: function () {
            return this.currentTime_;
        },
        set: function (currentTime) {
            if (this.currentTime_ !== currentTime) {
                this.currentTime_ = currentTime;
                this.redraw();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LayerTimelineGridDirective.prototype, "animation", {
        get: function () {
            return this.animation_;
        },
        set: function (animation) {
            this.animation_ = animation;
            this.redraw();
        },
        enumerable: true,
        configurable: true
    });
    LayerTimelineGridDirective.prototype.onMouseDown = function (event) {
        var _this = this;
        this.handleScrubEvent(event.clientX, ShortcutService.isOsDependentModifierKey(event));
        // tslint:disable-next-line: no-unused-expression
        new Dragger({
            direction: 'horizontal',
            downX: event.clientX,
            downY: event.clientY,
            shouldSkipSlopCheck: true,
            onDragFn: function (e) { return _this.handleScrubEvent(e.clientX, ShortcutService.isOsDependentModifierKey(e)); },
        });
        event.preventDefault();
        return false;
    };
    LayerTimelineGridDirective.prototype.handleScrubEvent = function (clientX, disableSnap) {
        var x = clientX - this.$canvas.offset().left;
        var time = ((x - TIMELINE_ANIMATION_PADDING) / (this.$canvas.width() - TIMELINE_ANIMATION_PADDING * 2)) *
            this.animation.duration;
        time = _.clamp(time, 0, this.animation.duration);
        this.scrub.emit({ time: time, disableSnap: disableSnap });
    };
    LayerTimelineGridDirective.prototype.redraw = function () {
        if (!this.$canvas.is(':visible')) {
            return;
        }
        var width = this.$canvas.width();
        var height = this.$canvas.height();
        this.$canvas.attr('width', width * window.devicePixelRatio);
        this.$canvas.attr('height', height * window.devicePixelRatio);
        var ctx = this.canvas.getContext('2d');
        ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
        ctx.translate(TIMELINE_ANIMATION_PADDING, 0);
        // Compute grid spacing (40 = minimum grid spacing in pixels).
        var interval = 0;
        var spacingMs = GRID_INTERVALS_MS[interval];
        while (spacingMs * this.horizZoom < 40 || interval >= GRID_INTERVALS_MS.length) {
            interval++;
            spacingMs = GRID_INTERVALS_MS[interval];
        }
        var spacingPx = spacingMs * this.horizZoom;
        if (this.isHeader) {
            // Text labels.
            ctx.fillStyle = this.themeService.getSecondaryTextColor();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = '10px Roboto';
            for (var x = 0, t = 0; round(x) <= round(width); x += spacingPx, t += spacingMs) {
                ctx.fillText(t / 1000 + "s", x, height / 2);
            }
            ctx.fillStyle = 'rgba(244, 67, 54, .7)';
            ctx.beginPath();
            ctx.arc(this.currentTime * this.horizZoom, height / 2, 4, 0, 2 * Math.PI, false);
            ctx.fill();
            ctx.closePath();
            ctx.fillRect(this.currentTime * this.horizZoom - 1, height / 2 + 4, 2, height);
        }
        else {
            // Grid lines.
            ctx.fillStyle = this.themeService.getDividerTextColor();
            for (var x = spacingPx; round(x) < round(width - TIMELINE_ANIMATION_PADDING * 2); x += spacingPx) {
                ctx.fillRect(x - 0.5, HEADER_HEIGHT, 1, height - HEADER_HEIGHT);
            }
            ctx.fillStyle = 'rgba(244, 67, 54, .7)';
            ctx.fillRect(this.currentTime * this.horizZoom - 1, HEADER_HEIGHT, 2, height - HEADER_HEIGHT);
        }
    };
    LayerTimelineGridDirective.prototype.onClick = function (event) {
        // This ensures that click events originating on top of the
        // host element aren't triggered in the component.
        event.stopPropagation();
    };
    return LayerTimelineGridDirective;
}(DestroyableMixin()));
export { LayerTimelineGridDirective };
function round(n) {
    return _.round(n, 8);
}
