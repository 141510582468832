import { CommandBuilder } from 'app/modules/editor/model/paths/Command';
import { MathUtil } from 'app/modules/editor/scripts/common';
import * as _ from 'lodash';
import { PointCalculator } from './PointCalculator';
var ROUNDING_PRECISION = 10;
var LineCalculator = /** @class */ (function () {
    function LineCalculator(id, svgChar, p1, p2) {
        this.id = id;
        this.svgChar = svgChar;
        this.p1 = p1;
        this.p2 = p2;
    }
    LineCalculator.prototype.getPathLength = function () {
        return MathUtil.distance(this.p1, this.p2);
    };
    LineCalculator.prototype.getPointAtLength = function (distance) {
        var t = distance / this.getPathLength();
        var _a = this.p1, x1 = _a.x, y1 = _a.y;
        var _b = this.p2, x2 = _b.x, y2 = _b.y;
        return {
            x: MathUtil.lerp(x1, x2, t),
            y: MathUtil.lerp(y1, y2, t),
        };
    };
    LineCalculator.prototype.project = function (_a) {
        var x = _a.x, y = _a.y;
        var _b = this.p1, x1 = _b.x, y1 = _b.y;
        var _c = this.p2, x2 = _c.x, y2 = _c.y;
        var a = x2 - x1;
        var b = y2 - y1;
        var dot = (x - x1) * a + (y - y1) * b;
        var lenSq = round(a * a + b * b);
        var param = lenSq === 0 ? -1 : round(dot / lenSq);
        var xx;
        var yy;
        if (param < 0) {
            xx = x1;
            yy = y1;
        }
        else if (param > 1) {
            xx = x2;
            yy = y2;
        }
        else {
            xx = x1 + param * a;
            yy = y1 + param * b;
        }
        var dx = x - xx;
        var dy = y - yy;
        var dd = Math.sqrt(dx * dx + dy * dy);
        var dt;
        var rx1 = round(x1);
        var rx2 = round(x2);
        var ry1 = round(y1);
        var ry2 = round(y2);
        if (rx2 !== rx1) {
            dt = (xx - x1) / (x2 - x1);
        }
        else if (ry2 !== ry1) {
            dt = (yy - y1) / (y2 - y1);
        }
        else {
            dt = 0.5;
        }
        return { x: round(xx), y: round(yy), d: round(dd), t: round(dt) };
    };
    LineCalculator.prototype.split = function (t1, t2) {
        var _a = this.p1, x1 = _a.x, y1 = _a.y;
        var _b = this.p2, x2 = _b.x, y2 = _b.y;
        var p1 = { x: MathUtil.lerp(x1, x2, t1), y: MathUtil.lerp(y1, y2, t1) };
        var p2 = { x: MathUtil.lerp(x1, x2, t2), y: MathUtil.lerp(y1, y2, t2) };
        if (MathUtil.arePointsEqual(p1, p2)) {
            return new PointCalculator(this.id, this.svgChar, p1);
        }
        return new LineCalculator(this.id, this.svgChar, p1, p2);
    };
    LineCalculator.prototype.convert = function (svgChar) {
        return new LineCalculator(this.id, svgChar, this.p1, this.p2);
    };
    LineCalculator.prototype.findTimeByDistance = function (distance) {
        return distance;
    };
    LineCalculator.prototype.toCommand = function () {
        var points;
        switch (this.svgChar) {
            case 'L':
            case 'Z':
                points = [this.p1, this.p2];
                break;
            case 'Q':
                var cp = {
                    x: MathUtil.lerp(this.p1.x, this.p2.x, 0.5),
                    y: MathUtil.lerp(this.p1.y, this.p2.y, 0.5),
                };
                points = [this.p1, cp, this.p2];
                break;
            case 'C':
                var cp1 = {
                    x: MathUtil.lerp(this.p1.x, this.p2.x, 1 / 3),
                    y: MathUtil.lerp(this.p1.y, this.p2.y, 1 / 3),
                };
                var cp2 = {
                    x: MathUtil.lerp(this.p1.x, this.p2.x, 2 / 3),
                    y: MathUtil.lerp(this.p1.y, this.p2.y, 2 / 3),
                };
                points = [this.p1, cp1, cp2, this.p2];
                break;
            default:
                throw new Error('Invalid command type: ' + this.svgChar);
        }
        return new CommandBuilder(this.svgChar, points).setId(this.id).build();
    };
    LineCalculator.prototype.getBoundingBox = function () {
        var minx = Math.min(this.p1.x, this.p2.x);
        var miny = Math.min(this.p1.y, this.p2.y);
        var maxx = Math.max(this.p1.x, this.p2.x);
        var maxy = Math.max(this.p1.y, this.p2.y);
        return { x: { min: minx, max: maxx }, y: { min: miny, max: maxy } };
    };
    LineCalculator.prototype.intersects = function (line) {
        if (MathUtil.arePointsEqual(this.p1, this.p2)) {
            // Points can't be intersected.
            return [];
        }
        // Check to see if the line from (a,b) to (c,d) intersects
        // with the line from (p,q) to (r,s).
        var _a = this.p1, a = _a.x, b = _a.y;
        var _b = this.p2, c = _b.x, d = _b.y;
        var _c = line.p1, p = _c.x, q = _c.y, _d = line.p2, r = _d.x, s = _d.y;
        var det = round((c - a) * (s - q) - (r - p) * (d - b));
        if (det === 0) {
            // Then the two lines are parallel. In our case it is fine to
            // return an empty list, even though the lines may technically
            // be collinear.
            return [];
        }
        else {
            var t = round(((s - q) * (r - a) + (p - r) * (s - b)) / det);
            var u = round(((b - d) * (r - a) + (c - a) * (s - b)) / det);
            return 0 <= t && t <= 1 && (0 <= u && u <= 1) ? [t] : [];
        }
    };
    return LineCalculator;
}());
export { LineCalculator };
function round(num) {
    return _.round(num, ROUNDING_PRECISION);
}
