import * as tslib_1 from "tslib";
import { MathUtil } from 'app/modules/editor/scripts/common';
import * as _ from 'lodash';
import { CONSTANTS, DIRECTIONS } from './Constants';
import { SnapBounds } from './SnapBounds';
// TODO: make sure to test things with different stroke width values!
var SNAP_TOLERANCE_PIXELS = 10;
/**
 * A helper function that snaps the given dragged snap points to each of its siblings.
 *
 * TODO: make it possible to create rulers optionally only if a flag is passed
 */
export function computeSnapInfo(dragSnapPoints, siblingSnapPointsTable, snapToDimensions) {
    if (snapToDimensions === void 0) { snapToDimensions = false; }
    var dsb = new (SnapBounds.bind.apply(SnapBounds, [void 0].concat(dragSnapPoints)))();
    var ssbs = siblingSnapPointsTable.map(function (pts) { return new (SnapBounds.bind.apply(SnapBounds, [void 0].concat(pts)))(); });
    var _a = snapToSiblings(dsb, ssbs, snapToDimensions), horizontal = _a.horizontal, vertical = _a.vertical;
    var isHorizontalHit = Math.abs(horizontal.delta) <= SNAP_TOLERANCE_PIXELS;
    var horizontalDelta = isHorizontalHit ? horizontal.delta : Infinity;
    var horizontalValues = isHorizontalHit ? horizontal.values : [];
    var isVerticalHit = Math.abs(vertical.delta) <= SNAP_TOLERANCE_PIXELS;
    var verticalDelta = isVerticalHit ? vertical.delta : Infinity;
    var verticalValues = isVerticalHit ? vertical.values : [];
    var snapInfo = {
        horizontal: { delta: horizontalDelta, values: horizontalValues },
        vertical: { delta: verticalDelta, values: verticalValues },
    };
    var projSnapDelta = {
        x: isFinite(horizontalDelta) ? -horizontalDelta : 0,
        y: isFinite(verticalDelta) ? -verticalDelta : 0,
    };
    return {
        projSnapDelta: projSnapDelta,
        guides: buildGuides(snapInfo),
        rulers: buildRulers(snapInfo),
    };
}
/** Snaps the dragged item to each of its sibling snap items. */
function snapToSiblings(dsb, ssbs, snapToDimensions) {
    // Compute a list of sibling snap results, where each entry represents a snapping
    // between two snap bounds in both directions.
    var ssrs = ssbs.map(function (ssb) {
        // For each direction, return an entry consisting of:
        // - dsb: the drag snap bounds
        // - ssb: the sibling snap bounds
        // - delta: the minimum delta value that would snap the two bounds
        // - values: a list of snap pairs that computed the above delta value
        return {
            horizontal: tslib_1.__assign({ dsb: dsb, ssb: ssb }, runSnapTest(dsb, ssb, snapToDimensions, 'horizontal')),
            vertical: tslib_1.__assign({ dsb: dsb, ssb: ssb }, runSnapTest(dsb, ssb, snapToDimensions, 'vertical')),
        };
    });
    return {
        horizontal: filterByMinDelta(ssrs.map(function (r) { return r.horizontal; })),
        vertical: filterByMinDelta(ssrs.map(function (r) { return r.vertical; })),
    };
}
/**
 * Runs a snap test for two snap bounds. The return result consists of (1) the
 * minimum delta value found, and (2) a list of the values that had the specified
 * delta value.
 */
function runSnapTest(dsb, ssb, snapToDimensions, dir) {
    var coord = CONSTANTS[dir].coord;
    var snapPairResults = [];
    if (snapToDimensions) {
        var getSnapBoundsSize = function (sb) {
            var min = _.minBy(sb.snapPoints, function (p) { return p[coord]; })[coord];
            var max = _.maxBy(sb.snapPoints, function (p) { return p[coord]; })[coord];
            return max - min;
        };
        var dsbSize = getSnapBoundsSize(dsb);
        var ssbSize = getSnapBoundsSize(ssb);
        // TODO: improve this snap pair API stuff?
        snapPairResults.push({
            isDimensionSnap: true,
            delta: MathUtil.round(dsbSize - ssbSize),
        });
    }
    else {
        dsb.snapPoints.forEach(function (dragPoint, dragIndex) {
            ssb.snapPoints.forEach(function (siblingPoint, siblingIndex) {
                var delta = MathUtil.round(dragPoint[coord] - siblingPoint[coord]);
                snapPairResults.push({ dragIndex: dragIndex, siblingIndex: siblingIndex, delta: delta });
            });
        });
    }
    return filterByMinDelta(snapPairResults);
}
/**
 * Filters the array of items, keeping the smallest delta values and
 * discarding the rest.
 */
function filterByMinDelta(list) {
    if (!list.length) {
        return undefined;
    }
    var _a = list.reduce(function (prev, curr) {
        var info = {
            min: Math.abs(curr.delta),
            pos: curr.delta >= 0 ? [curr] : [],
            neg: curr.delta >= 0 ? [] : [curr],
        };
        if (prev.min === info.min) {
            return {
                min: prev.min,
                pos: prev.pos.concat(info.pos),
                neg: prev.neg.concat(info.neg),
            };
        }
        return prev.min < info.min ? prev : info;
    }, { min: Infinity, pos: [], neg: [] }), min = _a.min, pos = _a.pos, neg = _a.neg;
    var isDeltaPositive = pos.length >= neg.length;
    return {
        delta: min * (isDeltaPositive ? 1 : -1),
        values: isDeltaPositive ? pos : neg,
    };
}
/**
 * Builds the snap guides to draw given a snap info object.
 * This function assumes the global project coordinate space.
 */
function buildGuides(snapInfo) {
    var guides = [];
    DIRECTIONS.forEach(function (d) { return guides.push.apply(guides, buildGuidesInDirection(snapInfo, d)); });
    return guides;
}
function buildGuidesInDirection(snapInfo, dir) {
    var guides = [];
    var _a = CONSTANTS[dir], coord = _a.coord, opp = _a.opp;
    snapInfo[dir].values.forEach(function (_a) {
        var dsb = _a.dsb, ssb = _a.ssb, values = _a.values;
        var firstGuideSnap = _.find(values, function (_a) {
            var dragIndex = _a.dragIndex, siblingIndex = _a.siblingIndex;
            return dragIndex >= 0 && siblingIndex >= 0;
        });
        if (firstGuideSnap) {
            var startMostBounds = dsb[opp.start] < ssb[opp.start] ? dsb : ssb;
            var endMostBounds = dsb[opp.end] < ssb[opp.end] ? ssb : dsb;
            var startGuide = startMostBounds[opp.start];
            var endGuide = endMostBounds[opp.end];
            var coordGuide = ssb.snapPoints[firstGuideSnap.siblingIndex][coord];
            if (dir === 'horizontal') {
                var from = { x: coordGuide, y: startGuide };
                var to = { x: coordGuide, y: endGuide };
                guides.push({ from: from, to: to });
            }
            else {
                var from = { x: startGuide, y: coordGuide };
                var to = { x: endGuide, y: coordGuide };
                guides.push({ from: from, to: to });
            }
        }
    });
    return guides;
}
/**
 * Builds the snap rulers to draw given a snap info object.
 * This function assumes the global project coordinate space.
 */
function buildRulers(snapInfo, snapToDimensions) {
    if (snapToDimensions === void 0) { snapToDimensions = false; }
    var rulers = [];
    DIRECTIONS.forEach(function (d) { return rulers.push.apply(rulers, buildRulersInDirection(snapInfo, snapToDimensions, d)); });
    return rulers;
}
// TODO: make sure that only one ruler total is made for the drag bounds!
// TODO: make sure that only one ruler total is made for the drag bounds!
// TODO: make sure that only one ruler total is made for the drag bounds!
// TODO: make sure that only one ruler total is made for the drag bounds!
// TODO: make sure that only one ruler total is made for the drag bounds!
function buildRulersInDirection(snapInfo, snapToDimensions, dir) {
    if (snapToDimensions === void 0) { snapToDimensions = false; }
    var rulers = [];
    snapInfo[dir].values.forEach(function (_a) {
        var dsb = _a.dsb, ssb = _a.ssb, values = _a.values;
        var dimensionSnaps = values.filter(function (_a) {
            var isDimensionSnap = _a.isDimensionSnap;
            return isDimensionSnap;
        });
        if (dimensionSnaps.length) {
            var createRulerFn = function (sb) {
                var from = { x: sb.left, y: sb.top };
                var to = {
                    x: dir === 'horizontal' ? sb.right : sb.left,
                    y: dir === 'horizontal' ? sb.top : sb.bottom,
                };
                return { from: from, to: to };
            };
            rulers.push(createRulerFn(dsb));
            rulers.push(createRulerFn(ssb));
        }
    });
    for (var _i = 0, _a = snapInfo[dir].values; _i < _a.length; _i++) {
        var _b = _a[_i], dsb = _b.dsb, ssb = _b.ssb, values = _b.values;
        var _c = CONSTANTS[dir], start = _c.start, end = _c.end, opp = _c.opp;
        var oppStartMostBounds = dsb[opp.start] < ssb[opp.start] ? dsb : ssb;
        var oppEndMostBounds = dsb[opp.end] < ssb[opp.end] ? ssb : dsb;
        var nonStartMostBounds = dsb[start] < ssb[start] ? ssb : dsb;
        var nonEndMostBounds = dsb[end] < ssb[end] ? dsb : ssb;
        var guideSnaps = values.filter(function (_a) {
            var isDimensionSnap = _a.isDimensionSnap;
            return !isDimensionSnap;
        });
        if (guideSnaps.length) {
            var oppStartRuler = oppStartMostBounds[opp.end];
            var oppEndRuler = oppEndMostBounds[opp.start];
            var startRuler = nonStartMostBounds[start];
            var endRuler = nonEndMostBounds[end];
            // TODO: handle the horizontal 'rulerLeft === rulerRight' case like sketch does
            // TODO: handle the vertical 'rulerTop === rulerBottom' case like sketch does
            var coordRuler = startRuler + (endRuler - startRuler) * 0.5;
            var from = {
                x: dir === 'horizontal' ? coordRuler : oppStartRuler,
                y: dir === 'horizontal' ? oppStartRuler : coordRuler,
            };
            var to = {
                x: dir === 'horizontal' ? coordRuler : oppEndRuler,
                y: dir === 'horizontal' ? oppEndRuler : coordRuler,
            };
            var guideDelta = to[opp.coord] - from[opp.coord];
            if (guideDelta > SNAP_TOLERANCE_PIXELS) {
                // Don't show a ruler if the items have been snapped (we assume that if
                // the delta values is less than the snap tolerance, then it would have
                // previously been snapped in the canvas such that the delta is now 0).
                rulers.push({ from: from, to: to });
            }
            break;
        }
    }
    var minDistsDragToSibling = [];
    var minDistsSiblingToSibling = [];
    // TODO: make it clear that there should only be one dsb in the snap info object?
    var dsbs = snapInfo[dir].values.map(function (_a) {
        var dsb = _a.dsb;
        return dsb;
    });
    var ssbs = snapInfo[dir].values.map(function (_a) {
        var ssb = _a.ssb;
        return ssb;
    });
    var numValues = snapInfo[dir].values.length;
    for (var i = 0; i < numValues; i++) {
        minDistsDragToSibling.push(tslib_1.__assign({ sb1: dsbs[i], sb2: ssbs[i] }, dsbs[i].distance(ssbs[i])));
    }
    for (var i = 0; i < numValues - 1; i++) {
        var minSsb = void 0;
        var minLine = void 0;
        var minDist = Infinity;
        for (var j = i + 1; j < numValues; j++) {
            var _d = ssbs[i].distance(ssbs[j]), line = _d.line, dist = _d.dist;
            var absDist = Math.abs(dist);
            if (absDist < minDist) {
                minSsb = ssbs[j];
                minLine = line;
                minDist = absDist;
            }
        }
        minDistsSiblingToSibling.push({ sb1: ssbs[i], sb2: minSsb, line: minLine, dist: minDist });
    }
    var minDistDragToSibling = _.minBy(minDistsDragToSibling, function (d) { return d.dist; });
    var matchingMinDistsSiblingToSibling = minDistsSiblingToSibling.filter(function (d) {
        return Math.abs(minDistDragToSibling.dist - d.dist) <= SNAP_TOLERANCE_PIXELS;
    });
    if (matchingMinDistsSiblingToSibling.length) {
        rulers.push(minDistDragToSibling.line);
        rulers.push.apply(rulers, matchingMinDistsSiblingToSibling.map(function (d) { return d.line; }));
    }
    return rulers;
}
