import { Command } from './Command';
/**
 * Takes an SVG path string (i.e. the text specified in the path's 'd' attribute) and returns
 * list of Commands that represent the SVG path's individual sequence of instructions.
 * Arcs are converted to bezier curves because they make life too complicated. :D
 */
export function parseCommands(pathData) {
    if (!pathData) {
        pathData = '';
    }
    pathData = pathData.trim();
    var start = 0;
    var end = 1;
    var nodes = [];
    while (end < pathData.length) {
        end = nextStart(pathData, end);
        var s = pathData.substring(start, end).trim();
        if (s.length > 0) {
            var val = getFloats(s);
            nodes.push({ type: s.charAt(0), params: val });
        }
        start = end;
        end++;
    }
    if (end - start === 1 && start < pathData.length) {
        nodes.push({ type: pathData.charAt(start), params: [] });
    }
    var current = [0, 0, 0, 0, 0, 0];
    var previousCommand = 'm';
    var builder = new CommandsBuilder();
    for (var _i = 0, nodes_1 = nodes; _i < nodes_1.length; _i++) {
        var n = nodes_1[_i];
        addCommand(builder, current, previousCommand, n.type, n.params);
        previousCommand = n.type;
    }
    return builder.toCommands();
}
function nextStart(s, end) {
    while (end < s.length) {
        var c = s.charAt(end);
        // Note that 'e' or 'E' are not valid path commands, but could be used for floating
        // point numbers' scientific notation. Therefore, when searching for the next command,
        // we should ignore 'e' and 'E'.
        if ((('A' <= c && c <= 'Z') || ('a' <= c && c <= 'z')) && c !== 'E' && c !== 'e') {
            return end;
        }
        end++;
    }
    return end;
}
var ExtractFloatResult = /** @class */ (function () {
    function ExtractFloatResult() {
        this.mEndPosition = 0;
        this.mEndWithNegOrDot = false;
    }
    return ExtractFloatResult;
}());
function getFloats(s) {
    if (s.charAt(0) === 'z' || s.charAt(0) === 'Z') {
        return [];
    }
    var results = [];
    var startPosition = 1;
    var endPosition;
    var result = new ExtractFloatResult();
    var totalLength = s.length;
    while (startPosition < totalLength) {
        extract(s, startPosition, result);
        endPosition = result.mEndPosition;
        if (startPosition < endPosition) {
            results.push(parseFloat(s.substring(startPosition, endPosition)));
        }
        if (result.mEndWithNegOrDot) {
            startPosition = endPosition;
        }
        else {
            startPosition = endPosition + 1;
        }
    }
    return results;
}
/**
 * Calculate the position of the next comma or space or negative sign
 *
 * @param {string} s the string to search
 * @param {number} start the position to start searching
 * @param {ExtractFloatResult} result the result of the extraction, including the position of the the starting position
 * of next number, whether it is ending with a '-'.
 */
function extract(s, start, result) {
    var currentIndex = start;
    var foundSeparator = false;
    result.mEndWithNegOrDot = false;
    var secondDot = false;
    var isExponential = false;
    for (; currentIndex < s.length; currentIndex++) {
        var isPrevExponential = isExponential;
        isExponential = false;
        var currentChar = s.charAt(currentIndex);
        switch (currentChar) {
            case ' ':
            case ',':
                foundSeparator = true;
                break;
            case '-':
                if (currentIndex !== start && !isPrevExponential) {
                    foundSeparator = true;
                    result.mEndWithNegOrDot = true;
                }
                break;
            case '.':
                if (!secondDot) {
                    secondDot = true;
                }
                else {
                    foundSeparator = true;
                    result.mEndWithNegOrDot = true;
                }
                break;
            case 'e':
            case 'E':
                isExponential = true;
                break;
        }
        if (foundSeparator) {
            break;
        }
    }
    result.mEndPosition = currentIndex;
}
function addCommand(path, current, prevCmd, cmd, val) {
    var increment = 2;
    var currentX = current[0], currentY = current[1], ctrlPointX = current[2], ctrlPointY = current[3], currentSegmentStartX = current[4], currentSegmentStartY = current[5];
    var reflectiveCtrlPointX;
    var reflectiveCtrlPointY;
    switch (cmd) {
        case 'z':
        case 'Z':
            path.close();
            currentX = currentSegmentStartX;
            currentY = currentSegmentStartY;
            ctrlPointX = currentSegmentStartX;
            ctrlPointY = currentSegmentStartY;
            break;
        case 'm':
        case 'M':
        case 'l':
        case 'L':
        case 't':
        case 'T':
            increment = 2;
            break;
        case 'h':
        case 'H':
        case 'v':
        case 'V':
            increment = 1;
            break;
        case 'c':
        case 'C':
            increment = 6;
            break;
        case 's':
        case 'S':
        case 'q':
        case 'Q':
            increment = 4;
            break;
        case 'a':
        case 'A':
            increment = 7;
            break;
    }
    for (var k = 0; k < val.length; k += increment) {
        switch (cmd) {
            case 'm':
                currentX += val[k];
                currentY += val[k + 1];
                if (k > 0) {
                    path.rLineTo(val[k], val[k + 1]);
                }
                else {
                    path.rMoveTo(val[k], val[k + 1]);
                    currentSegmentStartX = currentX;
                    currentSegmentStartY = currentY;
                }
                break;
            case 'M':
                currentX = val[k];
                currentY = val[k + 1];
                if (k > 0) {
                    path.lineTo(val[k], val[k + 1]);
                }
                else {
                    path.moveTo(val[k], val[k + 1]);
                    currentSegmentStartX = currentX;
                    currentSegmentStartY = currentY;
                }
                break;
            case 'l':
                path.rLineTo(val[k], val[k + 1]);
                currentX += val[k];
                currentY += val[k + 1];
                break;
            case 'L':
                path.lineTo(val[k], val[k + 1]);
                currentX = val[k];
                currentY = val[k + 1];
                break;
            case 'h':
                path.rLineTo(val[k], 0);
                currentX += val[k];
                break;
            case 'H':
                path.lineTo(val[k], currentY);
                currentX = val[k];
                break;
            case 'v':
                path.rLineTo(0, val[k]);
                currentY += val[k];
                break;
            case 'V':
                path.lineTo(currentX, val[k]);
                currentY = val[k];
                break;
            case 'c':
                path.rCubicTo(val[k], val[k + 1], val[k + 2], val[k + 3], val[k + 4], val[k + 5]);
                ctrlPointX = currentX + val[k + 2];
                ctrlPointY = currentY + val[k + 3];
                currentX += val[k + 4];
                currentY += val[k + 5];
                break;
            case 'C':
                path.cubicTo(val[k], val[k + 1], val[k + 2], val[k + 3], val[k + 4], val[k + 5]);
                currentX = val[k + 4];
                currentY = val[k + 5];
                ctrlPointX = val[k + 2];
                ctrlPointY = val[k + 3];
                break;
            case 's':
                reflectiveCtrlPointX = 0;
                reflectiveCtrlPointY = 0;
                if (prevCmd === 'c' || prevCmd === 's' || prevCmd === 'C' || prevCmd === 'S') {
                    reflectiveCtrlPointX = currentX - ctrlPointX;
                    reflectiveCtrlPointY = currentY - ctrlPointY;
                }
                path.rCubicTo(reflectiveCtrlPointX, reflectiveCtrlPointY, val[k], val[k + 1], val[k + 2], val[k + 3]);
                ctrlPointX = currentX + val[k];
                ctrlPointY = currentY + val[k + 1];
                currentX += val[k + 2];
                currentY += val[k + 3];
                break;
            case 'S':
                reflectiveCtrlPointX = currentX;
                reflectiveCtrlPointY = currentY;
                if (prevCmd === 'c' || prevCmd === 's' || prevCmd === 'C' || prevCmd === 'S') {
                    reflectiveCtrlPointX = 2 * currentX - ctrlPointX;
                    reflectiveCtrlPointY = 2 * currentY - ctrlPointY;
                }
                path.cubicTo(reflectiveCtrlPointX, reflectiveCtrlPointY, val[k], val[k + 1], val[k + 2], val[k + 3]);
                ctrlPointX = val[k];
                ctrlPointY = val[k + 1];
                currentX = val[k + 2];
                currentY = val[k + 3];
                break;
            case 'q':
                path.rQuadTo(val[k], val[k + 1], val[k + 2], val[k + 3]);
                ctrlPointX = currentX + val[k];
                ctrlPointY = currentY + val[k + 1];
                currentX += val[k + 2];
                currentY += val[k + 3];
                break;
            case 'Q':
                path.quadTo(val[k], val[k + 1], val[k + 2], val[k + 3]);
                ctrlPointX = val[k];
                ctrlPointY = val[k + 1];
                currentX = val[k + 2];
                currentY = val[k + 3];
                break;
            case 't':
                reflectiveCtrlPointX = 0;
                reflectiveCtrlPointY = 0;
                if (prevCmd === 'q' || prevCmd === 't' || prevCmd === 'Q' || prevCmd === 'T') {
                    reflectiveCtrlPointX = currentX - ctrlPointX;
                    reflectiveCtrlPointY = currentY - ctrlPointY;
                }
                path.rQuadTo(reflectiveCtrlPointX, reflectiveCtrlPointY, val[k], val[k + 1]);
                ctrlPointX = currentX + reflectiveCtrlPointX;
                ctrlPointY = currentY + reflectiveCtrlPointY;
                currentX += val[k];
                currentY += val[k + 1];
                break;
            case 'T':
                reflectiveCtrlPointX = currentX;
                reflectiveCtrlPointY = currentY;
                if (prevCmd === 'q' || prevCmd === 't' || prevCmd === 'Q' || prevCmd === 'T') {
                    reflectiveCtrlPointX = 2 * currentX - ctrlPointX;
                    reflectiveCtrlPointY = 2 * currentY - ctrlPointY;
                }
                path.quadTo(reflectiveCtrlPointX, reflectiveCtrlPointY, val[k], val[k + 1]);
                ctrlPointX = reflectiveCtrlPointX;
                ctrlPointY = reflectiveCtrlPointY;
                currentX = val[k];
                currentY = val[k + 1];
                break;
            case 'a':
                drawArc(path, currentX, currentY, val[k + 5] + currentX, val[k + 6] + currentY, val[k], val[k + 1], val[k + 2], val[k + 3] !== 0, val[k + 4] !== 0);
                currentX += val[k + 5];
                currentY += val[k + 6];
                ctrlPointX = currentX;
                ctrlPointY = currentY;
                break;
            case 'A':
                drawArc(path, currentX, currentY, val[k + 5], val[k + 6], val[k], val[k + 1], val[k + 2], val[k + 3] !== 0, val[k + 4] !== 0);
                currentX = val[k + 5];
                currentY = val[k + 6];
                ctrlPointX = currentX;
                ctrlPointY = currentY;
                break;
        }
        prevCmd = cmd;
    }
    current[0] = currentX;
    current[1] = currentY;
    current[2] = ctrlPointX;
    current[3] = ctrlPointY;
    current[4] = currentSegmentStartX;
    current[5] = currentSegmentStartY;
}
function drawArc(p, x0, y0, x1, y1, a, b, theta, isMoreThanHalf, isPositiveArc) {
    var thetaD = (theta * Math.PI) / 180;
    var cosTheta = Math.cos(thetaD);
    var sinTheta = Math.sin(thetaD);
    var x0p = (x0 * cosTheta + y0 * sinTheta) / a;
    var y0p = (-x0 * sinTheta + y0 * cosTheta) / b;
    var x1p = (x1 * cosTheta + y1 * sinTheta) / a;
    var y1p = (-x1 * sinTheta + y1 * cosTheta) / b;
    var dx = x0p - x1p;
    var dy = y0p - y1p;
    var xm = (x0p + x1p) / 2;
    var ym = (y0p + y1p) / 2;
    var dsq = dx * dx + dy * dy;
    if (dsq === 0.0) {
        return;
    }
    var disc = 1.0 / dsq - 1.0 / 4.0;
    if (disc < 0.0) {
        var adjust = Math.sqrt(dsq) / 1.99999;
        drawArc(p, x0, y0, x1, y1, a * adjust, b * adjust, theta, isMoreThanHalf, isPositiveArc);
        return;
    }
    var s = Math.sqrt(disc);
    var sdx = s * dx;
    var sdy = s * dy;
    var cx;
    var cy;
    if (isMoreThanHalf === isPositiveArc) {
        cx = xm - sdy;
        cy = ym + sdx;
    }
    else {
        cx = xm + sdy;
        cy = ym - sdx;
    }
    var eta0 = Math.atan2(y0p - cy, x0p - cx);
    var eta1 = Math.atan2(y1p - cy, x1p - cx);
    var sweep = eta1 - eta0;
    if (isPositiveArc !== sweep >= 0) {
        if (sweep > 0) {
            sweep -= 2 * Math.PI;
        }
        else {
            sweep += 2 * Math.PI;
        }
    }
    cx *= a;
    cy *= b;
    var tcx = cx;
    cx = cx * cosTheta - cy * sinTheta;
    cy = tcx * sinTheta + cy * cosTheta;
    arcToBezier(p, cx, cy, a, b, x0, y0, thetaD, eta0, sweep);
}
/**
 * Converts an arc to cubic Bezier segments and records them in p.
 *
 * @param {CommandsBuilder} p The target for the cubic Bezier segments
 * @param {number} cx The x coordinate center of the ellipse
 * @param {number} cy The y coordinate center of the ellipse
 * @param {number} a The radius of the ellipse in the horizontal direction
 * @param {number} b The radius of the ellipse in the vertical direction
 * @param {number} e1x E(eta1) x coordinate of the starting point of the arc
 * @param {number} e1y E(eta2) y coordinate of the starting point of the arc
 * @param {number} theta The angle that the ellipse bounding rectangle makes with horizontal plane
 * @param {number} start The start angle of the arc on the ellipse
 * @param {number} sweep The angle (positive or negative) of the sweep of the arc on the ellipse
 */
function arcToBezier(p, cx, cy, a, b, e1x, e1y, theta, start, sweep) {
    var numSegments = Math.trunc(Math.ceil(Math.abs((sweep * 4) / Math.PI)));
    var eta1 = start;
    var cosTheta = Math.cos(theta);
    var sinTheta = Math.sin(theta);
    var cosEta1 = Math.cos(eta1);
    var sinEta1 = Math.sin(eta1);
    var ep1x = -a * cosTheta * sinEta1 - b * sinTheta * cosEta1;
    var ep1y = -a * sinTheta * sinEta1 + b * cosTheta * cosEta1;
    var anglePerSegment = sweep / numSegments;
    for (var i = 0; i < numSegments; i++) {
        var eta2 = eta1 + anglePerSegment;
        var sinEta2 = Math.sin(eta2);
        var cosEta2 = Math.cos(eta2);
        var e2x = cx + a * cosTheta * cosEta2 - b * sinTheta * sinEta2;
        var e2y = cy + a * sinTheta * cosEta2 + b * cosTheta * sinEta2;
        var ep2x = -a * cosTheta * sinEta2 - b * sinTheta * cosEta2;
        var ep2y = -a * sinTheta * sinEta2 + b * cosTheta * cosEta2;
        var tanDiff2 = Math.tan((eta2 - eta1) / 2);
        var alpha = (Math.sin(eta2 - eta1) * (Math.sqrt(4 + 3 * tanDiff2 * tanDiff2) - 1)) / 3;
        var q1x = e1x + alpha * ep1x;
        var q1y = e1y + alpha * ep1y;
        var q2x = e2x - alpha * ep2x;
        var q2y = e2y - alpha * ep2y;
        p.cubicTo(q1x, q1y, q2x, q2y, e2x, e2y);
        eta1 = eta2;
        e1x = e2x;
        e1y = e2y;
        ep1x = ep2x;
        ep1y = ep2y;
    }
}
var CommandsBuilder = /** @class */ (function () {
    function CommandsBuilder() {
        this.commands = [];
        this.currentSegmentStartX = 0;
        this.currentSegmentStartY = 0;
        this.currentX = 0;
        this.currentY = 0;
    }
    CommandsBuilder.prototype.rMoveTo = function (ri0, ri1) {
        var i0 = this.currentX + ri0;
        var i1 = this.currentY + ri1;
        this.moveTo(i0, i1);
    };
    CommandsBuilder.prototype.moveTo = function (i0, i1) {
        var start = this.commands.length ? { x: this.currentX, y: this.currentY } : undefined;
        var end = { x: i0, y: i1 };
        this.commands.push(newMove(start, end));
        this.currentSegmentStartX = i0;
        this.currentSegmentStartY = i1;
        this.currentX = i0;
        this.currentY = i1;
    };
    CommandsBuilder.prototype.rLineTo = function (ri0, ri1) {
        var i0 = this.currentX + ri0;
        var i1 = this.currentY + ri1;
        this.lineTo(i0, i1);
    };
    CommandsBuilder.prototype.lineTo = function (i0, i1) {
        var start = { x: this.currentX, y: this.currentY };
        var end = { x: i0, y: i1 };
        this.commands.push(newLine(start, end));
        this.currentX = i0;
        this.currentY = i1;
    };
    CommandsBuilder.prototype.rQuadTo = function (ri0, ri1, ri2, ri3) {
        var i0 = this.currentX + ri0;
        var i1 = this.currentY + ri1;
        var i2 = this.currentX + ri2;
        var i3 = this.currentY + ri3;
        this.quadTo(i0, i1, i2, i3);
    };
    CommandsBuilder.prototype.quadTo = function (i0, i1, i2, i3) {
        var start = { x: this.currentX, y: this.currentY };
        var cp = { x: i0, y: i1 };
        var end = { x: i2, y: i3 };
        this.commands.push(newQuadraticCurve(start, cp, end));
        this.currentX = i2;
        this.currentY = i3;
    };
    CommandsBuilder.prototype.rCubicTo = function (ri0, ri1, ri2, ri3, ri4, ri5) {
        var i0 = this.currentX + ri0;
        var i1 = this.currentY + ri1;
        var i2 = this.currentX + ri2;
        var i3 = this.currentY + ri3;
        var i4 = this.currentX + ri4;
        var i5 = this.currentY + ri5;
        this.cubicTo(i0, i1, i2, i3, i4, i5);
    };
    CommandsBuilder.prototype.cubicTo = function (i0, i1, i2, i3, i4, i5) {
        var start = { x: this.currentX, y: this.currentY };
        var cp1 = { x: i0, y: i1 };
        var cp2 = { x: i2, y: i3 };
        var end = { x: i4, y: i5 };
        this.commands.push(newBezierCurve(start, cp1, cp2, end));
        this.currentX = i4;
        this.currentY = i5;
    };
    CommandsBuilder.prototype.close = function () {
        var start = { x: this.currentX, y: this.currentY };
        var end = { x: this.currentSegmentStartX, y: this.currentSegmentStartY };
        this.commands.push(newClosePath(start, end));
        this.currentX = this.currentSegmentStartX;
        this.currentY = this.currentSegmentStartY;
    };
    CommandsBuilder.prototype.toCommands = function () {
        return this.commands;
    };
    return CommandsBuilder;
}());
/** Takes an list of Commands and converts them back into a SVG path string. */
export function commandsToString(commands) {
    var tokens = [];
    commands.forEach(function (cmd) {
        tokens.push(cmd.type);
        var isClosePathCommand = cmd.type === 'Z';
        var pointsToNumberListFunc = function () {
            var points = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                points[_i] = arguments[_i];
            }
            return points.reduce(function (list, p) { return list.concat([p.x, p.y]); }, []);
        };
        var args = pointsToNumberListFunc.apply(void 0, (isClosePathCommand ? [] : cmd.points.slice(1)));
        tokens.splice.apply(tokens, [tokens.length, 0].concat(args.map(function (n) { return Number(n.toFixed(3)).toString(); })));
    });
    return tokens.join(' ');
}
function newMove(start, end) {
    return new Command('M', [start, end]);
}
function newLine(start, end) {
    return new Command('L', [start, end]);
}
function newQuadraticCurve(start, cp, end) {
    return new Command('Q', [start, cp, end]);
}
function newBezierCurve(start, cp1, cp2, end) {
    return new Command('C', [start, cp1, cp2, end]);
}
function newClosePath(start, end) {
    return new Command('Z', [start, end]);
}
