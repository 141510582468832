import * as tslib_1 from "tslib";
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import * as paper from 'paper';
/**
 * A gesture that selects one or more items using a selection box.
 *
 * Preconditions:
 * - The user is in default mode.
 */
var BatchSelectItemsGesture = /** @class */ (function (_super) {
    tslib_1.__extends(BatchSelectItemsGesture, _super);
    // private initialSelectedLayers: ReadonlySet<string>;
    function BatchSelectItemsGesture(ps) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.pl = paper.project.activeLayer;
        return _this;
    }
    // @Override
    BatchSelectItemsGesture.prototype.onMouseDown = function (event) {
        if (!event.modifiers.shift) {
            // A selection box implies that the gesture began with a failed hit test,
            // so deselect everything on mouse down (unless the user is holding shift).
            this.ps.setSelectedLayerIds(new Set());
        }
        // TODO: make use of this information (i.e. toggle the layers when shift is pressed)
        // this.initialSelectedLayers = this.ps.getSelectedLayerIds();
    };
    // @Override
    BatchSelectItemsGesture.prototype.onMouseDrag = function (event) {
        this.ps.setSelectionBox({
            from: this.pl.globalToLocal(event.downPoint),
            to: this.pl.globalToLocal(event.point),
        });
        this.selectItemsInSelectionBox(!event.modifiers.alt);
    };
    // @Override
    BatchSelectItemsGesture.prototype.onMouseUp = function (event) {
        this.selectItemsInSelectionBox(!event.modifiers.alt);
        this.ps.setSelectionBox(undefined);
        this.ps.setRotateItemsInfo(undefined);
        this.ps.setTransformPathsInfo(undefined);
    };
    // @Override
    BatchSelectItemsGesture.prototype.onKeyDown = function (event) {
        if (event.key === 'alt') {
            this.selectItemsInSelectionBox(false);
        }
    };
    // @Override
    BatchSelectItemsGesture.prototype.onKeyUp = function (event) {
        if (event.key === 'alt') {
            this.selectItemsInSelectionBox(true);
        }
    };
    BatchSelectItemsGesture.prototype.selectItemsInSelectionBox = function (includePartialOverlaps) {
        var box = this.ps.getSelectionBox();
        if (box) {
            var from = new paper.Point(box.from);
            var to = new paper.Point(box.to);
            var selectedItems = this.pl.findItemsInBounds(new paper.Rectangle(from, to), includePartialOverlaps);
            this.ps.setSelectedLayerIds(new Set(selectedItems.map(function (i) { return i.data.id; })));
        }
    };
    return BatchSelectItemsGesture;
}(Gesture));
export { BatchSelectItemsGesture };
