import { MathUtil } from 'app/modules/editor/scripts/common';
import { Command } from './Command';
import { Path } from './Path';
/**
 * Interpolates between a start and end path using the specified fraction.
 *
 * TODO: make it possible to create 'stateless' paths (to save memory on animation frames).
 */
export function interpolate(start, end, fraction) {
    if (!start.isMorphableWith(end)) {
        throw new Error('Attempt to interpolate two unmorphable paths');
    }
    var newCommands = [];
    start.getCommands().forEach(function (startCmd, i) {
        var endCmd = end.getCommands()[i];
        var points = [];
        for (var j = 0; j < startCmd.points.length; j++) {
            var p1 = startCmd.points[j];
            var p2 = endCmd.points[j];
            if (p1 && p2) {
                // The 'start' point of the first Move command in a path
                // will be undefined. Skip it.
                var px = MathUtil.lerp(p1.x, p2.x, fraction);
                var py = MathUtil.lerp(p1.y, p2.y, fraction);
                points.push({ x: px, y: py });
            }
            else {
                points.push(undefined);
            }
        }
        // TODO: avoid re-generating unique ids on each animation frame.
        newCommands.push(new Command(startCmd.type, points));
    });
    return new Path(newCommands);
}
/**
 * Sorts a list of path ops in descending order.
 */
export function sortPathOps(ops) {
    return ops.sort(function (_a, _b) {
        var s1 = _a.subIdx, c1 = _a.cmdIdx;
        var s2 = _b.subIdx, c2 = _b.cmdIdx;
        // Perform higher index splits first so that we don't alter the
        // indices of the lower index split operations.
        return s1 !== s2 ? s2 - s1 : c2 - c1;
    });
}
