import * as _ from 'lodash';
/** Returns the floor modulus of the integer argument. */
export function floorMod(num, maxNum) {
    return ((num % maxNum) + maxNum) % maxNum;
}
/** Linearly interpolate between a and b using time t. */
export function lerp(a, b, t) {
    return a + (b - a) * t;
}
/** Returns true if the points are collinear. */
export function areCollinear() {
    var points = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        points[_i] = arguments[_i];
    }
    if (points.length < 3) {
        return true;
    }
    var _a = points[0], a = _a.x, b = _a.y;
    var _b = points[1], m = _b.x, n = _b.y;
    return points.every(function (_a) {
        var x = _a.x, y = _a.y;
        // The points are collinear if the area of the triangle they form
        // is equal to (or in this case, close to) zero.
        return Math.abs(a * (n - y) + m * (y - b) + x * (b - n)) < 1e-9;
    });
}
/** Applies a list of transformation matrices to the specified point. */
export function transformPoint(point) {
    var matrices = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        matrices[_i - 1] = arguments[_i];
    }
    return matrices.reduce(function (p, m) {
        // [a c e]   [p.x]
        // [b d f] * [p.y]
        // [0 0 1]   [ 1 ]
        var x = round(m.a * p.x + m.c * p.y + m.e * 1);
        var y = round(m.b * p.x + m.d * p.y + m.f * 1);
        return { x: x, y: y };
    }, point);
}
/** Calculates the distance between two points. */
export function distance(p1, p2) {
    return Math.hypot(p1.x - p2.x, p1.y - p2.y);
}
/** Returns true if the two points are equal. */
export function arePointsEqual(p1, p2) {
    return p1 && p2 && isNearZero(distance(p1, p2));
}
/** Rounds the number to a prespecified precision. */
export function round(n) {
    return _.round(n, 9);
}
/** Snaps a directional vector to the specified angle. */
export function snapVectorToAngle(delta, snapAngleDegrees) {
    var snapAngle = (snapAngleDegrees * Math.PI) / 180;
    var angle = Math.round(Math.atan2(delta.y, delta.x) / snapAngle) * snapAngle;
    var dirx = Math.cos(angle);
    var diry = Math.sin(angle);
    var d = dirx * delta.x + diry * delta.y;
    return { x: dirx * d, y: diry * d };
}
/** Returns true iff the number is near 0. */
export function isNearZero(n) {
    return round(n) === 0;
}
