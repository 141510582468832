// TODO: test stroked paths
import { PathUtil } from 'app/modules/editor/model/paths';
import { bugsnagClient } from 'app/modules/editor/scripts/bugsnag';
import { MathUtil } from 'app/modules/editor/scripts/common';
import * as _ from 'lodash';
import { MATCH, MISMATCH, align } from './NeedlemanWunsch';
// POSSIBLE IMPROVEMENTS
//
// - Add additional points to both shapes first such that every segment longer than
//   a certain distance is bisected. This may help reduce a bit of noise during alignment.
// - Tweaking the placement of added points with simulated annealing.
// - Using a cost function that factors in self-intersections at the halfway mark in
//   addition to distance traveled.
// - Use triangulation and/or Volonoi topology diagram in order to more accurately morph
//   between SVGs with differing numbers of subpaths.
//
// Useful links/examples:
// - Triangulation: https://goo.gl/Ug2pj9
// - Jigsaw morphing: https://goo.gl/Za3akJ
// - Voronoi topology: https://goo.gl/VNM7Tb
// - Smoother polygon transitions: https://goo.gl/5njTsf
// - Redistricting: https://goo.gl/sMkYEM
/**
 * Takes two arbitrary paths, calculates a best-estimate alignment of the two,
 * and then inserts no-op commands into the alignment gaps to make the two paths
 * compatible with each other.
 */
export function autoFix(from, to) {
    var _a, _b, _c, _d, _e, _f, _g;
    // TODO: remove this once we fix the bug below
    var origFrom = from.getPathString();
    var origTo = to.getPathString();
    try {
        _a = autoUnconvertSubPaths(from, to), from = _a[0], to = _a[1];
        _b = autoAddCollapsingSubPaths(from, to), from = _b[0], to = _b[1];
        _c = orderSubPaths(from, to), from = _c[0], to = _c[1];
        var min = Math.min(from.getSubPaths().length, to.getSubPaths().length);
        for (var subIdx = 0; subIdx < min; subIdx++) {
            // Pass the command with the larger subpath as the 'from' command.
            var numFromCmds = from.getSubPath(subIdx).getCommands().length;
            var numToCmds = to.getSubPath(subIdx).getCommands().length;
            var shouldSwap = numFromCmds < numToCmds;
            if (shouldSwap) {
                _d = [to, from], from = _d[0], to = _d[1];
            }
            _e = alignSubPath(from, to, subIdx), from = _e[0], to = _e[1];
            if (shouldSwap) {
                _f = [to, from], from = _f[0], to = _f[1];
            }
        }
        for (var subIdx = 0; subIdx < min; subIdx++) {
            _g = permuteSubPath(from, to, subIdx), from = _g[0], to = _g[1];
        }
    }
    catch (e) {
        // TODO: remove this once we determine what is causing this bug...
        console.error('autofix failed', origFrom, origTo);
        bugsnagClient.leaveBreadcrumb('autoFix', {
            from: origFrom,
            to: origTo,
        });
        throw e;
    }
    return [from, to];
}
function autoUnconvertSubPaths(from, to) {
    return [from, to].map(function (p) {
        var pm = p.mutate();
        p.getSubPaths().forEach(function (unused, subIdx) { return pm.unconvertSubPath(subIdx); });
        return pm.build();
    });
}
export function autoAddCollapsingSubPaths(from, to) {
    var deleteCollapsingSubPathsFn = function (p) {
        return p.getSubPaths().some(function (s) { return s.isCollapsing(); })
            ? p
                .mutate()
                .deleteCollapsingSubPaths()
                .build()
            : p;
    };
    from = deleteCollapsingSubPathsFn(from);
    to = deleteCollapsingSubPathsFn(to);
    var numFrom = from.getSubPaths().length;
    var numTo = to.getSubPaths().length;
    if (numFrom === numTo) {
        return [from, to];
    }
    // TODO: allow the user to specify the location of collapsing paths?
    var pm = (numFrom < numTo ? from : to).mutate();
    for (var subIdx = Math.min(numFrom, numTo); subIdx < Math.max(numFrom, numTo); subIdx++) {
        var opp = numFrom < numTo ? to : from;
        var pole = opp.getPoleOfInaccessibility(subIdx);
        pm.addCollapsingSubPath(pole, opp.getSubPath(subIdx).getCommands().length);
    }
    if (numFrom < numTo) {
        from = pm.build();
    }
    else {
        to = pm.build();
    }
    return [from, to];
}
/**
 * Reorders the subpaths in each path to minimize the distance each shape will
 * travel during the morph.
 */
function orderSubPaths(from, to) {
    var _a, _b;
    if (from.getSubPaths().length > 8 || to.getSubPaths().length > 8) {
        // Don't attempt to order paths with many subpaths.
        return [from, to];
    }
    var shouldSwap = from.getSubPaths().length < to.getSubPaths().length;
    if (shouldSwap) {
        _a = [to, from], from = _a[0], to = _a[1];
    }
    var fromSubPaths = from.getSubPaths();
    var toSubPaths = to.getSubPaths();
    var distances = fromSubPaths.map(function (f, i) {
        return toSubPaths.map(function (t, j) {
            var pole1 = from.getPoleOfInaccessibility(i);
            var pole2 = to.getPoleOfInaccessibility(j);
            return MathUtil.distance(pole1, pole2);
        });
    });
    var min = Infinity;
    var best = [];
    (function recurseFn(arr, order) {
        if (order === void 0) { order = []; }
        if (order.length === toSubPaths.length) {
            var sum = 0;
            for (var i = 0; i < order.length; i++) {
                sum += distances[order[i]][i];
            }
            if (sum < min) {
                min = sum;
                best = order;
            }
            return;
        }
        for (var i = 0; i < arr.length; i++) {
            var cur = arr.splice(i, 1)[0];
            recurseFn(arr.slice(), order.concat([cur]));
            if (arr.length) {
                arr.splice(i, 0, cur);
            }
        }
    })(_.range(fromSubPaths.length));
    var pm = from.mutate();
    for (var i = 0; i < best.length; i++) {
        var m = best[i];
        pm.moveSubPath(m, i);
        for (var j = i + 1; j < best.length; j++) {
            var n = best[j];
            if (n < m) {
                best[j]++;
            }
        }
    }
    from = pm.build();
    if (shouldSwap) {
        _b = [to, from], from = _b[0], to = _b[1];
    }
    return [from, to];
}
/** Aligns two paths using the Needleman-Wunsch algorithm. */
function alignSubPath(from, to, subIdx) {
    // Create and return a list of reversed and shifted from paths to test.
    // Each generated 'from path' will be aligned with the target 'to path'.
    var fromPaths = _.flatMap([
        from,
        from
            .mutate()
            .reverseSubPath(subIdx)
            .build(),
    ], function (p) {
        var paths = [p];
        if (p.getSubPath(subIdx).isClosed()) {
            for (var i = 1; i < p.getSubPath(subIdx).getCommands().length - 1; i++) {
                // TODO: we need to find a way to reduce the number of paths to try.
                paths.push(p
                    .mutate()
                    .shiftSubPathBack(subIdx, i)
                    .build());
            }
        }
        return paths;
    });
    // The scoring function to use to calculate the alignment. Convert-able
    // commands are considered matches. However, the farther away the points
    // are from each other, the lower the score.
    var getScoreFn = function (a, b) {
        var charA = a.type;
        var charB = b.type;
        if (charA !== charB && !a.canConvertTo(charB) && !b.canConvertTo(charA)) {
            return MISMATCH;
        }
        var _a = a.end, x = _a.x, y = _a.y;
        var start = { x: x, y: y };
        var end = b.end;
        return 1 / Math.max(MATCH, MathUtil.distance(start, end));
    };
    var alignmentInfos = fromPaths.map(function (generatedFromPath) {
        var fromCmds = generatedFromPath.getSubPath(subIdx).getCommands();
        var toCmds = to.getSubPath(subIdx).getCommands();
        return {
            generatedFromPath: generatedFromPath,
            alignment: align(fromCmds, toCmds, getScoreFn),
        };
    });
    // Find the alignment with the highest score.
    var alignmentInfo = alignmentInfos.reduce(function (prev, curr) {
        var prevScore = prev.alignment.score;
        var currScore = curr.alignment.score;
        return prevScore > currScore ? prev : curr;
    });
    // For each alignment, determine whether it and its neighbor is a gap.
    var processAlignmentsFn = function (alignments) {
        var nextCmdIdx = 0;
        return alignments.map(function (alignment, i) {
            var isGap = !alignment.obj;
            var isNextGap = i + 1 < alignments.length && !alignments[i + 1].obj;
            if (!isGap) {
                nextCmdIdx++;
            }
            return { isGap: isGap, isNextGap: isNextGap, nextCmdIdx: nextCmdIdx };
        });
    };
    var fromCmdInfos = processAlignmentsFn(alignmentInfo.alignment.from);
    var toCmdInfos = processAlignmentsFn(alignmentInfo.alignment.to);
    // Process each list of alignments. Each streak of gaps represents a series
    // of one or more splits we'll perform on the path.
    var createGapStreaksFn = function (cmdInfos) {
        var gapStreaks = [];
        var currentGapStreak = [];
        for (var _i = 0, cmdInfos_1 = cmdInfos; _i < cmdInfos_1.length; _i++) {
            var cmdInfo = cmdInfos_1[_i];
            if (cmdInfo.isGap) {
                currentGapStreak.push(cmdInfo);
                if (!cmdInfo.isNextGap) {
                    gapStreaks.push(currentGapStreak);
                    currentGapStreak = [];
                }
            }
        }
        return gapStreaks;
    };
    var fromGapGroups = createGapStreaksFn(fromCmdInfos);
    var toGapGroups = createGapStreaksFn(toCmdInfos);
    // Fill in the gaps by applying linear subdivide batch splits.
    var applySplitsFn = function (path, gapGroups) {
        var splitOps = [];
        var numPaths = path.getSubPath(subIdx).getCommands().length;
        var _loop_1 = function (i) {
            var gapGroup = gapGroups[i];
            // Clamp the index between 1 and numCommands - 1 to account for cases
            // where the alignment algorithm attempts to append new commands to the
            // front and back of the sequence.
            var cmdIdx = _.clamp(_.last(gapGroup).nextCmdIdx, 1, numPaths - 1);
            var ts = gapGroup.map(function (unused, gapIdx) { return (gapIdx + 1) / (gapGroup.length + 1); });
            splitOps.push({ subIdx: subIdx, cmdIdx: cmdIdx, ts: ts });
        };
        for (var i = gapGroups.length - 1; i >= 0; i--) {
            _loop_1(i);
        }
        PathUtil.sortPathOps(splitOps);
        var mutator = path.mutate();
        for (var _i = 0, splitOps_1 = splitOps; _i < splitOps_1.length; _i++) {
            var _a = splitOps_1[_i], cmdIdx = _a.cmdIdx, ts = _a.ts;
            mutator.splitCommand.apply(mutator, [subIdx, cmdIdx].concat(ts));
        }
        return mutator.build();
    };
    var fromPathResult = applySplitsFn(alignmentInfo.generatedFromPath, fromGapGroups);
    var toPathResult = applySplitsFn(to, toGapGroups);
    // Finally, convert the commands before returning the result.
    return autoConvertSubPath(fromPathResult, toPathResult, subIdx);
}
/**
 * Takes two paths with an equal number of commands and makes them compatible
 * by converting each pair one-by-one.
 */
export function autoConvert(from, to) {
    var _a, _b;
    _a = autoUnconvertSubPaths(from, to), from = _a[0], to = _a[1];
    var numFrom = from.getSubPaths().length;
    var numTo = to.getSubPaths().length;
    for (var subIdx = 0; subIdx < Math.min(numFrom, numTo); subIdx++) {
        // Only auto convert when the number of commands in both canvases
        // are equal. Otherwise we'll wait for the user to add more points.
        _b = autoConvertSubPath(from, to, subIdx), from = _b[0], to = _b[1];
    }
    return [from, to];
}
function autoConvertSubPath(from, to, subIdx) {
    var numFrom = from.getSubPath(subIdx).getCommands().length;
    var numTo = to.getSubPath(subIdx).getCommands().length;
    if (numFrom !== numTo) {
        // Only auto convert when the number of commands in both subpaths are equal.
        return [from, to];
    }
    var fromPm = from.mutate();
    var toPm = to.mutate();
    for (var cmdIdx = 0; cmdIdx < numFrom; cmdIdx++) {
        var fromCmd = from.getCommand(subIdx, cmdIdx);
        var toCmd = to.getCommand(subIdx, cmdIdx);
        if (fromCmd.type === toCmd.type) {
            continue;
        }
        if (fromCmd.canConvertTo(toCmd.type)) {
            fromPm.convertCommand(subIdx, cmdIdx, toCmd.type);
        }
        else if (toCmd.canConvertTo(fromCmd.type)) {
            toPm.convertCommand(subIdx, cmdIdx, fromCmd.type);
        }
    }
    return [fromPm.build(), toPm.build()];
}
function permuteSubPath(from, to, subIdx) {
    if (from.isClockwise(subIdx) !== to.isClockwise(subIdx)) {
        // Make sure the paths share the same direction.
        to = to
            .mutate()
            .reverseSubPath(subIdx)
            .build();
    }
    // Create and return a list of reversed and shifted from paths to test.
    // Each generated 'from path' will be aligned with the target 'to path'.
    var fromPaths = [from];
    if (from.getSubPath(subIdx).isClosed()) {
        for (var i = 1; i < from.getSubPath(subIdx).getCommands().length - 1; i++) {
            // TODO: we need to find a way to reduce the number of paths to try.
            fromPaths.push(from
                .mutate()
                .shiftSubPathBack(subIdx, i)
                .build());
        }
    }
    var bestFromPath = from;
    var min = Infinity;
    var _loop_2 = function (fromPath) {
        var fromCmds = fromPath.getSubPath(subIdx).getCommands();
        var sumOfSquares = 0;
        var toCmds = to.getSubPath(subIdx).getCommands();
        fromCmds.forEach(function (c, cmdIdx) { return (sumOfSquares += Math.pow(MathUtil.distance(c.end, toCmds[cmdIdx].end), 2)); });
        if (sumOfSquares < min) {
            min = sumOfSquares;
            bestFromPath = fromPath;
        }
    };
    for (var _i = 0, fromPaths_1 = fromPaths; _i < fromPaths_1.length; _i++) {
        var fromPath = fromPaths_1[_i];
        _loop_2(fromPath);
    }
    return [bestFromPath, to];
}
