import * as tslib_1 from "tslib";
import { CursorType } from 'app/modules/editor/model/paper';
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { HitTests } from 'app/modules/editor/scripts/paper/item';
import { PaperUtil } from 'app/modules/editor/scripts/paper/util';
import { BatchAction } from 'app/modules/editor/store/batch/actions';
import { SetVectorLayer } from 'app/modules/editor/store/layers/actions';
import { SetEditPathInfo } from 'app/modules/editor/store/paper/actions';
import * as paper from 'paper';
/**
 * A gesture that performs hover operations over segments and curves.
 *
 * Preconditions:
 * - The user is in edit path mode for a selected layer id.
 */
var HoverSegmentsCurvesGesture = /** @class */ (function (_super) {
    tslib_1.__extends(HoverSegmentsCurvesGesture, _super);
    function HoverSegmentsCurvesGesture(ps) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.pl = paper.project.activeLayer;
        return _this;
    }
    // @Override
    HoverSegmentsCurvesGesture.prototype.onMouseMove = function (event) {
        this.ps.setCursorType(CursorType.Default);
        this.ps.setSplitCurveInfo(undefined);
        // TODO: this seems kinda hacky
        // TODO: currently necessary (if the previous gesture was the create/drag/draw segments gesture)
        this.ps.setCreatePathInfo(undefined);
        var editPathId = this.ps
            .getSelectedLayerIds()
            .values()
            .next().value;
        var editPath = this.pl.findItemByLayerId(editPathId);
        var segmentsAndHandlesHitResult = HitTests.editPathModeSegmentsAndHandles(event.point);
        if (segmentsAndHandlesHitResult) {
            // If we are hovering over a segment or a handle, then show a point select
            // cursor and return.
            this.ps.setCursorType(CursorType.PointSelect);
            return;
        }
        var editPathHitResult = HitTests.editPathMode(event.point, editPath, {
            curves: true,
        });
        if (editPathHitResult) {
            if (editPathHitResult.type !== 'curve') {
                // If we hit the edit path but missed its segments/handles/curves,
                // then do nothing.
                return;
            }
            // Show a pen add cursor and highlight the curve the user is about to split.
            this.ps.setCursorType(CursorType.PenAdd);
            var hitCurve = editPathHitResult.location.curve;
            var location_1 = event.modifiers.shift
                ? hitCurve.getLocationAt(hitCurve.length / 2)
                : editPathHitResult.location;
            var vpSplitPoint = this.localToVpPoint(editPath, location_1.point);
            var _a = this.localToVpSegment(editPath, location_1.curve.segment1), p1 = _a.point, in1 = _a.handleIn, out1 = _a.handleOut;
            var _b = this.localToVpSegment(editPath, location_1.curve.segment2), p2 = _b.point, in2 = _b.handleIn, out2 = _b.handleOut;
            this.ps.setSplitCurveInfo({
                splitPoint: vpSplitPoint,
                segment1: { point: p1, handleIn: in1, handleOut: out1 },
                segment2: { point: p2, handleIn: in2, handleOut: out2 },
            });
            return;
        }
        // Draw an 'extend path' preview curve if one of its end points
        // is selected and the path is still open.
        var singleSelectedSegmentIndex = this.findSingleSelectedEndSegmentIndex(editPath);
        if (singleSelectedSegmentIndex !== undefined) {
            this.ps.setCursorType(CursorType.PenAdd);
            var vpStartSegment = this.localToVpSegment(editPath, editPath.segments[singleSelectedSegmentIndex]);
            var vpEndSegment = new paper.Segment(this.pl.globalToLocal(event.point));
            var pathData = new paper.Path([vpStartSegment, vpEndSegment]).pathData;
            this.ps.setCreatePathInfo({
                pathData: pathData,
                strokeColor: '#979797',
            });
        }
    };
    /** Converts local coordinates to viewport coordinates for a point. */
    HoverSegmentsCurvesGesture.prototype.localToVpPoint = function (localItem, localPoint) {
        return localPoint ? this.pl.globalToLocal(localItem.localToGlobal(localPoint)) : undefined;
    };
    /** Converts local coordinates to viewport coordinates for a segment. */
    HoverSegmentsCurvesGesture.prototype.localToVpSegment = function (localItem, localSegment) {
        return new paper.Segment(this.localToVpPoint(localItem, localSegment.point), this.localToVpHandle(localItem, localSegment.point, localSegment.handleIn), this.localToVpHandle(localItem, localSegment.point, localSegment.handleOut));
    };
    /** Converts local coordinates to viewport coordinates for a segment handle. */
    HoverSegmentsCurvesGesture.prototype.localToVpHandle = function (localItem, localPoint, localHandle) {
        var vpPoint = this.localToVpPoint(localItem, localPoint);
        var vpHandle = this.localToVpPoint(localItem, localPoint.add(localHandle));
        return vpHandle.subtract(vpPoint);
    };
    /**
     * Returns the single selected end point segment index for the given path,
     * or undefined if one doesn't exist.
     */
    HoverSegmentsCurvesGesture.prototype.findSingleSelectedEndSegmentIndex = function (path) {
        if (path.closed) {
            // Return undefined if the path is closed.
            return undefined;
        }
        var selectedSegments = this.ps.getEditPathInfo().selectedSegments;
        if (selectedSegments.size !== 1) {
            // Return undefined if there is not a single selected segment.
            return undefined;
        }
        var lastIndex = path.segments.length - 1;
        return selectedSegments.has(0) ? 0 : selectedSegments.has(lastIndex) ? lastIndex : undefined;
    };
    // @Override
    HoverSegmentsCurvesGesture.prototype.onKeyDown = function (event) {
        switch (event.key) {
            case 'escape':
                // TODO: also do this in any other hover/pen/pencil related gestures?
                this.ps.exitEditPathMode();
                break;
            case 'backspace':
            case 'delete':
                this.deleteSelectedSegmentsAndHandles();
                break;
        }
    };
    HoverSegmentsCurvesGesture.prototype.deleteSelectedSegmentsAndHandles = function () {
        var layerId = this.ps
            .getSelectedLayerIds()
            .values()
            .next().value;
        var _a = this.ps.getEditPathInfo(), selectedHandleIn = _a.selectedHandleIn, selectedHandleOut = _a.selectedHandleOut, selectedSegments = _a.selectedSegments;
        if (selectedHandleIn === undefined &&
            selectedHandleOut === undefined &&
            selectedSegments.size === 0) {
            // Do nothing if there are no selected segments/handles.
            // TODO: should we delete the layer in this case?
            return;
        }
        var editPath = this.pl.findItemByLayerId(layerId);
        for (var i = editPath.segments.length - 1; i >= 0; i--) {
            var segment = editPath.segments[i];
            if (selectedSegments.has(i)) {
                segment.remove();
                continue;
            }
            if (selectedHandleIn === i) {
                segment.handleIn = undefined;
            }
            if (selectedHandleOut === i) {
                segment.handleOut = undefined;
            }
        }
        var actions = [];
        if (editPath.segments.length === 0) {
            // Delete the layer and exit edit path mode if there are no segments remaining.
            actions.push.apply(actions, this.ps.getDeleteSelectedModelsActions());
            actions.push.apply(actions, this.ps.getExitEditPathModeActions());
        }
        else {
            actions.push.apply(actions, [new SetVectorLayer(PaperUtil.getReplacePathInStoreVectorLayer(this.ps, layerId, editPath.pathData)),
                new SetEditPathInfo({
                    selectedHandleIn: undefined,
                    selectedHandleOut: undefined,
                    selectedSegments: new Set(),
                    visibleHandleIns: new Set(),
                    visibleHandleOuts: new Set(),
                })].concat(this.ps.getClearEditPathModeStateActions()));
        }
        this.ps.dispatchStore(new (BatchAction.bind.apply(BatchAction, [void 0].concat(actions)))());
    };
    return HoverSegmentsCurvesGesture;
}(Gesture));
export { HoverSegmentsCurvesGesture };
