import { MathUtil, Matrix } from 'app/modules/editor/scripts/common';
import * as _ from 'lodash';
/**
 * Represents an individual SVG command. These are the essential building blocks
 * of all Paths and SubPath objects.
 */
var Command = /** @class */ (function () {
    function Command(_type, _points, _isSplitPoint, _id, _isSplitSegment) {
        if (_isSplitPoint === void 0) { _isSplitPoint = false; }
        if (_id === void 0) { _id = _.uniqueId(); }
        if (_isSplitSegment === void 0) { _isSplitSegment = false; }
        this._type = _type;
        this._points = _points;
        this._isSplitPoint = _isSplitPoint;
        this._id = _id;
        this._isSplitSegment = _isSplitSegment;
        if (_type === undefined) {
            throw new Error('Attempt to set an undefined svgChar');
        }
    }
    Object.defineProperty(Command.prototype, "id", {
        /**
         * Returns the unique ID for this command.
         */
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Command.prototype, "type", {
        /**
         * Returns the SVG character for this command.
         */
        get: function () {
            return this._type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Command.prototype, "points", {
        /**
         * Returns the points for this command.
         */
        get: function () {
            return this._points;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Returns true iff the command was created as a result of being split.
     * Only split commands are able to be editted and deleted via the inspector/canvas.
     */
    Command.prototype.isSplitPoint = function () {
        return this._isSplitPoint;
    };
    /**
     * Returns true iff the command was created as a result of a subpath split.
     */
    Command.prototype.isSplitSegment = function () {
        return this._isSplitSegment;
    };
    Object.defineProperty(Command.prototype, "start", {
        /**
         * Returns the command's starting point. The starting point for the first
         * command of the first subpath will be undefined.
         */
        get: function () {
            return _.first(this._points);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Command.prototype, "end", {
        /**
         * Returns the command's ending point.
         */
        get: function () {
            return _.last(this._points);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Returns true iff this command can be converted into a new command
     * that is morphable with the specified SVG command type.
     */
    Command.prototype.canConvertTo = function (targetChar) {
        var ch = targetChar;
        if (this._type === 'M' || ch === 'M' || this._type === ch) {
            return false;
        }
        switch (this._type) {
            case 'L':
                return ch === 'Q' || ch === 'C';
            case 'Z':
                return ch === 'L' || ch === 'Q' || ch === 'C';
            case 'Q': {
                var uniquePoints = _.uniqWith(this._points, MathUtil.arePointsEqual);
                return ch === 'C' || (ch === 'L' && uniquePoints.length <= 2);
            }
            case 'C': {
                var uniquePoints = _.uniqWith(this._points, MathUtil.arePointsEqual);
                return ch === 'L' && uniquePoints.length <= 2;
            }
        }
        return false;
    };
    /**
     * Returns a builder to construct a mutated Command.
     */
    Command.prototype.mutate = function () {
        return new CommandBuilder(this._type, this._points.slice(), this.isSplitPoint(), this._id, this._isSplitSegment);
    };
    Command.prototype.toString = function () {
        if (this._type === 'Z') {
            return "" + this._type;
        }
        else {
            var p = _.last(this._points);
            var x = _.round(p.x, 3);
            var y = _.round(p.y, 3);
            return this._type + " " + x + ", " + y;
        }
    };
    return Command;
}());
export { Command };
var CommandBuilder = /** @class */ (function () {
    function CommandBuilder(svgChar, points, isSplitPoint, id, isSplitSegment) {
        if (isSplitPoint === void 0) { isSplitPoint = false; }
        if (id === void 0) { id = ''; }
        if (isSplitSegment === void 0) { isSplitSegment = false; }
        this.svgChar = svgChar;
        this.points = points;
        this.isSplitPoint = isSplitPoint;
        this.id = id;
        this.isSplitSegment = isSplitSegment;
        this.matrix = Matrix.identity();
    }
    CommandBuilder.prototype.setSvgChar = function (svgChar) {
        this.svgChar = svgChar;
        return this;
    };
    CommandBuilder.prototype.setId = function (id) {
        this.id = id;
        return this;
    };
    CommandBuilder.prototype.setPoints = function () {
        var points = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            points[_i] = arguments[_i];
        }
        this.points = points;
        return this;
    };
    CommandBuilder.prototype.toggleSplitPoint = function () {
        return this.setIsSplitPoint(!this.isSplitPoint);
    };
    CommandBuilder.prototype.setIsSplitPoint = function (isSplitPoint) {
        this.isSplitPoint = isSplitPoint;
        return this;
    };
    CommandBuilder.prototype.setIsSplitSegment = function (isSplitSegment) {
        this.isSplitSegment = isSplitSegment;
        return this;
    };
    CommandBuilder.prototype.transform = function (transform) {
        this.matrix = transform.dot(this.matrix);
        return this;
    };
    CommandBuilder.prototype.reverse = function () {
        if (this.svgChar !== 'M' || this.points[0]) {
            // The first move command of an SVG path has an undefined
            // starting point, so no change is required in that case.
            this.points.reverse();
        }
        return this;
    };
    CommandBuilder.prototype.build = function () {
        var _this = this;
        return new Command(this.svgChar, this.points.map(function (p) { return (p ? MathUtil.transformPoint(p, _this.matrix) : p); }), this.isSplitPoint, this.id || _.uniqueId(), this.isSplitSegment);
    };
    return CommandBuilder;
}());
export { CommandBuilder };
