import { EventEmitter, OnInit, } from '@angular/core';
import { Layer } from 'app/modules/editor/model/layers';
import { ModelUtil } from 'app/modules/editor/scripts/common';
import { ActionModeService } from 'app/modules/editor/services';
import { Store } from 'app/modules/editor/store';
import { getTimelineAnimationRowState } from 'app/modules/editor/store/common/selectors';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
var TimelineAnimationRowComponent = /** @class */ (function () {
    function TimelineAnimationRowComponent(store, actionModeService) {
        this.store = store;
        this.actionModeService = actionModeService;
        // MouseEvents from this layer (or children layers further down the tree)
        // are recursively handled by parent components until they reach
        // the LayerTimelineComponent.
        this.timelineBlockClick = new EventEmitter();
        this.timelineBlockMouseDown = new EventEmitter();
        this.timelineBlockDoubleClick = new EventEmitter();
    }
    TimelineAnimationRowComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.animationRowModel$ = this.store.select(getTimelineAnimationRowState).pipe(map(function (_a) {
            var animation = _a.animation, collapsedLayerIds = _a.collapsedLayerIds, selectedBlockIds = _a.selectedBlockIds, isActionMode = _a.isActionMode;
            // Returns a list of animation block lists. Each animation block list corresponds to
            // a property name displayed in the layer list tree.
            var blocksByPropertyNameValues = _.values(ModelUtil.getOrderedBlocksByPropertyByLayer(animation)[_this.layer.id]);
            return {
                animation: animation,
                blocksByPropertyNameValues: blocksByPropertyNameValues,
                isExpanded: !collapsedLayerIds.has(_this.layer.id),
                selectedBlockIds: selectedBlockIds,
                isActionMode: isActionMode,
            };
        }));
    };
    // @Override Callbacks
    TimelineAnimationRowComponent.prototype.onTimelineBlockClick = function (event, block) {
        event.stopPropagation();
        if (!this.actionModeService.isActionMode()) {
            this.timelineBlockClick.emit({ event: event, block: block });
        }
    };
    // @Override Callbacks
    TimelineAnimationRowComponent.prototype.onTimelineBlockDoubleClick = function (event, block) {
        event.stopPropagation();
        if (!this.actionModeService.isActionMode()) {
            this.timelineBlockDoubleClick.emit({ event: event, block: block });
        }
    };
    // @Override Callbacks
    TimelineAnimationRowComponent.prototype.onTimelineBlockMouseDown = function (event, block) {
        if (!this.actionModeService.isActionMode()) {
            this.timelineBlockMouseDown.emit({ event: event, block: block });
        }
    };
    // Used by *ngFor loop.
    TimelineAnimationRowComponent.prototype.trackLayerFn = function (index, layer) {
        return layer.id;
    };
    return TimelineAnimationRowComponent;
}());
export { TimelineAnimationRowComponent };
