import { NgZone } from '@angular/core';
import { Store } from 'app/modules/editor/store';
import { BatchAction } from 'app/modules/editor/store/batch/actions';
import { SetCurrentTime, SetIsPlaying, SetIsRepeating, SetIsSlowMotion, } from 'app/modules/editor/store/playback/actions';
import { getAnimatedVectorLayer, getCurrentTime, getIsPlaying, getIsRepeating, getIsSlowMotion, } from 'app/modules/editor/store/playback/selectors';
import { getAnimation } from 'app/modules/editor/store/timeline/selectors';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/** A simple service that provides an interface for making playback changes. */
var PlaybackService = /** @class */ (function () {
    // TODO: set current time to 0 when animation/vector layer changes (like before)?
    // TODO: reset time (or any other special handling) during workspace resets?
    function PlaybackService(store, ngZone) {
        var _this = this;
        this.store = store;
        this.animator = new Animator(ngZone, {
            onAnimationStart: function () {
                _this.setIsPlaying(true);
            },
            onAnimationUpdate: function (currentTime) {
                currentTime = Math.round(currentTime);
                _this.store.dispatch(new SetCurrentTime(currentTime));
            },
            onAnimationEnd: function () {
                _this.setIsPlaying(false);
            },
        });
        this.store.select(getIsPlaying).subscribe(function (isPlaying) {
            if (isPlaying) {
                var duration = _this.queryStore(getAnimation).duration;
                var currentTime = _this.getCurrentTime();
                var startTime = duration === _this.getCurrentTime() ? 0 : currentTime;
                _this.animator.play(duration, startTime);
            }
            else {
                _this.animator.pause();
            }
        });
        this.store.select(getIsSlowMotion).subscribe(function (isSlowMotion) {
            _this.animator.setIsSlowMotion(isSlowMotion);
        });
        this.store.select(getIsRepeating).subscribe(function (isRepeating) {
            _this.animator.setIsRepeating(isRepeating);
        });
    }
    PlaybackService.prototype.asObservable = function () {
        return this.store.select(getAnimatedVectorLayer);
    };
    PlaybackService.prototype.getCurrentTime = function () {
        return this.queryStore(getCurrentTime);
    };
    PlaybackService.prototype.setCurrentTime = function (currentTime) {
        currentTime = Math.round(currentTime);
        if (this.queryStore(getCurrentTime) !== currentTime) {
            this.store.dispatch(new SetCurrentTime(currentTime));
        }
    };
    // TODO: make it so rewind navigates to the start of the currently active block?
    PlaybackService.prototype.rewind = function () {
        var actions = [];
        if (this.getCurrentTime() !== 0) {
            actions.push(new SetCurrentTime(0));
        }
        if (this.queryStore(getIsPlaying)) {
            actions.push(new SetIsPlaying(false));
        }
        if (actions.length) {
            this.store.dispatch(new (BatchAction.bind.apply(BatchAction, [void 0].concat(actions)))());
        }
    };
    // TODO: make it so fast forward navigates to the end of the currently active block?
    PlaybackService.prototype.fastForward = function () {
        var actions = [];
        var duration = this.queryStore(getAnimation).duration;
        if (this.getCurrentTime() !== duration) {
            actions.push(new SetCurrentTime(duration));
        }
        if (this.queryStore(getIsPlaying)) {
            actions.push(new SetIsPlaying(false));
        }
        if (actions.length) {
            this.store.dispatch(new (BatchAction.bind.apply(BatchAction, [void 0].concat(actions)))());
        }
    };
    PlaybackService.prototype.toggleIsSlowMotion = function () {
        this.store.dispatch(new SetIsSlowMotion(!this.queryStore(getIsSlowMotion)));
    };
    PlaybackService.prototype.toggleIsRepeating = function () {
        this.store.dispatch(new SetIsRepeating(!this.queryStore(getIsRepeating)));
    };
    PlaybackService.prototype.toggleIsPlaying = function () {
        this.setIsPlaying(!this.queryStore(getIsPlaying));
    };
    PlaybackService.prototype.setIsPlaying = function (isPlaying) {
        if (isPlaying !== this.queryStore(getIsPlaying)) {
            this.store.dispatch(new SetIsPlaying(isPlaying));
        }
    };
    PlaybackService.prototype.queryStore = function (selector) {
        var obj;
        this.store
            .select(selector)
            .pipe(first())
            .subscribe(function (o) { return (obj = o); });
        return obj;
    };
    PlaybackService.ngInjectableDef = i0.defineInjectable({ factory: function PlaybackService_Factory() { return new PlaybackService(i0.inject(i1.Store), i0.inject(i0.NgZone)); }, token: PlaybackService, providedIn: "root" });
    return PlaybackService;
}());
export { PlaybackService };
var REPEAT_DELAY = 750;
var DEFAULT_PLAYBACK_SPEED = 1;
var SLOW_MOTION_PLAYBACK_SPEED = 5;
/** A simple class that simulates an animation loop. */
var Animator = /** @class */ (function () {
    function Animator(ngZone, callback) {
        this.ngZone = ngZone;
        this.callback = callback;
        this.playbackSpeed = DEFAULT_PLAYBACK_SPEED;
        this.isRepeating = false;
    }
    Animator.prototype.setIsRepeating = function (isRepeating) {
        this.isRepeating = isRepeating;
    };
    Animator.prototype.setIsSlowMotion = function (isSlowMotion) {
        // TODO: make it possible to change this mid-animation?
        this.playbackSpeed = isSlowMotion ? SLOW_MOTION_PLAYBACK_SPEED : DEFAULT_PLAYBACK_SPEED;
    };
    Animator.prototype.play = function (duration, startTime) {
        var _this = this;
        this.runOutsideAngular(function () { return _this.startAnimation(duration, startTime); });
        this.runInsideAngular(function () { return _this.callback.onAnimationStart(); });
    };
    Animator.prototype.startAnimation = function (duration, startTime) {
        var _this = this;
        var startTimestamp;
        var playbackSpeed = this.playbackSpeed;
        var onAnimationFrameFn = function (timestamp) {
            if (!startTimestamp) {
                startTimestamp = timestamp;
            }
            var progress = timestamp - startTimestamp + startTime;
            if (progress < duration * playbackSpeed) {
                _this.animationFrameId = window.requestAnimationFrame(onAnimationFrameFn);
            }
            else if (_this.isRepeating) {
                _this.timeoutId = window.setTimeout(function () { return _this.startAnimation(duration, startTime); }, REPEAT_DELAY);
            }
            else {
                _this.pause(true);
            }
            var fraction = _.clamp(progress / (duration * playbackSpeed), 0, 1);
            var executeFn = function () { return _this.callback.onAnimationUpdate(fraction * duration); };
            if (fraction === 0 || fraction === 1) {
                _this.runInsideAngular(executeFn);
            }
            else {
                executeFn();
            }
        };
        this.animationFrameId = window.requestAnimationFrame(onAnimationFrameFn);
    };
    Animator.prototype.pause = function (shouldNotify) {
        var _this = this;
        if (shouldNotify === void 0) { shouldNotify = false; }
        if (this.timeoutId) {
            window.clearTimeout(this.timeoutId);
            this.timeoutId = undefined;
        }
        if (this.animationFrameId) {
            window.cancelAnimationFrame(this.animationFrameId);
            this.animationFrameId = undefined;
        }
        if (shouldNotify) {
            this.runInsideAngular(function () { return _this.callback.onAnimationEnd(); });
        }
    };
    Animator.prototype.rewind = function () {
        this.pause();
    };
    Animator.prototype.fastForward = function () {
        this.pause();
    };
    Animator.prototype.runInsideAngular = function (fn) {
        if (NgZone.isInAngularZone()) {
            fn();
        }
        else {
            this.ngZone.run(fn);
        }
    };
    Animator.prototype.runOutsideAngular = function (fn) {
        if (NgZone.isInAngularZone()) {
            this.ngZone.runOutsideAngular(fn);
        }
        else {
            fn();
        }
    };
    return Animator;
}());
