<div *ngIf="(this.model$ | async) as model"
  class="tool-panel mat-elevation-z4"
  fxLayout="column"
  fxFlex>
  <button class="tool-button"
    matTooltip="Select"
    matTooltipPosition="right"
    matTooltipShowDelay="500"
    [class.is-checked]="model.isDefaultClick"
    (click)="onDefaultClick($event)">
    <mat-icon svgIcon="tool_select"></mat-icon>
  </button>
  <button class="tool-button"
    matTooltip="Rotate"
    matTooltipPosition="right"
    matTooltipShowDelay="500"
    [class.is-checked]="model.isRotateItemsChecked"
    [disabled]="!model.isRotateItemsEnabled"
    (click)="onRotateItemsClick($event)">
    <mat-icon>crop_rotate</mat-icon>
  </button>
  <button class="tool-button"
    matTooltip="Transform"
    matTooltipPosition="right"
    matTooltipShowDelay="500"
    [class.is-checked]="model.isTransformPathsChecked"
    [disabled]="!model.isTransformPathsEnabled"
    (click)="onTransformPathsClick($event)">
    <mat-icon>transform</mat-icon>
  </button>
  <button class="tool-button"
    matTooltip="Pencil"
    matTooltipPosition="right"
    matTooltipShowDelay="500"
    [class.is-checked]="model.toolMode === this.TOOL_MODE_PENCIL"
    (click)="onPencilClick($event)">
    <mat-icon svgIcon="tool_pencil"></mat-icon>
  </button>
  <button class="tool-button"
    matTooltip="Vector"
    matTooltipPosition="right"
    matTooltipShowDelay="500"
    [class.is-checked]="model.isEditPathChecked"
    (click)="onEditPathClick($event)">
    <mat-icon svgIcon="tool_vector"></mat-icon>
  </button>
  <button class="tool-button"
    matTooltip="Oval"
    matTooltipPosition="right"
    matTooltipShowDelay="500"
    [class.is-checked]="model.toolMode === this.TOOL_MODE_ELLIPSE"
    (click)="onEllipseClick($event)">
    <mat-icon svgIcon="tool_ellipse"></mat-icon>
  </button>
  <button class="tool-button"
    matTooltip="Rectangle"
    matTooltipPosition="right"
    matTooltipShowDelay="500"
    [class.is-checked]="model.toolMode === this.TOOL_MODE_RECTANGLE"
    (click)="onRectangleClick($event)">
    <mat-icon svgIcon="tool_rectangle"></mat-icon>
  </button>
  <!-- TODO: set the tooltip delay back to 500 once we have a better way of telling the user about zooming out -->
  <button class="tool-button"
    matTooltip="Zoom (hold {{ this.isMac() ? 'option' : 'alt' }} to zoom out)"
    matTooltipPosition="right"
    matTooltipShowDelay="0"
    [class.is-checked]="model.toolMode === this.TOOL_MODE_ZOOMPAN"
    (click)="onZoomPanClick($event)">
    <mat-icon>zoom_in</mat-icon>
  </button>
</div>