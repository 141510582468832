import * as tslib_1 from "tslib";
import { ToolMode } from 'app/modules/editor/model/paper';
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { PaperUtil } from 'app/modules/editor/scripts/paper/util';
import * as paper from 'paper';
/** Base class for all shape-building gestures. */
var ShapeGesture = /** @class */ (function (_super) {
    tslib_1.__extends(ShapeGesture, _super);
    function ShapeGesture(ps) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.pl = paper.project.activeLayer;
        return _this;
    }
    // @Override
    ShapeGesture.prototype.onMouseDrag = function (event) {
        var vpDownPoint = this.pl.globalToLocal(event.downPoint);
        var vpPoint = this.pl.globalToLocal(event.point);
        this.vpLastDragInfo = { vpDownPoint: vpDownPoint, vpPoint: vpPoint };
        this.processEvent(event);
    };
    // @Override
    ShapeGesture.prototype.onMouseUp = function (event) {
        if (this.vpLastDragInfo) {
            var pathData = this.ps.getCreatePathInfo().pathData;
            var newPathLayer = PaperUtil.addPathToStore(this.ps, pathData);
            this.ps.setSelectedLayerIds(new Set([newPathLayer.id]));
        }
        this.finishGesture();
    };
    // @Override
    ShapeGesture.prototype.onKeyDown = function (event) {
        if (event.key === 'shift' || event.key === 'alt') {
            this.processEvent(event);
        }
        else if (event.key === 'escape') {
            this.finishGesture();
        }
    };
    // @Override
    ShapeGesture.prototype.onKeyUp = function (event) {
        if (event.key === 'shift' || event.key === 'alt') {
            this.processEvent(event);
        }
    };
    ShapeGesture.prototype.processEvent = function (_a) {
        var _b = _a.modifiers, alt = _b.alt, shift = _b.shift;
        var _c = this.vpLastDragInfo, vpDownPoint = _c.vpDownPoint, vpPoint = _c.vpPoint;
        // If shift is pressed, then set the height equal to the width.
        var vpSize = new paper.Size(vpPoint.x - vpDownPoint.x, shift ? vpPoint.x - vpDownPoint.x : vpPoint.y - vpDownPoint.y).multiply(alt ? 2 : 1);
        // If alt is pressed, then the initial downpoint represents the shape's center point.
        var vpTopLeft = alt
            ? vpDownPoint.subtract(new paper.Point(vpSize.width / 2, vpSize.height / 2))
            : vpDownPoint;
        var pathData = this.newPath(new paper.Rectangle(vpTopLeft, vpSize)).pathData;
        this.ps.setCreatePathInfo({ pathData: pathData, strokeColor: '#979797' });
    };
    ShapeGesture.prototype.finishGesture = function () {
        this.ps.setCreatePathInfo(undefined);
        this.ps.setToolMode(ToolMode.Default);
    };
    return ShapeGesture;
}(Gesture));
export { ShapeGesture };
