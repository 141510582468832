import { OnInit } from '@angular/core';
import { ActionMode, ActionSource, SelectionType, } from 'app/modules/editor/model/actionmode';
import { ActionModeUtil } from 'app/modules/editor/scripts/actionmode';
import { ActionModeService, ThemeService } from 'app/modules/editor/services';
import { Store } from 'app/modules/editor/store';
import { getToolbarState } from 'app/modules/editor/store/actionmode/selectors';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
var ToolbarComponent = /** @class */ (function () {
    function ToolbarComponent(actionModeService, themeService, store) {
        this.actionModeService = actionModeService;
        this.themeService = themeService;
        this.store = store;
    }
    ToolbarComponent.prototype.ngOnInit = function () {
        var hasActionModeBeenEnabled = false;
        var prevThemeType;
        var currThemeType = this.themeService.getThemeType().themeType;
        var prevIsActionMode;
        var currIsActionMode = this.actionModeService.getActionMode() !== ActionMode.None;
        var toolbarState = this.store.select(getToolbarState);
        this.toolbarData$ = toolbarState.pipe(map(function (_a) {
            var mode = _a.mode, fromMl = _a.fromMl, toMl = _a.toMl, selections = _a.selections, unpairedSubPath = _a.unpairedSubPath, block = _a.block;
            return new ToolbarData(mode, fromMl, toMl, selections, unpairedSubPath, block);
        }));
        this.themeState$ = combineLatest(toolbarState, this.themeService.asObservable().pipe(map(function (t) { return t.themeType; }))).pipe(map(function (_a) {
            var mode = _a[0].mode, themeType = _a[1];
            hasActionModeBeenEnabled = hasActionModeBeenEnabled || mode !== ActionMode.None;
            prevThemeType = currThemeType;
            currThemeType = themeType;
            prevIsActionMode = currIsActionMode;
            currIsActionMode = mode !== ActionMode.None;
            return {
                hasActionModeBeenEnabled: hasActionModeBeenEnabled,
                prevThemeType: prevThemeType,
                currThemeType: currThemeType,
                prevIsActionMode: prevIsActionMode,
                currIsActionMode: currIsActionMode,
            };
        }));
    };
    Object.defineProperty(ToolbarComponent.prototype, "darkTheme", {
        get: function () {
            return this.themeService.getThemeType().themeType === 'dark';
        },
        set: function (isDark) {
            this.themeService.setTheme(isDark ? 'dark' : 'light');
        },
        enumerable: true,
        configurable: true
    });
    ToolbarComponent.prototype.onSendFeedbackClick = function (event) {
        ga('send', 'event', 'Miscellaneous', 'Send feedback click');
    };
    ToolbarComponent.prototype.onContributeClick = function (event) {
        ga('send', 'event', 'Miscellaneous', 'Contribute click');
    };
    ToolbarComponent.prototype.onGettingStartedClick = function (event) {
        ga('send', 'event', 'Miscellaneous', 'Getting started click');
    };
    ToolbarComponent.prototype.onAutoFixClick = function (event) {
        ga('send', 'event', 'Action mode', 'Auto fix click');
        event.stopPropagation();
        this.actionModeService.autoFix();
    };
    ToolbarComponent.prototype.onCloseActionModeClick = function (event) {
        event.stopPropagation();
        this.actionModeService.closeActionMode();
    };
    ToolbarComponent.prototype.onAddPointsClick = function (event) {
        ga('send', 'event', 'Action mode', 'Add points');
        event.stopPropagation();
        this.actionModeService.toggleSplitCommandsMode();
    };
    ToolbarComponent.prototype.onSplitSubPathsClick = function (event) {
        ga('send', 'event', 'Action mode', 'Split sub paths');
        event.stopPropagation();
        this.actionModeService.toggleSplitSubPathsMode();
    };
    ToolbarComponent.prototype.onPairSubPathsClick = function (event) {
        ga('send', 'event', 'Action mode', 'Pair sub paths');
        event.stopPropagation();
        this.actionModeService.togglePairSubPathsMode();
    };
    ToolbarComponent.prototype.onReversePointsClick = function (event) {
        event.stopPropagation();
        this.actionModeService.reverseSelectedSubPaths();
    };
    ToolbarComponent.prototype.onShiftBackPointsClick = function (event) {
        event.stopPropagation();
        this.actionModeService.shiftBackSelectedSubPaths();
    };
    ToolbarComponent.prototype.onShiftForwardPointsClick = function (event) {
        event.stopPropagation();
        this.actionModeService.shiftForwardSelectedSubPaths();
    };
    ToolbarComponent.prototype.onDeleteSubPathsClick = function (event) {
        event.stopPropagation();
        this.actionModeService.deleteSelectedActionModeModels();
    };
    ToolbarComponent.prototype.onDeleteSegmentsClick = function (event) {
        event.stopPropagation();
        this.actionModeService.deleteSelectedActionModeModels();
    };
    ToolbarComponent.prototype.onSetFirstPositionClick = function (event) {
        event.stopPropagation();
        this.actionModeService.shiftPointToFront();
    };
    ToolbarComponent.prototype.onSplitInHalfHoverEvent = function (isHovering) {
        if (isHovering) {
            this.actionModeService.splitInHalfHover();
        }
        else {
            this.actionModeService.clearHover();
        }
    };
    ToolbarComponent.prototype.onSplitInHalfClick = function (event) {
        event.stopPropagation();
        this.actionModeService.splitSelectedPointInHalf();
    };
    ToolbarComponent.prototype.onDeletePointsClick = function (event) {
        event.stopPropagation();
        this.actionModeService.deleteSelectedActionModeModels();
    };
    ToolbarComponent.prototype.isBeta = function () {
        return environment.beta;
    };
    return ToolbarComponent;
}());
export { ToolbarComponent };
var ToolbarData = /** @class */ (function () {
    function ToolbarData(mode, startMorphableLayer, endMorphableLayer, selections, unpair, block) {
        this.mode = mode;
        this.selections = selections;
        this.block = block;
        this.subPaths = [];
        this.segments = [];
        this.points = [];
        // Precondition: assume all selections are for the same canvas type
        if (!selections.length) {
            return;
        }
        var canvasType = selections[0].source;
        var morphableLayer = canvasType === ActionSource.From ? startMorphableLayer : endMorphableLayer;
        if (!morphableLayer) {
            return;
        }
        var activePath = morphableLayer.pathData;
        this.isFilled = morphableLayer.isFilled();
        this.isStroked = morphableLayer.isStroked();
        this.subPaths = selections.filter(function (s) { return s.type === SelectionType.SubPath; }).map(function (s) { return s.subIdx; });
        this.segments = selections
            .filter(function (s) {
            var subIdx = s.subIdx, cmdIdx = s.cmdIdx;
            return (s.type === SelectionType.Segment &&
                morphableLayer.isFilled() &&
                activePath.getCommand(subIdx, cmdIdx).isSplitSegment());
        })
            .map(function (s) {
            var subIdx = s.subIdx, cmdIdx = s.cmdIdx;
            return { subIdx: subIdx, cmdIdx: cmdIdx };
        });
        this.points = selections.filter(function (s) { return s.type === SelectionType.Point; }).map(function (s) {
            var subIdx = s.subIdx, cmdIdx = s.cmdIdx;
            return { subIdx: subIdx, cmdIdx: cmdIdx };
        });
        this.numSplitSubPaths = _.sumBy(this.subPaths, function (subIdx) {
            return activePath.getSubPath(subIdx).isUnsplittable() ? 1 : 0;
        });
        this.numSplitPoints = _.sumBy(this.points, function (s) {
            var subIdx = s.subIdx, cmdIdx = s.cmdIdx;
            return activePath.getCommand(subIdx, cmdIdx).isSplitPoint() ? 1 : 0;
        });
        this.showSetFirstPosition =
            this.points.length === 1 &&
                this.points[0].cmdIdx &&
                activePath.getSubPath(this.points[0].subIdx).isClosed();
        this.showShiftSubPath =
            this.subPaths.length > 0 && activePath.getSubPath(this.subPaths[0]).isClosed();
        this.showSplitInHalf = this.points.length === 1 && !!this.points[0].cmdIdx;
        if (this.mode === ActionMode.PairSubPaths) {
            if (unpair) {
                this.unpairedSubPathSource = unpair.source;
            }
        }
        this.showPairSubPaths =
            startMorphableLayer.pathData.getSubPaths().length === 1 &&
                endMorphableLayer.pathData.getSubPaths().length === 1
                ? false
                : this.getNumSubPaths() === 1 || this.getNumSegments() > 0 || !this.isSelectionMode();
    }
    ToolbarData.prototype.getNumSelections = function () {
        return this.subPaths.length + this.segments.length + this.points.length;
    };
    ToolbarData.prototype.getNumSubPaths = function () {
        return this.subPaths.length;
    };
    ToolbarData.prototype.getNumSegments = function () {
        return this.segments.length;
    };
    ToolbarData.prototype.getNumPoints = function () {
        return this.points.length;
    };
    ToolbarData.prototype.getToolbarTitle = function () {
        if (this.mode === ActionMode.SplitCommands) {
            return 'Add points';
        }
        if (this.mode === ActionMode.SplitSubPaths) {
            return 'Split subpaths';
        }
        if (this.mode === ActionMode.PairSubPaths) {
            return 'Pair subpaths';
        }
        var numSubPaths = this.getNumSubPaths();
        var subStr = numSubPaths + " subpath" + (numSubPaths === 1 ? '' : 's');
        var numSegments = this.getNumSegments();
        var segStr = numSegments + " segment" + (numSegments === 1 ? '' : 's');
        var numPoints = this.getNumPoints();
        var ptStr = numPoints + " point" + (numPoints === 1 ? '' : 's');
        if (numSubPaths > 0) {
            return subStr + " selected";
        }
        else if (numSegments > 0) {
            return segStr + " selected";
        }
        else if (numPoints > 0) {
            return ptStr + " selected";
        }
        else if (this.mode === ActionMode.Selection) {
            return 'Edit path morphing animation';
        }
        return 'Shape Shifter';
    };
    ToolbarData.prototype.getToolbarSubtitle = function () {
        if (this.mode === ActionMode.SplitCommands) {
            return 'Click along the edge of a subpath to add a point';
        }
        else if (this.mode === ActionMode.SplitSubPaths) {
            if (this.isFilled) {
                return 'Draw a line across a subpath to split it into 2';
            }
            else if (this.isStroked) {
                return 'Click along the edge of a subpath to split it into 2';
            }
        }
        else if (this.mode === ActionMode.PairSubPaths) {
            if (this.unpairedSubPathSource) {
                var toSourceDir = this.unpairedSubPathSource === ActionSource.From ? 'right' : 'left';
                return "Pair the selected subpath with a corresponding subpath on the " + toSourceDir;
            }
            return 'Select a subpath';
        }
        else if (this.mode === ActionMode.Selection) {
            var _a = ActionModeUtil.checkPathsCompatible(this.block), areCompatible = _a.areCompatible, errorPath = _a.errorPath, numPointsMissing_1 = _a.numPointsMissing;
            if (!areCompatible) {
                var createSubtitleFn = function (direction) {
                    if (numPointsMissing_1 === 1) {
                        return "Add 1 point to the highlighted subpath on the " + direction;
                    }
                    else {
                        return "Add " + numPointsMissing_1 + " points to the highlighted subpath on the " + direction;
                    }
                };
                if (errorPath === ActionSource.From) {
                    return createSubtitleFn('left');
                }
                else if (errorPath === ActionSource.To) {
                    return createSubtitleFn('right');
                }
                // This should never happen, but return empty string just to be safe.
                return '';
            }
            if (!this.getNumSubPaths() && !this.getNumSegments() && !this.getNumPoints()) {
                return 'Select something below to edit its properties';
            }
        }
        return '';
    };
    ToolbarData.prototype.shouldShowActionMode = function () {
        return this.mode !== ActionMode.None;
    };
    ToolbarData.prototype.shouldShowPairSubPaths = function () {
        return this.showPairSubPaths;
    };
    ToolbarData.prototype.getNumSplitSubPaths = function () {
        return this.numSplitSubPaths || 0;
    };
    ToolbarData.prototype.getNumSplitPoints = function () {
        return this.numSplitPoints || 0;
    };
    ToolbarData.prototype.shouldShowSetFirstPosition = function () {
        return this.showSetFirstPosition || false;
    };
    ToolbarData.prototype.shouldShowShiftSubPath = function () {
        return this.showShiftSubPath || false;
    };
    ToolbarData.prototype.shouldShowSplitInHalf = function () {
        return this.showSplitInHalf || false;
    };
    ToolbarData.prototype.isSelectionMode = function () {
        return this.mode === ActionMode.None || this.mode === ActionMode.Selection;
    };
    ToolbarData.prototype.isAddPointsMode = function () {
        return this.mode === ActionMode.SplitCommands;
    };
    ToolbarData.prototype.isSplitSubPathsMode = function () {
        return this.mode === ActionMode.SplitSubPaths;
    };
    ToolbarData.prototype.isPairSubPathsMode = function () {
        return this.mode === ActionMode.PairSubPaths;
    };
    ToolbarData.prototype.shouldShowAutoFix = function () {
        return this.mode === ActionMode.Selection && !this.getNumSelections();
    };
    return ToolbarData;
}());
