import * as tslib_1 from "tslib";
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { PaperUtil } from 'app/modules/editor/scripts/paper/util';
import * as _ from 'lodash';
import * as paper from 'paper';
/**
 * A gesture that selects multiple segments using a bounded box.
 *
 * Preconditions:
 * - The user is in edit path mode.
 */
var BatchSelectSegmentsGesture = /** @class */ (function (_super) {
    tslib_1.__extends(BatchSelectSegmentsGesture, _super);
    function BatchSelectSegmentsGesture(ps, editPathId, clearEditPathAfterDraglessClick) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.editPathId = editPathId;
        _this.clearEditPathAfterDraglessClick = clearEditPathAfterDraglessClick;
        _this.pl = paper.project.activeLayer;
        _this.isDragging = false;
        return _this;
    }
    // @Override
    BatchSelectSegmentsGesture.prototype.onMouseDown = function (event) {
        this.initialSelectedSegments = this.ps.getEditPathInfo().selectedSegments;
        this.updatedSelectedSegments = new Set();
        this.updateCurrentSelection(event.modifiers.command || event.modifiers.shift);
    };
    // @Override
    BatchSelectSegmentsGesture.prototype.onMouseDrag = function (event) {
        this.isDragging = true;
        this.ps.setSelectionBox({
            from: this.pl.globalToLocal(event.downPoint),
            to: this.pl.globalToLocal(event.point),
        });
        this.processToolEvent(event);
    };
    // @Override
    BatchSelectSegmentsGesture.prototype.onMouseUp = function (event) {
        if (this.isDragging) {
            this.processToolEvent(event);
        }
        else if (this.clearEditPathAfterDraglessClick) {
            this.ps.setEditPathInfo(undefined);
        }
        this.ps.setSelectionBox(undefined);
    };
    BatchSelectSegmentsGesture.prototype.processToolEvent = function (event) {
        // Calculate the bounding rectangle to use to select segments in
        // the edit path's local coordinate space.
        var editPath = this.pl.findItemByLayerId(this.editPathId);
        var rectangle = new paper.Rectangle(editPath.globalToLocal(event.downPoint), editPath.globalToLocal(event.point));
        this.updatedSelectedSegments = new Set(_.flatMap(editPath.segments, function (_a, segmentIndex) {
            var point = _a.point;
            return rectangle.contains(point) ? [segmentIndex] : [];
        }));
        this.updateCurrentSelection(event.modifiers.command || event.modifiers.shift);
    };
    // @Override
    BatchSelectSegmentsGesture.prototype.onKeyDown = function (event) {
        this.processKeyEvent(event);
    };
    // @Override
    BatchSelectSegmentsGesture.prototype.onKeyUp = function (event) {
        this.processKeyEvent(event);
    };
    BatchSelectSegmentsGesture.prototype.processKeyEvent = function (event) {
        if (event.key === 'ctrl' || event.key === 'meta' || event.key === 'shift') {
            this.updateCurrentSelection(event.modifiers.command || event.modifiers.shift);
        }
    };
    BatchSelectSegmentsGesture.prototype.updateCurrentSelection = function (toggleInitialSelections) {
        var selectedSegments = new Set(this.updatedSelectedSegments);
        if (toggleInitialSelections) {
            this.initialSelectedSegments.forEach(function (segmentIndex) {
                if (selectedSegments.has(segmentIndex)) {
                    selectedSegments.delete(segmentIndex);
                }
                else {
                    selectedSegments.add(segmentIndex);
                }
            });
        }
        var editPath = this.pl.findItemByLayerId(this.editPathId);
        this.ps.setEditPathInfo(tslib_1.__assign({}, PaperUtil.selectCurves(editPath, selectedSegments)));
    };
    return BatchSelectSegmentsGesture;
}(Gesture));
export { BatchSelectSegmentsGesture };
