import { Path } from 'app/modules/editor/model/paths';
import { MathUtil, Matrix } from 'app/modules/editor/scripts/common';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { ClipPathLayer, GroupLayer, PathLayer } from './Layer';
var IS_DEV_BUILD = !environment.production;
/**
 * Returns a single flattened transform matrix that can be used to perform canvas
 * transform operations. The resulting matrix will transform path coordinates to
 * canvas drawing coordinates. The inverse of the matrix will transform canvas
 * drawing coordinates back to path coordinates.
 */
export function getCanvasTransformForLayer(root, layerId) {
    return Matrix.flatten(getCanvasTransformsForLayer(root, layerId));
}
/**
 * Returns a list of parent transforms for the specified layer ID. The transforms
 * are returned in top-down order (i.e. the transform for the layer's
 * immediate parent will be the very last matrix in the returned list).
 */
function getCanvasTransformsForLayer(root, layerId) {
    return (function recurseFn(parents, current) {
        if (current.id === layerId) {
            return _.flatMap(parents, function (l) {
                return l instanceof GroupLayer ? getCanvasTransformsForGroupLayer(l) : [];
            });
        }
        for (var _i = 0, _a = current.children; _i < _a.length; _i++) {
            var child = _a[_i];
            var transforms = recurseFn(parents.concat([current]), child);
            if (transforms) {
                return transforms;
            }
        }
        return undefined;
    })([], root);
}
/**
 * Returns a list of matrix transforms for a given group layer.
 */
export function getCanvasTransformsForGroupLayer(l) {
    // First negative pivot, then scale, then rotation, then translation, then pivot.
    // When drawing a path, the transforms are applied at the bottom up, which
    // is why the order appears to be reversed below.
    return [
        Matrix.translation(l.pivotX, l.pivotY),
        Matrix.translation(l.translateX, l.translateY),
        Matrix.rotation(l.rotation),
        Matrix.scaling(l.scaleX, l.scaleY),
        Matrix.translation(-l.pivotX, -l.pivotY),
    ];
}
/**
 * Makes two vector layers with possibly different viewports compatible with each other.
 */
export function adjustViewports(vl1, vl2) {
    if (!vl1 || !vl2) {
        return { vl1: vl1, vl2: vl2 };
    }
    vl1 = vl1.deepClone();
    vl2 = vl2.deepClone();
    var w1 = vl1.width, h1 = vl1.height;
    var w2 = vl2.width, h2 = vl2.height;
    var isMaxDimenFn = function (n) { return Math.max(w1, h1, w2, h2, n) === n; };
    var scale1 = 1;
    var scale2 = 1;
    if (isMaxDimenFn(w1)) {
        scale2 = w1 / w2;
    }
    else if (isMaxDimenFn(h1)) {
        scale2 = h1 / h2;
    }
    else if (isMaxDimenFn(w2)) {
        scale1 = w2 / w1;
    }
    else {
        scale1 = h2 / h1;
    }
    if (isMaxDimenFn(w1) || isMaxDimenFn(h1)) {
        w1 = MathUtil.round(w1);
        h1 = MathUtil.round(h1);
        w2 = MathUtil.round(w2 * scale2);
        h2 = MathUtil.round(h2 * scale2);
    }
    else {
        w1 = MathUtil.round(w1 * scale1);
        h1 = MathUtil.round(h1 * scale1);
        w2 = MathUtil.round(w2);
        h2 = MathUtil.round(h2);
    }
    var tx1 = 0;
    var ty1 = 0;
    var tx2 = 0;
    var ty2 = 0;
    if (w1 > w2) {
        tx2 = (w1 - w2) / 2;
    }
    else if (w1 < w2) {
        tx1 = (w2 - w1) / 2;
    }
    else if (h1 > h2) {
        ty2 = (h1 - h2) / 2;
    }
    else if (h1 < h2) {
        ty1 = (h2 - h1) / 2;
    }
    var transformLayerFn = function (vl, scale, tx, ty) {
        var transforms = Matrix.flatten([Matrix.scaling(scale, scale), Matrix.translation(tx, ty)]);
        (function recurseFn(layer) {
            if (layer instanceof PathLayer || layer instanceof ClipPathLayer) {
                if (layer instanceof PathLayer && layer.isStroked()) {
                    layer.strokeWidth *= scale;
                }
                if (layer.pathData) {
                    layer.pathData = new Path(layer.pathData.getCommands().map(function (cmd) {
                        return cmd
                            .mutate()
                            .transform(transforms)
                            .build();
                    }));
                }
                return;
            }
            if (layer instanceof GroupLayer) {
                var l = layer;
                l.translateX *= scale;
                l.translateY *= scale;
                l.pivotX *= scale;
                l.pivotY *= scale;
            }
            layer.children.forEach(function (l) { return recurseFn(l); });
        })(vl);
    };
    transformLayerFn(vl1, scale1, tx1, ty1);
    transformLayerFn(vl2, scale2, tx2, ty2);
    var newWidth = Math.max(w1, w2);
    var newHeight = Math.max(h1, h2);
    vl1.width = newWidth;
    vl2.width = newWidth;
    vl1.height = newHeight;
    vl2.height = newHeight;
    return { vl1: vl1, vl2: vl2 };
}
export function mergeVectorLayers(vl1, vl2) {
    var _a = adjustViewports(vl1, vl2), newVl1 = _a.vl1, newVl2 = _a.vl2;
    var vl = setLayerChildren(newVl1, newVl1.children.concat(newVl2.children));
    if (!newVl1.children.length) {
        // Only replace the vector layer's alpha if there are no children
        // being displayed to the user. This is pretty much the best
        // we can do.
        vl.alpha = newVl2.alpha;
    }
    return vl;
}
/**
 * Adds a list of children to a parent layer in a vector layer tree.
 * @param root the root vector layer
 * @param addedLayerParentId the parent layer in which to add the given layers
 * @param startingChildIndex the index to start adding the layers
 * @param addedLayers the layers to add
 */
export function addLayers(root, addedLayerParentId, startingChildIndex) {
    var addedLayers = [];
    for (var _i = 3; _i < arguments.length; _i++) {
        addedLayers[_i - 3] = arguments[_i];
    }
    return (function recurseFn(curr) {
        if (curr.id === addedLayerParentId) {
            // If we have reached the added layer's parent, then
            // clone the parent, insert the new layer into its list
            // of children, and return the new parent node.
            var children = curr.children.slice();
            children.splice.apply(children, [startingChildIndex, 0].concat(addedLayers));
            return setLayerChildren(curr, children);
        }
        for (var i = 0; i < curr.children.length; i++) {
            var clonedChild = recurseFn(curr.children[i]);
            if (clonedChild) {
                // Then clone the current layer, insert the cloned child
                // into its list of children, and return the cloned current layer.
                var children = curr.children.slice();
                children[i] = clonedChild;
                return setLayerChildren(curr, children);
            }
        }
        return undefined;
    })(root);
}
export function removeLayers(layer) {
    var removedLayerIds = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        removedLayerIds[_i - 1] = arguments[_i];
    }
    var layerIds = new Set(removedLayerIds);
    return (function recurseFn(curr) {
        if (layerIds.has(curr.id)) {
            return undefined;
        }
        var children = curr.children.map(recurseFn).filter(function (l) { return !!l; });
        return setLayerChildren(curr, children);
    })(layer);
}
export function updateLayer(vl, layer) {
    return replaceLayer(vl, layer.id, layer);
}
export function replaceLayer(vl, layerId, replacement) {
    if (IS_DEV_BUILD && !vl.findLayerById(layerId)) {
        console.warn('Attempt to replace a layer that does not exist in the tree');
    }
    return (function recurseFn(curr) {
        return curr.id === layerId
            ? replacement
            : setLayerChildren(curr, curr.children.map(function (child) { return recurseFn(child); }));
    })(vl);
}
export function runPreorderTraversal(layer) {
    // Add the layers as we iterate the tree to ensure they are properly sorted.
    var layers = [];
    (function recurseFn(l) {
        layers.push(l);
        l.children.forEach(recurseFn);
    })(layer);
    return layers;
}
export function findLayerByName(layers, layerName) {
    for (var _i = 0, layers_1 = layers; _i < layers_1.length; _i++) {
        var layer = layers_1[_i];
        var target = layer.findLayerByName(layerName);
        if (target) {
            return target;
        }
    }
    return undefined;
}
export function findParent(vl, layerId) {
    return (function recurseFn(curr, parent) {
        if (curr.id === layerId) {
            return parent;
        }
        for (var _i = 0, _a = curr.children; _i < _a.length; _i++) {
            var child = _a[_i];
            var p = recurseFn(child, curr);
            if (p) {
                return p;
            }
        }
        return undefined;
    })(vl);
}
export function findNextSibling(vl, layerId) {
    return findSibling(layerId, findParent(vl, layerId), 1);
}
export function findPreviousSibling(vl, layerId) {
    return findSibling(layerId, findParent(vl, layerId), -1);
}
function findSibling(layerId, parent, offset) {
    if (!parent || !parent.children) {
        return undefined;
    }
    var index = _.findIndex(parent.children, function (c) { return c.id === layerId; });
    if (index < 0) {
        return undefined;
    }
    index += offset;
    if (index < 0 || parent.children.length <= index) {
        return undefined;
    }
    return parent.children[index];
}
export function getUniqueLayerName(layers, prefix) {
    return getUniqueName(prefix, function (name) { return findLayerByName(layers, name); });
}
export function getUniqueName(prefix, objectByNameFn) {
    if (prefix === void 0) { prefix = ''; }
    if (objectByNameFn === void 0) { objectByNameFn = function (s) { return undefined; }; }
    var n = 0;
    var nameFn = function () { return prefix + (n ? "_" + n : ''); };
    while (true) {
        var o = objectByNameFn(nameFn());
        if (!o) {
            break;
        }
        n++;
    }
    return nameFn();
}
/**
 * Returns a cloned layer with the specified list of children layers.
 */
function setLayerChildren(layer, children) {
    var clone = layer.clone();
    clone.children = children;
    return clone;
}
export function toStrokeDashArray(trimPathStart, trimPathEnd, trimPathOffset, pathLength, 
// TODO: remove this eventually... it is used to fix a canvas bug (that I am probably not handling correctly)
marginOfError) {
    if (marginOfError === void 0) { marginOfError = 0; }
    // Calculate the visible fraction of the trimmed path. If trimPathStart
    // is greater than trimPathEnd, then the result should be the combined
    // length of the two line segments: [trimPathStart,1] and [0,trimPathEnd].
    var shownFraction = trimPathEnd - trimPathStart;
    if (trimPathStart > trimPathEnd) {
        shownFraction += 1;
    }
    // Calculate the dash array. The first array element is the length of
    // the trimmed path and the second element is the gap, which is the
    // difference in length between the total path length and the visible
    // trimmed path length.
    return [shownFraction * pathLength, (1 - shownFraction + marginOfError) * pathLength];
}
export function toStrokeDashOffset(trimPathStart, trimPathEnd, trimPathOffset, pathLength) {
    // The amount to offset the path is equal to the trimPathStart plus
    // trimPathOffset. We mod the result because the trimmed path
    // should wrap around once it reaches 1.
    return pathLength * (1 - ((trimPathStart + trimPathOffset) % 1));
}
