import * as tslib_1 from "tslib";
import { ElementRef } from '@angular/core';
import { MathUtil } from 'app/modules/editor/scripts/common';
import { ThemeService } from 'app/modules/editor/services';
import * as $ from 'jquery';
import { CanvasLayoutMixin } from './CanvasLayoutMixin';
// All dimensions are in CSS pixels.
var RULER_SIZE = 32;
var EXTRA_RULER_PADDING = 12;
var GRID_INTERVALS_PX = [1, 2, 4, 8, 16, 24, 48, 100, 100, 250];
var LABEL_OFFSET = 12;
var TICK_SIZE = 6;
var CanvasRulerDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CanvasRulerDirective, _super);
    function CanvasRulerDirective(elementRef, themeService) {
        var _this = _super.call(this) || this;
        _this.themeService = themeService;
        _this.$canvas = $(elementRef.nativeElement);
        return _this;
    }
    // @Override
    CanvasRulerDirective.prototype.onDimensionsChanged = function () {
        this.draw();
    };
    // @Override
    CanvasRulerDirective.prototype.onZoomPanChanged = function () {
        this.draw();
    };
    CanvasRulerDirective.prototype.hideMouse = function () {
        if (this.vpMousePoint) {
            this.vpMousePoint = undefined;
            this.draw();
        }
    };
    // TODO: need to transform mouse point to account for zoom and translation
    CanvasRulerDirective.prototype.showMouse = function (mousePoint) {
        if (!this.vpMousePoint || !MathUtil.arePointsEqual(this.vpMousePoint, mousePoint)) {
            this.vpMousePoint = mousePoint;
            this.draw();
        }
    };
    CanvasRulerDirective.prototype.draw = function () {
        var isHorizontal = this.orientation === 'horizontal';
        var viewport = this.getViewport();
        var zoom = this.getZoom();
        var cssScale = this.cssScale;
        var width = isHorizontal
            ? viewport.w * cssScale * zoom + EXTRA_RULER_PADDING * 2
            : RULER_SIZE;
        var height = isHorizontal
            ? RULER_SIZE
            : viewport.h * cssScale * zoom + EXTRA_RULER_PADDING * 2;
        this.$canvas.css({ width: width, height: height });
        this.$canvas.attr({ width: width * devicePixelRatio, height: height * devicePixelRatio });
        var ctx = this.$canvas.get(0).getContext('2d');
        ctx.scale(devicePixelRatio, devicePixelRatio);
        var _a = this.getTranslation(), tx = _a.tx, ty = _a.ty;
        ctx.translate(isHorizontal ? tx + EXTRA_RULER_PADDING : 0, isHorizontal ? 0 : ty + EXTRA_RULER_PADDING);
        var widthMinusPadding = width - EXTRA_RULER_PADDING * 2;
        var heightMinusPadding = height - EXTRA_RULER_PADDING * 2;
        var rulerZoom = Math.max(1, isHorizontal ? widthMinusPadding / viewport.w : heightMinusPadding / viewport.h);
        // TODO: change the grid spacing depending on the current zoom?
        // Compute grid spacing (40 = minimum grid spacing in pixels).
        var interval = 0;
        var spacingViewportPx = GRID_INTERVALS_PX[interval];
        while (spacingViewportPx * rulerZoom < 40 || interval >= GRID_INTERVALS_PX.length) {
            interval++;
            spacingViewportPx = GRID_INTERVALS_PX[interval];
        }
        var spacingRulerPx = spacingViewportPx * rulerZoom;
        // Text labels.
        ctx.fillStyle = this.themeService.getDisabledTextColor();
        ctx.font = '10px Roboto, Helvetica Neue, sans-serif';
        if (isHorizontal) {
            ctx.textBaseline = 'alphabetic';
            ctx.textAlign = 'center';
            var minX = -tx;
            var maxX = minX + widthMinusPadding / zoom;
            for (var x = 0, t = 0; MathUtil.round(x) <= MathUtil.round(width - EXTRA_RULER_PADDING * 2); x += spacingRulerPx, t += spacingViewportPx) {
                if (minX <= x && x <= maxX) {
                    ctx.fillText(t.toString(), x, height - LABEL_OFFSET);
                    ctx.fillRect(x - 0.5, height - TICK_SIZE, 1, TICK_SIZE);
                }
            }
        }
        else {
            ctx.textBaseline = 'middle';
            ctx.textAlign = 'right';
            var minY = -ty;
            var maxY = minY + heightMinusPadding / zoom;
            for (var y = 0, t = 0; MathUtil.round(y) <= MathUtil.round(height - EXTRA_RULER_PADDING * 2); y += spacingRulerPx, t += spacingViewportPx) {
                if (minY <= y && y <= maxY) {
                    ctx.fillText(t.toString(), width - LABEL_OFFSET, y);
                    ctx.fillRect(width - TICK_SIZE, y - 0.5, TICK_SIZE, 1);
                }
            }
        }
        if (this.vpMousePoint) {
            var _b = this.vpMousePoint, x = _b.x, y = _b.y;
            ctx.fillStyle = this.themeService.getSecondaryTextColor();
            if (isHorizontal) {
                ctx.fillText(x.toString(), x * rulerZoom, height - LABEL_OFFSET);
            }
            else {
                ctx.fillText(y.toString(), width - LABEL_OFFSET, y * rulerZoom);
            }
        }
    };
    return CanvasRulerDirective;
}(CanvasLayoutMixin()));
export { CanvasRulerDirective };
