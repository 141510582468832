import * as tslib_1 from "tslib";
import { LayerUtil } from 'app/modules/editor/model/layers';
import { Path } from 'app/modules/editor/model/paths';
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { PaperUtil } from 'app/modules/editor/scripts/paper/util';
import * as paper from 'paper';
/**
 * A gesture that performs rotation operations.
 *
 * Preconditions:
 * - The user is in default mode.
 * - One or more layers are selected.
 * - A mouse down event occurred on a selection bounds handle.
 *
 * TODO: avoid jank at beginning of rotation (when angle is near 0)
 * TODO: make sure the 'empty group' logic we add also matches what we have in PaperLayer.ts
 * TODO: show a tool tip during rotations
 * TODO: make sure the pivot doesn't move during the initial drag
 */
var RotateItemsGesture = /** @class */ (function (_super) {
    tslib_1.__extends(RotateItemsGesture, _super);
    function RotateItemsGesture(ps) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.pl = paper.project.activeLayer;
        return _this;
    }
    // @Override
    RotateItemsGesture.prototype.onMouseDown = function (event) {
        var _this = this;
        this.ps.setHoveredLayerId(undefined);
        var scaleItems = [];
        var scaleItemsSet = new Set();
        Array.from(this.ps.getSelectedLayerIds())
            .map(function (id) { return _this.pl.findItemByLayerId(id); })
            // TODO: reuse this code with PaperLayer (filter out empty groups)
            .filter(function (i) { return !(i instanceof paper.Group) || i.children.length; })
            .forEach(function recurseFn(i) {
            if (i instanceof paper.Group) {
                i.children.forEach(recurseFn);
            }
            else if (!scaleItemsSet.has(i.data.id)) {
                scaleItemsSet.add(i.data.id);
                scaleItems.push(i);
            }
        });
        this.selectedItems = scaleItems;
        var invertedPaperLayerMatrix = this.pl.matrix.inverted();
        this.localToVpItemMatrices = this.selectedItems.map(function (item) {
            // Compute the matrices to directly transform during drag events.
            return item.globalMatrix.prepended(invertedPaperLayerMatrix).inverted();
        });
        var rii = this.ps.getRotateItemsInfo();
        if (rii.pivot) {
            this.vpPivot = new paper.Point(rii.pivot);
        }
        else {
            this.vpPivot = PaperUtil.transformRectangle(PaperUtil.computeBounds(this.selectedItems), invertedPaperLayerMatrix).center;
        }
        this.initialVectorLayer = this.ps.getVectorLayer();
        this.vpDownPoint = this.pl.globalToLocal(event.downPoint);
    };
    // @Override
    RotateItemsGesture.prototype.onMouseDrag = function (event) {
        this.vpPoint = this.pl.globalToLocal(event.point);
        this.processEvent(event);
    };
    // @Override
    RotateItemsGesture.prototype.onKeyDown = function (event) {
        this.processKeyEvent(event);
    };
    // @Override
    RotateItemsGesture.prototype.onKeyUp = function (event) {
        this.processKeyEvent(event);
    };
    RotateItemsGesture.prototype.processKeyEvent = function (event) {
        if (event.key === 'shift') {
            this.processEvent(event);
        }
    };
    // TODO: determine if we should be baking transforms into the children layers when rotating a group?
    RotateItemsGesture.prototype.processEvent = function (event) {
        var _this = this;
        if (!this.vpPoint) {
            return;
        }
        var rotationAngle = this.getRotationAngle(event);
        var newVl = this.initialVectorLayer.clone();
        this.selectedItems.forEach(function (item, index) {
            var path = item.clone();
            path.applyMatrix = true;
            var localToViewportMatrix = _this.localToVpItemMatrices[index];
            var matrix = localToViewportMatrix.clone();
            matrix.rotate(rotationAngle, _this.vpPivot);
            matrix.append(localToViewportMatrix.inverted());
            path.matrix = matrix;
            var newPl = newVl.findLayerById(item.data.id).clone();
            newPl.pathData = new Path(path.pathData);
            newVl = LayerUtil.replaceLayer(newVl, item.data.id, newPl);
        });
        this.ps.setVectorLayer(newVl);
    };
    RotateItemsGesture.prototype.getRotationAngle = function (event) {
        var initialDelta = this.vpDownPoint.subtract(this.vpPivot);
        var initialAngle = (Math.atan2(initialDelta.y, initialDelta.x) * 180) / Math.PI;
        var delta = this.vpPoint.subtract(this.vpPivot);
        var angle = (Math.atan2(delta.y, delta.x) * 180) / Math.PI - initialAngle;
        // TODO: this doesn't round properly if the angle was previously changed
        return event.modifiers.shift ? Math.round(angle / 15) * 15 : angle;
    };
    return RotateItemsGesture;
}(Gesture));
export { RotateItemsGesture };
