/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timelineanimationrow.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./timelineanimationrow.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../services/actionmode.service";
var styles_TimelineAnimationRowComponent = [i0.styles];
var RenderType_TimelineAnimationRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimelineAnimationRowComponent, data: {} });
export { RenderType_TimelineAnimationRowComponent as RenderType_TimelineAnimationRowComponent };
function View_TimelineAnimationRowComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "slt-timeline-block"], ["tabindex", "-1"]], null, [[null, "dblclick"], [null, "click"], [null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dblclick" === en)) {
        var pd_0 = (_co.onTimelineBlockDoubleClick($event, _v.context.$implicit, _co.layer) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onTimelineBlockClick($event, _v.context.$implicit, _co.layer) !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (_co.onTimelineBlockMouseDown($event, _v.context.$implicit, _co.layer) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "is-disabled": 0, "is-selected": 1, "has-error": 2, "is-selected-with-error": 3 }), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "left": 0, "width": 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "slt-timeline-block-edge slt-timeline-block-edge-start"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "slt-timeline-block-edge slt-timeline-block-edge-end"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "slt-timeline-block"; var currVal_1 = _ck(_v, 2, 0, _v.parent.parent.parent.context.ngIf.isActionMode, (_v.context.$implicit.isAnimatable() && _v.parent.parent.parent.context.ngIf.selectedBlockIds.has(_v.context.$implicit.id)), (!_v.context.$implicit.isAnimatable() && !_v.parent.parent.parent.context.ngIf.selectedBlockIds.has(_v.context.$implicit.id)), (!_v.context.$implicit.isAnimatable() && _v.parent.parent.parent.context.ngIf.selectedBlockIds.has(_v.context.$implicit.id))); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 4, 0, (((100 * _v.context.$implicit.startTime) / _v.parent.parent.parent.context.ngIf.animation.duration) + "%"), (((100 * (_v.context.$implicit.endTime - _v.context.$implicit.startTime)) / _v.parent.parent.parent.context.ngIf.animation.duration) + "%")); _ck(_v, 3, 0, currVal_2); }, null); }
function View_TimelineAnimationRowComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slt-property"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineAnimationRowComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TimelineAnimationRowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "slt-properties"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "slt-properties-empty": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineAnimationRowComponent_3)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = "slt-properties"; var currVal_1 = _ck(_v, 2, 0, !_v.parent.context.ngIf.blocksByPropertyNameValues.length); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.ngIf.blocksByPropertyNameValues; _ck(_v, 4, 0, currVal_2); }, null); }
function View_TimelineAnimationRowComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-timelineanimationrow", [], null, [[null, "timelineBlockMouseDown"], [null, "timelineBlockClick"], [null, "timelineBlockDoubleClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("timelineBlockMouseDown" === en)) {
        var pd_0 = (_co.onTimelineBlockMouseDown($event.event, $event.block, $event.layer) !== false);
        ad = (pd_0 && ad);
    } if (("timelineBlockClick" === en)) {
        var pd_1 = (_co.onTimelineBlockClick($event.event, $event.block, $event.layer) !== false);
        ad = (pd_1 && ad);
    } if (("timelineBlockDoubleClick" === en)) {
        var pd_2 = (_co.onTimelineBlockDoubleClick($event.event, $event.block, $event.layer) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_TimelineAnimationRowComponent_0, RenderType_TimelineAnimationRowComponent)), i1.ɵdid(2, 114688, null, 0, i3.TimelineAnimationRowComponent, [i4.Store, i5.ActionModeService], { layer: [0, "layer"] }, { timelineBlockClick: "timelineBlockClick", timelineBlockMouseDown: "timelineBlockMouseDown", timelineBlockDoubleClick: "timelineBlockDoubleClick" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TimelineAnimationRowComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "slt-children-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineAnimationRowComponent_6)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.layer.children; var currVal_1 = _co.trackLayerFn; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TimelineAnimationRowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineAnimationRowComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineAnimationRowComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf.isExpanded; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.layer.children.length > 0) && _v.context.ngIf.isExpanded); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TimelineAnimationRowComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "slt-layer-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TimelineAnimationRowComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.animationRowModel$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TimelineAnimationRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timelineanimationrow", [], null, null, null, View_TimelineAnimationRowComponent_0, RenderType_TimelineAnimationRowComponent)), i1.ɵdid(1, 114688, null, 0, i3.TimelineAnimationRowComponent, [i4.Store, i5.ActionModeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineAnimationRowComponentNgFactory = i1.ɵccf("app-timelineanimationrow", i3.TimelineAnimationRowComponent, View_TimelineAnimationRowComponent_Host_0, { layer: "layer" }, { timelineBlockClick: "timelineBlockClick", timelineBlockMouseDown: "timelineBlockMouseDown", timelineBlockDoubleClick: "timelineBlockDoubleClick" }, []);
export { TimelineAnimationRowComponentNgFactory as TimelineAnimationRowComponentNgFactory };
