import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { ActionSource } from 'app/modules/editor/model/actionmode';
import { DestroyableMixin } from 'app/modules/editor/scripts/mixins';
import { PaperProject } from 'app/modules/editor/scripts/paper';
import { PaperService } from 'app/modules/editor/services';
import { Store } from 'app/modules/editor/store';
import * as $ from 'jquery';
import { CanvasLayoutMixin } from './CanvasLayoutMixin';
var CanvasPaperDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CanvasPaperDirective, _super);
    function CanvasPaperDirective(elementRef, ps, store) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.store = store;
        _this.$canvas = $(elementRef.nativeElement);
        return _this;
    }
    CanvasPaperDirective.prototype.ngAfterViewInit = function () {
        this.paperProject = new PaperProject(this.$canvas.get(0), this.ps, this.store);
    };
    CanvasPaperDirective.prototype.ngOnDestroy = function () {
        this.paperProject.remove();
    };
    // @Override
    CanvasPaperDirective.prototype.onDimensionsChanged = function () {
        var _a = this.getViewport(), w = _a.w, h = _a.h;
        var scale = this.cssScale;
        this.$canvas.css({ width: w * scale, height: h * scale });
        this.paperProject.setDimensions(w, h, w * scale, h * scale);
    };
    return CanvasPaperDirective;
}(CanvasLayoutMixin(DestroyableMixin())));
export { CanvasPaperDirective };
