<div fxLayout="column"
  fxLayoutAlign="center center">

  <mat-icon class="splashscreen-logo"
    svgIcon="shapeshifter">
  </mat-icon>

  <div class="splashscreen-text">Shape Shifter is an icon animation tool designed for desktop browsers</div>

</div>
