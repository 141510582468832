import { MatIconRegistry, } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { errorHandlerFactory } from 'app/modules/editor/scripts/bugsnag';
var ɵ0 = errorHandlerFactory;
var EditorModule = /** @class */ (function () {
    function EditorModule(matIconRegistry, sanitizer) {
        this.sanitizer = sanitizer;
        matIconRegistry
            // Logo.
            .addSvgIcon('shapeshifter', this.trustUrl('assets/shapeshifter.svg'))
            // Icons.
            .addSvgIcon('addlayer', this.trustUrl('assets/icons/addlayer.svg'))
            .addSvgIcon('autofix', this.trustUrl('assets/icons/autofix.svg'))
            .addSvgIcon('contribute', this.trustUrl('assets/icons/contribute.svg'))
            .addSvgIcon('reverse', this.trustUrl('assets/icons/reverse.svg'))
            .addSvgIcon('animation', this.trustUrl('assets/icons/animation.svg'))
            .addSvgIcon('collection', this.trustUrl('assets/icons/collection.svg'))
            .addSvgIcon('animationblock', this.trustUrl('assets/icons/animationblock.svg'))
            .addSvgIcon('mask', this.trustUrl('assets/icons/clippathlayer.svg'))
            .addSvgIcon('group', this.trustUrl('assets/icons/grouplayer.svg'))
            .addSvgIcon('path', this.trustUrl('assets/icons/pathlayer.svg'))
            .addSvgIcon('vector', this.trustUrl('assets/icons/vectorlayer.svg'))
            // Tools.
            .addSvgIcon('tool_select', this.trustUrl('assets/tools/tool_select.svg'))
            .addSvgIcon('tool_pencil', this.trustUrl('assets/tools/tool_pencil.svg'))
            .addSvgIcon('tool_vector', this.trustUrl('assets/tools/tool_vector.svg'))
            .addSvgIcon('tool_ellipse', this.trustUrl('assets/tools/tool_ellipse.svg'))
            .addSvgIcon('tool_rectangle', this.trustUrl('assets/tools/tool_rectangle.svg'))
            .addSvgIcon('tool_zoompan', this.trustUrl('assets/tools/tool_zoompan.svg'));
    }
    EditorModule.prototype.trustUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    return EditorModule;
}());
export { EditorModule };
export { ɵ0 };
