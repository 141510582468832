import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Property } from './Property';
var EnumProperty = /** @class */ (function (_super) {
    tslib_1.__extends(EnumProperty, _super);
    function EnumProperty(name, options) {
        var _this = _super.call(this, name) || this;
        _this.options = options;
        return _this;
    }
    // @Override
    EnumProperty.prototype.displayValueForValue = function (value) {
        return _.find(this.options, function (o) { return o.value === value; }).label;
    };
    // @Override
    EnumProperty.prototype.getTypeName = function () {
        return 'EnumProperty';
    };
    return EnumProperty;
}(Property));
export { EnumProperty };
