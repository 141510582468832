import * as tslib_1 from "tslib";
import * as paper from 'paper';
var PIVOT_TYPES = [
    'bottomLeft',
    'leftCenter',
    'topLeft',
    'topCenter',
    'topRight',
    'rightCenter',
    'bottomRight',
    'bottomCenter',
];
var SelectionBoundsRaster = /** @class */ (function (_super) {
    tslib_1.__extends(SelectionBoundsRaster, _super);
    function SelectionBoundsRaster() {
        return _super.call(this, "/assets/paper/selection-bounds-segment.png") || this;
    }
    SelectionBoundsRaster.of = function (pivotType, center) {
        if (!SelectionBoundsRaster.instance) {
            SelectionBoundsRaster.instance = new SelectionBoundsRaster();
        }
        var raster = SelectionBoundsRaster.instance.clone(false);
        raster.position = center;
        raster.pivotType_ = pivotType;
        raster.oppositePivotType_ = getOppositePivotType(pivotType);
        return raster;
    };
    Object.defineProperty(SelectionBoundsRaster.prototype, "pivotType", {
        get: function () {
            return this.pivotType_;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectionBoundsRaster.prototype, "oppositePivotType", {
        get: function () {
            return this.oppositePivotType_;
        },
        enumerable: true,
        configurable: true
    });
    return SelectionBoundsRaster;
}(paper.Raster));
export { SelectionBoundsRaster };
var ɵ0 = function (arr) {
    return arr.map(function (_, i) { return arr[(i + arr.length / 2) % arr.length]; });
};
var OPPOSITE_PIVOT_TYPES = (ɵ0)(PIVOT_TYPES);
function getOppositePivotType(pivotType) {
    return OPPOSITE_PIVOT_TYPES[PIVOT_TYPES.indexOf(pivotType)];
}
export { ɵ0 };
