import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit, QueryList, } from '@angular/core';
import { DialogService } from 'app/modules/editor/components/dialogs';
import { ProjectService } from 'app/modules/editor/components/project';
import { ActionMode } from 'app/modules/editor/model/actionmode';
import { ClipPathLayer, GroupLayer, LayerUtil, PathLayer, } from 'app/modules/editor/model/layers';
import { ModelUtil } from 'app/modules/editor/scripts/common';
import { Dragger } from 'app/modules/editor/scripts/dragger';
import { IntervalTree } from 'app/modules/editor/scripts/intervals';
import { DestroyableMixin } from 'app/modules/editor/scripts/mixins';
import { ActionModeService, FileExportService, FileImportService, LayerTimelineService, PlaybackService, ThemeService, } from 'app/modules/editor/services';
import { Shortcut, ShortcutService } from 'app/modules/editor/services/shortcut.service';
import { Duration, SnackBarService } from 'app/modules/editor/services/snackbar.service';
import { Store } from 'app/modules/editor/store';
import { BatchAction } from 'app/modules/editor/store/batch/actions';
import { getLayerTimelineState, isWorkspaceDirty } from 'app/modules/editor/store/common/selectors';
import { SetSelectedLayers, SetVectorLayer } from 'app/modules/editor/store/layers/actions';
import { getVectorLayer } from 'app/modules/editor/store/layers/selectors';
import { ResetWorkspace } from 'app/modules/editor/store/reset/actions';
import { getAnimation } from 'app/modules/editor/store/timeline/selectors';
import { environment } from 'environments/environment';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import * as TimelineConsts from './constants';
import { LayerTimelineGridDirective } from './layertimelinegrid.directive';
var IS_DEV_BUILD = !environment.production;
// Distance in pixels from a snap point before snapping to the point.
var SNAP_PIXELS = 10;
var LAYER_INDENT_PIXELS = 20;
var MIN_BLOCK_DURATION = 10;
var MAX_ZOOM = 10;
var MIN_ZOOM = 0.01;
var DEFAULT_HORIZ_ZOOM = 2; // 1ms = 2px.
var MouseActions;
(function (MouseActions) {
    // We are dragging a block to a different location on the timeline.
    MouseActions[MouseActions["Moving"] = 1] = "Moving";
    // Scales all selected blocks w/o altering their initial positions.
    MouseActions[MouseActions["ScalingUniformStart"] = 2] = "ScalingUniformStart";
    MouseActions[MouseActions["ScalingUniformEnd"] = 3] = "ScalingUniformEnd";
    // Scales all blocks and also translates their initial positions.
    MouseActions[MouseActions["ScalingTogetherStart"] = 4] = "ScalingTogetherStart";
    MouseActions[MouseActions["ScalingTogetherEnd"] = 5] = "ScalingTogetherEnd";
})(MouseActions || (MouseActions = {}));
var LayerTimelineComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LayerTimelineComponent, _super);
    function LayerTimelineComponent(fileImportService, fileExportService, snackBarService, playbackService, store, dialogService, projectService, actionModeService, shortcutService, layerTimelineService, themeService) {
        var _this = _super.call(this) || this;
        _this.fileImportService = fileImportService;
        _this.fileExportService = fileExportService;
        _this.snackBarService = snackBarService;
        _this.playbackService = playbackService;
        _this.store = store;
        _this.dialogService = dialogService;
        _this.projectService = projectService;
        _this.actionModeService = actionModeService;
        _this.shortcutService = shortcutService;
        _this.layerTimelineService = layerTimelineService;
        _this.themeService = themeService;
        _this.dragIndicatorSubject = new BehaviorSubject({
            isVisible: false,
            left: 0,
            top: 0,
        });
        _this.dragIndicatorObservable = _this.dragIndicatorSubject.asObservable();
        _this.horizZoomSubject = new BehaviorSubject(DEFAULT_HORIZ_ZOOM);
        _this.horizZoomObservable = _this.horizZoomSubject.asObservable();
        _this.currentTime_ = 0;
        _this.shouldSuppressClick = false;
        _this.shouldSuppressRebuildSnapTimes = false;
        _this.performZoomRAF = undefined;
        _this.endZoomTimeout = undefined;
        return _this;
    }
    LayerTimelineComponent.prototype.ngOnInit = function () {
        var _this = this;
        var currActionMode;
        this.layerTimelineModel$ = this.store.select(getLayerTimelineState).pipe(map(function (_a) {
            var animation = _a.animation, vectorLayer = _a.vectorLayer, isAnimationSelected = _a.isAnimationSelected, selectedBlockIds = _a.selectedBlockIds, isBeingReset = _a.isBeingReset, isActionMode = _a.isActionMode, actionMode = _a.actionMode, singleSelectedPathBlock = _a.singleSelectedPathBlock;
            _this.animation = animation;
            _this.rebuildSnapTimes();
            _this.vectorLayer = vectorLayer;
            _this.selectedBlockIds = selectedBlockIds;
            if (isBeingReset) {
                // TODO: store the 'zoom' info in the store to avoid using this isBeingReset flag
                _this.autoZoomToAnimation();
            }
            if (currActionMode === ActionMode.None && actionMode === ActionMode.Selection) {
                // Move the current time to the beginning of the selected block when
                // entering action mode.
                _this.playbackService.setCurrentTime(singleSelectedPathBlock.startTime);
            }
            currActionMode = actionMode;
            return {
                animation: animation,
                vectorLayer: vectorLayer,
                isAnimationSelected: isAnimationSelected,
                isActionMode: isActionMode,
            };
        }));
        this.registerSubscription(this.shortcutService.asObservable().subscribe(function (shortcut) {
            if (shortcut === Shortcut.ZoomToFit) {
                _this.autoZoomToAnimation();
            }
        }));
    };
    LayerTimelineComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.$timeline = $(this.timelineRef.nativeElement);
        this.registerSubscription(this.playbackService.asObservable().subscribe(function (event) {
            // TODO: make this reactive/avoid storing current time locally
            _this.currentTime = event.currentTime;
        }));
        setTimeout(function () { return _this.autoZoomToAnimation(); });
    };
    Object.defineProperty(LayerTimelineComponent.prototype, "horizZoom", {
        get: function () {
            return this.horizZoomSubject.getValue();
        },
        set: function (horizZoom) {
            this.horizZoomSubject.next(horizZoom);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LayerTimelineComponent.prototype, "currentTime", {
        get: function () {
            return this.currentTime_;
        },
        set: function (currentTime) {
            this.currentTime_ = currentTime;
            this.timelineDirectives.forEach(function (dir) { return (dir.currentTime = currentTime); });
        },
        enumerable: true,
        configurable: true
    });
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onNewWorkspaceClick = function () {
        var _this = this;
        var resetWorkspaceFn = function () {
            ga('send', 'event', 'File', 'New');
            _this.store.dispatch(new ResetWorkspace());
        };
        this.store
            .select(isWorkspaceDirty)
            .pipe(first())
            .subscribe(function (isDirty) {
            if (isDirty && !IS_DEV_BUILD) {
                _this.dialogService
                    .confirm('Start over?', "You'll lose any unsaved changes.")
                    .pipe(filter(function (result) { return result; }))
                    .subscribe(resetWorkspaceFn);
            }
            else {
                resetWorkspaceFn();
            }
        });
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onSaveToFileClick = function () {
        ga('send', 'event', 'File', 'Save');
        this.fileExportService.exportJSON();
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onLoadDemoClick = function () {
        var _this = this;
        ga('send', 'event', 'File', 'Demo');
        this.dialogService
            .pickDemo()
            .pipe(filter(function (demoInfo) { return !!demoInfo; }))
            .subscribe(function (selectedDemoInfo) {
            ga('send', 'event', 'Demos', 'Demo selected', selectedDemoInfo.title);
            _this.projectService
                .getProject("demos/" + selectedDemoInfo.id + ".shapeshifter")
                .then(function (_a) {
                var vectorLayer = _a.vectorLayer, animation = _a.animation, hiddenLayerIds = _a.hiddenLayerIds;
                _this.store.dispatch(new ResetWorkspace(vectorLayer, animation, hiddenLayerIds));
            })
                .catch(function (error) {
                var msg = 'serviceWorker' in navigator && navigator.serviceWorker.controller
                    ? 'Demo not available offline'
                    : "Couldn't fetch demo";
                _this.snackBarService.show(msg, 'Dismiss', Duration.Long);
                return Promise.reject(error.message || error);
            });
        });
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onExportSvgClick = function () {
        ga('send', 'event', 'Export', 'SVG');
        this.fileExportService.exportSvg();
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onExportVectorDrawableClick = function () {
        ga('send', 'event', 'Export', 'Vector Drawable');
        this.fileExportService.exportVectorDrawable();
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onExportAnimatedVectorDrawableClick = function () {
        ga('send', 'event', 'Export', 'Animated Vector Drawable');
        this.fileExportService.exportAnimatedVectorDrawable();
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onExportSvgSpritesheetClick = function () {
        ga('send', 'event', 'Export', 'SVG Spritesheet');
        this.fileExportService.exportSvgSpritesheet();
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onExportCssKeyframesClick = function () {
        // TODO: implement this feature
        ga('send', 'event', 'Export', 'CSS Keyframes');
        this.fileExportService.exportCssKeyframes();
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onAnimationHeaderTextClick = function (event) {
        // Stop propagation to ensure that animationTimelineClick() isn't called.
        event.stopPropagation();
        if (!this.actionModeService.isActionMode()) {
            var isSelected = !ShortcutService.isOsDependentModifierKey(event) && !event.shiftKey;
            this.layerTimelineService.selectAnimation(isSelected);
        }
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onTimelineHeaderScrub = function (event) {
        var time = event.time;
        if (!event.disableSnap) {
            time = this.snapTime(time, false);
        }
        this.currentTime = time;
        this.playbackService.setCurrentTime(time);
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onAddPathLayerClick = function () {
        var _this = this;
        this.store
            .select(getVectorLayer)
            .pipe(first())
            .subscribe(function (vl) {
            var layer = new PathLayer({
                name: LayerUtil.getUniqueLayerName([vl], 'path'),
                children: [],
                pathData: undefined,
            });
            _this.layerTimelineService.addLayer(layer);
        });
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onAddClipPathLayerClick = function () {
        var _this = this;
        this.store
            .select(getVectorLayer)
            .pipe(first())
            .subscribe(function (vl) {
            var layer = new ClipPathLayer({
                name: LayerUtil.getUniqueLayerName([vl], 'mask'),
                children: [],
                pathData: undefined,
            });
            _this.layerTimelineService.addLayer(layer);
        });
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onAddGroupLayerClick = function () {
        var _this = this;
        this.store
            .select(getVectorLayer)
            .pipe(first())
            .subscribe(function (vl) {
            var name = LayerUtil.getUniqueLayerName([vl], 'group');
            var layer = new GroupLayer({ name: name, children: [] });
            _this.layerTimelineService.addLayer(layer);
        });
    };
    // @Override TimelineAnimationRowCallbacks
    LayerTimelineComponent.prototype.onTimelineBlockMouseDown = function (mouseDownEvent, dragBlock) {
        var _this = this;
        var animation = this.animation;
        // TODO: this JQuery 'class' stuff may not work with view encapsulation enabled
        var $target = $(mouseDownEvent.target);
        // Some geometry and hit-testing basics.
        var animRect = $(mouseDownEvent.target)
            .parents('.slt-property')
            .get(0)
            .getBoundingClientRect();
        var xToTimeFn = function (x) { return ((x - animRect.left) / animRect.width) * animation.duration; };
        var downTime = xToTimeFn(mouseDownEvent.clientX);
        // Determine the action based on where the user clicked and the modifier keys.
        var metaKey = ShortcutService.isOsDependentModifierKey(mouseDownEvent);
        var action = MouseActions.Moving;
        if ($target.hasClass('slt-timeline-block-edge-end')) {
            action =
                mouseDownEvent.shiftKey || metaKey
                    ? MouseActions.ScalingTogetherEnd
                    : MouseActions.ScalingUniformEnd;
        }
        else if ($target.hasClass('slt-timeline-block-edge-start')) {
            action =
                mouseDownEvent.shiftKey || metaKey
                    ? MouseActions.ScalingTogetherStart
                    : MouseActions.ScalingUniformStart;
        }
        // Start up a cache of info for each selected block, calculating the left and right
        // bounds for each selected block, based on adjacent non-dragging blocks.
        var blocksByPropertyByLayer = ModelUtil.getOrderedBlocksByPropertyByLayer(animation);
        // Either drag all selected blocks or just the mousedown block.
        var draggingBlocks = this.selectedBlockIds.has(dragBlock.id)
            ? animation.blocks.filter(function (b) { return _this.selectedBlockIds.has(b.id); })
            : [dragBlock];
        var stagnantBlocks = animation.blocks.filter(function (block) {
            return (draggingBlocks.every(function (b) { return block.id !== b.id; }) &&
                draggingBlocks.some(function (_a) {
                    var layerId = _a.layerId, propertyName = _a.propertyName;
                    return block.layerId === layerId && block.propertyName === propertyName;
                }));
        });
        var intervalTree = new IntervalTree();
        stagnantBlocks.forEach(function (b) {
            var id = b.id, layerId = b.layerId, propertyName = b.propertyName, startTime = b.startTime, endTime = b.endTime;
            intervalTree.insert(Math.min(startTime, animation.duration), Math.max(0, endTime), {
                blockId: id,
                layerId: layerId,
                propertyName: propertyName,
                startTime: startTime,
                endTime: endTime,
            });
        });
        var blockInfos = draggingBlocks.map(function (block) {
            var blockNeighbors = blocksByPropertyByLayer[block.layerId][block.propertyName];
            var indexIntoNeighbors = _.findIndex(blockNeighbors, function (b) { return block.id === b.id; });
            // By default the block is only bound by the animation duration.
            var startBound = 0;
            var endBound = animation.duration;
            // For each block find the left-most non-selected block and use that as
            // the start bound.
            if (indexIntoNeighbors > 0) {
                for (var i = indexIntoNeighbors - 1; i >= 0; i--) {
                    var neighbor = blockNeighbors[i];
                    if (!draggingBlocks.includes(neighbor) || action === MouseActions.ScalingUniformStart) {
                        // Only be bound by neighbors not being dragged
                        // except when uniformly changing just start time.
                        startBound = neighbor.endTime;
                        break;
                    }
                }
            }
            // For each block find the right-most non-selected block and use that as
            // the end bound.
            if (indexIntoNeighbors < blockNeighbors.length - 1) {
                for (var i = indexIntoNeighbors + 1; i < blockNeighbors.length; i++) {
                    var neighbor = blockNeighbors[i];
                    if (!draggingBlocks.includes(neighbor) || action === MouseActions.ScalingUniformEnd) {
                        // Only be bound by neighbors not being dragged
                        // except when uniformly changing just end time.
                        endBound = neighbor.startTime;
                        break;
                    }
                }
            }
            return {
                block: block,
                startBound: startBound,
                endBound: endBound,
                downStartTime: block.startTime,
                downEndTime: block.endTime,
            };
        });
        var dragBlockDownStartTime = dragBlock.startTime;
        var dragBlockDownEndTime = dragBlock.endTime;
        var minStartTime;
        var maxEndTime;
        if (action === MouseActions.ScalingTogetherStart ||
            action === MouseActions.ScalingTogetherEnd) {
            minStartTime = blockInfos.reduce(function (t, info) { return Math.min(t, info.block.startTime); }, Infinity);
            maxEndTime = blockInfos.reduce(function (t, info) { return Math.max(t, info.block.endTime); }, 0);
            // Avoid divide by zero.
            maxEndTime = Math.max(maxEndTime, minStartTime + MIN_BLOCK_DURATION);
        }
        var isOverlappingBlockFn = function (info, low, high) {
            var _a = info.block, layerId = _a.layerId, propertyName = _a.propertyName;
            return intervalTree.intersectsWith(low, high, function (d) { return d.layerId === layerId && d.propertyName === propertyName; });
        };
        // tslint:disable-next-line: no-unused-expression
        new Dragger({
            direction: 'horizontal',
            downX: mouseDownEvent.clientX,
            downY: mouseDownEvent.clientY,
            draggingCursor: action === MouseActions.Moving ? 'grabbing' : 'ew-resize',
            onBeginDragFn: function () {
                _this.shouldSuppressClick = true;
                _this.shouldSuppressRebuildSnapTimes = true;
            },
            onDropFn: function () {
                return setTimeout(function () {
                    _this.shouldSuppressClick = false;
                    _this.shouldSuppressRebuildSnapTimes = false;
                    _this.rebuildSnapTimes();
                }, 0);
            },
            onDragFn: function (event) {
                // Calculate the 'time delta' (the number of milliseconds the user has moved
                // since the gesture began).
                var timeDelta = Math.round(xToTimeFn(event.clientX) - downTime);
                var allowSnap = !event.shiftKey && !ShortcutService.isOsDependentModifierKey(event);
                var replacementBlocks = [];
                switch (action) {
                    case MouseActions.Moving: {
                        blockInfos.forEach(function (info) {
                            // Snap time delta.
                            if (allowSnap && info.block.id === dragBlock.id) {
                                var newStartTime = info.downStartTime + timeDelta;
                                var newStartTimeSnapDelta = _this.snapTime(newStartTime) - newStartTime;
                                var newEndTime = info.downEndTime + timeDelta;
                                var newEndTimeSnapDelta = _this.snapTime(newEndTime) - newEndTime;
                                if (newStartTimeSnapDelta) {
                                    if (newEndTimeSnapDelta) {
                                        timeDelta += Math.min(newStartTimeSnapDelta, newEndTimeSnapDelta);
                                    }
                                    else {
                                        timeDelta += newStartTimeSnapDelta;
                                    }
                                }
                                else if (newEndTimeSnapDelta) {
                                    timeDelta += newEndTimeSnapDelta;
                                }
                            }
                            // Clamp time delta to ensure it remains within the duration's bounds.
                            var min = -info.downStartTime;
                            var max = animation.duration - info.downEndTime;
                            timeDelta = _.clamp(timeDelta, min, max);
                        });
                        var deltas = _(blockInfos)
                            .filter(function (info) {
                            // For each block, check if it overlaps with any of the stagnant blocks.
                            var low = info.downStartTime + timeDelta;
                            var end = info.downEndTime + timeDelta;
                            return isOverlappingBlockFn(info, low, end);
                        })
                            .flatMap(function (info) {
                            var _a = info.block, id = _a.id, layerId = _a.layerId, propertyName = _a.propertyName;
                            var neighbors = blocksByPropertyByLayer[layerId][propertyName].filter(function (ngh) { return id !== ngh.id; });
                            return _.flatMap(neighbors, function (ngh) {
                                return [ngh.startTime - info.downEndTime, ngh.endTime - info.downStartTime];
                            });
                        })
                            .sort(function (a, b) { return Math.abs(a - timeDelta) - Math.abs(b - timeDelta); })
                            .value();
                        var deltaIndex = _.findIndex(deltas, function (delta) {
                            return blockInfos.every(function (info) {
                                var low = info.downStartTime + delta;
                                var high = info.downEndTime + delta;
                                if (low < 0 || high > animation.duration) {
                                    return false;
                                }
                                return !isOverlappingBlockFn(info, low, high);
                            });
                        });
                        if (deltaIndex >= 0) {
                            timeDelta = deltas[deltaIndex];
                        }
                        blockInfos.forEach(function (info) {
                            var blockDuration = info.block.endTime - info.block.startTime;
                            var block = info.block.clone();
                            block.startTime = info.downStartTime + timeDelta;
                            block.endTime = block.startTime + blockDuration;
                            replacementBlocks.push(block);
                        });
                        break;
                    }
                    case MouseActions.ScalingUniformStart: {
                        blockInfos.forEach(function (info) {
                            // Snap time delta.
                            if (allowSnap && info.block.id === dragBlock.id) {
                                var newStartTime = info.downStartTime + timeDelta;
                                var newStartTimeSnapDelta = _this.snapTime(newStartTime) - newStartTime;
                                if (newStartTimeSnapDelta) {
                                    timeDelta += newStartTimeSnapDelta;
                                }
                            }
                            // Clamp time delta.
                            var min = info.startBound - info.downStartTime;
                            var max = info.block.endTime - MIN_BLOCK_DURATION - info.downStartTime;
                            timeDelta = _.clamp(timeDelta, min, max);
                        });
                        blockInfos.forEach(function (info) {
                            var block = info.block.clone();
                            block.startTime = info.downStartTime + timeDelta;
                            replacementBlocks.push(block);
                        });
                        break;
                    }
                    case MouseActions.ScalingUniformEnd: {
                        blockInfos.forEach(function (info) {
                            // Snap time delta.
                            if (allowSnap && info.block === dragBlock) {
                                var newEndTime = info.downEndTime + timeDelta;
                                var newEndTimeSnapDelta = _this.snapTime(newEndTime) - newEndTime;
                                if (newEndTimeSnapDelta) {
                                    timeDelta += newEndTimeSnapDelta;
                                }
                            }
                            // Clamp time delta.
                            var min = info.block.startTime + MIN_BLOCK_DURATION - info.downEndTime;
                            var max = info.endBound - info.downEndTime;
                            timeDelta = _.clamp(timeDelta, min, max);
                        });
                        blockInfos.forEach(function (info) {
                            var block = info.block.clone();
                            block.endTime = info.downEndTime + timeDelta;
                            replacementBlocks.push(block);
                        });
                        break;
                    }
                    case MouseActions.ScalingTogetherStart: {
                        var scale_1 = (dragBlockDownStartTime + timeDelta - maxEndTime) /
                            (dragBlockDownStartTime - maxEndTime);
                        scale_1 = Math.min(scale_1, maxEndTime / (maxEndTime - minStartTime));
                        var cancel_1 = false;
                        blockInfos.forEach(function (info) {
                            info.newStartTime = maxEndTime - (maxEndTime - info.downStartTime) * scale_1;
                            info.newEndTime = Math.max(maxEndTime - (maxEndTime - info.downEndTime) * scale_1, info.newStartTime + MIN_BLOCK_DURATION);
                            if (info.newStartTime < info.startBound || info.newEndTime > info.endBound) {
                                cancel_1 = true;
                            }
                        });
                        if (!cancel_1) {
                            blockInfos.forEach(function (info) {
                                var block = info.block.clone();
                                block.startTime = info.newStartTime;
                                block.endTime = info.newEndTime;
                                replacementBlocks.push(block);
                            });
                        }
                        break;
                    }
                    case MouseActions.ScalingTogetherEnd: {
                        var scale_2 = (dragBlockDownEndTime + timeDelta - minStartTime) /
                            (dragBlockDownEndTime - minStartTime);
                        scale_2 = Math.min(scale_2, (animation.duration - minStartTime) / (maxEndTime - minStartTime));
                        var cancel_2 = false;
                        blockInfos.forEach(function (info) {
                            info.newStartTime = minStartTime + (info.downStartTime - minStartTime) * scale_2;
                            info.newEndTime = Math.max(minStartTime + (info.downEndTime - minStartTime) * scale_2, info.newStartTime + MIN_BLOCK_DURATION);
                            if (info.newStartTime < info.startBound || info.newEndTime > info.endBound) {
                                cancel_2 = true;
                            }
                        });
                        if (!cancel_2) {
                            blockInfos.forEach(function (info) {
                                var block = info.block.clone();
                                block.startTime = info.newStartTime;
                                block.endTime = info.newEndTime;
                                replacementBlocks.push(block);
                            });
                        }
                        break;
                    }
                }
                _this.store
                    .select(getAnimation)
                    .pipe(first())
                    .subscribe(function (anim) {
                    var blocks = replacementBlocks.filter(function (replacementBlock) {
                        // Note that existingBlock may not be found if changes were made to the animation
                        // (i.e. a block was deleted during a drag).
                        var existingBlock = _.find(anim.blocks, function (b) { return replacementBlock.id === b.id; });
                        return (existingBlock &&
                            (replacementBlock.startTime !== existingBlock.startTime ||
                                replacementBlock.endTime !== existingBlock.endTime));
                    });
                    _this.layerTimelineService.updateBlocks(blocks);
                });
            },
        });
    };
    /**
     * Builds a cache of snap times for all available animations.
     */
    LayerTimelineComponent.prototype.rebuildSnapTimes = function () {
        if (this.shouldSuppressRebuildSnapTimes) {
            return;
        }
        this.snapTimes = new Map();
        var snapTimesSet = new Set();
        snapTimesSet.add(0);
        snapTimesSet.add(this.animation.duration);
        this.animation.blocks.forEach(function (block) {
            snapTimesSet.add(block.startTime);
            snapTimesSet.add(block.endTime);
        });
        this.snapTimes.set(this.animation.id, Array.from(snapTimesSet));
    };
    /**
     * Returns a new time, possibly snapped to animation boundaries
     */
    LayerTimelineComponent.prototype.snapTime = function (time, includeActiveTime) {
        if (includeActiveTime === void 0) { includeActiveTime = true; }
        var animation = this.animation;
        var snapTimes = this.snapTimes.get(animation.id);
        var snapDelta = SNAP_PIXELS / this.horizZoom;
        var reducerFn = function (best, snapTime) {
            var dist = Math.abs(time - snapTime);
            return dist < snapDelta && dist < Math.abs(time - best) ? snapTime : best;
        };
        var bestSnapTime = snapTimes.reduce(reducerFn, Infinity);
        if (includeActiveTime) {
            bestSnapTime = reducerFn(bestSnapTime, this.currentTime);
        }
        return isFinite(bestSnapTime) ? bestSnapTime : time;
    };
    // @Override TimelineAnimationRowCallbacks
    LayerTimelineComponent.prototype.onTimelineBlockClick = function (event, block) {
        var clearExisting = !ShortcutService.isOsDependentModifierKey(event) && !event.shiftKey;
        this.layerTimelineService.selectBlock(block.id, clearExisting);
    };
    // @Override TimelineAnimationRowCallbacks
    LayerTimelineComponent.prototype.onTimelineBlockDoubleClick = function (event, block) {
        this.playbackService.setCurrentTime(block.startTime);
    };
    // @Override LayerListTreeComponentCallbacks
    LayerTimelineComponent.prototype.onAddTimelineBlockClick = function (event, layer, propertyName) {
        var clonedValue = layer.inspectableProperties
            .get(propertyName)
            .cloneValue(layer[propertyName]);
        this.layerTimelineService.addBlocks([
            {
                layerId: layer.id,
                propertyName: propertyName,
                fromValue: clonedValue,
                toValue: clonedValue,
                currentTime: this.currentTime,
            },
        ]);
    };
    // @Override LayerListTreeComponentCallbacks
    LayerTimelineComponent.prototype.onConvertToClipPathClick = function (event, layer) {
        var clipPathLayer = new ClipPathLayer(layer);
        clipPathLayer.id = _.uniqueId();
        this.layerTimelineService.swapLayers(layer.id, clipPathLayer);
    };
    // @Override LayerListTreeComponentCallbacks
    LayerTimelineComponent.prototype.onConvertToPathClick = function (event, layer) {
        var pathLayer = new PathLayer(layer);
        pathLayer.id = _.uniqueId();
        this.layerTimelineService.swapLayers(layer.id, pathLayer);
    };
    // @Override LayerListTreeComponentCallbacks
    LayerTimelineComponent.prototype.onFlattenGroupClick = function (event, layer) {
        this.layerTimelineService.flattenGroupLayer(layer.id);
    };
    // @Override LayerListTreeComponentCallbacks
    LayerTimelineComponent.prototype.onLayerClick = function (event, clickedLayer) {
        var isMeta = ShortcutService.isOsDependentModifierKey(event);
        var isShift = event.shiftKey;
        if (!isMeta && !isShift) {
            // Clear the existing selections.
            this.layerTimelineService.selectLayer(clickedLayer.id, true);
            return;
        }
        if (isMeta && !isShift) {
            // Add the single layer to the existing selections, toggling the
            // layer if it is already selected.
            this.layerTimelineService.selectLayer(clickedLayer.id, false);
            return;
        }
        if (isMeta && isShift) {
            // Add the single layer to the existing selections.
            var layerIds = this.layerTimelineService.getSelectedLayerIds();
            layerIds.add(clickedLayer.id);
            this.layerTimelineService.setSelectedLayers(layerIds);
            return;
        }
        // Batch add layers to the existing selections.
        var vectorLayer = this.vectorLayer;
        var topDownSortedLayers = LayerUtil.runPreorderTraversal(vectorLayer);
        var clickedLayerIndex = _.findIndex(topDownSortedLayers, function (l) { return l.id === clickedLayer.id; });
        var selectedLayerIds = this.layerTimelineService.getSelectedLayerIds();
        // TODO: re-implement this behavior to match the behavior of Sketch
        // TODO will need to store most recently selected layer ID in order to implement this behavior
        var _a = (function () {
            // Find the first selected layer before clickedLayerIndex.
            var beforeLayerIndex = _.findLastIndex(topDownSortedLayers, function (l) { return selectedLayerIds.has(l.id); }, clickedLayerIndex);
            if (beforeLayerIndex >= 0) {
                // Batch select [beforeLayerIndex, clickedLayerIndex].
                return { startIndex: beforeLayerIndex, endIndex: clickedLayerIndex };
            }
            // Find the first selected layer after clickedLayerIndex.
            var afterLayerIndex = _.findIndex(topDownSortedLayers, function (l) { return selectedLayerIds.has(l.id); }, clickedLayerIndex);
            if (afterLayerIndex >= 0) {
                // Batch select [clickedLayerIndex, afterLayerIndex].
                return { startIndex: clickedLayerIndex, endIndex: afterLayerIndex };
            }
            // Batch select [0, clickedLayerIndex].
            return { startIndex: 0, endIndex: clickedLayerIndex };
        })(), startIndex = _a.startIndex, endIndex = _a.endIndex;
        for (var i = startIndex; i <= endIndex; i++) {
            selectedLayerIds.add(topDownSortedLayers[i].id);
        }
        this.layerTimelineService.setSelectedLayers(selectedLayerIds);
    };
    // @Override LayerListTreeComponentCallbacks
    LayerTimelineComponent.prototype.onLayerToggleExpanded = function (event, layer) {
        var recursive = ShortcutService.isOsDependentModifierKey(event) || event.shiftKey;
        this.layerTimelineService.toggleExpandedLayer(layer.id, recursive);
    };
    // @Override LayerListTreeComponentCallbacks
    LayerTimelineComponent.prototype.onLayerToggleVisibility = function (event, layer) {
        this.layerTimelineService.toggleVisibleLayer(layer.id);
    };
    // @Override LayerListTreeComponentCallbacks
    LayerTimelineComponent.prototype.onLayerMouseDown = function (mouseDownEvent, mouseDownDragLayer) {
        var _this = this;
        var $layersList = $(mouseDownEvent.target).parents('.slt-layers-list');
        var $scroller = $(mouseDownEvent.target).parents('.slt-layers-list-scroller');
        var orderedLayerInfos = [];
        var scrollerRect;
        var targetLayerInfo;
        var targetEdge;
        var dragLayers = (function (lts) {
            var selectedLayerIds = lts.getSelectedLayerIds();
            // Don't drag any other selected layers if the drag layer isn't selected itself.
            // At the end of the drag, we will select the drag layer and deselect the others.
            var dragLayerIdSet = selectedLayerIds.has(mouseDownDragLayer.id)
                ? selectedLayerIds
                : new Set([mouseDownDragLayer.id]);
            var topDownSortedLayers = LayerUtil.runPreorderTraversal(lts.getVectorLayer());
            return topDownSortedLayers.filter(function (l) { return dragLayerIdSet.has(l.id); });
        })(this.layerTimelineService);
        // tslint:disable-next-line: no-unused-expression
        new Dragger({
            direction: 'both',
            downX: mouseDownEvent.clientX,
            downY: mouseDownEvent.clientY,
            onBeginDragFn: function () {
                _this.shouldSuppressClick = true;
                // Build up a list of all layers ordered by Y position.
                orderedLayerInfos = [];
                scrollerRect = $scroller.get(0).getBoundingClientRect();
                var scrollTop = $scroller.scrollTop();
                $layersList.find('.slt-layer-container').each(function (__, element) {
                    // toString() is necessary because JQuery converts the ID into a number.
                    var layerId = ($(element).data('layer-id') || '').toString();
                    if (!layerId) {
                        // The root layer doesn't have an ID set.
                        return;
                    }
                    var rect = element.getBoundingClientRect();
                    rect = {
                        left: rect.left,
                        top: rect.top + scrollTop - scrollerRect.top,
                        bottom: rect.bottom + scrollTop - scrollerRect.top,
                        height: rect.height,
                        right: rect.right,
                        width: rect.width,
                    };
                    var layer = _this.vectorLayer.findLayerById(layerId);
                    orderedLayerInfos.push({ layer: layer, element: element, localRect: rect });
                    // Add a fake target for empty groups.
                    if (layer instanceof GroupLayer && !layer.children.length) {
                        var left = rect.left + LAYER_INDENT_PIXELS;
                        var top_1 = rect.bottom;
                        rect = tslib_1.__assign({}, rect, { left: left, top: top_1 });
                        orderedLayerInfos.push({
                            layer: layer,
                            element: element,
                            localRect: rect,
                            moveIntoEmptyLayerGroup: true,
                        });
                    }
                });
                orderedLayerInfos.sort(function (a, b) { return a.localRect.top - b.localRect.top; });
                _this.updateDragIndicator({ isVisible: true, left: 0, top: 0 });
            },
            onDragFn: function (event) {
                var localEventY = event.clientY - scrollerRect.top + $scroller.scrollTop();
                // Find the target layer and edge (top or bottom).
                targetLayerInfo = undefined;
                var minDistance = Infinity;
                var minDistanceIndent = Infinity; // Tie break to most indented layer.
                for (var _i = 0, orderedLayerInfos_1 = orderedLayerInfos; _i < orderedLayerInfos_1.length; _i++) {
                    var layerInfo = orderedLayerInfos_1[_i];
                    // Skip if mouse to the left of this layer.
                    if (event.clientX < layerInfo.localRect.left) {
                        continue;
                    }
                    for (var _a = 0, _b = ['top', 'bottom']; _a < _b.length; _a++) {
                        var edge = _b[_a];
                        // Test distance to top edge.
                        var distance = Math.abs(localEventY - layerInfo.localRect[edge]);
                        var indent = layerInfo.localRect.left;
                        if (distance <= minDistance) {
                            if (distance !== minDistance || indent > minDistanceIndent) {
                                minDistance = distance;
                                minDistanceIndent = indent;
                                targetLayerInfo = layerInfo;
                                targetEdge = edge;
                            }
                        }
                    }
                }
                // Disallow dragging a layer into itself or its children.
                if (targetLayerInfo) {
                    var layer_1 = targetLayerInfo.layer;
                    while (layer_1) {
                        if (_.find(dragLayers, function (l) { return l.id === layer_1.id; })) {
                            targetLayerInfo = undefined;
                            break;
                        }
                        layer_1 = LayerUtil.findParent(_this.vectorLayer, layer_1.id);
                    }
                }
                if (targetLayerInfo && targetEdge === 'bottom') {
                    var nextSibling = LayerUtil.findNextSibling(_this.vectorLayer, targetLayerInfo.layer.id);
                    if (nextSibling && nextSibling.id === mouseDownDragLayer.id) {
                        targetLayerInfo = undefined;
                    }
                }
                var dragIndicatorInfo = { isVisible: !!targetLayerInfo };
                if (targetLayerInfo) {
                    dragIndicatorInfo.left = targetLayerInfo.localRect.left;
                    dragIndicatorInfo.top = targetLayerInfo.localRect[targetEdge];
                }
                _this.updateDragIndicator(dragIndicatorInfo);
            },
            onDropFn: function () {
                _this.updateDragIndicator({ isVisible: false });
                setTimeout(function () { return (_this.shouldSuppressClick = false); });
                if (!targetLayerInfo) {
                    return;
                }
                var dragLayerIds = dragLayers.map(function (l) { return l.id; });
                var addDragLayersFn = function (vl, parent, startingIndex) {
                    if (startingIndex === void 0) { startingIndex = parent.children.length; }
                    var layersToAdd = dragLayers.map(function (l) {
                        var otherDragLayerIds = dragLayerIds.filter(function (id) { return id !== l.id; });
                        return LayerUtil.removeLayers.apply(LayerUtil, [l].concat(otherDragLayerIds));
                    });
                    return LayerUtil.addLayers.apply(LayerUtil, [vl, parent.id, startingIndex].concat(layersToAdd));
                };
                var removeDragLayersFn = function (vl) { return LayerUtil.removeLayers.apply(LayerUtil, [vl].concat(dragLayerIds)); };
                var initialVl = _this.vectorLayer;
                var replacementVl;
                if (targetLayerInfo.moveIntoEmptyLayerGroup) {
                    // Moving into an empty layer group.
                    replacementVl = addDragLayersFn(removeDragLayersFn(initialVl), targetLayerInfo.layer);
                }
                else if (LayerUtil.findParent(initialVl, targetLayerInfo.layer.id)) {
                    // Moving next to another layer.
                    var tempVl = removeDragLayersFn(initialVl);
                    var parent_1 = LayerUtil.findParent(tempVl, targetLayerInfo.layer.id);
                    var index = _.findIndex(parent_1.children, function (l) { return l.id === targetLayerInfo.layer.id; });
                    if (index >= 0) {
                        replacementVl = addDragLayersFn(tempVl, parent_1, index + (targetEdge === 'top' ? 0 : 1));
                    }
                }
                if (replacementVl) {
                    _this.store.dispatch(new BatchAction(new SetVectorLayer(replacementVl), new SetSelectedLayers(new Set(dragLayerIds))));
                }
            },
        });
    };
    LayerTimelineComponent.prototype.updateDragIndicator = function (info) {
        var curr = this.dragIndicatorSubject.getValue();
        this.dragIndicatorSubject.next(tslib_1.__assign({}, curr, info));
    };
    /**
     * Handles ctrl + mouse wheel event for zooming into and out of the timeline.
     */
    LayerTimelineComponent.prototype.onWheelEvent = function (event) {
        var _this = this;
        var startZoomFn = function () {
            _this.$zoomStartActiveAnimation = $(_this.timelineAnimationRef.nativeElement);
            _this.zoomStartTimeCursorPos =
                _this.$zoomStartActiveAnimation.position().left +
                    _this.currentTime * _this.horizZoom +
                    TimelineConsts.TIMELINE_ANIMATION_PADDING;
        };
        var performZoomFn = function () {
            _this.horizZoom = _this.targetHorizZoom;
            // Set the scroll offset such that the time cursor remains at zoomStartTimeCursorPos
            if (_this.$zoomStartActiveAnimation) {
                var newScrollLeft = _this.$zoomStartActiveAnimation.position().left +
                    _this.$timeline.scrollLeft() +
                    _this.currentTime * _this.horizZoom +
                    TimelineConsts.TIMELINE_ANIMATION_PADDING -
                    _this.zoomStartTimeCursorPos;
                _this.$timeline.scrollLeft(newScrollLeft);
            }
        };
        var endZoomFn = function () {
            _this.zoomStartTimeCursorPos = 0;
            _this.$zoomStartActiveAnimation = undefined;
            _this.endZoomTimeout = undefined;
            _this.targetHorizZoom = 0;
        };
        // chrome+mac trackpad pinch-zoom = ctrlKey
        if (ShortcutService.isOsDependentModifierKey(event) || event.ctrlKey) {
            if (!this.targetHorizZoom) {
                // Multiple changes can happen to targetHorizZoom before the
                // actual zoom level is updated (see performZoom_).
                this.targetHorizZoom = this.horizZoom;
            }
            event.preventDefault();
            this.targetHorizZoom *= Math.pow(1.01, -event.deltaY);
            this.targetHorizZoom = _.clamp(this.targetHorizZoom, MIN_ZOOM, MAX_ZOOM);
            if (this.targetHorizZoom !== this.horizZoom) {
                // Zoom has changed.
                if (this.performZoomRAF) {
                    window.cancelAnimationFrame(this.performZoomRAF);
                }
                this.performZoomRAF = window.requestAnimationFrame(function () { return performZoomFn(); });
                if (this.endZoomTimeout) {
                    window.clearTimeout(this.endZoomTimeout);
                }
                else {
                    startZoomFn();
                }
                this.endZoomTimeout = window.setTimeout(function () { return endZoomFn(); }, 100);
            }
            return false;
        }
        return undefined;
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onZoomToFitClick = function (event) {
        event.stopPropagation();
        this.autoZoomToAnimation();
    };
    /**
     * Zooms the timeline to fit the first animation.
     */
    LayerTimelineComponent.prototype.autoZoomToAnimation = function () {
        // Shave off 48 pixels for safety.
        this.horizZoom = (this.$timeline.width() - 48) / this.animation.duration;
    };
    // Proxies a button click to the <input> tag that opens the file picker.
    // We clear the element's value to make it possible to import the same file
    // more than once.
    LayerTimelineComponent.prototype.onLaunchFilePickerClick = function (event, sourceElementId) {
        $("#" + sourceElementId)
            .val('')
            .trigger('click');
    };
    // Called from the LayerTimelineComponent template.
    LayerTimelineComponent.prototype.onImportedFilesPicked = function (event, fileList) {
        // TODO: determine if calling stopPropogation() is needed?
        event.stopPropagation();
        this.fileImportService.import(fileList);
    };
    LayerTimelineComponent.prototype.onTopSplitterChanged = function () {
        if (this.timelineDirectives) {
            this.timelineDirectives.forEach(function (d) { return d.redraw(); });
        }
    };
    // Used by *ngFor loop.
    LayerTimelineComponent.prototype.trackLayerFn = function (index, layer) {
        return layer.id;
    };
    return LayerTimelineComponent;
}(DestroyableMixin()));
export { LayerTimelineComponent };
