import * as tslib_1 from "tslib";
import { ColorUtil, MathUtil } from 'app/modules/editor/scripts/common';
import * as _ from 'lodash';
import { Property } from './Property';
var ColorProperty = /** @class */ (function (_super) {
    tslib_1.__extends(ColorProperty, _super);
    function ColorProperty() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // @Override
    ColorProperty.prototype.setEditableValue = function (model, propertyName, value) {
        if (!value) {
            model[propertyName] = undefined;
            return;
        }
        var processedValue = ColorUtil.parseAndroidColor(value);
        if (!processedValue) {
            processedValue = ColorUtil.parseAndroidColor(ColorUtil.svgToAndroidColor(value));
        }
        model[propertyName] = ColorUtil.toAndroidString(processedValue);
    };
    // @Override
    ColorProperty.prototype.interpolateValue = function (start, end, f) {
        if (!start || !end) {
            return undefined;
        }
        var s = ColorUtil.parseAndroidColor(start);
        var e = ColorUtil.parseAndroidColor(end);
        return ColorUtil.toAndroidString({
            r: _.clamp(Math.round(MathUtil.lerp(s.r, e.r, f)), 0, 0xff),
            g: _.clamp(Math.round(MathUtil.lerp(s.g, e.g, f)), 0, 0xff),
            b: _.clamp(Math.round(MathUtil.lerp(s.b, e.b, f)), 0, 0xff),
            a: _.clamp(Math.round(MathUtil.lerp(s.a, e.a, f)), 0, 0xff),
        });
    };
    // @Override
    ColorProperty.prototype.getAnimatorValueType = function () {
        return 'colorType';
    };
    // @Override
    ColorProperty.prototype.getTypeName = function () {
        return 'ColorProperty';
    };
    return ColorProperty;
}(Property));
export { ColorProperty };
