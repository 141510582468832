import * as tslib_1 from "tslib";
import * as paper from 'paper';
import { ShapeGesture } from './ShapeGesture';
/** A gesture that creates a rectangular path. */
var RectangleGesture = /** @class */ (function (_super) {
    tslib_1.__extends(RectangleGesture, _super);
    function RectangleGesture() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // @Override
    RectangleGesture.prototype.newPath = function (vpBounds) {
        return new paper.Path.Rectangle(vpBounds);
    };
    return RectangleGesture;
}(ShapeGesture));
export { RectangleGesture };
