import * as tslib_1 from "tslib";
import * as paper from 'paper';
import { ShapeGesture } from './ShapeGesture';
/** A gesture that creates an elliptical path. */
var EllipseGesture = /** @class */ (function (_super) {
    tslib_1.__extends(EllipseGesture, _super);
    function EllipseGesture() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // @Override
    EllipseGesture.prototype.newPath = function (vpBounds) {
        return new paper.Path.Ellipse(vpBounds);
    };
    return EllipseGesture;
}(ShapeGesture));
export { EllipseGesture };
