import * as tslib_1 from "tslib";
import { CursorType } from 'app/modules/editor/model/paper';
import { MathUtil } from 'app/modules/editor/scripts/common';
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { PaperUtil, SnapUtil } from 'app/modules/editor/scripts/paper/util';
import * as paper from 'paper';
/**
 * A gesture that performs selection and drag operations on one or more path
 * segments. It also supports adding a segment to an existing path's curve,
 * as well as extending an open path by appending segments to its end points.
 *
 * Preconditions:
 * - The user is in edit path mode.
 * - The user either hit a segment, a curve, or missed entirely
 *   (the 'missed entirely' case occurs when the user is in vector
 *   mode, in which the user can create new segments by clicking
 *   on the canvas).
 */
var SelectDragDrawSegmentsGesture = /** @class */ (function (_super) {
    tslib_1.__extends(SelectDragDrawSegmentsGesture, _super);
    function SelectDragDrawSegmentsGesture(ps, editPathId, hitSegmentInfo, hitCurveInfo) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.editPathId = editPathId;
        _this.hitSegmentInfo = hitSegmentInfo;
        _this.hitCurveInfo = hitCurveInfo;
        _this.pl = paper.project.activeLayer;
        // True if we should exit edit path mode on the next mouse up event.
        _this.exitEditPathModeOnMouseUp = false;
        return _this;
    }
    /** Static factory method to use when the user's mouse down hits a segment. */
    SelectDragDrawSegmentsGesture.hitSegment = function (ps, editPathId, segmentIndex) {
        return new SelectDragDrawSegmentsGesture(ps, editPathId, { segmentIndex: segmentIndex });
    };
    /** Static factory method to use when the user's mouse down hits a curve. */
    SelectDragDrawSegmentsGesture.hitCurve = function (ps, editPathId, curveIndex, time) {
        return new SelectDragDrawSegmentsGesture(ps, editPathId, undefined, { curveIndex: curveIndex, time: time });
    };
    /** Static factory method to use when the user misses the edit path. */
    SelectDragDrawSegmentsGesture.miss = function (ps, editPathId) {
        return new SelectDragDrawSegmentsGesture(ps, editPathId);
    };
    // @Override
    SelectDragDrawSegmentsGesture.prototype.onMouseDown = function (event) {
        var fpi = this.ps.getEditPathInfo();
        var beforeSelectedSegmentIndices = fpi.selectedSegments;
        var afterSelectedSegmentIndices = new Set(beforeSelectedSegmentIndices);
        var editPath = this.pl.findItemByLayerId(this.editPathId);
        if (this.hitSegmentInfo) {
            var isEndPointFn = function (i) { return i === 0 || i === editPath.segments.length - 1; };
            var segmentIndex = this.hitSegmentInfo.segmentIndex;
            var singleSelectedSegmentIndex = beforeSelectedSegmentIndices.size
                ? beforeSelectedSegmentIndices.values().next().value
                : undefined;
            if (!editPath.closed &&
                singleSelectedSegmentIndex !== undefined &&
                isEndPointFn(segmentIndex) &&
                isEndPointFn(singleSelectedSegmentIndex) &&
                segmentIndex !== singleSelectedSegmentIndex) {
                // If the path is open, one of the end points is selected, and the
                // user clicked the other end point segment, then close the path
                // and end the gesture on the next mouse up event.
                editPath.closed = true;
                this.exitEditPathModeOnMouseUp = true;
                PaperUtil.replacePathInStore(this.ps, this.editPathId, editPath.pathData);
            }
            if (event.modifiers.shift || event.modifiers.command) {
                // If shift or command is pressed, toggle the segment's selection state.
                if (beforeSelectedSegmentIndices.has(segmentIndex)) {
                    afterSelectedSegmentIndices.delete(segmentIndex);
                }
                else {
                    afterSelectedSegmentIndices.add(segmentIndex);
                }
            }
            else {
                // Otherwise, select the hit segment and deselect all others.
                afterSelectedSegmentIndices.clear();
                afterSelectedSegmentIndices.add(segmentIndex);
            }
        }
        else if (this.hitCurveInfo) {
            // If there is no hit segment, then create one along the curve
            // at the given location and select the new segment.
            var _a = this.hitCurveInfo, curveIndex = _a.curveIndex, time = _a.time;
            var curve = editPath.curves[curveIndex];
            var newSegment = event.modifiers.shift
                ? curve.divideAt(curve.getLocationAt(curve.length / 2))
                : curve.divideAtTime(time).segment1;
            PaperUtil.replacePathInStore(this.ps, this.editPathId, editPath.pathData);
            afterSelectedSegmentIndices.clear();
            afterSelectedSegmentIndices.add(newSegment.index);
            this.newSplitCurveSegmentIndex = newSegment.index;
        }
        else {
            // Otherwise, we are either (1) extending an existing open path (beginning
            // at one of its selected end points), or (2) beginning to create a new path
            // from scratch.
            var localPoint = editPath.globalToLocal(event.point);
            var addedSegment = void 0;
            if (editPath.segments.length === 0) {
                addedSegment = editPath.add(localPoint);
            }
            else {
                // Note that there will always be a single selected end point segment in this case
                // (otherwise we would have used a batch select segments gesture instead).
                var singleSelectedSegmentIndex = beforeSelectedSegmentIndices.values().next().value;
                var selectedSegment = editPath.segments[singleSelectedSegmentIndex];
                addedSegment = selectedSegment.isLast()
                    ? editPath.add(localPoint)
                    : editPath.insert(0, localPoint);
                afterSelectedSegmentIndices.delete(singleSelectedSegmentIndex);
            }
            afterSelectedSegmentIndices.add(addedSegment.index);
            PaperUtil.replacePathInStore(this.ps, this.editPathId, editPath.pathData);
        }
        this.selectedSegmentIndexToInitialLocationMap = new Map(Array.from(afterSelectedSegmentIndices).map(function (segmentIndex) {
            var point = editPath.segments[segmentIndex].point.clone();
            return [segmentIndex, point];
        }));
        this.ps.setEditPathInfo(tslib_1.__assign({}, fpi, PaperUtil.selectCurves(editPath, afterSelectedSegmentIndices)));
        this.ps.setCursorType(CursorType.PointSelect);
        this.ps.setCreatePathInfo(undefined);
        this.ps.setSplitCurveInfo(undefined);
    };
    // @Override
    SelectDragDrawSegmentsGesture.prototype.onMouseDrag = function (event) {
        var _this = this;
        var editPath = this.pl.findItemByLayerId(this.editPathId);
        var localDownPoint = editPath.globalToLocal(event.downPoint);
        if (!this.localLastPoint) {
            this.localLastPoint = localDownPoint;
        }
        var localPoint = editPath.globalToLocal(event.point);
        var localDownPointDelta = localPoint.subtract(localDownPoint);
        var localLastPointDelta = localPoint.subtract(this.localLastPoint);
        var localSnappedDownPointDelta = new paper.Point(MathUtil.snapVectorToAngle(localDownPointDelta, 90));
        if (this.hitSegmentInfo || this.hitCurveInfo) {
            // A segment was created on mouse down and is still being grabbed,
            // so continue to drag the currently selected segments.
            var selectedSegmentIndices_1 = new Set(this.selectedSegmentIndexToInitialLocationMap.keys());
            var nonSelectedSegmentIndices = editPath.segments
                .map(function (s, i) { return i; })
                .filter(function (s, i) { return !selectedSegmentIndices_1.has(i); });
            this.selectedSegmentIndexToInitialLocationMap.forEach(function (initialSegmentPoint, i) {
                var segment = editPath.segments[i];
                segment.point = event.modifiers.shift
                    ? initialSegmentPoint.add(localSnappedDownPointDelta)
                    : segment.point.add(localLastPointDelta);
            });
            var draggedSegmentIndex = this.hitSegmentInfo
                ? this.hitSegmentInfo.segmentIndex
                : this.newSplitCurveSegmentIndex;
            var dragSnapPoint = editPath.localToGlobal(editPath.segments[draggedSegmentIndex].point);
            var _a = Array.from(this.selectedSegmentIndexToInitialLocationMap.values()).reduce(function (rect, p) {
                p = editPath.localToGlobal(p);
                return rect ? rect.include(p) : new paper.Rectangle(p, new paper.Size(0, 0));
            }, undefined), topLeft = _a.topLeft, center = _a.center, bottomRight = _a.bottomRight;
            var siblingSnapPointsTable = [
                [topLeft, center, bottomRight]
            ].concat(nonSelectedSegmentIndices.map(function (i) {
                return [editPath.localToGlobal(editPath.segments[i].point)];
            }));
            // TODO: snap this stuff like we do in the other gestures!
            var snapInfo = SnapUtil.computeSnapInfo([dragSnapPoint], siblingSnapPointsTable);
            if (snapInfo) {
                this.ps.setSnapGuideInfo({
                    guides: snapInfo.guides.map(function (_a) {
                        var from = _a.from, to = _a.to;
                        from = _this.pl.globalToLocal(new paper.Point(from));
                        to = _this.pl.globalToLocal(new paper.Point(to));
                        return { from: from, to: to };
                    }),
                    rulers: [],
                });
            }
        }
        else {
            // Then we have just added a segment to the path in onMouseDown()
            // and should thus move the segment's handles onMouseDrag().
            // Note that there will only ever be one selected segment in this case.
            var selectedSegmentIndex = this.selectedSegmentIndexToInitialLocationMap.keys().next()
                .value;
            var selectedSegment = editPath.segments[selectedSegmentIndex];
            // TODO: dragging a handle belonging to an endpoint doesn't work! handle info is lost!
            // TODO: snap the dragged segment handle with the newly created segment
            if (event.modifiers.shift) {
                var index = selectedSegmentIndex;
                var initialSelectedSegmentPosition = this.selectedSegmentIndexToInitialLocationMap.get(index);
                var delta = localSnappedDownPointDelta;
                selectedSegment.handleIn = initialSelectedSegmentPosition.subtract(delta);
                selectedSegment.handleOut = initialSelectedSegmentPosition.add(delta);
            }
            else {
                selectedSegment.handleIn = selectedSegment.handleIn.subtract(localLastPointDelta);
                selectedSegment.handleOut = selectedSegment.handleOut.add(localLastPointDelta);
            }
        }
        this.localLastPoint = localPoint;
        PaperUtil.replacePathInStore(this.ps, this.editPathId, editPath.pathData);
    };
    // @Override
    SelectDragDrawSegmentsGesture.prototype.onMouseUp = function (event) {
        this.ps.setCursorType(CursorType.Default);
        this.ps.setSnapGuideInfo(undefined);
        if (this.exitEditPathModeOnMouseUp) {
            this.ps.setEditPathInfo(undefined);
        }
    };
    return SelectDragDrawSegmentsGesture;
}(Gesture));
export { SelectDragDrawSegmentsGesture };
