/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropfilesdialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./dropfilesdialog.component";
var styles_DropFilesDialogComponent = [i0.styles];
var RenderType_DropFilesDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropFilesDialogComponent, data: {} });
export { RenderType_DropFilesDialogComponent as RenderType_DropFilesDialogComponent };
export function View_DropFilesDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Start from scratch?"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Do you want to start from scratch or add the imported layers to the existing animation?"])), (_l()(), i1.ɵeld(7, 0, null, null, 17, "mat-dialog-actions", [["class", "mat-dialog-actions"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(8, 737280, null, 0, i3.LayoutDirective, [i4.MediaMonitor, i1.ElementRef, i4.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(9, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(11, 737280, null, 0, i3.FlexDirective, [i4.MediaMonitor, i1.ElementRef, [3, i3.LayoutDirective], i4.StyleUtils, i4.LAYOUT_CONFIG], { flex: [0, "flex"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["fxFlexOrder", "3"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(_co.ADD_TO_WORKSPACE) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(13, 737280, null, 0, i3.FlexOrderDirective, [i4.MediaMonitor, i1.ElementRef, i4.StyleUtils], { order: [0, "order"] }, null), i1.ɵdid(14, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Add layers"])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "button", [["fxFlexOrder", "2"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(_co.RESET_WORKSPACE) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(17, 737280, null, 0, i3.FlexOrderDirective, [i4.MediaMonitor, i1.ElementRef, i4.StyleUtils], { order: [0, "order"] }, null), i1.ɵdid(18, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Start from scratch"])), (_l()(), i1.ɵeld(20, 0, null, null, 4, "button", [["fxFlexOrder", "1"], ["mat-button", ""], ["matDialogClose", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).dialogRef.close(i1.ɵnov(_v, 23).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(21, 737280, null, 0, i3.FlexOrderDirective, [i4.MediaMonitor, i1.ElementRef, i4.StyleUtils], { order: [0, "order"] }, null), i1.ɵdid(22, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(23, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_1 = "row"; _ck(_v, 8, 0, currVal_1); var currVal_2 = ""; _ck(_v, 11, 0, currVal_2); var currVal_5 = "3"; _ck(_v, 13, 0, currVal_5); var currVal_8 = "2"; _ck(_v, 17, 0, currVal_8); var currVal_12 = "1"; _ck(_v, 21, 0, currVal_12); var currVal_13 = ""; _ck(_v, 23, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_3 = (i1.ɵnov(_v, 14).disabled || null); var currVal_4 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_3, currVal_4); var currVal_6 = (i1.ɵnov(_v, 18).disabled || null); var currVal_7 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_6, currVal_7); var currVal_9 = (i1.ɵnov(_v, 22).disabled || null); var currVal_10 = (i1.ɵnov(_v, 22)._animationMode === "NoopAnimations"); var currVal_11 = i1.ɵnov(_v, 23).ariaLabel; _ck(_v, 20, 0, currVal_9, currVal_10, currVal_11); }); }
export function View_DropFilesDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dropfilesdialog", [], null, null, null, View_DropFilesDialogComponent_0, RenderType_DropFilesDialogComponent)), i1.ɵdid(1, 49152, null, 0, i10.DropFilesDialogComponent, [i2.MatDialogRef], null, null)], null, null); }
var DropFilesDialogComponentNgFactory = i1.ɵccf("app-dropfilesdialog", i10.DropFilesDialogComponent, View_DropFilesDialogComponent_Host_0, {}, {}, []);
export { DropFilesDialogComponentNgFactory as DropFilesDialogComponentNgFactory };
