/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./demodialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/radio/typings/index.ngfactory";
import * as i3 from "@angular/material/radio";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/cdk/collections";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/forms";
import * as i9 from "@angular/common";
import * as i10 from "@angular/flex-layout/flex";
import * as i11 from "@angular/flex-layout/core";
import * as i12 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i13 from "@angular/material/button";
import * as i14 from "@angular/cdk/platform";
import * as i15 from "./demodialog.component";
var styles_DemoDialogComponent = [i0.styles];
var RenderType_DemoDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DemoDialogComponent, data: {} });
export { RenderType_DemoDialogComponent as RenderType_DemoDialogComponent };
function View_DemoDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-radio-button", [["class", "dialog-radio-button mat-radio-button"]], [[2, "mat-radio-checked", null], [2, "mat-radio-disabled", null], [2, "_mat-animation-noopable", null], [1, "tabindex", 0], [1, "id", 0]], [[null, "focus"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatRadioButton_0, i2.RenderType_MatRadioButton)), i1.ɵdid(1, 4440064, [[1, 4]], 0, i3.MatRadioButton, [[2, i3.MatRadioGroup], i1.ElementRef, i1.ChangeDetectorRef, i4.FocusMonitor, i5.UniqueSelectionDispatcher, [2, i6.ANIMATION_MODULE_TYPE]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var currVal_5 = _v.context.$implicit; _ck(_v, 1, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).checked; var currVal_1 = i1.ɵnov(_v, 1).disabled; var currVal_2 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_3 = null; var currVal_4 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_6); }); }
export function View_DemoDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i7.MatDialogTitle, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Choose a demo"])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "mat-radio-group", [["class", "dialog-radio-group mat-radio-group"], ["role", "radiogroup"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedDemoInfo = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 1064960, null, 1, i3.MatRadioGroup, [i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { _radios: 1 }), i1.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatRadioGroup]), i1.ɵdid(7, 671744, null, 0, i8.NgModel, [[8, null], [8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i1.ɵdid(9, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DemoDialogComponent_1)), i1.ɵdid(11, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 13, "mat-dialog-actions", [["class", "mat-dialog-actions"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(13, 737280, null, 0, i10.LayoutDirective, [i11.MediaMonitor, i1.ElementRef, i11.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(14, 16384, null, 0, i7.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(16, 737280, null, 0, i10.FlexDirective, [i11.MediaMonitor, i1.ElementRef, [3, i10.LayoutDirective], i11.StyleUtils, i11.LAYOUT_CONFIG], { flex: [0, "flex"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 3, "button", [["fxFlexOrder", "2"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(_co.selectedDemoInfo) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_MatButton_0, i12.RenderType_MatButton)), i1.ɵdid(18, 737280, null, 0, i10.FlexOrderDirective, [i11.MediaMonitor, i1.ElementRef, i11.StyleUtils], { order: [0, "order"] }, null), i1.ɵdid(19, 180224, null, 0, i13.MatButton, [i1.ElementRef, i14.Platform, i4.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["OK"])), (_l()(), i1.ɵeld(21, 0, null, null, 4, "button", [["fxFlexOrder", "1"], ["mat-button", ""], ["matDialogClose", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).dialogRef.close(i1.ɵnov(_v, 24).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_MatButton_0, i12.RenderType_MatButton)), i1.ɵdid(22, 737280, null, 0, i10.FlexOrderDirective, [i11.MediaMonitor, i1.ElementRef, i11.StyleUtils], { order: [0, "order"] }, null), i1.ɵdid(23, 180224, null, 0, i13.MatButton, [i1.ElementRef, i14.Platform, i4.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(24, 606208, null, 0, i7.MatDialogClose, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_8 = _co.selectedDemoInfo; _ck(_v, 7, 0, currVal_8); var currVal_9 = _co.demoInfos; _ck(_v, 11, 0, currVal_9); var currVal_10 = "row"; _ck(_v, 13, 0, currVal_10); var currVal_11 = ""; _ck(_v, 16, 0, currVal_11); var currVal_14 = "2"; _ck(_v, 18, 0, currVal_14); var currVal_18 = "1"; _ck(_v, 22, 0, currVal_18); var currVal_19 = ""; _ck(_v, 24, 0, currVal_19); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 9).ngClassValid; var currVal_6 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_12 = (i1.ɵnov(_v, 19).disabled || null); var currVal_13 = (i1.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_12, currVal_13); var currVal_15 = (i1.ɵnov(_v, 23).disabled || null); var currVal_16 = (i1.ɵnov(_v, 23)._animationMode === "NoopAnimations"); var currVal_17 = i1.ɵnov(_v, 24).ariaLabel; _ck(_v, 21, 0, currVal_15, currVal_16, currVal_17); }); }
export function View_DemoDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-demodialog", [], null, null, null, View_DemoDialogComponent_0, RenderType_DemoDialogComponent)), i1.ɵdid(1, 49152, null, 0, i15.DemoDialogComponent, [i7.MatDialogRef], null, null)], null, null); }
var DemoDialogComponentNgFactory = i1.ɵccf("app-demodialog", i15.DemoDialogComponent, View_DemoDialogComponent_Host_0, {}, {}, []);
export { DemoDialogComponentNgFactory as DemoDialogComponentNgFactory };
