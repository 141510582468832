import { Store } from 'app/modules/editor/store';
import { SetTheme } from 'app/modules/editor/store/theme/actions';
import { getThemeType } from 'app/modules/editor/store/theme/selectors';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
// TODO: change the animation block green color
var LIGHT_PRIMARY_TEXT = 'rgba(0, 0, 0, 0.87)';
var DARK_PRIMARY_TEXT = 'rgba(255, 255, 255, 1)';
var LIGHT_SECONDARY_TEXT = 'rgba(0, 0, 0, 0.54)';
var DARK_SECONDARY_TEXT = 'rgba(255, 255, 255, 0.7)';
var LIGHT_DISABLED_TEXT = 'rgba(0, 0, 0, 0.26)';
var DARK_DISABLED_TEXT = 'rgba(255, 255, 255, 0.3)';
var LIGHT_DIVIDER_TEXT = 'rgba(0, 0, 0, 0.12)';
var DARK_DIVIDER_TEXT = 'rgba(255, 255, 255, 0.12)';
/**
 * A simple service that provides an interface for making theme changes.
 */
var ThemeService = /** @class */ (function () {
    function ThemeService(store) {
        this.store = store;
    }
    ThemeService.prototype.setTheme = function (themeType) {
        this.store.dispatch(new SetTheme(themeType));
    };
    ThemeService.prototype.toggleTheme = function () {
        this.setTheme(this.getThemeType().themeType === 'dark' ? 'light' : 'dark');
    };
    ThemeService.prototype.getThemeType = function () {
        var result;
        this.asObservable()
            .pipe(first())
            .subscribe(function (res) { return (result = res); });
        return result;
    };
    ThemeService.prototype.asObservable = function () {
        return this.store.select(getThemeType);
    };
    ThemeService.prototype.getPrimaryTextColor = function () {
        return this.getThemeType().themeType === 'dark' ? DARK_PRIMARY_TEXT : LIGHT_PRIMARY_TEXT;
    };
    ThemeService.prototype.getSecondaryTextColor = function () {
        return this.getThemeType().themeType === 'dark' ? DARK_SECONDARY_TEXT : LIGHT_SECONDARY_TEXT;
    };
    ThemeService.prototype.getDisabledTextColor = function () {
        return this.getThemeType().themeType === 'dark' ? DARK_DISABLED_TEXT : LIGHT_DISABLED_TEXT;
    };
    ThemeService.prototype.getDividerTextColor = function () {
        return this.getThemeType().themeType === 'dark' ? DARK_DIVIDER_TEXT : LIGHT_DIVIDER_TEXT;
    };
    ThemeService.ngInjectableDef = i0.defineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(i0.inject(i1.Store)); }, token: ThemeService, providedIn: "root" });
    return ThemeService;
}());
export { ThemeService };
