import * as $ from 'jquery';
var DRAG_SLOP_PIXELS = 4;
var Dragger = /** @class */ (function () {
    function Dragger(opts) {
        if (opts === void 0) { opts = {}; }
        var _this = this;
        this.direction = opts.direction || 'both';
        this.downX = opts.downX;
        this.downY = opts.downY;
        this.shouldSkipSlopCheck = !!opts.shouldSkipSlopCheck;
        this.onBeginDragFn = opts.onBeginDragFn || (function () { });
        this.onDragFn = opts.onDragFn || (function () { });
        this.onDropFn = opts.onDropFn || (function () { });
        this.isDragging = false;
        this.draggingScrim = undefined;
        this.draggingCursor = opts.draggingCursor || 'grabbing';
        var mouseMoveHandlerFn = function (event) {
            if (!_this.isDragging && _this.shouldBeginDragging(event)) {
                _this.isDragging = true;
                _this.draggingScrim = _this.buildDraggingScrim().appendTo(document.body);
                _this.draggingCursor = _this.draggingCursor_;
                _this.onBeginDragFn(event);
            }
            if (_this.isDragging) {
                _this.onDragFn(event, { x: event.clientX - _this.downX, y: event.clientY - _this.downY });
            }
        };
        var mouseUpHandlerFn = function (event) {
            $(window).off('mousemove', mouseMoveHandlerFn).off('mouseup', mouseUpHandlerFn);
            if (_this.isDragging) {
                _this.onDragFn(event, { x: event.clientX - _this.downX, y: event.clientY - _this.downY });
                _this.onDropFn();
                _this.draggingScrim.remove();
                _this.draggingScrim = undefined;
                _this.isDragging = false;
                event.stopPropagation();
                event.preventDefault();
                return false;
            }
            return undefined;
        };
        $(window).on('mousemove', mouseMoveHandlerFn).on('mouseup', mouseUpHandlerFn);
    }
    Dragger.prototype.shouldBeginDragging = function (mouseMoveEvent) {
        if (this.shouldSkipSlopCheck) {
            return true;
        }
        var begin = false;
        if (this.direction === 'both' || this.direction === 'horizontal') {
            begin = begin || Math.abs(mouseMoveEvent.clientX - this.downX) > DRAG_SLOP_PIXELS;
        }
        if (this.direction === 'both' || this.direction === 'vertical') {
            begin = begin || Math.abs(mouseMoveEvent.clientY - this.downY) > DRAG_SLOP_PIXELS;
        }
        return begin;
    };
    Object.defineProperty(Dragger.prototype, "draggingCursor", {
        set: function (cursor) {
            if (cursor === 'grabbing') {
                cursor = "-webkit-" + cursor;
            }
            this.draggingCursor_ = cursor;
            if (this.draggingScrim) {
                this.draggingScrim.css({ cursor: cursor });
            }
        },
        enumerable: true,
        configurable: true
    });
    Dragger.prototype.buildDraggingScrim = function () {
        return $('<div>').css({
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
        });
    };
    return Dragger;
}());
export { Dragger };
