import * as tslib_1 from "tslib";
import { INTERPOLATORS } from 'app/modules/editor/model/interpolators';
import { ColorProperty, EnumProperty, NumberProperty, PathProperty, Property, } from 'app/modules/editor/model/properties';
import * as _ from 'lodash';
/**
 * An animation block is an individual layer property tween (property animation).
 */
var AnimationBlock = /** @class */ (function () {
    function AnimationBlock(obj) {
        this.id = obj.id || _.uniqueId();
        this.layerId = obj.layerId;
        this.propertyName = obj.propertyName;
        this.startTime = obj.startTime || 0;
        this.endTime = obj.endTime || 100;
        if (this.startTime > this.endTime) {
            // TODO: don't let this happen (usually results in behavior that seems weird to users)
            var tmp = this.endTime;
            this.endTime = this.startTime;
            this.startTime = tmp;
        }
        // TODO: use the correct default interpolator for import svg/avd/property input
        this.interpolator = obj.interpolator || INTERPOLATORS[0].value;
        this.fromValue = obj.fromValue;
        this.toValue = obj.toValue;
        this.type = obj.type;
    }
    AnimationBlock_1 = AnimationBlock;
    AnimationBlock.from = function (obj) {
        switch (obj.type) {
            case 'path':
                return new PathAnimationBlock(obj);
            case 'color':
                return new ColorAnimationBlock(obj);
            case 'number':
                return new NumberAnimationBlock(obj);
            default:
                throw new Error('invalid block type: ' + obj.type);
        }
    };
    AnimationBlock.prototype.toJSON = function () {
        return {
            id: this.id,
            layerId: this.layerId,
            propertyName: this.propertyName,
            startTime: this.startTime,
            endTime: this.endTime,
            interpolator: this.interpolator,
            type: this.type,
            fromValue: this.fromValue,
            toValue: this.toValue,
        };
    };
    AnimationBlock.prototype.clone = function () {
        return AnimationBlock_1.from(this);
    };
    var AnimationBlock_1;
    AnimationBlock = AnimationBlock_1 = tslib_1.__decorate([
        Property.register(new NumberProperty('startTime', { min: 0, isInteger: true }), new NumberProperty('endTime', { min: 0, isInteger: true }), new EnumProperty('interpolator', INTERPOLATORS)),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], AnimationBlock);
    return AnimationBlock;
}());
export { AnimationBlock };
/**
 * An animation block that animates the 'pathData' property.
 */
var PathAnimationBlock = /** @class */ (function (_super) {
    tslib_1.__extends(PathAnimationBlock, _super);
    function PathAnimationBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // @Override
    PathAnimationBlock.prototype.toJSON = function () {
        return Object.assign(_super.prototype.toJSON.call(this), {
            fromValue: this.fromValue ? this.fromValue.getPathString() : '',
            toValue: this.toValue ? this.toValue.getPathString() : '',
        });
    };
    // @Override
    PathAnimationBlock.prototype.isAnimatable = function () {
        return !!this.fromValue && !!this.toValue && this.fromValue.isMorphableWith(this.toValue);
    };
    PathAnimationBlock = tslib_1.__decorate([
        Property.register(new PathProperty('fromValue'), new PathProperty('toValue'))
    ], PathAnimationBlock);
    return PathAnimationBlock;
}(AnimationBlock));
export { PathAnimationBlock };
/**
 * An animation block that animates a color property.
 */
var ColorAnimationBlock = /** @class */ (function (_super) {
    tslib_1.__extends(ColorAnimationBlock, _super);
    function ColorAnimationBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // @Override
    ColorAnimationBlock.prototype.isAnimatable = function () {
        // TODO should this be more specific (i.e. check if valid color values?)
        return !!this.fromValue && !!this.toValue;
    };
    ColorAnimationBlock = tslib_1.__decorate([
        Property.register(new ColorProperty('fromValue'), new ColorProperty('toValue'))
    ], ColorAnimationBlock);
    return ColorAnimationBlock;
}(AnimationBlock));
export { ColorAnimationBlock };
/**
 * An animation block that animates a number property.
 */
var NumberAnimationBlock = /** @class */ (function (_super) {
    tslib_1.__extends(NumberAnimationBlock, _super);
    function NumberAnimationBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // @Override
    NumberAnimationBlock.prototype.isAnimatable = function () {
        return _.isFinite(this.fromValue) && _.isFinite(this.toValue);
    };
    NumberAnimationBlock = tslib_1.__decorate([
        Property.register(new NumberProperty('fromValue'), new NumberProperty('toValue'))
    ], NumberAnimationBlock);
    return NumberAnimationBlock;
}(AnimationBlock));
export { NumberAnimationBlock };
