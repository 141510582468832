import * as tslib_1 from "tslib";
import { ActionMode, ActionSource, SelectionType } from 'app/modules/editor/model/actionmode';
import { LayerUtil } from 'app/modules/editor/model/layers';
import { ActionModeUtil } from 'app/modules/editor/scripts/actionmode';
import { AnimationRenderer } from 'app/modules/editor/scripts/animator';
import { getHiddenLayerIds, getSelectedLayerIds, getVectorLayer, } from 'app/modules/editor/store/layers/selectors';
import { createDeepEqualSelector, getEditorState } from 'app/modules/editor/store/selectors';
import { getAnimation, getSingleSelectedBlockLayerId, getSingleSelectedPathBlock, } from 'app/modules/editor/store/timeline/selectors';
import { createSelector, createStructuredSelector } from 'reselect';
var ɵ0 = function (s) { return s.actionmode; };
var getActionModeState = createSelector(getEditorState, ɵ0);
export var getActionMode = createSelector(getActionModeState, function (s) { return s.mode; });
export var isActionMode = createSelector(getActionMode, function (mode) { return mode !== ActionMode.None; });
export var getActionModeHover = createDeepEqualSelector(getActionModeState, function (s) { return s.hover; });
export var getActionModeSelections = createDeepEqualSelector(getActionModeState, function (s) { return s.selections; });
export var getActionModeSubPathSelections = createDeepEqualSelector(getActionModeSelections, function (selections) { return selections.filter(function (s) { return s.type === SelectionType.SubPath; }); });
export var getActionModeSegmentSelections = createDeepEqualSelector(getActionModeSelections, function (selections) { return selections.filter(function (s) { return s.type === SelectionType.Segment; }); });
export var getActionModePointSelections = createDeepEqualSelector(getActionModeSelections, function (selections) { return selections.filter(function (s) { return s.type === SelectionType.Point; }); });
export var getPairedSubPaths = createDeepEqualSelector(getActionModeState, function (state) { return state.pairedSubPaths; });
export var getUnpairedSubPath = createDeepEqualSelector(getActionModeState, function (state) { return state.unpairedSubPath; });
function getVectorLayerValue(getTimeFn) {
    return createSelector([getVectorLayer, getAnimation, getSingleSelectedPathBlock], function (vl, anim, block) {
        if (!block) {
            return undefined;
        }
        // Note this is a bit dangerous because the renderer interpolates paths
        // and that causes all mutated path state to be lost if we aren't careful.
        // There are currently checks in PathProperty.ts to avoid this by returning
        // the start and end path when the interpolated fraction is 0 and 1 respectively.
        var renderer = new AnimationRenderer(vl, anim);
        var timeMillis = getTimeFn(block);
        // First interpolate the entire vector layer.
        var renderedVl = renderer.setCurrentTime(timeMillis);
        // TODO: this is hacky! the real solution is to not clear path state after interpolations
        // Replace the interpolated value with the block's to/from value.
        var layer = vl.findLayerById(block.layerId).clone();
        layer.pathData = timeMillis === block.startTime ? block.fromValue : block.toValue;
        return LayerUtil.updateLayer(renderedVl, layer);
    });
}
var ɵ1 = function (block) { return block.startTime; };
var getVectorLayerFromValue = getVectorLayerValue(ɵ1);
var ɵ2 = function (block) { return block.endTime; };
var getVectorLayerToValue = getVectorLayerValue(ɵ2);
function getMorphableLayerValue(selector) {
    return createSelector([selector, getSingleSelectedBlockLayerId], function (vl, blockLayerId) {
        if (!vl || !blockLayerId) {
            return undefined;
        }
        return vl.findLayerById(blockLayerId);
    });
}
var getMorphableLayerFromValue = getMorphableLayerValue(getVectorLayerFromValue);
var getMorphableLayerToValue = getMorphableLayerValue(getVectorLayerToValue);
var ɵ3 = function (block) { return (block ? ActionModeUtil.checkPathsCompatible(block) : undefined); };
var getPathsCompatibleResult = createSelector(getSingleSelectedPathBlock, ɵ3);
function getHighlightedSubIdxWithError(actionSource) {
    return createSelector([getActionMode, getActionModeSelections, getPathsCompatibleResult], function (mode, selections, result) {
        if (!result) {
            // Then there is no path animation block currently selected.
            return undefined;
        }
        var areCompatible = result.areCompatible, errorPath = result.errorPath, errorSubIdx = result.errorSubIdx;
        if (mode !== ActionMode.Selection || selections.length) {
            // Don't show any highlights if we're not in selection mode, or
            // if there are any existing selections.
            return undefined;
        }
        if (areCompatible || errorPath !== actionSource || errorSubIdx === undefined) {
            return undefined;
        }
        return errorSubIdx;
    });
}
var actionModeBaseSelectors = {
    blockLayerId: getSingleSelectedBlockLayerId,
    isActionMode: isActionMode,
    hover: getActionModeHover,
    selections: getActionModeSelections,
    pairedSubPaths: getPairedSubPaths,
    unpairedSubPath: getUnpairedSubPath,
    hiddenLayerIds: getHiddenLayerIds,
    selectedLayerIds: getSelectedLayerIds,
};
export var getActionModeStartState = createStructuredSelector(tslib_1.__assign({}, actionModeBaseSelectors, { vectorLayer: getVectorLayerFromValue, subIdxWithError: getHighlightedSubIdxWithError(ActionSource.From) }));
export var getActionModeEndState = createStructuredSelector(tslib_1.__assign({}, actionModeBaseSelectors, { vectorLayer: getVectorLayerToValue, subIdxWithError: getHighlightedSubIdxWithError(ActionSource.To) }));
export var getToolbarState = createStructuredSelector({
    mode: getActionMode,
    fromMl: getMorphableLayerFromValue,
    toMl: getMorphableLayerToValue,
    selections: getActionModeSelections,
    unpairedSubPath: getUnpairedSubPath,
    block: getSingleSelectedPathBlock,
});
export { ɵ0, ɵ1, ɵ2, ɵ3 };
