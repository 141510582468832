import * as tslib_1 from "tslib";
export function DestroyableMixin(Base) {
    if (Base === void 0) { Base = /** @class */ (function () {
        function class_1() {
        }
        return class_1;
    }()); }
    return /** @class */ (function (_super) {
        tslib_1.__extends(class_2, _super);
        function class_2() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.subscriptions = [];
            return _this;
        }
        class_2.prototype.registerSubscription = function (sub) {
            this.subscriptions.push(sub);
        };
        class_2.prototype.ngOnDestroy = function () {
            this.subscriptions.forEach(function (x) { return x.unsubscribe(); });
        };
        return class_2;
    }(Base));
}
