import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef } from '@angular/core';
import { ActionMode, ActionSource, HoverType, SelectionType, } from 'app/modules/editor/model/actionmode';
import { ClipPathLayer, GroupLayer, LayerUtil, PathLayer, VectorLayer, } from 'app/modules/editor/model/layers';
import { MathUtil, Matrix } from 'app/modules/editor/scripts/common';
import { DestroyableMixin } from 'app/modules/editor/scripts/mixins';
import { ActionModeService, LayerTimelineService, PlaybackService, ShortcutService, } from 'app/modules/editor/services';
import { Store } from 'app/modules/editor/store';
import { getActionMode, getActionModeEndState, getActionModeHover, getActionModeStartState, } from 'app/modules/editor/store/actionmode/selectors';
import { getCanvasOverlayState } from 'app/modules/editor/store/common/selectors';
import { getVectorLayer } from 'app/modules/editor/store/layers/selectors';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { combineLatest, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanvasLayoutMixin } from './CanvasLayoutMixin';
import * as CanvasUtil from './CanvasUtil';
import { PairSubPathHelper } from './PairSubPathHelper';
import { SegmentSplitter } from './SegmentSplitter';
import { SelectionHelper } from './SelectionHelper';
import { ShapeSplitter } from './ShapeSplitter';
// The line width of a highlight in css pixels.
var HIGHLIGHT_LINE_WIDTH = 6;
// The line dash of a highlight in css pixels.
var HIGHLIGHT_LINE_DASH = 12;
// The minimum distance between a point and a path that causes a snap.
var MIN_SNAP_THRESHOLD = 12;
// The distance of a mouse gesture that triggers a drag, in css pixels.
var DRAG_TRIGGER_TOUCH_SLOP = 6;
// The radius of a medium point in css pixels.
var MEDIUM_POINT_RADIUS = 8;
// The radius of a small point in css pixels.
var SMALL_POINT_RADIUS = MEDIUM_POINT_RADIUS / 1.7;
var SPLIT_POINT_RADIUS_FACTOR = 0.8;
var SELECTED_POINT_RADIUS_FACTOR = 1.25;
var POINT_BORDER_FACTOR = 1.075;
var NORMAL_POINT_COLOR = '#2962FF'; // Blue A400
var SPLIT_POINT_COLOR = '#E65100'; // Orange 900
var HIGHLIGHT_COLOR = '#448AFF';
var POINT_BORDER_COLOR = '#000';
var POINT_TEXT_COLOR = '#fff';
var ERROR_COLOR = '#F44336';
/**
 * A directive that draws overlay selections and other content on top
 * of the currently active vector layer.
 */
var CanvasOverlayDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CanvasOverlayDirective, _super);
    function CanvasOverlayDirective(elementRef, store, actionModeService, playbackService, layerTimelineService) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.actionModeService = actionModeService;
        _this.playbackService = playbackService;
        _this.layerTimelineService = layerTimelineService;
        // Normal mode variables.
        _this.hiddenLayerIds = new Set();
        _this.selectedLayerIds = new Set();
        _this.selectedBlockLayerIds = new Set();
        _this.$canvas = $(elementRef.nativeElement);
        return _this;
    }
    CanvasOverlayDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.actionSource === ActionSource.Animated) {
            // Animated canvas specific setup.
            this.registerSubscription(combineLatest(
            // TODO: don't think this is necessary anymore? only need to query playback service now?
            merge(this.playbackService.asObservable().pipe(map(function (event) { return event.vl; })), this.store.select(getVectorLayer)), this.store.select(getCanvasOverlayState)).subscribe(function (_a) {
                var vectorLayer = _a[0], _b = _a[1], hiddenLayerIds = _b.hiddenLayerIds, selectedLayerIds = _b.selectedLayerIds, isActionMode = _b.isActionMode, selectedBlockLayerIds = _b.selectedBlockLayerIds;
                _this.vectorLayer = vectorLayer;
                _this.hiddenLayerIds = hiddenLayerIds;
                _this.selectedLayerIds = selectedLayerIds;
                _this.isActionMode = isActionMode;
                _this.selectedBlockLayerIds = selectedBlockLayerIds;
                _this.draw();
            }));
        }
        else {
            // From & to canvas specific setup.
            var shapeShifterSelector = this.actionSource === ActionSource.From ? getActionModeStartState : getActionModeEndState;
            this.registerSubscription(this.store
                .select(shapeShifterSelector)
                .subscribe(function (_a) {
                var vectorLayer = _a.vectorLayer, blockLayerId = _a.blockLayerId, isActionMode = _a.isActionMode, hover = _a.hover, selections = _a.selections, pairedSubPaths = _a.pairedSubPaths, unpairedSubPath = _a.unpairedSubPath, hiddenLayerIds = _a.hiddenLayerIds, selectedLayerIds = _a.selectedLayerIds, subIdxWithError = _a.subIdxWithError;
                _this.vectorLayer = vectorLayer;
                _this.blockLayerId = blockLayerId;
                _this.isActionMode = isActionMode;
                _this.actionHover = hover;
                _this.actionSelections = selections;
                _this.pairedSubPaths = new Set(pairedSubPaths);
                _this.unpairedSubPath = unpairedSubPath;
                _this.hiddenLayerIds = hiddenLayerIds;
                _this.selectedLayerIds = selectedLayerIds;
                _this.subIdxWithError = subIdxWithError;
                _this.draw();
            }));
            this.registerSubscription(this.store.select(getActionMode).subscribe(function (mode) {
                _this.actionMode = mode;
                var layer = _this.activePathLayer;
                if (_this.actionMode === ActionMode.SplitCommands ||
                    (_this.actionMode === ActionMode.SplitSubPaths &&
                        layer &&
                        layer.isStroked() &&
                        !layer.isFilled())) {
                    var subIdxs = new Set();
                    for (var _i = 0, _a = _this.actionSelections; _i < _a.length; _i++) {
                        var s = _a[_i];
                        subIdxs.add(s.subIdx);
                    }
                    _this.segmentSplitter = new SegmentSplitter(_this);
                }
                else {
                    _this.segmentSplitter = undefined;
                }
                _this.selectionHelper =
                    _this.actionMode === ActionMode.Selection ? new SelectionHelper(_this) : undefined;
                if (_this.actionMode === ActionMode.PairSubPaths) {
                    _this.pairSubPathHelper = new PairSubPathHelper(_this);
                    var selections = _this.actionSelections.filter(function (s) { return s.type === SelectionType.SubPath; });
                    if (selections.length) {
                        var _b = selections[0], source = _b.source, subIdx = _b.subIdx;
                        // TODO: avoid calling this in a subscription (should automatically do this)
                        _this.actionModeService.setUnpairedSubPath({ source: source, subIdx: subIdx });
                    }
                }
                else {
                    _this.pairSubPathHelper = undefined;
                }
                _this.shapeSplitter =
                    _this.actionMode === ActionMode.SplitSubPaths && layer && layer.isFilled()
                        ? new ShapeSplitter(_this)
                        : undefined;
                _this.currentHoverPreviewPath = undefined;
                _this.draw();
            }));
            var updateCurrentHoverFn_1 = function (hover) {
                var previewPath;
                if (_this.vectorLayer && _this.activePath && hover) {
                    // If the user is hovering over the inspector split button, then build
                    // a snapshot of what the path would look like after the action
                    // and display the result.
                    var mutator = _this.activePath.mutate();
                    var type = hover.type, subIdx = hover.subIdx, cmdIdx = hover.cmdIdx;
                    switch (type) {
                        case HoverType.Split:
                            previewPath = mutator.splitCommandInHalf(subIdx, cmdIdx).build();
                            break;
                        case HoverType.Unsplit:
                            previewPath = mutator.unsplitCommand(subIdx, cmdIdx).build();
                            break;
                    }
                }
                _this.currentHoverPreviewPath = previewPath;
                _this.draw();
            };
            // TODO: avoid re-executing the draw by combining with the above subscriptions
            this.registerSubscription(this.store.select(getActionModeHover).subscribe(function (hover) {
                if (!hover) {
                    // Clear the current hover.
                    updateCurrentHoverFn_1(undefined);
                    return;
                }
                if (hover.source !== _this.actionSource && hover.type !== HoverType.Point) {
                    updateCurrentHoverFn_1(undefined);
                    return;
                }
                updateCurrentHoverFn_1(hover);
            }));
        }
    };
    Object.defineProperty(CanvasOverlayDirective.prototype, "overlayCtx", {
        get: function () {
            return this.$canvas.get(0).getContext('2d');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanvasOverlayDirective.prototype, "highlightLineWidth", {
        get: function () {
            return HIGHLIGHT_LINE_WIDTH / this.cssScale;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanvasOverlayDirective.prototype, "minSnapThreshold", {
        get: function () {
            return MIN_SNAP_THRESHOLD / this.cssScale;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanvasOverlayDirective.prototype, "highlightLineDash", {
        get: function () {
            return [HIGHLIGHT_LINE_DASH / this.cssScale, HIGHLIGHT_LINE_DASH / this.cssScale];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanvasOverlayDirective.prototype, "smallPointRadius", {
        get: function () {
            return SMALL_POINT_RADIUS / this.cssScale;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanvasOverlayDirective.prototype, "mediumPointRadius", {
        get: function () {
            return MEDIUM_POINT_RADIUS / this.cssScale;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanvasOverlayDirective.prototype, "splitPointRadius", {
        get: function () {
            return this.mediumPointRadius * SPLIT_POINT_RADIUS_FACTOR;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanvasOverlayDirective.prototype, "selectedSegmentLineWidth", {
        get: function () {
            return HIGHLIGHT_LINE_WIDTH / this.cssScale / 1.9;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanvasOverlayDirective.prototype, "unselectedSegmentLineWidth", {
        get: function () {
            return HIGHLIGHT_LINE_WIDTH / this.cssScale / 3;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanvasOverlayDirective.prototype, "dragTriggerTouchSlop", {
        get: function () {
            return DRAG_TRIGGER_TOUCH_SLOP / this.cssScale;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanvasOverlayDirective.prototype, "activePathLayer", {
        // NOTE: only use this for action mode
        get: function () {
            if (!this.vectorLayer) {
                return undefined;
            }
            return this.vectorLayer.findLayerById(this.blockLayerId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanvasOverlayDirective.prototype, "activePath", {
        // NOTE: only use this for action mode
        get: function () {
            var layer = this.activePathLayer;
            if (!layer) {
                return undefined;
            }
            return layer.pathData;
        },
        enumerable: true,
        configurable: true
    });
    // @Override
    CanvasOverlayDirective.prototype.onDimensionsChanged = function () {
        var _a = this.getViewport(), w = _a.w, h = _a.h;
        this.$canvas.attr({ width: w * this.attrScale, height: h * this.attrScale });
        this.$canvas.css({ width: w * this.cssScale, height: h * this.cssScale });
        this.draw();
    };
    CanvasOverlayDirective.prototype.draw = function () {
        var ctx = this.overlayCtx;
        if (this.vectorLayer) {
            var _a = this.getViewport(), w = _a.w, h = _a.h;
            ctx.save();
            ctx.scale(this.attrScale, this.attrScale);
            ctx.clearRect(0, 0, w, h);
            this.drawLayerSelections(ctx, this.vectorLayer);
            this.drawHighlights(ctx);
            ctx.restore();
            // Draw points in terms of physical pixels, not viewport pixels.
            this.drawLabeledPoints(ctx);
            this.drawDraggingPoints(ctx);
            this.drawFloatingPreviewPoint(ctx);
            this.drawFloatingSplitFilledPathPreviewPoints(ctx);
        }
        this.drawPixelGrid(ctx);
    };
    // Recursively draws all layer selections to the canvas.
    CanvasOverlayDirective.prototype.drawLayerSelections = function (ctx, curr) {
        var _this = this;
        if (this.isActionMode) {
            // Don't draw selections for hidden layers or while in action mode.
            return;
        }
        if (this.selectedLayerIds.has(curr.id) || this.selectedBlockLayerIds.has(curr.id)) {
            var root = this.vectorLayer;
            var flattenedTransform = LayerUtil.getCanvasTransformForLayer(root, curr.id);
            if (curr instanceof ClipPathLayer) {
                if (curr.pathData && curr.pathData.getCommands().length) {
                    CanvasUtil.executeCommands(ctx, curr.pathData.getCommands(), flattenedTransform);
                    executeHighlights(ctx, HIGHLIGHT_COLOR, this.highlightLineWidth, this.highlightLineDash);
                    ctx.clip();
                }
            }
            else if (curr instanceof PathLayer) {
                if (curr.pathData && curr.pathData.getCommands().length) {
                    ctx.save();
                    CanvasUtil.executeCommands(ctx, curr.pathData.getCommands(), flattenedTransform);
                    executeHighlights(ctx, HIGHLIGHT_COLOR, this.highlightLineWidth);
                    ctx.restore();
                }
            }
            else if (curr instanceof VectorLayer || curr instanceof GroupLayer) {
                var bounds = curr.bounds;
                if (bounds) {
                    ctx.save();
                    var a = flattenedTransform.a, b = flattenedTransform.b, c = flattenedTransform.c, d = flattenedTransform.d, e = flattenedTransform.e, f = flattenedTransform.f;
                    ctx.transform(a, b, c, d, e, f);
                    ctx.beginPath();
                    ctx.rect(bounds.l, bounds.t, bounds.r - bounds.l, bounds.b - bounds.t);
                    executeHighlights(ctx, HIGHLIGHT_COLOR, this.highlightLineWidth);
                    ctx.restore();
                }
            }
        }
        curr.children.forEach(function (child) { return _this.drawLayerSelections(ctx, child); });
    };
    // Draw any highlighted segments.
    CanvasOverlayDirective.prototype.drawHighlights = function (ctx) {
        var _this = this;
        if (!this.isActionMode || this.actionSource === ActionSource.Animated || !this.activePath) {
            return;
        }
        var flattenedTransform = LayerUtil.getCanvasTransformForLayer(this.vectorLayer, this.blockLayerId);
        var pathLayer = this.activePathLayer;
        var activePath = pathLayer.pathData;
        var currentHover = this.actionHover;
        if (this.selectionHelper) {
            // Draw any highlighted subpaths. We'll highlight a subpath if a subpath
            // selection or a point selection exists.
            var selectedSubPaths = _(this.actionSelections)
                .filter(function (s) {
                return (s.source === _this.actionSource &&
                    (s.type === SelectionType.Point || s.type === SelectionType.SubPath));
            })
                .map(function (s) { return s.subIdx; })
                .uniq()
                .map(function (subIdx) { return activePath.getSubPath(subIdx); })
                .filter(function (subPath) { return !subPath.isCollapsing(); })
                .value();
            for (var _i = 0, selectedSubPaths_1 = selectedSubPaths; _i < selectedSubPaths_1.length; _i++) {
                var subPath = selectedSubPaths_1[_i];
                // If the subpath has a split segment, highlight it in orange. Otherwise,
                // use the default blue highlight color.
                var isSplitSubPath = subPath.getCommands().some(function (c) { return c.isSplitSegment(); });
                var highlightColor = isSplitSubPath ? SPLIT_POINT_COLOR : HIGHLIGHT_COLOR;
                CanvasUtil.executeCommands(ctx, subPath.getCommands(), flattenedTransform);
                executeHighlights(ctx, highlightColor, this.selectedSegmentLineWidth);
            }
            var segmentSelections = this.actionSelections
                .filter(function (s) { return s.type === SelectionType.Segment; })
                .filter(function (s) { return s.source === _this.actionSource; })
                .map(function (s) {
                return { subIdx: s.subIdx, cmdIdx: s.cmdIdx };
            });
            var hover = currentHover;
            if (hover && hover.source === this.actionSource && hover.type === HoverType.Segment) {
                segmentSelections.push({
                    subIdx: hover.subIdx,
                    cmdIdx: hover.cmdIdx,
                });
            }
            var segmentSelectionCmds = segmentSelections
                .map(function (s) { return activePath.getCommand(s.subIdx, s.cmdIdx); })
                .filter(function (cmd) { return cmd.isSplitSegment(); });
            CanvasUtil.executeCommands(ctx, segmentSelectionCmds, flattenedTransform);
            executeHighlights(ctx, SPLIT_POINT_COLOR, this.selectedSegmentLineWidth);
            // Highlight any subpaths with errors.
            if (this.subIdxWithError !== undefined) {
                var cmds = activePath.getSubPath(this.subIdxWithError).getCommands();
                CanvasUtil.executeCommands(ctx, cmds, flattenedTransform);
                executeHighlights(ctx, ERROR_COLOR, this.highlightLineWidth, this.highlightLineDash);
            }
        }
        else if (this.segmentSplitter && this.segmentSplitter.getProjectionOntoPath()) {
            // Highlight the segment as the user hovers over it.
            var _a = this.segmentSplitter.getProjectionOntoPath(), subIdx = _a.subIdx, cmdIdx = _a.cmdIdx, d = _a.projection.d;
            if (d < this.minSnapThreshold) {
                CanvasUtil.executeCommands(ctx, [activePath.getCommand(subIdx, cmdIdx)], flattenedTransform);
                executeHighlights(ctx, SPLIT_POINT_COLOR, this.selectedSegmentLineWidth);
            }
        }
        // Draw any existing split shape segments to the canvas.
        var commands = _(activePath.getSubPaths())
            .filter(function (s) { return !s.isCollapsing(); })
            .flatMap(function (s) { return s.getCommands(); })
            .filter(function (c) { return c.isSplitSegment(); })
            .value();
        CanvasUtil.executeCommands(ctx, commands, flattenedTransform);
        executeHighlights(ctx, SPLIT_POINT_COLOR, this.unselectedSegmentLineWidth);
        if (this.pairSubPathHelper) {
            var currUnpair = this.unpairedSubPath;
            if (currUnpair) {
                // Draw the current unpaired subpath in orange, if it exists.
                var source = currUnpair.source, subIdx = currUnpair.subIdx;
                if (source === this.actionSource) {
                    var subPath = activePath.getSubPath(subIdx);
                    CanvasUtil.executeCommands(ctx, subPath.getCommands(), flattenedTransform);
                    executeHighlights(ctx, SPLIT_POINT_COLOR, this.selectedSegmentLineWidth);
                }
            }
            var pairedSubPaths = this.pairedSubPaths;
            var hasHover = currentHover &&
                currentHover.source === this.actionSource &&
                currentHover.type === HoverType.SubPath;
            if (hasHover) {
                pairedSubPaths.delete(currentHover.subIdx);
            }
            if (pairedSubPaths.size) {
                // Draw any already paired subpaths in blue.
                var pairedCmds = _.flatMap(Array.from(pairedSubPaths), function (subIdx) { return activePath.getSubPath(subIdx).getCommands(); });
                CanvasUtil.executeCommands(ctx, pairedCmds, flattenedTransform);
                executeHighlights(ctx, NORMAL_POINT_COLOR, this.selectedSegmentLineWidth);
            }
            if (hasHover) {
                // Highlight the hover in orange, if it exists.
                var hoverCmds = activePath.getSubPath(currentHover.subIdx).getCommands();
                CanvasUtil.executeCommands(ctx, hoverCmds, flattenedTransform);
                executeHighlights(ctx, SPLIT_POINT_COLOR, this.selectedSegmentLineWidth);
            }
        }
        else if (this.shapeSplitter) {
            // If we are splitting a filled subpath, draw the in progress drag segment.
            var proj1 = this.shapeSplitter.getInitialProjectionOntoPath();
            var proj2 = this.shapeSplitter.getFinalProjectionOntoPath();
            if (proj1) {
                // Draw a line from the starting projection to the final projection (or
                // to the last known mouse location, if one doesn't exist).
                var startPoint = applyGroupTransform(proj1.projection, flattenedTransform);
                var endPoint = proj2
                    ? applyGroupTransform(proj2.projection, flattenedTransform)
                    : this.shapeSplitter.getLastKnownMouseLocation();
                ctx.beginPath();
                ctx.moveTo(startPoint.x, startPoint.y);
                ctx.lineTo(endPoint.x, endPoint.y);
                executeHighlights(ctx, SPLIT_POINT_COLOR, this.selectedSegmentLineWidth);
            }
            if (!proj1 || proj2) {
                // Highlight the segment as the user hovers over it.
                var projectionOntoPath = this.shapeSplitter.getCurrentProjectionOntoPath();
                if (projectionOntoPath) {
                    var projection = projectionOntoPath.projection;
                    if (projection && projection.d < this.minSnapThreshold) {
                        var subIdx = projectionOntoPath.subIdx, cmdIdx = projectionOntoPath.cmdIdx;
                        CanvasUtil.executeCommands(ctx, [activePath.getCommand(subIdx, cmdIdx)], flattenedTransform);
                        executeHighlights(ctx, SPLIT_POINT_COLOR, this.selectedSegmentLineWidth);
                    }
                }
            }
        }
    };
    // Draw any labeled points.
    CanvasOverlayDirective.prototype.drawLabeledPoints = function (ctx) {
        if (!this.isActionMode || this.actionSource === ActionSource.Animated || !this.activePath) {
            return;
        }
        var pathLayer = this.activePathLayer;
        var path = pathLayer.pathData;
        if (this.currentHoverPreviewPath) {
            path = this.currentHoverPreviewPath;
        }
        // Create a list of all path points in their normal order.
        var pointInfos = _(path.getSubPaths())
            .filter(function (s) { return !s.isCollapsing(); })
            .map(function (s, subIdx) {
            return s.getCommands().map(function (cmd, cmdIdx) {
                return { cmd: cmd, subIdx: subIdx, cmdIdx: cmdIdx };
            });
        })
            .flatMap(function (pis) { return pis; })
            .reverse()
            .value();
        var subPathSelections = this.actionSelections.filter(function (s) { return s.type === SelectionType.SubPath; });
        var pointSelections = this.actionSelections.filter(function (s) { return s.type === SelectionType.Point; });
        // Remove all selected points from the list.
        var isPointInfoSelectedFn = function (_a) {
            var subIdx = _a.subIdx, cmdIdx = _a.cmdIdx;
            return (subPathSelections.some(function (s) { return s.subIdx === subIdx; }) ||
                pointSelections.some(function (s) { return s.subIdx === subIdx && s.cmdIdx === cmdIdx; }));
        };
        var selectedPointInfos = _.remove(pointInfos, function (pi) { return isPointInfoSelectedFn(pi); });
        // Remove any subpath points that share the same subIdx as an existing selection.
        // We'll call these 'medium' points (i.e. labeled, but not selected), and we'll
        // always draw selected points on top of medium points, and medium points
        // on top of small points.
        var isPointInfoAtLeastMediumFn = function (_a) {
            var subIdx = _a.subIdx;
            return (subPathSelections.some(function (s) { return s.subIdx === subIdx; }) ||
                pointSelections.some(function (s) { return s.subIdx === subIdx; }));
        };
        pointInfos.push.apply(pointInfos, _.remove(pointInfos, function (pi) { return isPointInfoAtLeastMediumFn(pi); }));
        pointInfos.push.apply(pointInfos, selectedPointInfos);
        var currentHover = this.actionHover;
        // Remove a hovering point, if one exists.
        var hoveringPointInfos = _.remove(pointInfos, function (_a) {
            var subIdx = _a.subIdx, cmdIdx = _a.cmdIdx;
            var hover = currentHover;
            return (hover &&
                hover.type === HoverType.Point &&
                hover.subIdx === subIdx &&
                hover.cmdIdx === cmdIdx);
        });
        // Remove any subpath points that share the same subIdx as an existing hover.
        var isPointInfoHoveringFn = function (_a) {
            var subIdx = _a.subIdx;
            var hover = currentHover;
            return hover && hover.type !== HoverType.Segment && hover.subIdx === subIdx;
        };
        // Similar to above, always draw hover points on top of subpath hover points.
        pointInfos.push.apply(pointInfos, _.remove(pointInfos, function (pi) { return isPointInfoHoveringFn(pi); }));
        pointInfos.push.apply(pointInfos, hoveringPointInfos);
        var draggingIndex = this.selectionHelper && this.selectionHelper.isDragTriggered()
            ? this.selectionHelper.getDraggableSplitIndex()
            : undefined;
        for (var _i = 0, pointInfos_1 = pointInfos; _i < pointInfos_1.length; _i++) {
            var _a = pointInfos_1[_i], cmd = _a.cmd, subIdx = _a.subIdx, cmdIdx = _a.cmdIdx;
            if (draggingIndex && subIdx === draggingIndex.subIdx && cmdIdx === draggingIndex.cmdIdx) {
                // Skip the currently dragged point. We'll draw that next.
                continue;
            }
            var radius = this.smallPointRadius;
            var text = void 0;
            var isHovering = isPointInfoHoveringFn({ cmd: cmd, subIdx: subIdx, cmdIdx: cmdIdx });
            var isAtLeastMedium = isPointInfoAtLeastMediumFn({ cmd: cmd, subIdx: subIdx, cmdIdx: cmdIdx });
            if ((isAtLeastMedium || isHovering) && this.actionMode === ActionMode.Selection) {
                radius = this.mediumPointRadius * SELECTED_POINT_RADIUS_FACTOR;
                var isPointEnlargedFn = function (source, sIdx, cIdx) {
                    return pointSelections.some(function (s) {
                        return s.subIdx === sIdx && s.cmdIdx === cIdx && s.source === source;
                    });
                };
                if ((isHovering && cmdIdx === currentHover.cmdIdx) ||
                    isPointEnlargedFn(ActionSource.From, subIdx, cmdIdx) ||
                    isPointEnlargedFn(ActionSource.To, subIdx, cmdIdx)) {
                    radius /= SPLIT_POINT_RADIUS_FACTOR;
                }
                text = (cmdIdx + 1).toString();
            }
            var color = void 0;
            if (cmd.isSplitPoint()) {
                radius *= SPLIT_POINT_RADIUS_FACTOR;
                color = SPLIT_POINT_COLOR;
            }
            else {
                color = NORMAL_POINT_COLOR;
            }
            var flattenedTransform = LayerUtil.getCanvasTransformForLayer(this.vectorLayer, this.blockLayerId);
            executeLabeledPoint(ctx, this.attrScale, applyGroupTransform(_.last(cmd.points), flattenedTransform), radius, color, text);
        }
    };
    // Draw any actively dragged points along the path in selection mode.
    CanvasOverlayDirective.prototype.drawDraggingPoints = function (ctx) {
        if (!this.isActionMode || this.actionSource === ActionSource.Animated || !this.activePath) {
            return;
        }
        if (this.actionMode !== ActionMode.Selection ||
            !this.selectionHelper ||
            !this.selectionHelper.isDragTriggered()) {
            return;
        }
        var flattenedTransform = LayerUtil.getCanvasTransformForLayer(this.vectorLayer, this.blockLayerId);
        var projection = this.selectionHelper.getProjectionOntoPath().projection;
        var point = projection.d < this.minSnapThreshold
            ? applyGroupTransform(projection, flattenedTransform)
            : this.selectionHelper.getLastKnownMouseLocation();
        executeLabeledPoint(ctx, this.attrScale, point, this.splitPointRadius, SPLIT_POINT_COLOR);
    };
    // Draw a floating point preview over the canvas in split commands mode
    // and split subpaths mode for stroked paths.
    CanvasOverlayDirective.prototype.drawFloatingPreviewPoint = function (ctx) {
        if (!this.isActionMode || this.actionSource === ActionSource.Animated || !this.activePath) {
            return;
        }
        var pathLayer = this.activePathLayer;
        if ((this.actionMode !== ActionMode.SplitCommands &&
            this.actionMode !== ActionMode.SplitSubPaths &&
            !pathLayer.isStroked()) ||
            !this.segmentSplitter ||
            !this.segmentSplitter.getProjectionOntoPath()) {
            return;
        }
        var projection = this.segmentSplitter.getProjectionOntoPath().projection;
        if (projection.d < this.minSnapThreshold) {
            var flattenedTransform = LayerUtil.getCanvasTransformForLayer(this.vectorLayer, this.blockLayerId);
            executeLabeledPoint(ctx, this.attrScale, applyGroupTransform(projection, flattenedTransform), this.splitPointRadius, SPLIT_POINT_COLOR);
        }
    };
    // Draw the floating points on top of the drag line in split filled subpath mode.
    CanvasOverlayDirective.prototype.drawFloatingSplitFilledPathPreviewPoints = function (ctx) {
        if (!this.isActionMode || this.actionSource === ActionSource.Animated || !this.activePath) {
            return;
        }
        if (this.actionMode !== ActionMode.SplitSubPaths || !this.shapeSplitter) {
            return;
        }
        var flattenedTransform = LayerUtil.getCanvasTransformForLayer(this.vectorLayer, this.blockLayerId);
        var proj1 = this.shapeSplitter.getInitialProjectionOntoPath();
        if (proj1) {
            var proj2 = this.shapeSplitter.getFinalProjectionOntoPath();
            executeLabeledPoint(ctx, this.attrScale, applyGroupTransform(proj1.projection, flattenedTransform), this.splitPointRadius, SPLIT_POINT_COLOR);
            if (this.shapeSplitter.willFinalProjectionOntoPathCreateSplitPoint()) {
                var endPoint = proj2
                    ? applyGroupTransform(proj2.projection, flattenedTransform)
                    : this.shapeSplitter.getLastKnownMouseLocation();
                executeLabeledPoint(ctx, this.attrScale, endPoint, this.splitPointRadius, SPLIT_POINT_COLOR);
            }
        }
        else if (this.shapeSplitter.getCurrentProjectionOntoPath()) {
            var projection = this.shapeSplitter.getCurrentProjectionOntoPath().projection;
            if (projection.d < this.minSnapThreshold) {
                executeLabeledPoint(ctx, this.attrScale, applyGroupTransform(projection, flattenedTransform), this.splitPointRadius, SPLIT_POINT_COLOR);
            }
        }
    };
    // Draws the pixel grid on top of the canvas content.
    CanvasOverlayDirective.prototype.drawPixelGrid = function (ctx) {
        // Note that we draw the pixel grid in terms of physical pixels,
        // not viewport pixels.
        if (this.cssScale > 4) {
            ctx.save();
            ctx.fillStyle = 'rgba(128, 128, 128, .25)';
            var devicePixelRatio_1 = window.devicePixelRatio || 1;
            var viewport = this.getViewport();
            for (var x = 1; x < viewport.w; x++) {
                ctx.fillRect(x * this.attrScale - devicePixelRatio_1 / 2, 0, devicePixelRatio_1, viewport.h * this.attrScale);
            }
            for (var y = 1; y < viewport.h; y++) {
                ctx.fillRect(0, y * this.attrScale - devicePixelRatio_1 / 2, viewport.w * this.attrScale, devicePixelRatio_1);
            }
            ctx.restore();
        }
    };
    // Called by the CanvasComponent.
    CanvasOverlayDirective.prototype.onMouseDown = function (event) {
        var mouseDown = this.mouseEventToViewportCoords(event);
        if (this.actionSource === ActionSource.Animated && !this.isActionMode) {
            // Detect layer selections.
            var hitLayer = this.hitTestForLayer(mouseDown);
            var isMetaOrShiftPressed = ShortcutService.isOsDependentModifierKey(event) || event.shiftKey;
            if (hitLayer) {
                this.layerTimelineService.selectLayer(hitLayer.id, !isMetaOrShiftPressed);
            }
            else if (!isMetaOrShiftPressed) {
                this.layerTimelineService.clearSelections();
            }
            return;
        }
        if (this.actionSource === ActionSource.Animated) {
            // Don't need to do anything for the animated canvas if we are in action mode.
            return;
        }
        if (this.actionMode === ActionMode.Selection) {
            this.selectionHelper.onMouseDown(mouseDown, event.shiftKey || ShortcutService.isOsDependentModifierKey(event));
        }
        else if (this.actionMode === ActionMode.PairSubPaths) {
            this.pairSubPathHelper.onMouseDown(mouseDown, event.shiftKey || ShortcutService.isOsDependentModifierKey(event));
        }
        else if (this.actionMode === ActionMode.SplitCommands) {
            this.segmentSplitter.onMouseDown(mouseDown);
        }
        else if (this.actionMode === ActionMode.SplitSubPaths) {
            var pathLayer = this.activePathLayer;
            if (!pathLayer.isFilled() && pathLayer.isStroked()) {
                this.segmentSplitter.onMouseDown(mouseDown);
            }
            else {
                this.shapeSplitter.onMouseDown(mouseDown);
            }
        }
    };
    // Called by the CanvasComponent.
    CanvasOverlayDirective.prototype.onMouseMove = function (event) {
        if (this.actionSource === ActionSource.Animated && !this.isActionMode) {
            return;
        }
        var mouseMove = this.mouseEventToViewportCoords(event);
        if (this.actionMode === ActionMode.Selection) {
            this.selectionHelper.onMouseMove(mouseMove);
        }
        else if (this.actionMode === ActionMode.PairSubPaths) {
            this.pairSubPathHelper.onMouseMove(mouseMove);
        }
        else if (this.actionMode === ActionMode.SplitCommands) {
            this.segmentSplitter.onMouseMove(mouseMove);
        }
        else if (this.actionMode === ActionMode.SplitSubPaths) {
            var pathLayer = this.activePathLayer;
            if (!pathLayer.isFilled() && pathLayer.isStroked()) {
                this.segmentSplitter.onMouseMove(mouseMove);
            }
            else {
                this.shapeSplitter.onMouseMove(mouseMove);
            }
        }
    };
    // Called by the CanvasComponent.
    CanvasOverlayDirective.prototype.onMouseUp = function (event) {
        if (this.actionSource === ActionSource.Animated && !this.isActionMode) {
            return;
        }
        var mouseUp = this.mouseEventToViewportCoords(event);
        if (this.actionMode === ActionMode.Selection) {
            this.selectionHelper.onMouseUp(mouseUp, event.shiftKey || ShortcutService.isOsDependentModifierKey(event));
        }
        else if (this.actionMode === ActionMode.PairSubPaths) {
            this.pairSubPathHelper.onMouseUp(mouseUp);
        }
        else if (this.actionMode === ActionMode.SplitCommands) {
            this.segmentSplitter.onMouseUp(mouseUp);
        }
        else if (this.actionMode === ActionMode.SplitSubPaths) {
            var pathLayer = this.activePathLayer;
            if (!pathLayer.isFilled() && pathLayer.isStroked()) {
                this.segmentSplitter.onMouseUp(mouseUp);
            }
            else {
                this.shapeSplitter.onMouseUp(mouseUp);
            }
        }
    };
    // Called by the CanvasComponent.
    CanvasOverlayDirective.prototype.onMouseLeave = function (event) {
        if (this.actionSource === ActionSource.Animated && !this.isActionMode) {
            return;
        }
        var mouseLeave = this.mouseEventToViewportCoords(event);
        if (this.actionMode === ActionMode.Selection) {
            // TODO: how to handle the case where the mouse leaves and re-enters mid-gesture?
            this.selectionHelper.onMouseLeave(mouseLeave);
        }
        else if (this.actionMode === ActionMode.PairSubPaths) {
            this.pairSubPathHelper.onMouseLeave(mouseLeave);
        }
        else if (this.actionMode === ActionMode.SplitCommands) {
            this.segmentSplitter.onMouseLeave(mouseLeave);
        }
        else if (this.actionMode === ActionMode.SplitSubPaths) {
            var pathLayer = this.activePathLayer;
            if (!pathLayer.isFilled() && pathLayer.isStroked()) {
                this.segmentSplitter.onMouseLeave(mouseLeave);
            }
            else {
                this.shapeSplitter.onMouseLeave(mouseLeave);
            }
        }
        this.actionModeService.clearHover();
    };
    CanvasOverlayDirective.prototype.mouseEventToViewportCoords = function (event) {
        var canvasOffset = this.$canvas.offset();
        var x = (event.pageX - canvasOffset.left) / this.cssScale;
        var y = (event.pageY - canvasOffset.top) / this.cssScale;
        return { x: x, y: y };
    };
    CanvasOverlayDirective.prototype.hitTestForLayer = function (point) {
        var _this = this;
        var root = this.vectorLayer;
        if (!root) {
            return undefined;
        }
        var recurseFn = function (layer) {
            if (_this.hiddenLayerIds.has(layer.id)) {
                return undefined;
            }
            // TODO: use a user-defined type check to confirm this layer is an instance of MorphableLayer
            if ((layer instanceof PathLayer || layer instanceof ClipPathLayer) && layer.pathData) {
                var canvasToLayerMatrix = LayerUtil.getCanvasTransformForLayer(root, layer.id).invert();
                if (!canvasToLayerMatrix) {
                    // Do nothing if matrix is non-invertible.
                    return undefined;
                }
                var transformedPoint = MathUtil.transformPoint(point, canvasToLayerMatrix);
                var isSegmentInRangeFn = void 0;
                isSegmentInRangeFn = function (distance) {
                    var maxDistance = 0;
                    if (layer instanceof PathLayer && layer.isStroked()) {
                        maxDistance = Math.max(_this.minSnapThreshold, layer.strokeWidth / 2);
                    }
                    return distance <= maxDistance;
                };
                var findShapesInRange = layer.isFilled();
                var hitResult = layer.pathData.hitTest(transformedPoint, {
                    isSegmentInRangeFn: isSegmentInRangeFn,
                    findShapesInRange: findShapesInRange,
                });
                return hitResult.isHit ? layer : undefined;
            }
            // Use 'hitTestLayer || h' and not the other way around because of reverse z-order.
            return layer.children.reduce(function (h, l) { return recurseFn(l) || h; }, undefined);
        };
        return recurseFn(root);
    };
    // NOTE: this should only be used in action mode
    CanvasOverlayDirective.prototype.performHitTest = function (mousePoint, opts) {
        var _this = this;
        if (opts === void 0) { opts = {}; }
        var flattenedTransform = LayerUtil.getCanvasTransformForLayer(this.vectorLayer, this.blockLayerId).invert();
        var transformedMousePoint = MathUtil.transformPoint(mousePoint, flattenedTransform);
        var isPointInRangeFn;
        if (!opts.noPoints) {
            isPointInRangeFn = function (distance, cmd) {
                var multiplyFactor = cmd.isSplitPoint() ? SPLIT_POINT_RADIUS_FACTOR : 1;
                return distance <= _this.mediumPointRadius * multiplyFactor;
            };
        }
        var pathLayer = this.vectorLayer.findLayerById(this.blockLayerId);
        if (!pathLayer.pathData) {
            return { isHit: false };
        }
        var isSegmentInRangeFn;
        if (!opts.noSegments) {
            isSegmentInRangeFn = function (distance) {
                var maxDistance = opts.withExtraSegmentPadding ? _this.minSnapThreshold : 0;
                if (pathLayer.isStroked()) {
                    maxDistance = Math.max(maxDistance, pathLayer.strokeWidth / 2);
                }
                return distance <= maxDistance;
            };
        }
        var findShapesInRange = pathLayer.isFilled() && !opts.noShapes;
        var restrictToSubIdx = opts.restrictToSubIdx;
        return pathLayer.pathData.hitTest(transformedMousePoint, {
            isPointInRangeFn: isPointInRangeFn,
            isSegmentInRangeFn: isSegmentInRangeFn,
            findShapesInRange: findShapesInRange,
            restrictToSubIdx: restrictToSubIdx,
        });
    };
    return CanvasOverlayDirective;
}(CanvasLayoutMixin(DestroyableMixin())));
export { CanvasOverlayDirective };
function executeHighlights(ctx, color, lineWidth, lineDash) {
    if (lineDash === void 0) { lineDash = []; }
    ctx.save();
    ctx.setLineDash(lineDash);
    ctx.lineCap = 'round';
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.stroke();
    ctx.restore();
}
// Draws a labeled point with optional text.
function executeLabeledPoint(ctx, attrScale, point, radius, color, text) {
    // Convert the point and the radius to physical pixel coordinates.
    // We do this to avoid fractional font sizes less than 1px, which
    // show up OK on Chrome but not on Firefox or Safari.
    point = MathUtil.transformPoint(point, Matrix.scaling(attrScale, attrScale));
    radius *= attrScale;
    ctx.save();
    ctx.beginPath();
    ctx.arc(point.x, point.y, radius * POINT_BORDER_FACTOR, 0, 2 * Math.PI, false);
    ctx.fillStyle = POINT_BORDER_COLOR;
    ctx.fill();
    ctx.beginPath();
    ctx.arc(point.x, point.y, radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = color;
    ctx.fill();
    if (text) {
        ctx.beginPath();
        ctx.fillStyle = POINT_TEXT_COLOR;
        ctx.font = radius + 'px Roboto, Helvetica Neue, sans-serif';
        var width = ctx.measureText(text).width;
        // TODO: is there a better way to get the height?
        var height = ctx.measureText('o').width;
        ctx.fillText(text, point.x - width / 2, point.y + height / 2);
        ctx.fill();
    }
    ctx.restore();
}
// Takes a path point and transforms it so that its coordinates are in terms
// of the VectorLayer's viewport coordinates.
function applyGroupTransform(mousePoint, transform) {
    return MathUtil.transformPoint(mousePoint, transform);
}
