import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef } from '@angular/core';
import { ActionSource } from 'app/modules/editor/model/actionmode';
import { ClipPathLayer, LayerUtil, PathLayer, } from 'app/modules/editor/model/layers';
import { ColorUtil } from 'app/modules/editor/scripts/common';
import { DestroyableMixin } from 'app/modules/editor/scripts/mixins';
import { PlaybackService } from 'app/modules/editor/services';
import { Store } from 'app/modules/editor/store';
import { getActionModeEndState, getActionModeStartState, } from 'app/modules/editor/store/actionmode/selectors';
import { getHiddenLayerIds, getVectorLayer } from 'app/modules/editor/store/layers/selectors';
import * as $ from 'jquery';
import { combineLatest, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanvasLayoutMixin } from './CanvasLayoutMixin';
import * as CanvasUtil from './CanvasUtil';
/**
 * Directive that draws the current vector layer to the canvas.
 */
var CanvasLayersDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CanvasLayersDirective, _super);
    function CanvasLayersDirective(elementRef, playbackService, store) {
        var _this = _super.call(this) || this;
        _this.playbackService = playbackService;
        _this.store = store;
        _this.hiddenLayerIds = new Set();
        _this.$renderingCanvas = $(elementRef.nativeElement);
        _this.$offscreenCanvas = $(document.createElement('canvas'));
        return _this;
    }
    CanvasLayersDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.actionSource === ActionSource.Animated) {
            // Preview canvas specific setup.
            this.registerSubscription(combineLatest(
            // TODO: don't think this is necessary anymore? only need to query playback service now?
            merge(this.playbackService.asObservable().pipe(map(function (event) { return event.vl; })), this.store.select(getVectorLayer)), this.store.select(getHiddenLayerIds)).subscribe(function (_a) {
                var vectorLayer = _a[0], hiddenLayerIds = _a[1];
                _this.vectorLayer = vectorLayer;
                _this.hiddenLayerIds = hiddenLayerIds;
                _this.draw();
            }));
        }
        else {
            // Start & end canvas specific setup.
            var actionModeSelector = this.actionSource === ActionSource.From ? getActionModeStartState : getActionModeEndState;
            this.registerSubscription(this.store.select(actionModeSelector).subscribe(function (_a) {
                var vectorLayer = _a.vectorLayer, hiddenLayerIds = _a.hiddenLayerIds;
                _this.vectorLayer = vectorLayer;
                _this.hiddenLayerIds = hiddenLayerIds;
                _this.draw();
            }));
        }
    };
    Object.defineProperty(CanvasLayersDirective.prototype, "renderingCtx", {
        get: function () {
            return this.$renderingCanvas.get(0).getContext('2d');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanvasLayersDirective.prototype, "offscreenCtx", {
        get: function () {
            return this.$offscreenCanvas.get(0).getContext('2d');
        },
        enumerable: true,
        configurable: true
    });
    // @Override
    CanvasLayersDirective.prototype.onDimensionsChanged = function (bounds, viewport) {
        var _this = this;
        var _a = this.getViewport(), w = _a.w, h = _a.h;
        [this.$renderingCanvas, this.$offscreenCanvas].forEach(function (canvas) {
            canvas.attr({ width: w * _this.attrScale, height: h * _this.attrScale });
            canvas.css({ width: w * _this.cssScale, height: h * _this.cssScale });
        });
        this.draw();
    };
    CanvasLayersDirective.prototype.draw = function () {
        var _this = this;
        if (!this.vectorLayer) {
            return;
        }
        // Scale the canvas so that everything from this point forward is drawn
        // in terms of the SVG's viewport coordinates.
        var setupCtxWithViewportCoordsFn = function (ctx) {
            ctx.scale(_this.attrScale, _this.attrScale);
            var _a = _this.getViewport(), w = _a.w, h = _a.h;
            ctx.clearRect(0, 0, w, h);
        };
        this.renderingCtx.save();
        setupCtxWithViewportCoordsFn(this.renderingCtx);
        if (this.vectorLayer.canvasColor) {
            this.renderingCtx.fillStyle = ColorUtil.androidToCssRgbaColor(this.vectorLayer.canvasColor);
            this.renderingCtx.fillRect(0, 0, this.vectorLayer.width, this.vectorLayer.height);
        }
        var currentAlpha = this.vectorLayer ? this.vectorLayer.alpha : 1;
        if (currentAlpha < 1) {
            this.offscreenCtx.save();
            setupCtxWithViewportCoordsFn(this.offscreenCtx);
        }
        // If the canvas is disabled, draw the layer to an offscreen canvas
        // so that we can draw it translucently w/o affecting the rest of
        // the layer's appearance.
        var layerCtx = currentAlpha < 1 ? this.offscreenCtx : this.renderingCtx;
        this.drawLayer(this.vectorLayer, this.vectorLayer, layerCtx);
        if (currentAlpha < 1) {
            this.renderingCtx.save();
            this.renderingCtx.globalAlpha = currentAlpha;
            // Bring the canvas back to its original coordinates before
            // drawing the offscreen canvas contents.
            this.renderingCtx.scale(1 / this.attrScale, 1 / this.attrScale);
            this.renderingCtx.drawImage(this.offscreenCtx.canvas, 0, 0);
            this.renderingCtx.restore();
            this.offscreenCtx.restore();
        }
        this.renderingCtx.restore();
    };
    CanvasLayersDirective.prototype.drawLayer = function (vl, layer, ctx) {
        var _this = this;
        if (this.hiddenLayerIds.has(layer.id)) {
            return;
        }
        if (layer instanceof ClipPathLayer) {
            this.drawClipPathLayer(vl, layer, ctx);
        }
        else if (layer instanceof PathLayer) {
            this.drawPathLayer(vl, layer, ctx);
        }
        else {
            ctx.save();
            layer.children.forEach(function (child) { return _this.drawLayer(vl, child, ctx); });
            ctx.restore();
        }
    };
    CanvasLayersDirective.prototype.drawClipPathLayer = function (vl, layer, ctx) {
        if (!layer.pathData || !layer.pathData.getCommands().length) {
            return;
        }
        var flattenedTransform = LayerUtil.getCanvasTransformForLayer(vl, layer.id);
        CanvasUtil.executeCommands(ctx, layer.pathData.getCommands(), flattenedTransform);
        ctx.clip();
    };
    CanvasLayersDirective.prototype.drawPathLayer = function (vl, layer, ctx) {
        if (!layer.pathData || !layer.pathData.getCommands().length) {
            return;
        }
        var layerToCanvasMatrix = LayerUtil.getCanvasTransformForLayer(vl, layer.id);
        var canvasToLayerMatrix = layerToCanvasMatrix.invert();
        if (!canvasToLayerMatrix) {
            // Do nothing if matrix is non-invertible.
            return;
        }
        ctx.save();
        CanvasUtil.executeCommands(ctx, layer.pathData.getCommands(), layerToCanvasMatrix);
        var strokeWidthMultiplier = canvasToLayerMatrix.getScaleFactor();
        ctx.strokeStyle = ColorUtil.androidToCssRgbaColor(layer.strokeColor, layer.strokeAlpha);
        ctx.lineWidth = layer.strokeWidth * strokeWidthMultiplier;
        ctx.fillStyle = ColorUtil.androidToCssRgbaColor(layer.fillColor, layer.fillAlpha);
        ctx.lineCap = layer.strokeLinecap;
        ctx.lineJoin = layer.strokeLinejoin;
        ctx.miterLimit = layer.strokeMiterLimit;
        if (layer.trimPathStart !== 0 || layer.trimPathEnd !== 1 || layer.trimPathOffset !== 0) {
            var a = canvasToLayerMatrix.a, d = canvasToLayerMatrix.d;
            // Note that we only return the length of the first sub path due to
            // https://code.google.com/p/android/issues/detail?id=172547
            var pathLength = void 0;
            if (Math.abs(a) !== 1 || Math.abs(d) !== 1) {
                // Then recompute the scaled path length.
                pathLength = layer.pathData
                    .mutate()
                    .transform(canvasToLayerMatrix)
                    .build()
                    .getSubPathLength(0);
            }
            else {
                pathLength = layer.pathData.getSubPathLength(0);
            }
            var strokeDashArray = LayerUtil.toStrokeDashArray(layer.trimPathStart, layer.trimPathEnd, layer.trimPathOffset, pathLength, 0.001);
            var strokeDashOffset = LayerUtil.toStrokeDashOffset(layer.trimPathStart, layer.trimPathEnd, layer.trimPathOffset, pathLength);
            ctx.setLineDash(strokeDashArray);
            ctx.lineDashOffset = strokeDashOffset;
        }
        else {
            ctx.setLineDash([]);
        }
        if (layer.isStroked() && layer.strokeWidth && layer.trimPathStart !== layer.trimPathEnd) {
            ctx.stroke();
        }
        if (layer.isFilled()) {
            if (layer.fillType === 'evenOdd') {
                // Unlike VectorDrawables, SVGs spell 'evenodd' with a lowercase 'o'.
                ctx.fill('evenodd');
            }
            else {
                ctx.fill();
            }
        }
        ctx.restore();
    };
    return CanvasLayersDirective;
}(CanvasLayoutMixin(DestroyableMixin())));
export { CanvasLayersDirective };
