import * as tslib_1 from "tslib";
import * as paper from 'paper';
var RotateItemsPivotRaster = /** @class */ (function (_super) {
    tslib_1.__extends(RotateItemsPivotRaster, _super);
    function RotateItemsPivotRaster() {
        return _super.call(this, '/assets/paper/rotate-items-pivot.png') || this;
    }
    RotateItemsPivotRaster.of = function (position) {
        if (!RotateItemsPivotRaster.instance) {
            RotateItemsPivotRaster.instance = new RotateItemsPivotRaster();
        }
        var raster = RotateItemsPivotRaster.instance.clone(false);
        raster.position = position;
        return raster;
    };
    return RotateItemsPivotRaster;
}(paper.Raster));
export { RotateItemsPivotRaster };
