import * as tslib_1 from "tslib";
import { ToolMode } from 'app/modules/editor/model/paper';
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { PaperUtil } from 'app/modules/editor/scripts/paper/util';
import * as paper from 'paper';
/**
 * A gesture that draws a path.
 *
 * Preconditions:
 * - The user is in pencil mode.
 */
var PencilGesture = /** @class */ (function (_super) {
    tslib_1.__extends(PencilGesture, _super);
    function PencilGesture(ps) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.pl = paper.project.activeLayer;
        return _this;
    }
    // @Override
    PencilGesture.prototype.onMouseDrag = function (event) {
        var vpDownPoint = this.pl.globalToLocal(event.downPoint);
        var vpMiddlePoint = this.pl.globalToLocal(event.middlePoint);
        var vpPoint = this.pl.globalToLocal(event.point);
        if (!this.vpLastPoint) {
            this.vpLastPoint = vpDownPoint;
        }
        var vpDelta = vpPoint.subtract(this.vpLastPoint);
        vpDelta.angle += 90;
        var createPathInfo = this.ps.getCreatePathInfo();
        var pencilPath = createPathInfo ? new paper.Path(createPathInfo.pathData) : new paper.Path();
        pencilPath.add(vpMiddlePoint.add(vpDelta));
        this.ps.setCreatePathInfo({ pathData: pencilPath.pathData, strokeColor: '#979797' });
        this.vpLastPoint = vpPoint;
    };
    // @Override
    PencilGesture.prototype.onMouseUp = function (event) {
        if (this.vpLastPoint) {
            this.vpLastPoint = this.pl.globalToLocal(event.point);
        }
        this.finishGesture();
    };
    // @Override
    PencilGesture.prototype.onKeyDown = function (event) {
        if (event.key === 'escape') {
            this.finishGesture();
        }
    };
    PencilGesture.prototype.finishGesture = function () {
        if (this.vpLastPoint) {
            var newPath = new paper.Path(this.ps.getCreatePathInfo().pathData);
            var projStartPoint = this.pl.localToGlobal(newPath.firstSegment.point);
            var projLastPoint = this.pl.localToGlobal(this.vpLastPoint);
            // If the pencil path's start and end point are within 10px of each other
            // at the end of the gesture, then we should close the path before saving
            // it to the store.
            if (projStartPoint.isClose(projLastPoint, 10)) {
                newPath.closePath(true);
            }
            newPath.smooth({ type: 'continuous' });
            var newPathLayer = PaperUtil.addPathToStore(this.ps, newPath.pathData);
            this.ps.setSelectedLayerIds(new Set([newPathLayer.id]));
            this.ps.setCreatePathInfo(undefined);
        }
        this.ps.setToolMode(ToolMode.Default);
    };
    return PencilGesture;
}(Gesture));
export { PencilGesture };
