import * as tslib_1 from "tslib";
import { LayerUtil } from 'app/modules/editor/model/layers';
import { Path } from 'app/modules/editor/model/paths';
import { MathUtil } from 'app/modules/editor/scripts/common';
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { PaperUtil, SnapUtil } from 'app/modules/editor/scripts/paper/util';
import * as _ from 'lodash';
import * as paper from 'paper';
/**
 * A gesture that performs scaling operations.
 *
 * Preconditions:
 * - The user is in default mode.
 * - One or more layers are selected.
 * - A mouse down event occurred on a selection bounds handle.
 *
 * TODO: should we also scale the stroke width?
 */
var ScaleItemsGesture = /** @class */ (function (_super) {
    tslib_1.__extends(ScaleItemsGesture, _super);
    function ScaleItemsGesture(ps, selectionBoundsRaster) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.selectionBoundsRaster = selectionBoundsRaster;
        _this.pl = paper.project.activeLayer;
        return _this;
    }
    // @Override
    ScaleItemsGesture.prototype.onMouseDown = function (event) {
        var _this = this;
        this.ps.setHoveredLayerId(undefined);
        // TODO: make searches like this more efficient...
        var scaleItems = [];
        var scaleItemsSet = new Set();
        Array.from(this.ps.getSelectedLayerIds())
            .map(function (id) { return _this.pl.findItemByLayerId(id); })
            // TODO: reuse this code with PaperLayer (filter out empty groups)
            .filter(function (i) { return !(i instanceof paper.Group) || i.children.length; })
            .forEach(function recurseFn(i) {
            if (i instanceof paper.Group) {
                i.children.forEach(recurseFn);
            }
            else if (!scaleItemsSet.has(i.data.id)) {
                scaleItemsSet.add(i.data.id);
                scaleItems.push(i);
            }
        });
        this.selectedItems = scaleItems;
        this.localToVpItemMatrices = this.selectedItems.map(function (item) {
            // Compute the matrices to directly transform during drag events.
            return item.globalMatrix.prepended(_this.pl.matrix.inverted()).inverted();
        });
        var bounds = PaperUtil.transformRectangle(PaperUtil.computeBounds(this.selectedItems), this.pl.matrix.inverted());
        this.vpInitialPivot = bounds[this.selectionBoundsRaster.oppositePivotType];
        this.vpInitialDraggedSegment = bounds[this.selectionBoundsRaster.pivotType];
        this.vpDownPoint = bounds[this.selectionBoundsRaster.pivotType];
        this.vpPoint = this.vpDownPoint;
        this.vpInitialSize = this.vpDownPoint.subtract(this.vpInitialPivot);
        this.vpInitialCenteredSize = this.vpInitialSize.multiply(0.5);
        this.vpInitialCenter = bounds.center.clone();
        this.initialVectorLayer = this.ps.getVectorLayer();
    };
    // @Override
    ScaleItemsGesture.prototype.onMouseDrag = function (event) {
        this.vpPoint = this.pl.globalToLocal(event.point);
        var _a = this.vpPoint, x = _a.x, y = _a.y;
        this.ps.setTooltipInfo({
            point: { x: x, y: y },
            // TODO: display the current width/height of the shape
            label: _.round(x, 1) + " \u2A2F " + _.round(y, 1),
        });
        this.processEvent(event);
    };
    // @Override
    ScaleItemsGesture.prototype.onMouseUp = function (event) {
        // TODO: need to disable this in onKeyEvents as well?
        this.ps.setSnapGuideInfo(undefined);
        this.ps.setTooltipInfo(undefined);
    };
    // @Override
    ScaleItemsGesture.prototype.onKeyDown = function (event) {
        this.processKeyEvent(event);
    };
    // @Override
    ScaleItemsGesture.prototype.onKeyUp = function (event) {
        this.processKeyEvent(event);
    };
    ScaleItemsGesture.prototype.processKeyEvent = function (event) {
        if (event.key === 'alt' || event.key === 'shift') {
            this.processEvent(event);
        }
    };
    // TODO: make sure it is possible to scale/shrink the item when holding shift?
    ScaleItemsGesture.prototype.processEvent = function (event) {
        var _this = this;
        var projDownPoint = this.pl.localToGlobal(this.vpDownPoint);
        var projPoint = this.pl.localToGlobal(this.vpPoint);
        var projDelta = projPoint.subtract(projDownPoint);
        var newVl = this.initialVectorLayer.clone();
        newVl = this.scaleItems(newVl, projDelta, event.modifiers.alt, event.modifiers.shift);
        this.ps.setVectorLayer(newVl);
        // TODO: this could be WAY more efficient (no need to scale/snap things twice)
        // TODO: snap if shift is held and aspect ratio doesn't change?
        // TODO: first snap the widths and heights, then snap the guides
        var shouldSnap = !event.modifiers.shift;
        if (shouldSnap) {
            var snapInfo = this.buildSnapInfo();
            if (snapInfo) {
                var projSnapDelta = new paper.Point(snapInfo.projSnapDelta);
                if (!projSnapDelta.isZero()) {
                    var shouldScaleAboutCenter = event.modifiers.alt;
                    var vpFixedPivot = shouldScaleAboutCenter ? this.vpInitialCenter : this.vpInitialPivot;
                    // TODO: confirm this is the correct way to fix the project snap delta?
                    if (this.vpPoint.x < vpFixedPivot.x) {
                        projSnapDelta.x *= -1;
                    }
                    if (this.vpPoint.y < vpFixedPivot.y) {
                        projSnapDelta.y *= -1;
                    }
                    newVl = this.scaleItems(newVl, projPoint.add(projSnapDelta).subtract(projDownPoint), shouldScaleAboutCenter);
                    this.ps.setVectorLayer(newVl);
                }
            }
        }
        if (shouldSnap) {
            var snapInfo = this.buildSnapInfo();
            if (snapInfo) {
                this.ps.setSnapGuideInfo({
                    guides: snapInfo.guides.map(function (g) { return _this.projToVpLine(g); }),
                    rulers: snapInfo.rulers.map(function (r) { return _this.projToVpLine(r); }),
                });
            }
            else {
                this.ps.setSnapGuideInfo(undefined);
            }
        }
        else {
            this.ps.setSnapGuideInfo(undefined);
        }
    };
    ScaleItemsGesture.prototype.scaleItems = function (newVl, projDelta, shouldScaleAboutCenter, shouldSnapDelta) {
        var _this = this;
        if (shouldSnapDelta === void 0) { shouldSnapDelta = false; }
        // Transform about the center if alt is pressed. Otherwise trasform about
        // the pivot opposite of the currently active pivot.
        var vpFixedPivot = shouldScaleAboutCenter ? this.vpInitialCenter : this.vpInitialPivot;
        var currentSize = this.vpInitialDraggedSegment
            .add(this.pl.globalToLocal(projDelta))
            .subtract(vpFixedPivot);
        var initialSize = shouldScaleAboutCenter ? this.vpInitialCenteredSize : this.vpInitialSize;
        var sx = 1;
        var sy = 1;
        if (!MathUtil.isNearZero(initialSize.x)) {
            sx = currentSize.x / initialSize.x;
        }
        if (!MathUtil.isNearZero(initialSize.y)) {
            sy = currentSize.y / initialSize.y;
        }
        if (shouldSnapDelta) {
            var signx = sx > 0 ? 1 : -1;
            var signy = sy > 0 ? 1 : -1;
            sx = sy = Math.max(Math.abs(sx), Math.abs(sy));
            sx *= signx;
            sy *= signy;
        }
        // TODO: determine if we should be baking transforms into the children layers when scaling a group?
        this.selectedItems.forEach(function (item, index) {
            var path = item.clone();
            path.applyMatrix = true;
            var localToVpMatrix = _this.localToVpItemMatrices[index];
            var matrix = localToVpMatrix.clone();
            matrix.scale(sx, sy, vpFixedPivot);
            matrix.append(localToVpMatrix.inverted());
            path.matrix = matrix;
            console.log(item.data.id);
            var newPl = newVl.findLayerById(item.data.id).clone();
            newPl.pathData = new Path(path.pathData);
            newVl = LayerUtil.replaceLayer(newVl, item.data.id, newPl);
        });
        return newVl;
    };
    // TODO: reuse this code with SelectDragCloneItemsGesture
    ScaleItemsGesture.prototype.buildSnapInfo = function () {
        var _this = this;
        var selectedLayerIds = this.ps.getSelectedLayerIds();
        if (!selectedLayerIds.size) {
            return undefined;
        }
        var draggedItems = Array.from(selectedLayerIds).map(function (id) { return _this.pl.findItemByLayerId(id); });
        var parent = draggedItems[0].parent;
        if (!draggedItems.every(function (item) { return item.parent === parent; })) {
            // TODO: copy the behavior used in Sketch
            console.warn('All snapped items must share the same parent item.');
            return undefined;
        }
        var siblingItems = parent.children.filter(function (i) { return !draggedItems.includes(i); });
        if (!siblingItems.length) {
            return undefined;
        }
        // Perform the snap test.
        var toSnapPointsFn = function (items) {
            var _a = PaperUtil.computeBounds(items), topLeft = _a.topLeft, center = _a.center, bottomRight = _a.bottomRight;
            return [topLeft, center, bottomRight];
        };
        // TODO: also snap-to-VectorLayer bounds (similar to the dragging gesture)
        return SnapUtil.computeSnapInfo(toSnapPointsFn(draggedItems), siblingItems.map(function (siblingItem) { return toSnapPointsFn([siblingItem]); }), true /* snapToDimensions */);
    };
    ScaleItemsGesture.prototype.projToVpLine = function (_a) {
        var from = _a.from, to = _a.to;
        return {
            from: this.pl.globalToLocal(new paper.Point(from)),
            to: this.pl.globalToLocal(new paper.Point(to)),
        };
    };
    return ScaleItemsGesture;
}(Gesture));
export { ScaleItemsGesture };
