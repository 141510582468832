import * as tslib_1 from "tslib";
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { PaperUtil } from 'app/modules/editor/scripts/paper/util';
import * as paper from 'paper';
/**
 * A gesture that allows the user to mould a curve by dragging a point on its path.
 *
 * Based on math from here: https://pomax.github.io/bezierinfo/#moulding
 *
 * Preconditions:
 * - The user is in edit path mode.
 * - The user hit one of the edit path's curves.
 */
var MouldCurveGesture = /** @class */ (function (_super) {
    tslib_1.__extends(MouldCurveGesture, _super);
    // TODO: update HoverSegmentsCurvesGesture to *not* display a split path dot when command is held down
    // TODO: handle cases where t === 0 and t === 1?
    function MouldCurveGesture(ps, editPathId, hitCurveInfo) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.editPathId = editPathId;
        _this.hitCurveInfo = hitCurveInfo;
        _this.pl = paper.project.activeLayer;
        return _this;
    }
    // @Override
    MouldCurveGesture.prototype.onMouseDown = function (event) {
        var editPath = this.pl.findItemByLayerId(this.editPathId);
        var curve = editPath.curves[this.hitCurveInfo.curveIndex];
        var start = curve.segment1.point;
        var end = curve.segment2.point;
        var cp1 = start.add(curve.handle1);
        var cp2 = end.add(curve.handle2);
        var points = [start, cp1, cp2, end];
        var t = this.hitCurveInfo.time;
        var A = hull(points, t)[5];
        var B = curve.getPointAtTime(t);
        var C = lli(A, B, start, end);
        var bottom = Math.pow(t, 3) + Math.pow((1 - t), 3);
        var top = bottom - 1;
        var ratio = Math.abs(top / bottom);
        // Cache these for later use.
        this.points = points;
        this.B = B;
        this.C = C;
        this.ratio = ratio;
        this.t = t;
    };
    // @Override
    MouldCurveGesture.prototype.onMouseDrag = function (event) {
        var editPath = this.pl.findItemByLayerId(this.editPathId);
        var localDownPoint = editPath.globalToLocal(event.downPoint);
        var localDragPoint = editPath.globalToLocal(event.point);
        var _a = this, points = _a.points, B = _a.B, C = _a.C, ratio = _a.ratio, t = _a.t;
        var newB = B.add(localDragPoint.subtract(localDownPoint));
        // Preserve struts for B when repositioning.
        var hullPoints = hull(this.points, t);
        var Bl = hullPoints[7];
        var Br = hullPoints[8];
        var dbl = Bl.subtract(B);
        var dbr = Br.subtract(B);
        // Now that we know A, B, C and the AB:BC ratio, we can compute the new A' based on the desired B'.
        var newA = newB.subtract(C.subtract(newB).divide(ratio));
        // Find new point on s--c1.
        var p1 = newB.add(dbl);
        var sc1 = newA.subtract(newA.subtract(p1).divide(1 - t));
        // Find new point on c2--e.
        var p2 = newB.add(dbr);
        var sc2 = newA.add(p2.subtract(newA).divide(t));
        // Construct new c1` based on the fact that s--sc1 is s--c1 * t.
        var nc1 = points[0].add(sc1.subtract(points[0]).divide(t));
        // Construct new c2` based on the fact that e--sc2 is e--c2 * (1-t).
        var nc2 = points[3].subtract(points[3].subtract(sc2).divide(1 - t));
        var curve = editPath.curves[this.hitCurveInfo.curveIndex];
        curve.handle1 = nc1.subtract(points[0]);
        curve.handle2 = nc2.subtract(points[3]);
        PaperUtil.replacePathInStore(this.ps, this.editPathId, editPath.pathData);
    };
    // @Override
    MouldCurveGesture.prototype.onKeyDown = function (event) {
        // TODO: react to escape key presses?
    };
    return MouldCurveGesture;
}(Gesture));
export { MouldCurveGesture };
// TODO: rename this...
function lli(_a, _b, _c, _d) {
    var x1 = _a.x, y1 = _a.y;
    var x2 = _b.x, y2 = _b.y;
    var x3 = _c.x, y3 = _c.y;
    var x4 = _d.x, y4 = _d.y;
    var nx = (x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4);
    var ny = (x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4);
    var d = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
    if (d === 0) {
        return undefined;
    }
    return new paper.Point(nx / d, ny / d);
}
function hull(points, t) {
    var p = points;
    var _p = [];
    var pt;
    var q = p.slice();
    // We lerp between all points at each iteration, until we have 1 point left.
    while (p.length > 1) {
        _p = [];
        for (var i = 0, l = p.length - 1; i < l; i++) {
            pt = p[i].add(p[i + 1].subtract(p[i]).multiply(t));
            q.push(pt);
            _p.push(pt);
        }
        p = _p;
    }
    return q;
}
