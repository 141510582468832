import * as tslib_1 from "tslib";
import { NumberProperty } from './NumberProperty';
var FractionProperty = /** @class */ (function (_super) {
    tslib_1.__extends(FractionProperty, _super);
    function FractionProperty(name, config) {
        if (config === void 0) { config = {}; }
        return _super.call(this, name, {
            isAnimatable: config.isAnimatable,
            min: 0,
            max: 1,
            isInteger: false,
        }) || this;
    }
    // @Override
    FractionProperty.prototype.getAnimatorValueType = function () {
        return 'floatType';
    };
    // @Override
    FractionProperty.prototype.getTypeName = function () {
        return 'FractionProperty';
    };
    return FractionProperty;
}(NumberProperty));
export { FractionProperty };
