import * as tslib_1 from "tslib";
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
/**
 * A gesture that deselects a single item.
 *
 * Preconditions:
 * - The user is in default mode.
 */
var DeselectItemGesture = /** @class */ (function (_super) {
    tslib_1.__extends(DeselectItemGesture, _super);
    function DeselectItemGesture(ps, deselectedItemId) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.deselectedItemId = deselectedItemId;
        return _this;
    }
    // @Override
    DeselectItemGesture.prototype.onMouseDown = function (event) {
        var layerIds = new Set(this.ps.getSelectedLayerIds());
        layerIds.delete(this.deselectedItemId);
        this.ps.setSelectedLayerIds(layerIds);
    };
    return DeselectItemGesture;
}(Gesture));
export { DeselectItemGesture };
