import { NgZone } from '@angular/core';
import { CursorType, ToolMode } from 'app/modules/editor/model/paper';
import { Store } from 'app/modules/editor/store';
import { BatchAction } from 'app/modules/editor/store/batch/actions';
import { getHiddenLayerIds, getSelectedLayerIds } from 'app/modules/editor/store/layers/selectors';
import { SetCreatePathInfo, SetCursorType, SetEditPathInfo, SetHoveredLayerId, SetRotateItemsInfo, SetSelectionBox, SetSnapGuideInfo, SetSplitCurveInfo, SetToolMode, SetTooltipInfo, SetTransformPathsInfo, SetZoomPanInfo, } from 'app/modules/editor/store/paper/actions';
import { getCreatePathInfo, getCursorType, getEditPathInfo, getHoveredLayerId, getRotateItemsInfo, getSelectionBox, getSnapGuideInfo, getSplitCurveInfo, getToolMode, getToolPanelState, getTooltipInfo, getTransformPathsInfo, getZoomPanInfo, } from 'app/modules/editor/store/paper/selectors';
import { getAnimatedVectorLayer } from 'app/modules/editor/store/playback/selectors';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import { LayerTimelineService } from './layertimeline.service';
import * as i0 from "@angular/core";
import * as i1 from "./layertimeline.service";
import * as i2 from "@ngrx/store";
/** A simple service that provides an interface for making paper.js changes to the store. */
var PaperService = /** @class */ (function () {
    function PaperService(layerTimelineService, store, 
    // TODO: figure out if this is the most efficient use of NgZone...
    // TODO: can we get away with only executing in NgZone for certain dispatch store ops?
    ngZone) {
        this.layerTimelineService = layerTimelineService;
        this.store = store;
        this.ngZone = ngZone;
    }
    PaperService.prototype.observeToolPanelState = function () {
        return this.store.select(getToolPanelState);
    };
    PaperService.prototype.enterDefaultMode = function () {
        this.setToolMode(ToolMode.Default);
        this.setEditPathInfo(undefined);
        this.setRotateItemsInfo(undefined);
        this.setTransformPathsInfo(undefined);
    };
    PaperService.prototype.enterEditPathMode = function () {
        this.setToolMode(ToolMode.Default);
        this.setEditPathInfo({
            selectedSegments: new Set(),
            visibleHandleIns: new Set(),
            visibleHandleOuts: new Set(),
            selectedHandleIn: undefined,
            selectedHandleOut: undefined,
        });
        this.setRotateItemsInfo(undefined);
        this.setTransformPathsInfo(undefined);
        this.setCursorType(CursorType.PenAdd);
    };
    /** Exits edit path mode. */
    PaperService.prototype.exitEditPathMode = function () {
        this.dispatchStore(new (BatchAction.bind.apply(BatchAction, [void 0].concat(this.getExitEditPathModeActions())))());
    };
    /** Returns a list of actions that will exit edit path mode. */
    PaperService.prototype.getExitEditPathModeActions = function () {
        return [new SetEditPathInfo(undefined)].concat(this.getClearEditPathModeStateActions());
    };
    /** Returns a list of actions that will clear any state associated with edit path mode. */
    PaperService.prototype.getClearEditPathModeStateActions = function () {
        return [
            new SetCreatePathInfo(undefined),
            new SetSplitCurveInfo(undefined),
            new SetSnapGuideInfo(undefined),
            new SetCursorType(CursorType.Default),
        ];
    };
    PaperService.prototype.enterRotateItemsMode = function () {
        this.setToolMode(ToolMode.Default);
        this.setEditPathInfo(undefined);
        this.setRotateItemsInfo({});
        this.setTransformPathsInfo(undefined);
    };
    PaperService.prototype.enterTransformPathsMode = function () {
        this.setToolMode(ToolMode.Default);
        this.setEditPathInfo(undefined);
        this.setRotateItemsInfo(undefined);
        this.setTransformPathsInfo({});
    };
    PaperService.prototype.enterPencilMode = function () {
        this.setToolMode(ToolMode.Pencil);
        this.setCursorType(CursorType.Pencil);
    };
    PaperService.prototype.enterCreateRectangleMode = function () {
        this.setToolMode(ToolMode.Rectangle);
        this.setCursorType(CursorType.Crosshair);
    };
    PaperService.prototype.enterCreateEllipseMode = function () {
        this.setToolMode(ToolMode.Ellipse);
        this.setCursorType(CursorType.Crosshair);
    };
    PaperService.prototype.setVectorLayer = function (vl) {
        var _this = this;
        // TODO: avoid running in angular zone whenever possible?
        this.ngZone.run(function () { return _this.layerTimelineService.setVectorLayer(vl); });
    };
    PaperService.prototype.getVectorLayer = function () {
        // TODO: return the non-animated vector layer here (using layer timeline service) instead?
        return this.queryStore(getAnimatedVectorLayer).vl;
    };
    PaperService.prototype.setSelectedLayerIds = function (layerIds) {
        var _this = this;
        if (!_.isEqual(this.queryStore(getSelectedLayerIds), layerIds)) {
            this.ngZone.run(function () { return _this.layerTimelineService.setSelectedLayers(layerIds); });
        }
    };
    PaperService.prototype.getSelectedLayerIds = function () {
        return this.queryStore(getSelectedLayerIds);
    };
    PaperService.prototype.setHoveredLayerId = function (layerId) {
        var _this = this;
        if (this.queryStore(getHoveredLayerId) !== layerId) {
            this.ngZone.run(function () { return _this.store.dispatch(new SetHoveredLayerId(layerId)); });
        }
    };
    PaperService.prototype.getHoveredLayerId = function () {
        return this.queryStore(getHoveredLayerId);
    };
    PaperService.prototype.getHiddenLayerIds = function () {
        return this.queryStore(getHiddenLayerIds);
    };
    PaperService.prototype.setSelectionBox = function (box) {
        if (!_.isEqual(this.queryStore(getSelectionBox), box)) {
            // TODO: run this outside angular zone instead?
            this.dispatchStore(new SetSelectionBox(box));
        }
    };
    PaperService.prototype.getSelectionBox = function () {
        return this.queryStore(getSelectionBox);
    };
    PaperService.prototype.setCreatePathInfo = function (info) {
        if (!_.isEqual(this.queryStore(getCreatePathInfo), info)) {
            this.dispatchStore(new SetCreatePathInfo(info));
        }
    };
    PaperService.prototype.getCreatePathInfo = function () {
        return this.queryStore(getCreatePathInfo);
    };
    PaperService.prototype.setSplitCurveInfo = function (info) {
        if (!_.isEqual(this.queryStore(getSplitCurveInfo), info)) {
            this.dispatchStore(new SetSplitCurveInfo(info));
        }
    };
    PaperService.prototype.setToolMode = function (toolMode) {
        if (!_.isEqual(this.queryStore(getToolMode), toolMode)) {
            this.dispatchStore(new SetToolMode(toolMode));
        }
    };
    PaperService.prototype.getToolMode = function () {
        return this.queryStore(getToolMode);
    };
    PaperService.prototype.setEditPathInfo = function (info) {
        if (!_.isEqual(this.queryStore(getEditPathInfo), info)) {
            this.dispatchStore(new SetEditPathInfo(info));
        }
    };
    PaperService.prototype.getEditPathInfo = function () {
        return this.queryStore(getEditPathInfo);
    };
    PaperService.prototype.setRotateItemsInfo = function (info) {
        if (!_.isEqual(this.queryStore(getRotateItemsInfo), info)) {
            this.dispatchStore(new SetRotateItemsInfo(info));
        }
    };
    PaperService.prototype.getRotateItemsInfo = function () {
        return this.queryStore(getRotateItemsInfo);
    };
    PaperService.prototype.setTransformPathsInfo = function (info) {
        if (!_.isEqual(this.queryStore(getTransformPathsInfo), info)) {
            this.dispatchStore(new SetTransformPathsInfo(info));
        }
    };
    PaperService.prototype.getTransformPathsInfo = function () {
        return this.queryStore(getTransformPathsInfo);
    };
    PaperService.prototype.setCursorType = function (cursorType) {
        if (!_.isEqual(this.queryStore(getCursorType), cursorType)) {
            this.dispatchStore(new SetCursorType(cursorType));
        }
    };
    PaperService.prototype.setSnapGuideInfo = function (info) {
        if (!_.isEqual(this.queryStore(getSnapGuideInfo), info)) {
            this.dispatchStore(new SetSnapGuideInfo(info));
        }
    };
    PaperService.prototype.setZoomPanInfo = function (info) {
        if (!_.isEqual(this.queryStore(getZoomPanInfo), info)) {
            this.dispatchStore(new SetZoomPanInfo(info));
        }
    };
    PaperService.prototype.setTooltipInfo = function (info) {
        if (!_.isEqual(this.queryStore(getTooltipInfo), info)) {
            this.dispatchStore(new SetTooltipInfo(info));
        }
    };
    PaperService.prototype.deleteSelectedModels = function () {
        if (this.getRotateItemsInfo() || this.getTransformPathsInfo()) {
            // Do not delete layers when in rotate items or transform paths mode.
            return;
        }
        this.layerTimelineService.deleteSelectedModels();
    };
    PaperService.prototype.getDeleteSelectedModelsActions = function () {
        return this.layerTimelineService.getDeleteSelectedModelsActions();
    };
    PaperService.prototype.dispatchStore = function (action) {
        var _this = this;
        if (NgZone.isInAngularZone()) {
            this.store.dispatch(action);
        }
        else {
            // PaperService methods are usually executed outside of the Angular zone
            // (since they originate from event handlers registered by paper.js). In
            // order to ensure change detection works properly, we need to force
            // state changes to be executed inside the Angular zone.
            this.ngZone.run(function () { return _this.store.dispatch(action); });
        }
    };
    PaperService.prototype.queryStore = function (selector) {
        var obj;
        this.store
            .select(selector)
            .pipe(first())
            .subscribe(function (o) { return (obj = o); });
        return obj;
    };
    PaperService.ngInjectableDef = i0.defineInjectable({ factory: function PaperService_Factory() { return new PaperService(i0.inject(i1.LayerTimelineService), i0.inject(i2.Store), i0.inject(i0.NgZone)); }, token: PaperService, providedIn: "root" });
    return PaperService;
}());
export { PaperService };
