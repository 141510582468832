import * as tslib_1 from "tslib";
import { CursorType } from 'app/modules/editor/model/paper';
import * as _ from 'lodash';
import * as paper from 'paper';
import { Tool } from './Tool';
/** A tool that enables zooming and panning in the canvas. */
var ZoomPanTool = /** @class */ (function (_super) {
    tslib_1.__extends(ZoomPanTool, _super);
    function ZoomPanTool(ps) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        return _this;
    }
    // @Override
    ZoomPanTool.prototype.onActivate = function () {
        this.viewLastPoint = new paper.Point(0, 0);
        this.isDragging = false;
        this.ps.setCursorType(CursorType.ZoomIn);
    };
    // @Override
    ZoomPanTool.prototype.onDeactivate = function () {
        // TODO: set the cursor type to whatever it was before onActivate()?
        this.ps.setCursorType(CursorType.Default);
    };
    // @Override
    ZoomPanTool.prototype.onToolEvent = function (event) {
        if (event.type === 'mousedown') {
            this.onMouseDown(event);
        }
        else if (event.type === 'mousedrag') {
            this.onMouseDrag(event);
        }
        else if (event.type === 'mouseup') {
            this.onMouseUp(event);
        }
    };
    ZoomPanTool.prototype.onMouseDown = function (event) {
        this.isDragging = false;
        this.updateCursorType(event);
        if (event.modifiers.space) {
            // If space is pressed, then grab/pan the canvas. We store the last known
            // mouse point in view space coordinates (which means the top left corner
            // of the canvas will always be (0, 0), no matter how much we've panned/zoomed
            // so far).
            this.viewLastPoint = paper.view.projectToView(event.point);
            return;
        }
        // Zoom out if alt is pressed, and zoom in otherwise.
        var zoom = paper.view.zoom * (event.modifiers.alt ? 1 / 2 : 2);
        var _a = paper.view.projectToView(event.point).subtract(event.point.multiply(zoom)), x = _a.x, y = _a.y;
        this.setZoomPanInfo(zoom, x, y);
    };
    ZoomPanTool.prototype.onMouseDrag = function (event) {
        this.isDragging = false;
        this.updateCursorType(event);
        if (!event.modifiers.space) {
            return;
        }
        // In order to have coordinate changes not mess up the dragging, we need to
        // convert coordinates to view space, and then back to project space after
        // the view has been scrolled.
        var projPoint = event.point;
        var viewPoint = paper.view.projectToView(projPoint);
        var _a = paper.view.matrix
            .clone()
            .translate(projPoint.subtract(paper.view.viewToProject(this.viewLastPoint))), tx = _a.tx, ty = _a.ty;
        this.setZoomPanInfo(paper.view.zoom, tx, ty);
        this.viewLastPoint = viewPoint;
    };
    ZoomPanTool.prototype.onMouseUp = function (event) {
        this.isDragging = false;
        this.updateCursorType(event);
    };
    ZoomPanTool.prototype.setZoomPanInfo = function (zoom, tx, ty) {
        var _a = paper.view.viewSize, width = _a.width, height = _a.height;
        zoom = _.clamp(zoom, 1, 256);
        tx = _.clamp(tx, -width * (zoom - 1), 0);
        ty = _.clamp(ty, -height * (zoom - 1), 0);
        this.ps.setZoomPanInfo({ zoom: zoom, translation: { tx: tx, ty: ty } });
    };
    // @Override
    ZoomPanTool.prototype.onKeyEvent = function (event) {
        if (event.key === 'space' || event.key === 'alt') {
            this.updateCursorType(event);
        }
    };
    ZoomPanTool.prototype.updateCursorType = function (event) {
        if (event.modifiers.space) {
            this.ps.setCursorType(this.isDragging ? CursorType.Grabbing : CursorType.Grab);
        }
        else {
            this.ps.setCursorType(event.modifiers.alt ? CursorType.ZoomOut : CursorType.ZoomIn);
        }
    };
    return ZoomPanTool;
}(Tool));
export { ZoomPanTool };
