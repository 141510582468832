
  <span matDialogTitle>{{ this.data.title }}</span>
  <mat-dialog-content>
    <p>{{ this.data.message }}</p>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row">
    <!-- The ordering here matters (it ensures that 'OK' gets focus first). -->
    <span fxFlex></span>
    <button fxFlexOrder="2" mat-button (click)="dialogRef.close(true)">OK</button>
    <button fxFlexOrder="1" mat-button matDialogClose>Cancel</button>
  </mat-dialog-actions>