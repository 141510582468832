import { MatSnackBar } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export var Duration;
(function (Duration) {
    Duration[Duration["Short"] = 2750] = "Short";
    Duration[Duration["Long"] = 5000] = "Long";
})(Duration || (Duration = {}));
var SnackBarService = /** @class */ (function () {
    function SnackBarService(snackBar) {
        this.snackBar = snackBar;
    }
    SnackBarService.prototype.show = function (message, action, duration) {
        if (action === void 0) { action = ''; }
        if (duration === void 0) { duration = Duration.Short; }
        this.snackBar.open(message, action.toUpperCase(), { duration: duration });
    };
    SnackBarService.ngInjectableDef = i0.defineInjectable({ factory: function SnackBarService_Factory() { return new SnackBarService(i0.inject(i1.MatSnackBar)); }, token: SnackBarService, providedIn: "root" });
    return SnackBarService;
}());
export { SnackBarService };
