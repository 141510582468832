import * as tslib_1 from "tslib";
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { PaperUtil } from 'app/modules/editor/scripts/paper/util';
import * as paper from 'paper';
/**
 * A gesture that toggles the handles associated with a path segment.
 *
 * Preconditions:
 * - The user is in edit path mode.
 * - The gesture began with a mouse down event on top of a segment
 *   (typically this is the second mouse down of a double click).
 */
var ToggleSegmentHandlesGesture = /** @class */ (function (_super) {
    tslib_1.__extends(ToggleSegmentHandlesGesture, _super);
    function ToggleSegmentHandlesGesture(ps, editPathId, hitSegmentIndex) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.editPathId = editPathId;
        _this.hitSegmentIndex = hitSegmentIndex;
        return _this;
    }
    // @Override
    ToggleSegmentHandlesGesture.prototype.onMouseDown = function (event) {
        var path = new paper.Path(PaperUtil.getPathFromStore(this.ps, this.editPathId));
        var segment = path.segments[this.hitSegmentIndex];
        if (segment.hasHandles()) {
            segment.clearHandles();
        }
        else {
            // TODO: polish this a bit more using the extra options argument?
            segment.smooth();
        }
        PaperUtil.replacePathInStore(this.ps, this.editPathId, path.pathData);
    };
    return ToggleSegmentHandlesGesture;
}(Gesture));
export { ToggleSegmentHandlesGesture };
