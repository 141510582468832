import * as tslib_1 from "tslib";
import { CursorType } from 'app/modules/editor/model/paper';
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { PaperUtil } from 'app/modules/editor/scripts/paper/util';
import * as paper from 'paper';
/**
 * A gesture that performs rotation operations.
 *
 * Preconditions:
 * - The user is in default mode.
 * - One or more layers are selected.
 * - A mouse down event occurred on top of the rotate items pivot.
 */
var RotateItemsDragPivotGesture = /** @class */ (function (_super) {
    tslib_1.__extends(RotateItemsDragPivotGesture, _super);
    function RotateItemsDragPivotGesture(ps) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.pl = paper.project.activeLayer;
        return _this;
    }
    // @Override
    RotateItemsDragPivotGesture.prototype.onMouseDown = function (event) {
        var _this = this;
        this.ps.setCursorType(CursorType.Grabbing);
        // TODO: reuse this code with PaperLayer (filter out empty groups)
        var selectedItems = Array.from(this.ps.getSelectedLayerIds())
            .map(function (id) { return _this.pl.findItemByLayerId(id); })
            .filter(function (i) { return !(i instanceof paper.Group) || i.children.length; });
        var invertedPaperLayerMatrix = this.pl.matrix.inverted();
        var rii = this.ps.getRotateItemsInfo();
        if (rii.pivot) {
            this.vpInitialPivotPoint = new paper.Point(rii.pivot);
        }
        else {
            this.vpInitialPivotPoint = PaperUtil.transformRectangle(PaperUtil.computeBounds(selectedItems), invertedPaperLayerMatrix).center;
        }
        this.vpDownPoint = this.pl.globalToLocal(event.downPoint);
    };
    // @Override
    RotateItemsDragPivotGesture.prototype.onMouseDrag = function (event) {
        var vpPoint = this.pl.globalToLocal(event.point);
        var pivot = this.vpInitialPivotPoint.add(vpPoint.subtract(this.vpDownPoint));
        this.ps.setRotateItemsInfo({ pivot: pivot });
    };
    // @Override
    RotateItemsDragPivotGesture.prototype.onMouseUp = function (event) {
        this.ps.setCursorType(CursorType.Default);
    };
    // @Override
    RotateItemsDragPivotGesture.prototype.onKeyDown = function (event) { };
    // @Override
    RotateItemsDragPivotGesture.prototype.onKeyUp = function (event) { };
    return RotateItemsDragPivotGesture;
}(Gesture));
export { RotateItemsDragPivotGesture };
