import { ActionMode, ActionSource, HoverType, SelectionType, } from 'app/modules/editor/model/actionmode';
import { PathUtil } from 'app/modules/editor/model/paths';
import { AutoAwesome } from 'app/modules/editor/scripts/algorithms';
import { Store } from 'app/modules/editor/store';
import { SetActionMode, SetActionModeHover, SetActionModeSelections, SetPairedSubPaths, SetUnpairedSubPath, } from 'app/modules/editor/store/actionmode/actions';
import { getActionMode, getActionModeHover, getActionModeSelections, getPairedSubPaths, getUnpairedSubPath, } from 'app/modules/editor/store/actionmode/selectors';
import { BatchAction } from 'app/modules/editor/store/batch/actions';
import { SetAnimation } from 'app/modules/editor/store/timeline/actions';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import { LayerTimelineService } from './layertimeline.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./layertimeline.service";
/**
 * A simple service that provides an interface for making action mode changes.
 */
var ActionModeService = /** @class */ (function () {
    function ActionModeService(store, layerTimelineService) {
        this.store = store;
        this.layerTimelineService = layerTimelineService;
    }
    // Action mode.
    ActionModeService.prototype.isActionMode = function () {
        return this.getActionMode() !== ActionMode.None;
    };
    ActionModeService.prototype.getActionMode = function () {
        return this.queryStore(getActionMode);
    };
    ActionModeService.prototype.setActionMode = function (mode) {
        this.store.dispatch(new SetActionMode(mode));
    };
    ActionModeService.prototype.toggleSplitCommandsMode = function () {
        this.toggleActionMode(ActionMode.SplitCommands);
    };
    ActionModeService.prototype.toggleSplitSubPathsMode = function () {
        this.toggleActionMode(ActionMode.SplitSubPaths);
    };
    ActionModeService.prototype.togglePairSubPathsMode = function () {
        this.toggleActionMode(ActionMode.PairSubPaths);
    };
    ActionModeService.prototype.toggleActionMode = function (modeToToggle) {
        var currentMode = this.getActionMode();
        if (currentMode === ActionMode.None) {
            return;
        }
        this.setActionMode(currentMode === modeToToggle ? ActionMode.Selection : modeToToggle);
    };
    ActionModeService.prototype.isShowingSubPathActionMode = function () {
        return this.isShowingActionModeType(SelectionType.SubPath);
    };
    ActionModeService.prototype.isShowingSegmentActionMode = function () {
        return this.isShowingActionModeType(SelectionType.Segment);
    };
    ActionModeService.prototype.isShowingPointActionMode = function () {
        return this.isShowingActionModeType(SelectionType.Point);
    };
    ActionModeService.prototype.isShowingActionModeType = function (type) {
        var mode = this.getActionMode();
        return (mode !== ActionMode.None &&
            (mode !== ActionMode.Selection || this.getSelections().filter(function (s) { return s.type === type; }).length));
    };
    ActionModeService.prototype.closeActionMode = function () {
        var mode = this.getActionMode();
        if (mode === ActionMode.None) {
            return;
        }
        if (mode === ActionMode.Selection) {
            if (this.queryStore(getActionModeSelections).length) {
                // TODO: move this logic out into a component (it's confusing)
                this.store.dispatch(new SetActionModeSelections([]));
            }
            else {
                this.store.dispatch(new SetActionMode(ActionMode.None));
            }
        }
        else {
            this.store.dispatch(new SetActionMode(ActionMode.Selection));
        }
    };
    // Selections.
    ActionModeService.prototype.setSelections = function (selections) {
        this.store.dispatch(new SetActionModeSelections(selections));
    };
    ActionModeService.prototype.getSelections = function () {
        return this.queryStore(getActionModeSelections);
    };
    ActionModeService.prototype.toggleSubPathSelection = function (source, subIdx) {
        var selections = this.getSelections().slice();
        _.remove(selections, function (s) { return s.type !== SelectionType.SubPath || s.source !== source; });
        var type = SelectionType.SubPath;
        var toggledSelections = this.toggleSelections(selections, [{ type: type, source: source, subIdx: subIdx }]);
        this.store.dispatch(new SetActionModeSelections(toggledSelections));
    };
    ActionModeService.prototype.toggleSegmentSelections = function (source, segments) {
        var selections = this.getSelections().slice();
        _.remove(selections, function (s) { return s.type !== SelectionType.Segment || s.source !== source; });
        var type = SelectionType.Segment;
        var toggledSelections = this.toggleSelections(selections, segments.map(function (_a) {
            var subIdx = _a.subIdx, cmdIdx = _a.cmdIdx;
            return ({ type: type, source: source, subIdx: subIdx, cmdIdx: cmdIdx });
        }));
        this.store.dispatch(new SetActionModeSelections(toggledSelections));
    };
    ActionModeService.prototype.togglePointSelection = function (source, subIdx, cmdIdx, isShiftOrMetaPressed) {
        var selections = this.getSelections().slice();
        _.remove(selections, function (s) { return s.type !== SelectionType.Point || s.source !== source; });
        var type = SelectionType.Point;
        var toggledSelections = this.toggleSelections(selections, [{ type: type, source: source, subIdx: subIdx, cmdIdx: cmdIdx }], isShiftOrMetaPressed);
        this.store.dispatch(new SetActionModeSelections(toggledSelections));
    };
    /**
     * Toggles the specified shape shifter selections. If a selection exists, all selections
     * will be removed from the list. Otherwise, they will be added to the list of selections.
     * By default, all other selections from the list will be cleared.
     */
    ActionModeService.prototype.toggleSelections = function (currentSelections, newSelections, appendToList) {
        if (appendToList === void 0) { appendToList = false; }
        var matchingSelections = _.remove(currentSelections, function (currSel) {
            // Remove any selections that are equal to a new selection.
            return newSelections.some(function (s) { return _.isEqual(s, currSel); });
        });
        if (!matchingSelections.length) {
            // If no selections were removed, then add all of the selections to the list.
            currentSelections.push.apply(currentSelections, newSelections);
        }
        if (!appendToList) {
            // If we aren't appending multiple selections at a time, then clear
            // any previous selections from the list.
            _.remove(currentSelections, function (currSel) {
                return newSelections.every(function (newSel) { return !_.isEqual(currSel, newSel); });
            });
        }
        return currentSelections;
    };
    // Hovers.
    ActionModeService.prototype.setHover = function (newHover) {
        var currHover = this.queryStore(getActionModeHover);
        if (!_.isEqual(newHover, currHover)) {
            this.store.dispatch(new SetActionModeHover(newHover));
        }
    };
    ActionModeService.prototype.splitInHalfHover = function () {
        var pointSelections = this.getSelections().filter(function (s) { return s.type === SelectionType.Point; });
        if (pointSelections.length) {
            var _a = pointSelections[0], source = _a.source, subIdx = _a.subIdx, cmdIdx = _a.cmdIdx;
            this.setHover({ type: HoverType.Split, source: source, subIdx: subIdx, cmdIdx: cmdIdx });
        }
    };
    ActionModeService.prototype.clearHover = function () {
        this.setHover(undefined);
    };
    // Mutate subpaths.
    ActionModeService.prototype.reverseSelectedSubPaths = function () {
        this.mutateSelectedSubPaths(function (pm, subIdx) { return pm.reverseSubPath(subIdx); });
    };
    ActionModeService.prototype.shiftBackSelectedSubPaths = function () {
        this.mutateSelectedSubPaths(function (pm, subIdx) { return pm.shiftSubPathBack(subIdx); });
    };
    ActionModeService.prototype.shiftForwardSelectedSubPaths = function () {
        this.mutateSelectedSubPaths(function (pm, subIdx) { return pm.shiftSubPathForward(subIdx); });
    };
    ActionModeService.prototype.mutateSelectedSubPaths = function (mutatorFn) {
        var selections = this.getSelections().filter(function (s) { return s.type === SelectionType.SubPath; });
        var source = selections[0].source;
        var pm = this.getActivePathBlockValue(source).mutate();
        for (var _i = 0, selections_1 = selections; _i < selections_1.length; _i++) {
            var subIdx = selections_1[_i].subIdx;
            mutatorFn(pm, subIdx);
        }
        this.store.dispatch(new BatchAction(this.buildUpdatedActivePathBlockAnimationAction(source, pm.build()), new SetActionModeHover(undefined)));
    };
    // Mutate points.
    ActionModeService.prototype.shiftPointToFront = function () {
        var selections = this.getSelections().filter(function (s) { return s.type === SelectionType.Point; });
        var _a = selections[0], source = _a.source, subIdx = _a.subIdx, cmdIdx = _a.cmdIdx;
        var activePath = this.getActivePathBlockValue(source);
        var pm = activePath.mutate();
        pm.shiftSubPathForward(subIdx, cmdIdx);
        this.store.dispatch(this.buildUpdatedActivePathBlockAnimationAction(source, pm.build()));
    };
    ActionModeService.prototype.splitSelectedPointInHalf = function () {
        var selections = this.getSelections().filter(function (s) { return s.type === SelectionType.Point; });
        var _a = selections[0], source = _a.source, subIdx = _a.subIdx, cmdIdx = _a.cmdIdx;
        var activePath = this.getActivePathBlockValue(source);
        var pm = activePath.mutate();
        pm.splitCommandInHalf(subIdx, cmdIdx);
        this.store.dispatch(new BatchAction(this.buildUpdatedActivePathBlockAnimationAction(source, pm.build()), new SetActionModeSelections([]), new SetActionModeHover(undefined)));
    };
    // Pair/unpair subpaths.
    ActionModeService.prototype.pairSubPath = function (subIdx, actionSource) {
        var currUnpair = this.getUnpairedSubPath();
        var actions = [];
        if (currUnpair && actionSource !== currUnpair.source) {
            var fromSource_1 = currUnpair.source, fromSubIdx_1 = currUnpair.subIdx;
            var toSource_1 = actionSource;
            var toSubIdx_1 = subIdx;
            actions.push(new SetUnpairedSubPath(undefined));
            var fromSelections = this.getSelections().filter(function (s) { return s.source === fromSource_1; });
            var toSelections = this.getSelections().filter(function (s) { return s.source === toSource_1; });
            if (fromSelections.length) {
                actions.push(new SetActionModeSelections(fromSelections.map(function (s) {
                    var sIdx = s.subIdx, cmdIdx = s.cmdIdx, source = s.source, type = s.type;
                    return {
                        subIdx: sIdx === fromSubIdx_1 ? 0 : sIdx,
                        cmdIdx: cmdIdx,
                        source: source,
                        type: type,
                    };
                })));
            }
            else if (toSelections.length) {
                actions.push(new SetActionModeSelections(toSelections.map(function (s) {
                    var sIdx = s.subIdx, cmdIdx = s.cmdIdx, source = s.source, type = s.type;
                    return {
                        subIdx: sIdx === toSubIdx_1 ? 0 : sIdx,
                        cmdIdx: cmdIdx,
                        source: source,
                        type: type,
                    };
                })));
            }
            var pairedSubPaths_1 = new Set();
            this.getPairedSubPaths().forEach(function (p) { return pairedSubPaths_1.add(p); });
            if (pairedSubPaths_1.has(fromSubIdx_1)) {
                pairedSubPaths_1.delete(fromSubIdx_1);
            }
            if (pairedSubPaths_1.has(toSubIdx_1)) {
                pairedSubPaths_1.delete(toSubIdx_1);
            }
            pairedSubPaths_1.add(pairedSubPaths_1.size);
            actions.push(new SetPairedSubPaths(pairedSubPaths_1));
            actions.push(new SetActionModeHover(undefined));
            var updatedAnimation = this.buildUpdatedActivePathBlockAnimation(fromSource_1, this.getActivePathBlockValue(fromSource_1)
                .mutate()
                .moveSubPath(fromSubIdx_1, 0)
                .build());
            updatedAnimation = this.buildUpdatedActivePathBlockAnimation(toSource_1, this.getActivePathBlockValue(toSource_1)
                .mutate()
                .moveSubPath(toSubIdx_1, 0)
                .build(), updatedAnimation);
            actions.push(new SetAnimation(updatedAnimation));
        }
        else {
            actions.push(new SetUnpairedSubPath({ source: actionSource, subIdx: subIdx }));
        }
        this.store.dispatch(new (BatchAction.bind.apply(BatchAction, [void 0].concat(actions)))());
    };
    ActionModeService.prototype.getPairedSubPaths = function () {
        return this.queryStore(getPairedSubPaths);
    };
    ActionModeService.prototype.setUnpairedSubPath = function (unpair) {
        if (!_.isEqual(this.getUnpairedSubPath(), unpair)) {
            this.store.dispatch(new SetUnpairedSubPath(unpair));
        }
    };
    ActionModeService.prototype.getUnpairedSubPath = function () {
        return this.queryStore(getUnpairedSubPath);
    };
    // Autofix.
    ActionModeService.prototype.autoFix = function () {
        var _a = AutoAwesome.autoFix(this.getActivePathBlockValue(ActionSource.From), this.getActivePathBlockValue(ActionSource.To)), from = _a[0], to = _a[1];
        var animation = this.buildUpdatedActivePathBlockAnimation(ActionSource.From, from);
        animation = this.buildUpdatedActivePathBlockAnimation(ActionSource.To, to, animation);
        this.store.dispatch(new SetAnimation(animation));
    };
    // Delete selected action mode models.
    ActionModeService.prototype.deleteSelectedActionModeModels = function () {
        if (this.getActionMode() !== ActionMode.Selection) {
            return;
        }
        var selections = this.getSelections();
        if (!selections.length) {
            return;
        }
        var subPathSelections = selections.filter(function (s) { return s.type === SelectionType.SubPath; });
        var segmentSelections = selections.filter(function (s) { return s.type === SelectionType.Segment; });
        var pointSelections = selections.filter(function (s) { return s.type === SelectionType.Point; });
        var updatePathAction;
        if (subPathSelections.length) {
            var _a = subPathSelections[0], source = _a.source, subIdx = _a.subIdx;
            var path = this.getActivePathBlockValue(source);
            if (path.getSubPath(subIdx).isSplit()) {
                var pm = path.mutate();
                var layer = this.getActivePathBlockLayer();
                if (layer.isFilled()) {
                    pm.deleteFilledSubPath(subIdx);
                }
                else if (layer.isStroked()) {
                    pm.deleteStrokedSubPath(subIdx);
                }
                updatePathAction = this.buildUpdatedActivePathBlockAnimationAction(source, pm.build());
            }
        }
        else if (segmentSelections.length) {
            var _b = segmentSelections[0], source = _b.source, subIdx = _b.subIdx, cmdIdx = _b.cmdIdx;
            var path = this.getActivePathBlockValue(source);
            if (path.getCommand(subIdx, cmdIdx).isSplitSegment()) {
                updatePathAction = this.buildUpdatedActivePathBlockAnimationAction(source, path
                    .mutate()
                    .deleteFilledSubPathSegment(subIdx, cmdIdx)
                    .build());
            }
        }
        else if (pointSelections.length) {
            var source = pointSelections[0].source;
            var path = this.getActivePathBlockValue(source);
            var unsplitOpsMap = new Map();
            for (var _i = 0, pointSelections_1 = pointSelections; _i < pointSelections_1.length; _i++) {
                var _c = pointSelections_1[_i], subIdx = _c.subIdx, cmdIdx = _c.cmdIdx;
                if (!path.getCommand(subIdx, cmdIdx).isSplitPoint()) {
                    continue;
                }
                var subIdxOps = unsplitOpsMap.get(subIdx);
                if (!subIdxOps) {
                    subIdxOps = [];
                }
                subIdxOps.push({ subIdx: subIdx, cmdIdx: cmdIdx });
                unsplitOpsMap.set(subIdx, subIdxOps);
            }
            if (unsplitOpsMap.size) {
                var pm_1 = path.mutate();
                unsplitOpsMap.forEach(function (ops, idx) {
                    PathUtil.sortPathOps(ops);
                    ops.forEach(function (op) { return pm_1.unsplitCommand(op.subIdx, op.cmdIdx); });
                });
                updatePathAction = this.buildUpdatedActivePathBlockAnimationAction(source, pm_1.build());
            }
        }
        if (updatePathAction) {
            this.store.dispatch(new BatchAction(updatePathAction, new SetActionModeSelections([]), new SetActionModeHover(undefined)));
        }
    };
    // Update active path block.
    ActionModeService.prototype.updateActivePathBlock = function (source, path) {
        this.store.dispatch(this.buildUpdatedActivePathBlockAnimationAction(source, path));
    };
    ActionModeService.prototype.buildUpdatedActivePathBlockAnimationAction = function (source, path, animation) {
        if (animation === void 0) { animation = this.layerTimelineService.getAnimation(); }
        return new SetAnimation(this.buildUpdatedActivePathBlockAnimation(source, path, animation));
    };
    ActionModeService.prototype.buildUpdatedActivePathBlockAnimation = function (source, path, animation) {
        if (animation === void 0) { animation = this.layerTimelineService.getAnimation(); }
        var _a, _b;
        var blockId = this.getActivePathBlock().id;
        var blockIndex = _.findIndex(animation.blocks, function (b) { return b.id === blockId; });
        var block = animation.blocks[blockIndex];
        // Remove any existing conversions and collapsing sub paths from the path.
        var oppSource = source === ActionSource.From ? ActionSource.To : ActionSource.From;
        var oppPath = oppSource === ActionSource.From ? block.fromValue : block.toValue;
        _a = AutoAwesome.autoAddCollapsingSubPaths(path, oppPath), path = _a[0], oppPath = _a[1];
        _b = AutoAwesome.autoConvert(path, oppPath), path = _b[0], oppPath = _b[1];
        var setBlockValueFn = function (b, t, p) {
            if (t === ActionSource.From) {
                b.fromValue = p;
            }
            else {
                b.toValue = p;
            }
        };
        var newBlock = block.clone();
        setBlockValueFn(newBlock, source, path);
        setBlockValueFn(newBlock, oppSource, oppPath);
        var newBlocks = animation.blocks.map(function (b, i) { return (i === blockIndex ? newBlock : b); });
        animation = animation.clone();
        animation.blocks = newBlocks;
        return animation;
    };
    ActionModeService.prototype.getActivePathBlock = function () {
        return this.layerTimelineService.getSelectedBlocks()[0];
    };
    ActionModeService.prototype.getActivePathBlockValue = function (source) {
        var activeBlock = this.getActivePathBlock();
        return source === ActionSource.From ? activeBlock.fromValue : activeBlock.toValue;
    };
    ActionModeService.prototype.getActivePathBlockLayer = function () {
        var vl = this.layerTimelineService.getVectorLayer();
        return vl.findLayerById(this.getActivePathBlock().layerId);
    };
    ActionModeService.prototype.queryStore = function (selector) {
        var obj;
        this.store
            .select(selector)
            .pipe(first())
            .subscribe(function (o) { return (obj = o); });
        return obj;
    };
    ActionModeService.ngInjectableDef = i0.defineInjectable({ factory: function ActionModeService_Factory() { return new ActionModeService(i0.inject(i1.Store), i0.inject(i2.LayerTimelineService)); }, token: ActionModeService, providedIn: "root" });
    return ActionModeService;
}());
export { ActionModeService };
