import * as tslib_1 from "tslib";
import { CursorType } from 'app/modules/editor/model/paper';
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { HitTests } from 'app/modules/editor/scripts/paper/item';
// prettier-ignore
var RESIZE_CURSOR_MAP = new Map([
    ['bottomLeft', CursorType.Resize45], ['leftCenter', CursorType.Resize90],
    ['topLeft', CursorType.Resize135], ['topCenter', CursorType.Resize0],
    ['topRight', CursorType.Resize45], ['rightCenter', CursorType.Resize90],
    ['bottomRight', CursorType.Resize135], ['bottomCenter', CursorType.Resize0],
]);
// prettier-ignore
var ROTATE_CURSOR_MAP = new Map([
    ['bottomLeft', CursorType.Rotate225], ['leftCenter', CursorType.Rotate270],
    ['topLeft', CursorType.Rotate315], ['topCenter', CursorType.Rotate0],
    ['topRight', CursorType.Rotate45], ['rightCenter', CursorType.Rotate90],
    ['bottomRight', CursorType.Rotate135], ['bottomCenter', CursorType.Rotate180],
]);
// prettier-ignore
var TRANSFORM_CURSOR_MAP = new Map([
    ['bottomLeft', CursorType.Resize45], ['leftCenter', CursorType.Resize0],
    ['topLeft', CursorType.Resize135], ['topCenter', CursorType.Resize90],
    ['topRight', CursorType.Resize45], ['rightCenter', CursorType.Resize0],
    ['bottomRight', CursorType.Resize135], ['bottomCenter', CursorType.Resize90],
]);
/**
 * A gesture that performs hover operations on items.
 *
 * Preconditions:
 * - The user is in default, rotate items, or transform paths mode.
 */
var HoverItemsGesture = /** @class */ (function (_super) {
    tslib_1.__extends(HoverItemsGesture, _super);
    function HoverItemsGesture(ps) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        return _this;
    }
    // @Override
    HoverItemsGesture.prototype.onMouseMove = function (event) {
        this.ps.setCursorType(CursorType.Default);
        var selectedLayers = this.ps.getSelectedLayerIds();
        if (selectedLayers.size) {
            var rii = this.ps.getRotateItemsInfo();
            var selectionBoundSegmentsHitResult = HitTests.selectionModeSegments(event.point);
            if (selectionBoundSegmentsHitResult) {
                var tpi = this.ps.getTransformPathsInfo();
                var cursorMap = rii ? ROTATE_CURSOR_MAP : tpi ? TRANSFORM_CURSOR_MAP : RESIZE_CURSOR_MAP;
                this.ps.setCursorType(cursorMap.get(selectionBoundSegmentsHitResult.item.pivotType));
                this.ps.setHoveredLayerId(undefined);
                return;
            }
            if (rii && HitTests.rotateItemsPivot(event.point)) {
                this.ps.setCursorType(CursorType.Grab);
                this.ps.setHoveredLayerId(undefined);
                return;
            }
        }
        var hitResult = HitTests.selectionMode(event.point, this.ps);
        if (hitResult && !selectedLayers.has(hitResult.hitItem.data.id)) {
            this.ps.setHoveredLayerId(hitResult.hitItem.data.id);
        }
        else {
            this.ps.setHoveredLayerId(undefined);
        }
    };
    // @Override
    HoverItemsGesture.prototype.onKeyDown = function (event) {
        switch (event.key) {
            case 'escape':
                // TODO: also do this in any other hover/pen/pencil related gestures?
                this.ps.setCursorType(CursorType.Default);
                this.ps.setSnapGuideInfo(undefined);
                this.ps.setRotateItemsInfo(undefined);
                this.ps.setTransformPathsInfo(undefined);
                break;
            case 'backspace':
            case 'delete':
                this.ps.deleteSelectedModels();
                break;
        }
    };
    return HoverItemsGesture;
}(Gesture));
export { HoverItemsGesture };
