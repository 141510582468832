import { MathUtil } from 'app/modules/editor/scripts/common';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { Command } from './Command';
import { CommandState } from './CommandState';
import * as PathParser from './PathParser';
import { PathState } from './PathState';
import { SubPathState, flattenSubPathStates } from './SubPathState';
/**
 * A compound path that contains all of the information associated with a
 * PathLayer's pathData attribute.
 */
var Path = /** @class */ (function () {
    function Path(obj) {
        this.ps = typeof obj === 'string' || Array.isArray(obj) ? new PathState(obj) : obj;
        if (!environment.production) {
            // Don't initialize variables lazily for dev builds (to avoid
            // ngrx-store-freeze crashes).
            this.getPathString();
            var allIds_1 = this.getCommands().map(function (c) { return c.id; });
            var uniqueIds = new Set(allIds_1);
            var numCommands = allIds_1.length;
            if (uniqueIds.size !== numCommands) {
                var dumpInfo = this.getSubPaths().map(function (s, subIdx) {
                    return s.getCommands().map(function (c, cmdIdx) {
                        return {
                            subIdx: subIdx,
                            cmdIdx: cmdIdx,
                            id: c.id,
                            isDup: allIds_1.filter(function (id) { return id === c.id; }).length > 1,
                        };
                    });
                });
                console.warn('duplicate IDs found!', this, _.flatten(dumpInfo));
            }
        }
    }
    /**
     * Returns the path's SVG path string.
     */
    Path.prototype.getPathString = function () {
        if (this.pathString === undefined) {
            this.pathString = PathParser.commandsToString(this.getCommands());
        }
        return this.pathString;
    };
    /**
     * Returns the list of SubPaths in this path.
     */
    Path.prototype.getSubPaths = function () {
        return this.ps.subPaths;
    };
    /**
     * Returns the subpath at the specified index.
     */
    Path.prototype.getSubPath = function (subIdx) {
        var numSubPaths = this.getSubPaths().length;
        if (subIdx < 0 || numSubPaths <= subIdx) {
            console.error(this);
            throw new Error("Subpath index out of bounds: " + ("subIdx=" + subIdx + " numSubPaths=" + numSubPaths));
        }
        return this.getSubPaths()[subIdx];
    };
    /**
     * Returns the list of Commands in this path.
     */
    Path.prototype.getCommands = function () {
        return this.ps.commands;
    };
    /**
     * Returns the command at the specified index.
     */
    Path.prototype.getCommand = function (subIdx, cmdIdx) {
        var subPath = this.getSubPath(subIdx);
        var numCommands = subPath.getCommands().length;
        if (cmdIdx < 0 || numCommands <= cmdIdx) {
            console.error(this);
            throw new Error("Command index out of bounds: " +
                ("subIdx=" + subIdx + " cmdIdx=" + cmdIdx + ", numCommands=" + numCommands));
        }
        return subPath.getCommands()[cmdIdx];
    };
    /**
     * Returns the length of the path.
     */
    Path.prototype.getPathLength = function () {
        return this.ps.getPathLength();
    };
    /**
     * Returns the length of the subpath.
     */
    Path.prototype.getSubPathLength = function (subIdx) {
        return this.ps.getSubPathLength(subIdx);
    };
    /**
     * Returns the point at the given length along the path.
     */
    Path.prototype.getPointAtLength = function (distance) {
        return this.ps.getPointAtLength(distance);
    };
    /**
     * Returns true iff this path is morphable with the specified path.
     */
    Path.prototype.isMorphableWith = function (path) {
        var cmds1 = this.getCommands();
        var cmds2 = path.getCommands();
        return cmds1.length === cmds2.length && cmds1.every(function (cmd1, i) { return cmd1.type === cmds2[i].type; });
    };
    /**
     * Calculates the point on this path that is closest to the specified point argument.
     * Returns undefined if no point is found.
     */
    Path.prototype.project = function (point, restrictToSubIdx) {
        return this.ps.project(point, restrictToSubIdx);
    };
    /**
     * Performs a hit test on the path and returns a HitResult.
     */
    Path.prototype.hitTest = function (point, opts) {
        return this.ps.hitTest(point, opts);
    };
    /**
     * Returns the pole of inaccessibility for the specified subpath index.
     */
    Path.prototype.getPoleOfInaccessibility = function (subIdx) {
        return this.ps.getPoleOfInaccessibility(subIdx);
    };
    /**
     * Returns the bounding box for this path.
     */
    Path.prototype.getBoundingBox = function () {
        return this.ps.getBoundingBox();
    };
    /**
     * Returns true iff the subpath at the specified index is clockwise.
     */
    Path.prototype.isClockwise = function (subIdx) {
        return this.ps.isClockwise(subIdx);
    };
    /**
     * Returns true iff the path is closed.
     */
    Path.prototype.isClosed = function () {
        return this.getSubPaths().every(function (s) { return s.isClosed(); });
    };
    /**
     * Transforms the path using the specified transform matrix.
     */
    Path.prototype.transform = function (transform) {
        return this.mutate()
            .transform(transform)
            .build()
            .clone();
    };
    /**
     * Creates a builder that can create a mutated Path object.
     */
    Path.prototype.mutate = function () {
        return new PathMutator(this.ps);
    };
    /**
     * Returns a cloned instance of this path. Any existing path state will be cleared.
     */
    Path.prototype.clone = function () {
        return new Path(this.getPathString());
    };
    /**
     * Returns a Path representing its initial unmutated state.
     */
    Path.prototype.revert = function () {
        return this.mutate()
            .revert()
            .build();
    };
    return Path;
}());
export { Path };
var ENABLE_LOGS = !environment.production && false;
/**
 * A builder class for creating mutated Path objects.
 */
var PathMutator = /** @class */ (function () {
    function PathMutator(ps) {
        this.subPathStateMap = ps.subPathStateMap.slice();
        this.subPathOrdering = ps.subPathOrdering.slice();
        this.numCollapsingSubPaths = ps.numCollapsingSubPaths;
    }
    /**
     * Reverses the order of the points in the sub path at the specified index.
     */
    PathMutator.prototype.reverseSubPath = function (subIdx) {
        LOG('reverseSubPath', subIdx);
        this.setSubPathStateLeaf(subIdx, this.findSubPathStateLeaf(subIdx)
            .mutate()
            .reverse()
            .build());
        return this;
    };
    /**
     * Shifts back the order of the points in the sub path at the specified index.
     */
    PathMutator.prototype.shiftSubPathBack = function (subIdx, numShifts) {
        if (numShifts === void 0) { numShifts = 1; }
        LOG('shiftSubPathBack', subIdx, numShifts);
        return this.findSubPathStateLeaf(subIdx).isReversed()
            ? this.shift(subIdx, function (o, n) { return (o + numShifts) % (n - 1); })
            : this.shift(subIdx, function (o, n) { return MathUtil.floorMod(o - numShifts, n - 1); });
    };
    /**
     * Shifts forward the order of the points in the sub path at the specified index.
     */
    PathMutator.prototype.shiftSubPathForward = function (subIdx, numShifts) {
        if (numShifts === void 0) { numShifts = 1; }
        LOG('shiftSubPathForward', subIdx, numShifts);
        return this.findSubPathStateLeaf(subIdx).isReversed()
            ? this.shift(subIdx, function (o, n) { return MathUtil.floorMod(o - numShifts, n - 1); })
            : this.shift(subIdx, function (o, n) { return (o + numShifts) % (n - 1); });
    };
    PathMutator.prototype.shift = function (subIdx, calcOffsetFn) {
        var sps = this.findSubPathStateLeaf(subIdx);
        var numCmdsInSubPath = _.sumBy(sps.getCommandStates(), function (cs) { return cs.getCommands().length; });
        if (numCmdsInSubPath <= 1) {
            return this;
        }
        var firstCmd = sps.getCommandStates()[0].getCommands()[0];
        var lastCmd = _.last(_.last(sps.getCommandStates()).getCommands());
        if (!MathUtil.arePointsEqual(firstCmd.end, lastCmd.end)) {
            // TODO: in some cases there may be rounding errors that cause a closed subpath
            // to show up as non-closed. is there anything we can do to alleviate this?
            console.warn('Ignoring attempt to shift a non-closed subpath');
            return this;
        }
        this.setSubPathStateLeaf(subIdx, sps
            .mutate()
            .setShiftOffset(calcOffsetFn(sps.getShiftOffset(), numCmdsInSubPath))
            .build());
        return this;
    };
    /**
     * Splits the command using the specified t values.
     */
    PathMutator.prototype.splitCommand = function (subIdx, cmdIdx) {
        var ts = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            ts[_i - 2] = arguments[_i];
        }
        LOG('splitCommand', subIdx, cmdIdx, ts);
        if (!ts.length) {
            throw new Error('Must specify at least one t value');
        }
        var _a = this.findReversedAndShiftedInternalIndices(subIdx, cmdIdx), targetCs = _a.targetCs, csIdx = _a.csIdx, splitIdx = _a.splitIdx;
        var shiftOffset = this.getUpdatedShiftOffsetsAfterSplit(subIdx, csIdx, splitIdx, ts.length);
        var sps = this.findSubPathStateLeaf(subIdx);
        if (sps.isReversed()) {
            ts = ts.map(function (t) { return 1 - t; });
        }
        this.setSubPathStateLeaf(subIdx, this.findSubPathStateLeaf(subIdx)
            .mutate()
            .setShiftOffset(shiftOffset)
            .setCommandState(csIdx, targetCs
            .mutate()
            .splitAtIndex(splitIdx, ts)
            .build())
            .build());
        return this;
    };
    /**
     * Splits the command into two approximately equal parts.
     */
    PathMutator.prototype.splitCommandInHalf = function (subIdx, cmdIdx) {
        LOG('splitCommandInHalf', subIdx, cmdIdx);
        var _a = this.findReversedAndShiftedInternalIndices(subIdx, cmdIdx), targetCs = _a.targetCs, csIdx = _a.csIdx, splitIdx = _a.splitIdx;
        var shiftOffset = this.getUpdatedShiftOffsetsAfterSplit(subIdx, csIdx, splitIdx, 1);
        this.setSubPathStateLeaf(subIdx, this.findSubPathStateLeaf(subIdx)
            .mutate()
            .setShiftOffset(shiftOffset)
            .setCommandState(csIdx, targetCs
            .mutate()
            .splitInHalfAtIndex(splitIdx)
            .build())
            .build());
        return this;
    };
    // If 0 <= position <= shiftOffset, then that means we need to increase the
    // shift offset to account for the new split points that are about to be inserted.
    // Note that this method assumes all splits will occur within the same cmdIdx
    // command. This means that the shift offset will only ever increase by either
    // 'numShifts' or '0', since it will be impossible for splits to be added on
    // both sides of the shift pivot. We could fix that, but it's a lot of
    // complicated indexing and I don't think the user will ever need to do this anyway.
    PathMutator.prototype.getUpdatedShiftOffsetsAfterSplit = function (subIdx, csIdx, splitIdx, numSplits) {
        var sps = this.findSubPathStateLeaf(subIdx);
        var shiftOffset = sps.getShiftOffset();
        var position = splitIdx;
        for (var i = 0; i < csIdx; i++) {
            position += sps.getCommandStates()[i].getCommands().length;
        }
        if (shiftOffset && position <= shiftOffset) {
            return shiftOffset + numSplits;
        }
        return shiftOffset;
    };
    /**
     * Un-splits the path at the specified index. Returns a new path object.
     */
    PathMutator.prototype.unsplitCommand = function (subIdx, cmdIdx) {
        LOG('unsplitCommand', subIdx, cmdIdx);
        var _a = this.findReversedAndShiftedInternalIndices(subIdx, cmdIdx), targetCs = _a.targetCs, csIdx = _a.csIdx, splitIdx = _a.splitIdx;
        var isSubPathReversed = this.findSubPathStateLeaf(subIdx).isReversed();
        this.setSubPathStateLeaf(subIdx, this.findSubPathStateLeaf(subIdx)
            .mutate()
            .setCommandState(csIdx, targetCs
            .mutate()
            .unsplitAtIndex(isSubPathReversed ? splitIdx - 1 : splitIdx)
            .build())
            .build());
        var sps = this.findSubPathStateLeaf(subIdx);
        var shiftOffset = sps.getShiftOffset();
        var position = splitIdx;
        for (var i = 0; i < csIdx; i++) {
            position += sps.getCommandStates()[i].getCommands().length;
        }
        if (shiftOffset && position <= shiftOffset) {
            // Subtract the shift offset by 1 to ensure that the unsplit operation
            // doesn't alter the positions of the path points.
            this.setSubPathStateLeaf(subIdx, this.findSubPathStateLeaf(subIdx)
                .mutate()
                .setShiftOffset(shiftOffset - 1)
                .build());
        }
        return this;
    };
    /**
     * Convert the path at the specified index. Returns a new path object.
     */
    PathMutator.prototype.convertCommand = function (subIdx, cmdIdx, svgChar) {
        var _a = this.findReversedAndShiftedInternalIndices(subIdx, cmdIdx), targetCs = _a.targetCs, csIdx = _a.csIdx, splitIdx = _a.splitIdx;
        this.setSubPathStateLeaf(subIdx, this.findSubPathStateLeaf(subIdx)
            .mutate()
            .setCommandState(csIdx, targetCs
            .mutate()
            .convertAtIndex(splitIdx, svgChar)
            .build())
            .build());
        return this;
    };
    /**
     * Reverts any conversions previously performed in the specified sub path.
     */
    PathMutator.prototype.unconvertSubPath = function (subIdx) {
        var sps = this.findSubPathStateLeaf(subIdx);
        var css = sps.getCommandStates().map(function (cs, csIdx) {
            return csIdx === 0
                ? cs
                : cs
                    .mutate()
                    .unconvertSubpath()
                    .build();
        });
        this.setSubPathStateLeaf(subIdx, sps
            .mutate()
            .setCommandStates(css)
            .build());
        return this;
    };
    /**
     * Transforms the path using the specified transformation matrix.
     */
    PathMutator.prototype.transform = function (transform) {
        var spss = flattenSubPathStates(this.subPathStateMap);
        for (var spsIdx = 0; spsIdx < spss.length; spsIdx++) {
            var sps = spss[spsIdx];
            var css = sps.getCommandStates();
            var subIdx = this.subPathOrdering.indexOf(spsIdx);
            this.setSubPathStateLeaf(subIdx, sps
                .mutate()
                .setCommandStates(css.map(function (cs) {
                return cs
                    .mutate()
                    .transform(transform)
                    .build();
            }))
                .build());
        }
        return this;
    };
    /**
     * Moves a subpath from one index to another. Returns a new path object.
     */
    PathMutator.prototype.moveSubPath = function (fromSubIdx, toSubIdx) {
        LOG('moveSubPath', fromSubIdx, toSubIdx);
        this.subPathOrdering.splice(toSubIdx, 0, this.subPathOrdering.splice(fromSubIdx, 1)[0]);
        return this;
    };
    /**
     * Splits a stroked sub path using the specified indices.
     * A 'moveTo' command will be inserted after the command at 'cmdIdx'.
     */
    PathMutator.prototype.splitStrokedSubPath = function (subIdx, cmdIdx) {
        LOG('splitStrokedSubPath', subIdx, cmdIdx);
        var sps = this.findSubPathStateLeaf(subIdx);
        var css = reverseAndShiftCommandStates(sps.getCommandStates(), sps.isReversed(), sps.getShiftOffset());
        var _a = this.findInternalIndices(css, cmdIdx), csIdx = _a.csIdx, splitIdx = _a.splitIdx;
        var startCommandStates = [];
        var endCommandStates = [];
        for (var i = 0; i < css.length; i++) {
            if (i < csIdx) {
                startCommandStates.push(css[i]);
            }
            else if (csIdx < i) {
                endCommandStates.push(css[i]);
            }
            else {
                var splitPoint = css[i].getCommands()[splitIdx].end;
                var _b = css[i].slice(splitIdx), left = _b.left, right = _b.right;
                startCommandStates.push(left);
                var endMoveCs = new CommandState(new Command('M', [splitPoint, splitPoint]));
                if (sps.isReversed()) {
                    endMoveCs = endMoveCs
                        .mutate()
                        .reverse()
                        .build();
                }
                endCommandStates.push(endMoveCs);
                if (right) {
                    endCommandStates.push(right);
                }
            }
        }
        var splitSubPaths = [
            new SubPathState(startCommandStates),
            new SubPathState(endCommandStates),
        ];
        this.setSubPathStateLeaf(subIdx, sps
            .mutate()
            .setSplitSubPaths(splitSubPaths)
            .build());
        this.subPathOrdering.push(this.subPathOrdering.length);
        return this;
    };
    /**
     * Deletes the stroked subpath at the specified index. The subpath's sibling
     * will be deleted as well.
     */
    PathMutator.prototype.deleteStrokedSubPath = function (subIdx) {
        LOG('unsplitStrokedSubPath', subIdx);
        var parent = this.findSubPathStateParent(subIdx);
        var splitId = _.last(_.last(parent.getSplitSubPaths()[0].getCommandStates()).getCommands())
            .id;
        var mutator = parent.mutate().setSplitSubPaths([]);
        this.deleteSpsSplitPoint(parent.getCommandStates(), splitId, mutator);
        this.subPathStateMap = this.replaceSubPathStateNode(parent, function (states, i) { return (states[i] = mutator.build()); });
        this.updateOrderingAfterUnsplitSubPath(subIdx);
        return this;
    };
    /**
     * Splits a filled subpath using the specified indices.
     *
     * Consider the following filled subpath:
     *
     * 2-------------------3
     * |                   |
     * |                   |
     * |                   |
     * 1                   4
     * |                   |
     * |                   |
     * |                   |
     * 0-------------------5
     *
     * Splitting the filled subpath with startCmdIdx=1 and endCmdIdx=4
     * results in the following split subpaths:
     *
     * xxxxxxxxxxxxxxxxxxxxx    1-------->>>--------2
     * x                   x    |                   |
     * x                   x    ↑                   ↓
     * x                   x    |                   |
     * 1-------->>>--------2    0--------<<<--------3
     * |                   |    x                   x
     * ↑                   ↓    x                   x
     * |                   |    x                   x
     * 0--------<<<--------3    xxxxxxxxxxxxxxxxxxxxx
     */
    PathMutator.prototype.splitFilledSubPath = function (subIdx, startCmdIdx, endCmdIdx) {
        var _this = this;
        LOG('splitFilledSubPath', subIdx, startCmdIdx, endCmdIdx);
        var targetSps = this.findSubPathStateLeaf(subIdx);
        var targetCss = reverseAndShiftCommandStates(targetSps.getCommandStates(), targetSps.isReversed(), targetSps.getShiftOffset());
        var findTargetSplitIdxs = function () {
            var s = _this.findInternalIndices(targetCss, startCmdIdx);
            var e = _this.findInternalIndices(targetCss, endCmdIdx);
            if (s.csIdx > e.csIdx || (s.csIdx === e.csIdx && s.splitIdx > e.csIdx)) {
                // Make sure the start index appears before the end index in the path.
                var temp = s;
                s = e;
                e = temp;
            }
            return {
                startCsIdx: s.csIdx,
                startSplitIdx: s.splitIdx,
                endCsIdx: e.csIdx,
                endSplitIdx: e.splitIdx,
            };
        };
        // firstLeft: left portion of the 1st split segment (used in the 1st split path).
        // secondLeft: left portion of the 2nd split segment (used in the 2nd split path).
        // firstRight: right portion of the 1st split segment (used in the 2nd split path).
        // secondRight: right portion of the 2nd split segment (used in the 1st split path).
        var _a = findTargetSplitIdxs(), startCsIdx = _a.startCsIdx, startSplitIdx = _a.startSplitIdx, endCsIdx = _a.endCsIdx, endSplitIdx = _a.endSplitIdx;
        var _b = targetCss[startCsIdx].slice(startSplitIdx), firstLeft = _b.left, firstRight = _b.right;
        var _c = targetCss[endCsIdx].slice(endSplitIdx), secondLeft = _c.left, secondRight = _c.right;
        var startSplitCmd = firstLeft.getCommands()[startSplitIdx];
        var startSplitPoint = startSplitCmd.end;
        var endSplitCmd = secondLeft.getCommands()[endSplitIdx];
        var endSplitPoint = endSplitCmd.end;
        // Give both line segments the same unique ID so that we can later identify which
        // split segments were added together during the deletion phase.
        var splitSegmentId = _.uniqueId();
        var endLine = new CommandState(new Command('L', [endSplitPoint, startSplitPoint]))
            .mutate()
            .setSplitSegmentInfo(secondLeft, splitSegmentId)
            .build();
        var startLine = new CommandState(new Command('L', [startSplitPoint, endSplitPoint]))
            .mutate()
            .setSplitSegmentInfo(firstLeft, splitSegmentId)
            .build();
        var startCommandStates = [];
        for (var i = 0; i < targetCss.length; i++) {
            if (i < startCsIdx || endCsIdx < i) {
                startCommandStates.push(targetCss[i]);
            }
            else if (i === startCsIdx) {
                startCommandStates.push(firstLeft);
                startCommandStates.push(startLine);
            }
            else if (i === endCsIdx && secondRight) {
                startCommandStates.push(secondRight);
            }
        }
        var endCommandStates = [];
        for (var i = 0; i < targetCss.length; i++) {
            if (i === startCsIdx) {
                endCommandStates.push(new CommandState(new Command('M', [startSplitPoint, startSplitPoint]))
                    .mutate()
                    // The move command identifies the beginning of a new split segment,
                    // so we'll mark it with the parent state as well (we'll need this
                    // information later on if the segment is deleted). Note that unlike
                    // the two segments above, we don't need to specify an ID here.
                    .setSplitSegmentInfo(firstLeft, '')
                    .build());
                if (firstRight) {
                    endCommandStates.push(firstRight);
                }
            }
            else if (startCsIdx < i && i < endCsIdx) {
                endCommandStates.push(targetCss[i]);
            }
            else if (i === endCsIdx) {
                endCommandStates.push(secondLeft);
                endCommandStates.push(endLine);
            }
        }
        var splitSubPaths = [
            new SubPathState(startCommandStates),
            new SubPathState(endCommandStates),
        ];
        var newStates = [];
        var parent = this.findSubPathStateParent(subIdx);
        // Find the backing IDs for each parent command state that is a split segment.
        var parentSplitBackingIds = _((parent ? parent.getCommandStates() : []))
            .filter(function (cs) { return !!cs.getSplitSegmentId(); })
            .map(function (cs) { return cs.getBackingId(); })
            .value();
        // Find the backing IDs for each sibling command state that is a split segment,
        // not including split segments that were inherited from the parent.
        var siblingSplitBackingIds = _(targetSps.getCommandStates())
            .filter(function (cs) { return !!cs.getSplitSegmentId() && !parentSplitBackingIds.includes(cs.getBackingId()); })
            .map(function (cs) { return cs.getBackingId(); })
            .value();
        // Checking for the existence of 'firstRight' and 'secondRight' ensures that
        // paths connected to the end point of a deleted split segment will still be kept.
        if (this.subPathStateMap.includes(targetSps) ||
            (firstRight && siblingSplitBackingIds.includes(firstLeft.getBackingId())) ||
            (secondRight && siblingSplitBackingIds.includes(secondLeft.getBackingId()))) {
            // If we are at the first level of the tree or if one of the new
            // split edges is a split segment, then add a new level to the tree.
            // If the already existing split segment is deleted, we want to
            // delete the split segment we are creating right now as well.
            newStates.push(targetSps
                .mutate()
                .setSplitSubPaths(splitSubPaths)
                .build());
        }
        else {
            // Otherwise insert the sub paths in the current level of the tree.
            newStates.push.apply(newStates, splitSubPaths);
        }
        // Insert the new SubPathStates into the tree.
        this.subPathStateMap = this.replaceSubPathStateNode(targetSps, function (states, i) {
            return states.splice.apply(states, [i, 1].concat(newStates));
        });
        this.subPathOrdering.push(this.subPathOrdering.length);
        return this;
    };
    /**
     * Deletes the filled subpath at the specified index. All adjacent sibling subpaths
     * will be deleted as well (i.e. subpaths that share the same split segment ID).
     */
    PathMutator.prototype.deleteFilledSubPath = function (subIdx) {
        var _this = this;
        LOG('deleteFilledSubPath', subIdx);
        var targetCss = this.findSubPathStateLeaf(subIdx).getCommandStates();
        // Get the list of parent split segment IDs.
        var parentSplitSegIds = _(this.findSubPathStateParent(subIdx).getCommandStates())
            .map(function (cs) { return cs.getSplitSegmentId(); })
            .compact()
            .uniq()
            .value();
        // Get the list of sibling split segment IDs, not including split segment
        // IDs inherited from the parent.
        var siblingSplitSegIds = _(targetCss)
            .map(function (cs) { return cs.getSplitSegmentId(); })
            .compact()
            .uniq()
            .difference(parentSplitSegIds)
            .value();
        siblingSplitSegIds.forEach(function (id) {
            var targetCs = _.find(targetCss, function (cs) { return cs.getSplitSegmentId() === id; });
            var deletedSubIdxs = _this.calculateDeletedSubIdxs(subIdx, targetCs);
            _this.deleteFilledSubPathSegmentInternal(subIdx, targetCs);
            subIdx -= _.sumBy(deletedSubIdxs, function (idx) { return (idx <= subIdx ? 1 : 0); });
        });
        return this;
    };
    /**
     * Deletes the subpath split segment with the specified indices.
     */
    PathMutator.prototype.deleteFilledSubPathSegment = function (subIdx, cmdIdx) {
        LOG('deleteSubPathSplitSegment', subIdx, cmdIdx);
        var targetCs = this.findReversedAndShiftedInternalIndices(subIdx, cmdIdx).targetCs;
        this.deleteFilledSubPathSegmentInternal(subIdx, targetCs);
        return this;
    };
    /**
     * Deletes the subpath split segment with the specified index. The two subpaths
     * that share the split segment ID will be merged into a single subpath.
     */
    PathMutator.prototype.deleteFilledSubPathSegmentInternal = function (subIdx, targetCs) {
        // Get the SubPathState ID of the node representing the subpath with index 'subIdx'.
        var targetSpsId = this.findSubPathStateLeaf(subIdx).getId();
        // Get the split segment ID of the target command state object.
        var targetSplitSegId = targetCs.getSplitSegmentId();
        var psps = this.findSplitSegmentParentNode(targetSplitSegId);
        var pssps = psps.getSplitSubPaths();
        var pcss = psps.getCommandStates();
        // Find the first index of the split sub path containing the target.
        var splitSubPathIdx1 = _.findIndex(pssps, function (sps) {
            return sps.getCommandStates().some(function (cs) { return cs.getSplitSegmentId() === targetSplitSegId; });
        });
        // Find the second index of the split sub path containing the target.
        var splitSubPathIdx2 = _.findLastIndex(pssps, function (sps) {
            return sps.getCommandStates().some(function (cs) { return cs.getSplitSegmentId() === targetSplitSegId; });
        });
        var deletedSubIdxs = this.calculateDeletedSubIdxs(subIdx, targetCs);
        var splitCss1 = pssps[splitSubPathIdx1].getCommandStates();
        var splitCss2 = pssps[splitSubPathIdx2].getCommandStates();
        var updatedSplitSubPaths = [];
        if (pssps.length > 2) {
            // In addition to deleting the split segment, we will also have to merge its
            // two adjacent sub paths together into one.
            var parentBackingId2_1 = _.last(splitCss2)
                .getParentCommandState()
                .getBackingId();
            var parentBackingCmd2_1 = _.find(pcss, function (c) { return parentBackingId2_1 === c.getBackingId(); });
            var newCss = [];
            var cs = void 0;
            var i = 0;
            for (; i < splitCss1.length; i++) {
                cs = splitCss1[i];
                if (splitCss1[i + 1].getSplitSegmentId() === targetSplitSegId) {
                    // Iterate until we reach the location of the first split.
                    break;
                }
                newCss.push(cs);
            }
            var parentBackingCmdIdx1 = i;
            if (cs.getBackingId() === splitCss2[1].getBackingId()) {
                newCss.push(splitCss2[1].merge(cs));
            }
            else {
                newCss.push(cs);
                newCss.push(splitCss2[1]);
            }
            cs = undefined;
            for (i = 2; i < splitCss2.length - 1; i++) {
                cs = splitCss2[i];
                if (splitCss2[i + 1].getSplitSegmentId() === targetSplitSegId) {
                    // Iterate until we reach the location of the second split.
                    break;
                }
                newCss.push(cs);
            }
            i = _.findIndex(splitCss1, function (c) { return c.getBackingId() === parentBackingCmd2_1.getBackingId(); });
            if (i >= 0) {
                if (cs) {
                    if (splitCss1[i].getBackingId() === cs.getBackingId()) {
                        // If the split created a new point, then merge the left/right commands
                        // together to reconstruct the previous state.
                        newCss.push(splitCss1[i].merge(cs));
                    }
                    else if (cs) {
                        // If the split was done at an existing point, then simply push the next
                        // command state onto the list.
                        newCss.push(cs);
                    }
                }
            }
            else {
                i = parentBackingCmdIdx1 + 1;
                if (cs) {
                    newCss.push(cs);
                }
            }
            for (i = i + 1; i < splitCss1.length; i++) {
                newCss.push(splitCss1[i]);
            }
            var splits = pssps.slice();
            splits[splitSubPathIdx1] = new SubPathState(newCss.slice())
                .mutate()
                .setId(targetSpsId)
                .build();
            splits.splice(splitSubPathIdx2, 1);
            updatedSplitSubPaths = splits;
        }
        var mutator = psps.mutate().setSplitSubPaths(updatedSplitSubPaths);
        var firstSplitSegId = _.last(splitCss2[0].getParentCommandState().getCommands()).id;
        var secondSplitSegId = _.last(_.last(splitCss2)
            .getParentCommandState()
            .getCommands()).id;
        for (var _i = 0, _a = [firstSplitSegId, secondSplitSegId]; _i < _a.length; _i++) {
            var id = _a[_i];
            this.deleteSpsSplitPoint(pcss, id, mutator);
        }
        this.subPathStateMap = this.replaceSubPathStateNode(psps, function (states, i) { return (states[i] = mutator.build()); });
        for (var _b = 0, deletedSubIdxs_1 = deletedSubIdxs; _b < deletedSubIdxs_1.length; _b++) {
            var idx = deletedSubIdxs_1[_b];
            this.updateOrderingAfterUnsplitSubPath(idx);
        }
        return this;
    };
    /**
     * Calculates the sub path indices that will be removed after unsplitting subIdx.
     * targetCs is the command state object containing the split segment in question.
     */
    PathMutator.prototype.calculateDeletedSubIdxs = function (subIdx, targetCs) {
        var _this = this;
        var splitSegId = targetCs.getSplitSegmentId();
        var psps = this.findSplitSegmentParentNode(splitSegId);
        var pssps = psps.getSplitSubPaths();
        var splitSubPathIdx1 = _.findIndex(pssps, function (sps) {
            return sps.getCommandStates().some(function (cs) { return cs.getSplitSegmentId() === splitSegId; });
        });
        var splitSubPathIdx2 = _.findLastIndex(pssps, function (sps) {
            return sps.getCommandStates().some(function (cs) { return cs.getSplitSegmentId() === splitSegId; });
        });
        var pssp1 = pssps[splitSubPathIdx1];
        var pssp2 = pssps[splitSubPathIdx2];
        var deletedSps = flattenSubPathStates([pssp1]).concat(flattenSubPathStates([pssp2]));
        var spss = flattenSubPathStates(this.subPathStateMap);
        return deletedSps
            .slice(1)
            .map(function (sps) { return _this.subPathOrdering[spss.indexOf(sps)]; })
            .sort(function (a, b) { return b - a; });
    };
    PathMutator.prototype.deleteSpsSplitPoint = function (css, splitCmdId, mutator) {
        var csIdx = 0, splitIdx = -1;
        var _loop_1 = function () {
            var cs = css[csIdx];
            var csIds = cs.getCommands().map(function (unused, idx) { return cs.getIdAtIndex(idx); });
            splitIdx = csIds.indexOf(splitCmdId);
            if (splitIdx >= 0) {
                return "break";
            }
        };
        for (; csIdx < css.length; csIdx++) {
            var state_1 = _loop_1();
            if (state_1 === "break")
                break;
        }
        if (splitIdx >= 0 && css[csIdx].isSplitAtIndex(splitIdx)) {
            // Delete the split point that created the sub path.
            var unsplitCs = css[csIdx]
                .mutate()
                .unsplitAtIndex(splitIdx)
                .build();
            mutator.setCommandState(csIdx, unsplitCs);
        }
    };
    PathMutator.prototype.updateOrderingAfterUnsplitSubPath = function (subIdx) {
        var spsIdx = this.subPathOrdering[subIdx];
        this.subPathOrdering.splice(subIdx, 1);
        // tslint:disable-next-line: prefer-for-of
        for (var i = 0; i < this.subPathOrdering.length; i++) {
            if (spsIdx < this.subPathOrdering[i]) {
                this.subPathOrdering[i]--;
            }
        }
    };
    /**
     * Adds a collapsing subpath to the path.
     */
    PathMutator.prototype.addCollapsingSubPath = function (point, numCommands) {
        var prevCmd = _.last(this.buildOrderedCommands());
        var css = [new CommandState(new Command('M', [prevCmd.end, point]))];
        for (var i = 1; i < numCommands; i++) {
            css.push(new CommandState(new Command('L', [point, point])));
        }
        this.subPathStateMap.push(new SubPathState(css));
        this.subPathOrdering.push(this.subPathOrdering.length);
        this.numCollapsingSubPaths++;
        return this;
    };
    /**
     * Deletes all collapsing subpaths from the path.
     */
    PathMutator.prototype.deleteCollapsingSubPaths = function () {
        var numSubPathsBeforeDelete = this.subPathOrdering.length;
        var spsIdxToSubIdxMap = [];
        for (var spsIdx = 0; spsIdx < numSubPathsBeforeDelete; spsIdx++) {
            spsIdxToSubIdxMap.push(this.subPathOrdering.indexOf(spsIdx));
        }
        var numCollapsingSubPathsBeforeDelete = this.numCollapsingSubPaths;
        var numSubPathsAfterDelete = numSubPathsBeforeDelete - numCollapsingSubPathsBeforeDelete;
        function deleteCollapsingSubPathInfoFn(arr) {
            arr.splice(numSubPathsAfterDelete, numCollapsingSubPathsBeforeDelete);
        }
        for (var i = 0; i < numCollapsingSubPathsBeforeDelete; i++) {
            this.subPathStateMap.pop();
        }
        deleteCollapsingSubPathInfoFn(spsIdxToSubIdxMap);
        this.subPathOrdering = [];
        for (var subIdx = 0; subIdx < numSubPathsBeforeDelete; subIdx++) {
            for (var i = 0; i < spsIdxToSubIdxMap.length; i++) {
                if (spsIdxToSubIdxMap[i] === subIdx) {
                    this.subPathOrdering.push(i);
                    break;
                }
            }
        }
        this.numCollapsingSubPaths = 0;
        return this;
    };
    /**
     * Returns the initial starting state of this path.
     */
    PathMutator.prototype.revert = function () {
        this.deleteCollapsingSubPaths();
        this.subPathStateMap = this.subPathStateMap.map(function (sps) { return sps.revert(); });
        this.subPathOrdering = this.subPathStateMap.map(function (unused, i) { return i; });
        return this;
    };
    /**
     * Builds a new mutated path.
     */
    PathMutator.prototype.build = function () {
        return new Path(new PathState(this.buildOrderedCommands(), this.subPathStateMap, this.subPathOrdering, this.numCollapsingSubPaths));
    };
    PathMutator.prototype.buildOrderedCommands = function () {
        var _this = this;
        var spsCmds = flattenSubPathStates(this.subPathStateMap).map(function (sps) {
            return reverseAndShiftCommands(sps);
        });
        var orderedSubPathCmds = this.subPathOrdering.map(function (unused, subIdx) { return spsCmds[_this.subPathOrdering[subIdx]]; });
        return _(orderedSubPathCmds)
            .map(function (cmds, subIdx) {
            var moveCmd = cmds[0];
            if (subIdx === 0 && moveCmd.start) {
                cmds[0] = moveCmd
                    .mutate()
                    .setPoints(undefined, moveCmd.end)
                    .build();
            }
            else if (subIdx !== 0) {
                var start = _.last(orderedSubPathCmds[subIdx - 1]).end;
                cmds[0] = moveCmd
                    .mutate()
                    .setPoints(start, moveCmd.end)
                    .build();
            }
            return cmds;
        })
            .flatMap(function (cmds) { return cmds; })
            .value();
    };
    /**
     * Returns the leaf node at the specified subpath index.
     */
    PathMutator.prototype.findSubPathStateLeaf = function (subIdx) {
        return flattenSubPathStates(this.subPathStateMap)[this.subPathOrdering[subIdx]];
    };
    /**
     * Replaces the leaf node at the specified subpath index.
     */
    PathMutator.prototype.setSubPathStateLeaf = function (subIdx, newState) {
        this.subPathStateMap = this.replaceSubPathStateNode(this.findSubPathStateLeaf(subIdx), function (states, i) { return (states[i] = newState); });
    };
    /**
     * Returns the immediate parent of the leaf node at the specified subpath index.
     */
    PathMutator.prototype.findSubPathStateParent = function (subIdx) {
        var subPathStateParents = [];
        (function recurseFn(currentLevel, parent) {
            currentLevel.forEach(function (state) {
                if (!state.getSplitSubPaths().length) {
                    subPathStateParents.push(parent);
                    return;
                }
                recurseFn(state.getSplitSubPaths(), state);
            });
        })(this.subPathStateMap);
        return subPathStateParents[this.subPathOrdering[subIdx]];
    };
    /**
     * Returns the command state indices associated with the specified command index.
     * The targetCs is the command state object that contains the command. The csIdx
     * is the index in the sub path state's list of command state objects that points
     * to targetCs. The splitIdx is the index into the targetCs pointing to the command.
     * This method should only be used during sub path splitting. All other methods should
     * use the findReversedAndShiftedInternalIndices() method below.
     */
    PathMutator.prototype.findInternalIndices = function (css, cmdIdx) {
        var counter = 0;
        var csIdx = 0;
        for (var _i = 0, css_1 = css; _i < css_1.length; _i++) {
            var targetCs = css_1[_i];
            if (counter + targetCs.getCommands().length > cmdIdx) {
                return { targetCs: targetCs, csIdx: csIdx, splitIdx: cmdIdx - counter };
            }
            counter += targetCs.getCommands().length;
            csIdx++;
        }
        throw new Error('Error retrieving command mutation');
    };
    /**
     * Same as above, except this method first takes reversals and shift offsets
     * into account.
     */
    PathMutator.prototype.findReversedAndShiftedInternalIndices = function (subIdx, cmdIdx) {
        var sps = this.findSubPathStateLeaf(subIdx);
        var css = sps.getCommandStates();
        var numCommandsInSubPath = _.sumBy(css, function (cs) { return cs.getCommands().length; });
        if (cmdIdx && sps.isReversed()) {
            cmdIdx = numCommandsInSubPath - cmdIdx;
        }
        cmdIdx += sps.getShiftOffset();
        if (cmdIdx >= numCommandsInSubPath) {
            // Note that subtracting (numCommandsInSubPath - 1) is intentional here
            // (as opposed to subtracting numCommandsInSubPath).
            cmdIdx -= numCommandsInSubPath - 1;
        }
        return this.findInternalIndices(css, cmdIdx);
    };
    /**
     * Replaces a node in the sub path state map. Note that this function uses
     * object equality to determine the location of the node in the tree.
     */
    PathMutator.prototype.replaceSubPathStateNode = function (nodeToReplace, replaceNodeFn) {
        return (function recurseFn(states) {
            if (!states.length) {
                return undefined;
            }
            for (var i = 0; i < states.length; i++) {
                var currentState = states[i];
                if (currentState === nodeToReplace) {
                    replaceNodeFn(states, i);
                    return states;
                }
                var recurseStates = recurseFn(currentState.getSplitSubPaths().slice());
                if (recurseStates) {
                    states[i] = currentState
                        .mutate()
                        .setSplitSubPaths(recurseStates)
                        .build();
                    return states;
                }
            }
            // Return undefined to signal that the parent was not found.
            return undefined;
        })(this.subPathStateMap.slice());
    };
    /**
     * Finds the first node in the tree that contains the specified split segment ID.
     */
    PathMutator.prototype.findSplitSegmentParentNode = function (splitSegId) {
        return (function recurseFn() {
            var states = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                states[_i] = arguments[_i];
            }
            for (var _a = 0, states_1 = states; _a < states_1.length; _a++) {
                var state = states_1[_a];
                for (var _b = 0, _c = state.getSplitSubPaths(); _b < _c.length; _b++) {
                    var sps = _c[_b];
                    if (sps.getCommandStates().some(function (cs) { return cs.getSplitSegmentId() === splitSegId; })) {
                        return state;
                    }
                    var parent_1 = recurseFn(sps);
                    if (parent_1) {
                        return parent_1;
                    }
                }
            }
            return undefined;
        }).apply(void 0, this.subPathStateMap);
    };
    return PathMutator;
}());
export { PathMutator };
/**
 * Returns a list of shifted and reversed command state objects. Used during
 * subpath splitting when creating new children split subpaths.
 */
function reverseAndShiftCommandStates(css, isReversed, shiftOffset) {
    // If the last command is a 'Z', replace it with a line before we shift.
    // TODO: replacing the 'Z' messes up certain stroke-linejoin values
    var newCss = css.slice();
    newCss[newCss.length - 1] = _.last(css)
        .mutate()
        .forceConvertClosepathsToLines()
        .build();
    return shiftCommandStates(reverseCommandStates(newCss, isReversed), isReversed, shiftOffset);
}
/**
 * Returns a list of reversed command state objects.
 */
function reverseCommandStates(css, isReversed) {
    if (isReversed) {
        var revCss = [
            new CommandState(new Command('M', [css[0].getCommands()[0].start, _.last(_.last(css).getCommands()).end])),
        ];
        for (var i = css.length - 1; i > 0; i--) {
            revCss.push(css[i]
                .mutate()
                .reverse()
                .build());
        }
        css = revCss;
    }
    return css;
}
/**
 * Returns a list of shifted command state objects.
 */
function shiftCommandStates(css, isReversed, shiftOffset) {
    if (!shiftOffset || css.length === 1) {
        return css;
    }
    var numCommands = _.sumBy(css, function (cs) { return cs.getCommands().length; });
    if (isReversed) {
        shiftOffset *= -1;
        shiftOffset += numCommands - 1;
    }
    var newCss = [];
    var counter = 0;
    var targetCsIdx;
    var targetSplitIdx;
    var targetCs;
    for (var i = 0; i < css.length; i++) {
        var cs = css[i];
        var size = cs.getCommands().length;
        if (counter + size <= shiftOffset) {
            counter += size;
            continue;
        }
        targetCs = cs;
        targetCsIdx = i;
        targetSplitIdx = shiftOffset - counter;
        break;
    }
    newCss.push(new CommandState(new Command('M', [css[0].getCommands()[0].start, targetCs.getCommands()[targetSplitIdx].end])));
    var _a = targetCs.slice(targetSplitIdx), left = _a.left, right = _a.right;
    if (right) {
        newCss.push(right);
    }
    for (var i = targetCsIdx + 1; i < css.length; i++) {
        newCss.push(css[i]);
    }
    for (var i = 1; i < targetCsIdx; i++) {
        newCss.push(css[i]);
    }
    newCss.push(left);
    return newCss;
}
/**
 * Returns a list of reversed and shifted commands.
 */
function reverseAndShiftCommands(subPathState) {
    return shiftCommands(subPathState, reverseCommands(subPathState));
}
/**
 * Returns a list of reversed commands.
 */
function reverseCommands(subPathState) {
    var _a;
    var subPathCss = subPathState.getCommandStates();
    var hasOneCmd = subPathCss.length === 1 && subPathCss[0].getCommands().length === 1;
    if (hasOneCmd || !subPathState.isReversed()) {
        // Nothing to do in these two cases.
        return _.flatMap(subPathCss, function (cm) { return cm.getCommands(); });
    }
    // Extract the commands from our command mutation map.
    var cmds = _.flatMap(subPathCss, function (cm) {
        // Consider a segment A ---- B ---- C with AB split and
        // BC non-split. When reversed, we want the user to see
        // C ---- B ---- A w/ CB split and BA non-split.
        var cmCmds = cm.getCommands().slice();
        if (cmCmds[0].type === 'M') {
            return cmCmds;
        }
        cmCmds[0] = cmCmds[0]
            .mutate()
            .toggleSplitPoint()
            .build();
        cmCmds[cmCmds.length - 1] = cmCmds[cmCmds.length - 1]
            .mutate()
            .toggleSplitPoint()
            .build();
        return cmCmds;
    });
    // If the last command is a 'Z', replace it with a line before we reverse.
    // TODO: replacing the 'Z' messes up certain stroke-linejoin values
    var lastCmd = _.last(cmds);
    if (lastCmd.type === 'Z') {
        cmds[cmds.length - 1] = (_a = lastCmd
            .mutate()
            .setSvgChar('L')).setPoints.apply(_a, lastCmd.points).build();
    }
    // Reverse the commands.
    var newCmds = [];
    for (var i = cmds.length - 1; i > 0; i--) {
        newCmds.push(cmds[i]
            .mutate()
            .reverse()
            .build());
    }
    newCmds.unshift(cmds[0]
        .mutate()
        .setPoints(cmds[0].start, newCmds[0].start)
        .build());
    return newCmds;
}
/**
 * Returns a list of shifted commands.
 */
function shiftCommands(subPathState, cmds) {
    var _a;
    var shiftOffset = subPathState.getShiftOffset();
    if (!shiftOffset ||
        cmds.length === 1 ||
        !MathUtil.arePointsEqual(_.first(cmds).end, _.last(cmds).end)) {
        // If there is no shift offset, the sub path is one command long,
        // or if the sub path is not closed, then do nothing.
        return cmds;
    }
    var numCommands = cmds.length;
    if (subPathState.isReversed()) {
        shiftOffset *= -1;
        shiftOffset += numCommands - 1;
    }
    // If the last command is a 'Z', replace it with a line before we shift.
    var lastCmd = _.last(cmds);
    if (lastCmd.type === 'Z') {
        // TODO: replacing the 'Z' messes up certain stroke-linejoin values
        cmds[numCommands - 1] = (_a = lastCmd
            .mutate()
            .setSvgChar('L')).setPoints.apply(_a, lastCmd.points).build();
    }
    var newCmds = [];
    // Handle these case separately cause they are annoying and I'm sick of edge cases.
    if (shiftOffset === 1) {
        newCmds.push(cmds[0]
            .mutate()
            .setPoints(cmds[0].start, cmds[1].end)
            .build());
        for (var i = 2; i < cmds.length; i++) {
            newCmds.push(cmds[i]);
        }
        newCmds.push(cmds[1]);
        return newCmds;
    }
    else if (shiftOffset === numCommands - 1) {
        newCmds.push(cmds[0]
            .mutate()
            .setPoints(cmds[0].start, cmds[numCommands - 2].end)
            .build());
        newCmds.push(_.last(cmds));
        for (var i = 1; i < cmds.length - 1; i++) {
            newCmds.push(cmds[i]);
        }
        return newCmds;
    }
    // Shift the sequence of commands. After the shift, the original move
    // command will be at index 'numCommands - shiftOffset'.
    for (var i = 0; i < numCommands; i++) {
        newCmds.push(cmds[(i + shiftOffset) % numCommands]);
    }
    // The first start point will either be undefined,
    // or the end point of the previous sub path.
    var prevMoveCmd = newCmds.splice(numCommands - shiftOffset, 1)[0];
    newCmds.push(newCmds.shift());
    newCmds.unshift(cmds[0]
        .mutate()
        .setPoints(prevMoveCmd.start, _.last(newCmds).end)
        .build());
    return newCmds;
}
function LOG() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (ENABLE_LOGS) {
        var obj = args[0], objs = args.slice(1);
        // tslint:disable-next-line: no-console
        console.info.apply(console, [obj].concat(objs));
    }
}
