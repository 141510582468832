// These names correspond to CSS class names declared in the root component.
export enum CursorType {
  Default = 'default',
  Pointer = 'pointer',
  PointSelect = 'point-select',
  Crosshair = 'crosshair',
  Pen = 'pen',
  PenAdd = 'pen-add',
  PenClose = 'pen-close',
  Pencil = 'pencil',
  Resize0 = 'resize0',
  Resize45 = 'resize45',
  Resize90 = 'resize90',
  Resize135 = 'resize135',
  Rotate0 = 'rotate0',
  Rotate45 = 'rotate45',
  Rotate90 = 'rotate90',
  Rotate135 = 'rotate135',
  Rotate180 = 'rotate180',
  Rotate225 = 'rotate225',
  Rotate270 = 'rotate270',
  Rotate315 = 'rotate315',
  ZoomIn = 'zoom-in',
  ZoomOut = 'zoom-out',
  Grab = 'grab',
  Grabbing = 'grabbing',
}
