import { Store } from 'app/modules/editor/store';
import { environment } from 'environments/environment';
import * as $ from 'jquery';
import { ActionCreators } from 'redux-undo';
import { Subject } from 'rxjs';
import { ActionModeService } from './actionmode.service';
import { LayerTimelineService } from './layertimeline.service';
import { PlaybackService } from './playback.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./actionmode.service";
import * as i3 from "./playback.service";
import * as i4 from "./layertimeline.service";
export var Shortcut;
(function (Shortcut) {
    Shortcut[Shortcut["ZoomToFit"] = 1] = "ZoomToFit";
})(Shortcut || (Shortcut = {}));
var ShortcutService = /** @class */ (function () {
    function ShortcutService(store, actionModeService, playbackService, layerTimelineService) {
        this.store = store;
        this.actionModeService = actionModeService;
        this.playbackService = playbackService;
        this.layerTimelineService = layerTimelineService;
        this.isInit = false;
        this.shortcutSubject = new Subject();
    }
    /** Returns true if the event is a modifier key (meta for Macs, ctrl for others). */
    ShortcutService.isOsDependentModifierKey = function (event) {
        return !!(ShortcutService.isMac() ? !!event.metaKey : !!event.ctrlKey);
    };
    ShortcutService.isMac = function () {
        return navigator.appVersion.includes('Mac');
    };
    ShortcutService.prototype.asObservable = function () {
        return this.shortcutSubject.asObservable();
    };
    ShortcutService.prototype.init = function () {
        var _this = this;
        if (this.isInit) {
            return;
        }
        this.isInit = true;
        $(window).on('keydown', function (event) {
            if (ShortcutService.isOsDependentModifierKey(event)) {
                if (event.keyCode === 'Z'.charCodeAt(0)) {
                    _this.store.dispatch(event.shiftKey ? ActionCreators.redo() : ActionCreators.undo());
                    return false;
                }
                if (event.keyCode === 'G'.charCodeAt(0)) {
                    _this.layerTimelineService.groupOrUngroupSelectedLayers(!event.shiftKey);
                    return false;
                }
                if (event.keyCode === 'O'.charCodeAt(0)) {
                    _this.shortcutSubject.next(Shortcut.ZoomToFit);
                    return false;
                }
            }
            if (event.ctrlKey || event.metaKey) {
                // Do nothing if the ctrl or meta keys are pressed.
                return undefined;
            }
            if (document.activeElement.matches('input')) {
                // Ignore shortcuts when an input element has focus.
                return true;
            }
            if (event.keyCode === 8 || event.keyCode === 46) {
                // Backspace or delete.
                var isActionMode = _this.actionModeService.isActionMode();
                // If we aren't in beta or it is action mode, handle the backspace/delete
                // event here. Otherwise we will handle it in the gesture tool (which is
                // where we will likely want to move all of the shortcut logic in the future).
                if (!environment.beta || isActionMode) {
                    // In case there's a JS error, never navigate away.
                    event.preventDefault();
                    if (isActionMode) {
                        _this.actionModeService.deleteSelectedActionModeModels();
                    }
                    else {
                        _this.layerTimelineService.deleteSelectedModels();
                    }
                    return false;
                }
            }
            if (event.keyCode === 27) {
                // Escape.
                _this.actionModeService.closeActionMode();
                return false;
            }
            // TODO: figure out how to re-enable this keyboard shortcut in beta
            if (!environment.beta && event.keyCode === 32) {
                // Spacebar.
                _this.playbackService.toggleIsPlaying();
                return false;
            }
            if (event.keyCode === 37) {
                // Left arrow.
                _this.playbackService.rewind();
                return false;
            }
            if (event.keyCode === 39) {
                // Right arrow.
                _this.playbackService.fastForward();
                return false;
            }
            if (event.keyCode === 'R'.charCodeAt(0)) {
                if (_this.actionModeService.isShowingSubPathActionMode()) {
                    _this.actionModeService.reverseSelectedSubPaths();
                }
                else {
                    _this.playbackService.toggleIsRepeating();
                }
                return false;
            }
            if (event.keyCode === 'S'.charCodeAt(0)) {
                if (_this.actionModeService.isShowingSubPathActionMode() ||
                    _this.actionModeService.isShowingSegmentActionMode()) {
                    _this.actionModeService.toggleSplitSubPathsMode();
                }
                else {
                    _this.playbackService.toggleIsSlowMotion();
                }
                return false;
            }
            if (event.keyCode === 'A'.charCodeAt(0)) {
                if (_this.actionModeService.isShowingSubPathActionMode() ||
                    _this.actionModeService.isShowingSegmentActionMode()) {
                    _this.actionModeService.toggleSplitCommandsMode();
                }
                else if (_this.actionModeService.isShowingPointActionMode()) {
                    _this.actionModeService.splitSelectedPointInHalf();
                }
                return false;
            }
            if (event.keyCode === 'D'.charCodeAt(0)) {
                if (_this.actionModeService.isShowingSubPathActionMode() ||
                    _this.actionModeService.isShowingSegmentActionMode()) {
                    _this.actionModeService.togglePairSubPathsMode();
                }
                return false;
            }
            if (event.keyCode === 'B'.charCodeAt(0)) {
                if (_this.actionModeService.isShowingSubPathActionMode()) {
                    _this.actionModeService.shiftBackSelectedSubPaths();
                }
                return false;
            }
            if (event.keyCode === 'F'.charCodeAt(0)) {
                if (_this.actionModeService.isShowingSubPathActionMode()) {
                    _this.actionModeService.shiftForwardSelectedSubPaths();
                }
                else if (_this.actionModeService.isShowingPointActionMode()) {
                    _this.actionModeService.shiftPointToFront();
                }
                return false;
            }
            return undefined;
        });
    };
    ShortcutService.prototype.destroy = function () {
        if (!this.isInit) {
            return;
        }
        this.isInit = false;
        $(window).unbind('keydown');
    };
    ShortcutService.prototype.getZoomToFitText = function () {
        return this.getCmdOrCtrlText() + " + O";
    };
    ShortcutService.prototype.getCmdOrCtrlText = function () {
        return ShortcutService.isMac() ? 'Cmd' : 'Ctrl';
    };
    ShortcutService.ngInjectableDef = i0.defineInjectable({ factory: function ShortcutService_Factory() { return new ShortcutService(i0.inject(i1.Store), i0.inject(i2.ActionModeService), i0.inject(i3.PlaybackService), i0.inject(i4.LayerTimelineService)); }, token: ShortcutService, providedIn: "root" });
    return ShortcutService;
}());
export { ShortcutService };
