import * as tslib_1 from "tslib";
import { ElementRef } from '@angular/core';
import * as $ from 'jquery';
import { CanvasLayoutMixin } from './CanvasLayoutMixin';
/**
 * Directive that resizes the canvas container when necessary.
 */
var CanvasContainerDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CanvasContainerDirective, _super);
    function CanvasContainerDirective(elementRef) {
        var _this = _super.call(this) || this;
        _this.element = $(elementRef.nativeElement);
        return _this;
    }
    // @Override
    CanvasContainerDirective.prototype.onDimensionsChanged = function (bounds, viewport) {
        var w = viewport.w, h = viewport.h;
        this.element.attr({ width: w * this.attrScale, height: h * this.attrScale });
        this.element.css({ width: w * this.cssScale, height: h * this.cssScale });
    };
    return CanvasContainerDirective;
}(CanvasLayoutMixin()));
export { CanvasContainerDirective };
