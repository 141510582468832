import * as tslib_1 from "tslib";
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { PaperUtil } from 'app/modules/editor/scripts/paper/util';
import * as paper from 'paper';
/**
 * A gesture that exits default mode and enters edit path mode.
 *
 * Preconditions:
 * - The user is in default mode.
 */
var EditPathGesture = /** @class */ (function (_super) {
    tslib_1.__extends(EditPathGesture, _super);
    function EditPathGesture(ps, editPathId) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.editPathId = editPathId;
        _this.pl = paper.project.activeLayer;
        return _this;
    }
    // @Override
    EditPathGesture.prototype.onMouseDown = function (event) {
        var editPath = this.pl.findItemByLayerId(this.editPathId);
        this.ps.setSelectedLayerIds(new Set([this.editPathId]));
        this.ps.setEditPathInfo(tslib_1.__assign({}, PaperUtil.selectCurves(editPath, new Set([editPath.segments.length - 1]))));
        this.ps.setRotateItemsInfo(undefined);
        this.ps.setTransformPathsInfo(undefined);
    };
    return EditPathGesture;
}(Gesture));
export { EditPathGesture };
