import * as tslib_1 from "tslib";
import { ColorProperty, EnumProperty, FractionProperty, NameProperty, NumberProperty, PathProperty, Property, } from 'app/modules/editor/model/properties';
import { MathUtil, Matrix } from 'app/modules/editor/scripts/common';
import * as _ from 'lodash';
/**
 * Interface that is shared by all vector drawable layer models below.
 */
var Layer = /** @class */ (function () {
    function Layer(obj) {
        this.id = obj.id || _.uniqueId();
        this.name = obj.name || '';
        this.children = (obj.children || []).map(function (child) { return load(child); });
    }
    /**
     * Returns the first descendent layer with the specified id.
     */
    Layer.prototype.findLayerById = function (id) {
        if (this.id === id) {
            return this;
        }
        for (var _i = 0, _a = this.children; _i < _a.length; _i++) {
            var child = _a[_i];
            var layer = child.findLayerById(id);
            if (layer) {
                return layer;
            }
        }
        return undefined;
    };
    /**
     * Returns the first descendent layer with the specified name.
     */
    Layer.prototype.findLayerByName = function (name) {
        if (this.name === name) {
            return this;
        }
        for (var _i = 0, _a = this.children; _i < _a.length; _i++) {
            var child = _a[_i];
            var layer = child.findLayerByName(name);
            if (layer) {
                return layer;
            }
        }
        return undefined;
    };
    /**
     * Walks the layer tree, executing beforeFunc on each node using a
     * preorder traversal.
     */
    Layer.prototype.walk = function (beforeFn) {
        var visitFn = function (layer) {
            beforeFn(layer);
            layer.children.forEach(function (l) { return visitFn(l); });
        };
        visitFn(this);
    };
    /**
     * Returns the JSON representation of this layer.
     */
    Layer.prototype.toJSON = function () {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
        };
    };
    Layer = tslib_1.__decorate([
        Property.register(new NameProperty('name')),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], Layer);
    return Layer;
}());
export { Layer };
function load(obj) {
    if (obj instanceof Layer) {
        return obj;
    }
    if (obj.type === 'vector') {
        return new VectorLayer(obj);
    }
    if (obj.type === 'group') {
        return new GroupLayer(obj);
    }
    if (obj.type === 'path') {
        return new PathLayer(obj);
    }
    if (obj.type === 'mask') {
        return new ClipPathLayer(obj);
    }
    console.error('Attempt to load layer with invalid object: ', obj);
    throw new Error('Attempt to load layer with invalid object');
}
var VECTOR_DEFAULTS = {
    canvasColor: '',
    alpha: 1,
};
/**
 * Model object that mirrors the VectorDrawable's '<vector>' element.
 */
var VectorLayer = /** @class */ (function (_super) {
    tslib_1.__extends(VectorLayer, _super);
    function VectorLayer(obj) {
        if (obj === void 0) { obj = { children: [], name: 'vector' }; }
        var _this = _super.call(this, obj) || this;
        // @Override
        _this.type = 'vector';
        var setterFn = function (num, def) { return (_.isNil(num) ? def : num); };
        _this.canvasColor = obj.canvasColor || VECTOR_DEFAULTS.canvasColor;
        _this.width = setterFn(obj.width, 24);
        _this.height = setterFn(obj.height, 24);
        _this.alpha = setterFn(obj.alpha, VECTOR_DEFAULTS.alpha);
        return _this;
    }
    VectorLayer_1 = VectorLayer;
    Object.defineProperty(VectorLayer.prototype, "bounds", {
        // @Override
        get: function () {
            return { l: 0, t: 0, r: this.width, b: this.height };
        },
        enumerable: true,
        configurable: true
    });
    // @Override
    VectorLayer.prototype.clone = function () {
        var clone = new VectorLayer_1(this);
        clone.children = this.children.slice();
        return clone;
    };
    // @Override
    VectorLayer.prototype.deepClone = function () {
        var clone = this.clone();
        clone.children = this.children.map(function (c) { return c.deepClone(); });
        return clone;
    };
    // @Override
    VectorLayer.prototype.toJSON = function () {
        var obj = Object.assign(_super.prototype.toJSON.call(this), {
            canvasColor: this.canvasColor,
            width: this.width,
            height: this.height,
            alpha: this.alpha,
            children: this.children.map(function (child) { return child.toJSON(); }),
        });
        Object.entries(VECTOR_DEFAULTS).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (obj[key] === value) {
                delete obj[key];
            }
        });
        return obj;
    };
    var VectorLayer_1;
    VectorLayer = VectorLayer_1 = tslib_1.__decorate([
        Property.register(new ColorProperty('canvasColor'), new NumberProperty('width', { isAnimatable: false, min: 1, isInteger: true }), new NumberProperty('height', { isAnimatable: false, min: 1, isInteger: true }), new FractionProperty('alpha', { isAnimatable: true })),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], VectorLayer);
    return VectorLayer;
}(Layer));
export { VectorLayer };
var GROUP_DEFAULTS = {
    rotation: 0,
    scaleX: 1,
    scaleY: 1,
    pivotX: 0,
    pivotY: 0,
    translateX: 0,
    translateY: 0,
};
/**
 * Model object that mirrors the VectorDrawable's '<group>' element.
 */
var GroupLayer = /** @class */ (function (_super) {
    tslib_1.__extends(GroupLayer, _super);
    function GroupLayer(obj) {
        var _this = _super.call(this, obj) || this;
        // @Override
        _this.type = 'group';
        var setterFn = function (num, def) { return (_.isNil(num) ? def : num); };
        _this.pivotX = setterFn(obj.pivotX, GROUP_DEFAULTS.pivotX);
        _this.pivotY = setterFn(obj.pivotY, GROUP_DEFAULTS.pivotY);
        _this.rotation = setterFn(obj.rotation, GROUP_DEFAULTS.rotation);
        _this.scaleX = setterFn(obj.scaleX, GROUP_DEFAULTS.scaleX);
        _this.scaleY = setterFn(obj.scaleY, GROUP_DEFAULTS.scaleY);
        _this.translateX = setterFn(obj.translateX, GROUP_DEFAULTS.translateX);
        _this.translateY = setterFn(obj.translateY, GROUP_DEFAULTS.translateY);
        return _this;
    }
    GroupLayer_1 = GroupLayer;
    Object.defineProperty(GroupLayer.prototype, "bounds", {
        // @Override
        get: function () {
            var bounds;
            this.children.forEach(function (child) {
                var childBounds = child.bounds;
                if (!childBounds) {
                    return;
                }
                if (bounds) {
                    bounds.l = Math.min(childBounds.l, bounds.l);
                    bounds.t = Math.min(childBounds.t, bounds.t);
                    bounds.r = Math.max(childBounds.r, bounds.r);
                    bounds.b = Math.max(childBounds.b, bounds.b);
                }
                else {
                    bounds = tslib_1.__assign({}, childBounds);
                }
            });
            if (!bounds) {
                return undefined;
            }
            bounds.l -= this.pivotX;
            bounds.t -= this.pivotY;
            bounds.r -= this.pivotX;
            bounds.b -= this.pivotY;
            var transforms = [
                Matrix.scaling(this.scaleX, this.scaleY),
                Matrix.rotation(this.rotation),
                Matrix.translation(this.translateX, this.translateY),
            ];
            var topLeft = MathUtil.transformPoint.apply(MathUtil, [{ x: bounds.l, y: bounds.t }].concat(transforms));
            var bottomRight = MathUtil.transformPoint.apply(MathUtil, [{ x: bounds.r, y: bounds.b }].concat(transforms));
            return {
                l: topLeft.x + this.pivotX,
                t: topLeft.y + this.pivotY,
                r: bottomRight.x + this.pivotX,
                b: bottomRight.y + this.pivotY,
            };
        },
        enumerable: true,
        configurable: true
    });
    // @Override
    GroupLayer.prototype.clone = function () {
        var clone = new GroupLayer_1(this);
        clone.children = this.children.slice();
        return clone;
    };
    // @Override
    GroupLayer.prototype.deepClone = function () {
        var clone = this.clone();
        clone.children = this.children.map(function (c) { return c.deepClone(); });
        return clone;
    };
    // @Override
    GroupLayer.prototype.toJSON = function () {
        var obj = Object.assign(_super.prototype.toJSON.call(this), {
            rotation: this.rotation,
            scaleX: this.scaleX,
            scaleY: this.scaleY,
            pivotX: this.pivotX,
            pivotY: this.pivotY,
            translateX: this.translateX,
            translateY: this.translateY,
            children: this.children.map(function (child) { return child.toJSON(); }),
        });
        Object.entries(GROUP_DEFAULTS).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (obj[key] === value) {
                delete obj[key];
            }
        });
        return obj;
    };
    var GroupLayer_1;
    GroupLayer = GroupLayer_1 = tslib_1.__decorate([
        Property.register(new NumberProperty('rotation', { isAnimatable: true }), new NumberProperty('scaleX', { isAnimatable: true }), new NumberProperty('scaleY', { isAnimatable: true }), new NumberProperty('pivotX', { isAnimatable: true }), new NumberProperty('pivotY', { isAnimatable: true }), new NumberProperty('translateX', { isAnimatable: true }), new NumberProperty('translateY', { isAnimatable: true })),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], GroupLayer);
    return GroupLayer;
}(Layer));
export { GroupLayer };
/**
 * Model object that mirrors the VectorDrawable's '<clip-path>' element.
 */
var ClipPathLayer = /** @class */ (function (_super) {
    tslib_1.__extends(ClipPathLayer, _super);
    function ClipPathLayer(obj) {
        var _this = _super.call(this, obj) || this;
        // @Override
        _this.type = 'mask';
        _this.pathData = obj.pathData;
        return _this;
    }
    ClipPathLayer_1 = ClipPathLayer;
    Object.defineProperty(ClipPathLayer.prototype, "bounds", {
        // @Override
        get: function () {
            return this.pathData ? this.pathData.getBoundingBox() : undefined;
        },
        enumerable: true,
        configurable: true
    });
    // @Override
    ClipPathLayer.prototype.clone = function () {
        return new ClipPathLayer_1(this);
    };
    // @Override
    ClipPathLayer.prototype.deepClone = function () {
        return this.clone();
    };
    // @Override
    ClipPathLayer.prototype.toJSON = function () {
        return Object.assign(_super.prototype.toJSON.call(this), {
            pathData: this.pathData ? this.pathData.getPathString() : '',
        });
    };
    ClipPathLayer.prototype.isStroked = function () {
        // TODO: this may be the case for Android... but does this limit what web/iOS devs can do?
        return false;
    };
    ClipPathLayer.prototype.isFilled = function () {
        return true;
    };
    var ClipPathLayer_1;
    ClipPathLayer = ClipPathLayer_1 = tslib_1.__decorate([
        Property.register(new PathProperty('pathData', { isAnimatable: true })),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], ClipPathLayer);
    return ClipPathLayer;
}(Layer));
export { ClipPathLayer };
var ENUM_LINECAP_OPTIONS = [
    { value: 'butt', label: 'Butt' },
    { value: 'square', label: 'Square' },
    { value: 'round', label: 'Round' },
];
var ENUM_LINEJOIN_OPTIONS = [
    { value: 'miter', label: 'Miter' },
    { value: 'round', label: 'Round' },
    { value: 'bevel', label: 'Bevel' },
];
var ENUM_FILLTYPE_OPTIONS = [
    { value: 'nonZero', label: 'nonZero' },
    { value: 'evenOdd', label: 'evenOdd' },
];
var PATH_DEFAULTS = {
    fillColor: '',
    fillAlpha: 1,
    strokeColor: '',
    strokeAlpha: 1,
    strokeWidth: 0,
    strokeLinecap: 'butt',
    strokeLinejoin: 'miter',
    strokeMiterLimit: 4,
    trimPathStart: 0,
    trimPathEnd: 1,
    trimPathOffset: 0,
    fillType: 'nonZero',
};
/**
 * Model object that mirrors the VectorDrawable's '<path>' element.
 */
var PathLayer = /** @class */ (function (_super) {
    tslib_1.__extends(PathLayer, _super);
    function PathLayer(obj) {
        var _this = _super.call(this, obj) || this;
        // @Override
        _this.type = 'path';
        var setterFn = function (num, def) { return (_.isNil(num) ? def : num); };
        _this.pathData = obj.pathData;
        _this.fillColor = obj.fillColor || PATH_DEFAULTS.fillColor;
        _this.fillAlpha = setterFn(obj.fillAlpha, PATH_DEFAULTS.fillAlpha);
        _this.strokeColor = obj.strokeColor || PATH_DEFAULTS.strokeColor;
        _this.strokeAlpha = setterFn(obj.strokeAlpha, PATH_DEFAULTS.strokeAlpha);
        _this.strokeWidth = setterFn(obj.strokeWidth, PATH_DEFAULTS.strokeWidth);
        _this.strokeLinecap = obj.strokeLinecap || PATH_DEFAULTS.strokeLinecap;
        _this.strokeLinejoin = obj.strokeLinejoin || PATH_DEFAULTS.strokeLinejoin;
        _this.strokeMiterLimit = setterFn(obj.strokeMiterLimit, PATH_DEFAULTS.strokeMiterLimit);
        _this.trimPathStart = setterFn(obj.trimPathStart, PATH_DEFAULTS.trimPathStart);
        _this.trimPathEnd = setterFn(obj.trimPathEnd, PATH_DEFAULTS.trimPathEnd);
        _this.trimPathOffset = setterFn(obj.trimPathOffset, PATH_DEFAULTS.trimPathOffset);
        _this.fillType = obj.fillType || PATH_DEFAULTS.fillType;
        return _this;
    }
    PathLayer_1 = PathLayer;
    Object.defineProperty(PathLayer.prototype, "bounds", {
        // @Override
        get: function () {
            return this.pathData ? this.pathData.getBoundingBox() : undefined;
        },
        enumerable: true,
        configurable: true
    });
    // @Override
    PathLayer.prototype.clone = function () {
        return new PathLayer_1(this);
    };
    // @Override
    PathLayer.prototype.deepClone = function () {
        return this.clone();
    };
    // @Override
    PathLayer.prototype.toJSON = function () {
        var obj = Object.assign(_super.prototype.toJSON.call(this), {
            pathData: this.pathData ? this.pathData.getPathString() : '',
            fillColor: this.fillColor,
            fillAlpha: this.fillAlpha,
            strokeColor: this.strokeColor,
            strokeAlpha: this.strokeAlpha,
            strokeWidth: this.strokeWidth,
            strokeLinecap: this.strokeLinecap,
            strokeLinejoin: this.strokeLinejoin,
            strokeMiterLimit: this.strokeMiterLimit,
            trimPathStart: this.trimPathStart,
            trimPathEnd: this.trimPathEnd,
            trimPathOffset: this.trimPathOffset,
            fillType: this.fillType,
        });
        Object.entries(PATH_DEFAULTS).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (obj[key] === value) {
                delete obj[key];
            }
        });
        return obj;
    };
    PathLayer.prototype.isStroked = function () {
        return !!this.strokeColor;
    };
    PathLayer.prototype.isFilled = function () {
        return !!this.fillColor;
    };
    var PathLayer_1;
    PathLayer = PathLayer_1 = tslib_1.__decorate([
        Property.register(new PathProperty('pathData', { isAnimatable: true }), new ColorProperty('fillColor', { isAnimatable: true }), new FractionProperty('fillAlpha', { isAnimatable: true }), new ColorProperty('strokeColor', { isAnimatable: true }), new FractionProperty('strokeAlpha', { isAnimatable: true }), new NumberProperty('strokeWidth', { min: 0, isAnimatable: true }), new EnumProperty('strokeLinecap', ENUM_LINECAP_OPTIONS), new EnumProperty('strokeLinejoin', ENUM_LINEJOIN_OPTIONS), new NumberProperty('strokeMiterLimit', { min: 1 }), new FractionProperty('trimPathStart', { isAnimatable: true }), new FractionProperty('trimPathEnd', { isAnimatable: true }), new FractionProperty('trimPathOffset', { isAnimatable: true }), new EnumProperty('fillType', ENUM_FILLTYPE_OPTIONS)) // TODO: need to fix enum properties so they store/return strings instead of options?
        ,
        tslib_1.__metadata("design:paramtypes", [Object])
    ], PathLayer);
    return PathLayer;
}(Layer));
export { PathLayer };
