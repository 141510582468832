/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./splitter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./splitter.component";
var styles_SplitterComponent = [i0.styles];
var RenderType_SplitterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SplitterComponent, data: {} });
export { RenderType_SplitterComponent as RenderType_SplitterComponent };
export function View_SplitterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_SplitterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-splitter", [], [[2, "splt-horizontal", null], [2, "splt-vertical", null], [2, "splt-edge-left", null], [2, "splt-edge-right", null], [4, "backgroundColor", null]], [[null, "mousedown"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_SplitterComponent_0, RenderType_SplitterComponent)), i1.ɵdid(1, 114688, null, 0, i2.SplitterComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).spltHorizontal; var currVal_1 = i1.ɵnov(_v, 1).spltVertical; var currVal_2 = i1.ɵnov(_v, 1).spltEdgeLeft; var currVal_3 = i1.ɵnov(_v, 1).spltEdgeRight; var currVal_4 = i1.ɵnov(_v, 1).backgroundColor; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
var SplitterComponentNgFactory = i1.ɵccf("app-splitter", i2.SplitterComponent, View_SplitterComponent_Host_0, { edge: "edge", min: "min", persistId: "persistId" }, { split: "split" }, []);
export { SplitterComponentNgFactory as SplitterComponentNgFactory };
