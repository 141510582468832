import * as tslib_1 from "tslib";
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { PaperUtil } from 'app/modules/editor/scripts/paper/util';
import * as paper from 'paper';
/**
 * A gesture that performs selection and drag operations on a segment handle.
 *
 * Preconditions:
 * - The user is in edit path mode.
 * - The user hit a segment handle.
 */
var SelectDragHandleGesture = /** @class */ (function (_super) {
    tslib_1.__extends(SelectDragHandleGesture, _super);
    function SelectDragHandleGesture(ps, editPathId, hitSegmentIndex, hitResultType) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.editPathId = editPathId;
        _this.hitSegmentIndex = hitSegmentIndex;
        _this.pl = paper.project.activeLayer;
        _this.hitHandleType = hitResultType === 'handle-in' ? 'handleIn' : 'handleOut';
        return _this;
    }
    // @Override
    SelectDragHandleGesture.prototype.onMouseDown = function (event) {
        // Deselect all currently selected segments.
        var selectedSegments = new Set();
        var selectedHandleIn = this.hitHandleType === 'handleIn' ? this.hitSegmentIndex : undefined;
        var selectedHandleOut = this.hitHandleType === 'handleOut' ? this.hitSegmentIndex : undefined;
        this.ps.setEditPathInfo(tslib_1.__assign({}, this.ps.getEditPathInfo(), { selectedSegments: selectedSegments,
            selectedHandleIn: selectedHandleIn,
            selectedHandleOut: selectedHandleOut }));
        var editPath = this.pl.findItemByLayerId(this.editPathId);
        this.localDownHandle = editPath.segments[this.hitSegmentIndex][this.hitHandleType].clone();
    };
    // @Override
    SelectDragHandleGesture.prototype.onMouseDrag = function (event) {
        var editPath = this.pl.findItemByLayerId(this.editPathId);
        this.localLastDragInfo = {
            localDownPoint: editPath.globalToLocal(event.downPoint),
            localPoint: editPath.globalToLocal(event.point),
        };
        this.processEvent(event);
    };
    // @Override
    SelectDragHandleGesture.prototype.onKeyDown = function (event) {
        this.processKeyEvent(event);
    };
    // @Override
    SelectDragHandleGesture.prototype.onKeyUp = function (event) {
        this.processKeyEvent(event);
    };
    // TODO: react to 'escape' key presses
    SelectDragHandleGesture.prototype.processKeyEvent = function (event) {
        if (event.key === 'shift') {
            this.processEvent(event);
        }
    };
    SelectDragHandleGesture.prototype.processEvent = function (event) {
        if (!this.localLastDragInfo) {
            return;
        }
        // TODO: add 'straight', 'mirrored', 'disconnected', and 'asymmetric' modes (similar to Sketch)
        var _a = this.localLastDragInfo, localDownPoint = _a.localDownPoint, localPoint = _a.localPoint;
        var localHandle = this.localDownHandle.add(localPoint.subtract(localDownPoint));
        if (event.modifiers.shift) {
            // Project the handle onto the handle's original vector.
            var theta = (-(this.localDownHandle.angle - localHandle.angle) * Math.PI) / 180;
            localHandle.set(this.localDownHandle.normalize().multiply(localHandle.length * Math.cos(theta)));
        }
        var editPath = this.pl.findItemByLayerId(this.editPathId);
        editPath.segments[this.hitSegmentIndex][this.hitHandleType] = localHandle;
        PaperUtil.replacePathInStore(this.ps, this.editPathId, editPath.pathData);
    };
    return SelectDragHandleGesture;
}(Gesture));
export { SelectDragHandleGesture };
