import { MathUtil } from 'app/modules/editor/scripts/common';
import * as _ from 'lodash';
import polylabel from 'polylabel';
import { CommandState } from './CommandState';
import * as PathParser from './PathParser';
import { createSubPaths } from './SubPath';
import { SubPathState, findSubPathState } from './SubPathState';
/**
 * Container class that encapsulates a Path's underlying state.
 */
var PathState = /** @class */ (function () {
    function PathState(obj, 
    // Maps internal spsIdx indices to SubPathState objects. The last 'numCollapsingSubPaths'
    // indices hold references to the collapsing sub paths.
    subPathStateMap, 
    // Maps client-visible subIdx values to their positions in the subPathStateMap.
    subPathOrdering, 
    // The number of collapsing subpaths appended to the end of the subPathStateMap.
    numCollapsingSubPaths) {
        if (numCollapsingSubPaths === void 0) { numCollapsingSubPaths = 0; }
        var _this = this;
        this.subPathStateMap = subPathStateMap;
        this.subPathOrdering = subPathOrdering;
        this.numCollapsingSubPaths = numCollapsingSubPaths;
        var commands = typeof obj === 'string' ? PathParser.parseCommands(obj) : obj;
        var subPaths = createSubPaths(commands);
        this.subPathStateMap =
            subPathStateMap ||
                subPaths.map(function (s) { return new SubPathState(s.getCommands().map(function (c) { return new CommandState(c); })); });
        this.subPathOrdering = subPathOrdering || subPaths.map(function (unused, i) { return i; });
        this.subPaths = subPaths.map(function (subPath, subIdx) {
            var cmds = subPath.getCommands().map(function (cmd, cmdIdx) {
                var _a = _this.findCommandStateInfo(subIdx, cmdIdx), cs = _a.cs, splitIdx = _a.splitIdx;
                return cmd
                    .mutate()
                    .setId(cs.getIdAtIndex(splitIdx))
                    .build();
            });
            var spsIdx = _this.subPathOrdering[subIdx];
            var isCollapsing = _this.subPathOrdering.length - _this.numCollapsingSubPaths <= spsIdx;
            var sps = _this.findSubPathState(subIdx);
            var isSplit = isSubPathSplit(_this.subPathStateMap, spsIdx);
            var isUnsplittable = !_this.subPathStateMap.includes(sps);
            return subPath
                .mutate()
                .setId(sps.getId())
                .setCommands(cmds)
                .setIsCollapsing(isCollapsing)
                .setIsReversed(sps.isReversed())
                .setShiftOffset(sps.getShiftOffset())
                .setIsSplit(isSplit)
                .setIsUnsplittable(isUnsplittable)
                .build();
        });
        this.commands = _.flatMap(this.subPaths, function (subPath) { return subPath.getCommands(); });
    }
    PathState.prototype.getPathLength = function () {
        var length = 0;
        for (var i = 0; i < this.subPathStateMap.length; i++) {
            length += this.getSubPathLength(i);
        }
        return length;
    };
    PathState.prototype.getSubPathLength = function (subIdx) {
        var sps = this.findSubPathState(subIdx);
        return _.sumBy(sps.getCommandStates(), function (cs) { return cs.getPathLength(); });
    };
    PathState.prototype.getPointAtLength = function (distance) {
        var _this = this;
        var spss = this.subPathStateMap.map(function (unused, i) { return _this.findSubPathState(i); });
        var length = 0;
        for (var _i = 0, spss_1 = spss; _i < spss_1.length; _i++) {
            var sps = spss_1[_i];
            for (var _a = 0, _b = sps.getCommandStates(); _a < _b.length; _a++) {
                var cs = _b[_a];
                var len = cs.getPathLength();
                if (length <= distance && distance < length + len) {
                    return cs.getPointAtLength(distance - length);
                }
                length += len;
            }
        }
        return undefined;
    };
    PathState.prototype.project = function (point, restrictToSubIdx) {
        var _this = this;
        var minProjectionResultInfo = _(this.subPaths)
            .map(function (subPath, subIdx) { return ({ subPath: subPath, subIdx: subIdx }); })
            .filter(function (_a) {
            var subPath = _a.subPath, subIdx = _a.subIdx;
            return !subPath.isCollapsing() &&
                (restrictToSubIdx === undefined || restrictToSubIdx === subIdx);
        })
            .map(function (obj) {
            var subIdx = obj.subIdx;
            var sps = _this.findSubPathState(subIdx);
            return sps.getCommandStates().map(function (cs, csIdx) {
                var csProjection = cs.project(point);
                if (csProjection && sps.isReversed()) {
                    var t = csProjection.projection.t;
                    csProjection.projection.t = 1 - t;
                }
                return {
                    spsIdx: _this.subPathOrdering[subIdx],
                    csIdx: csIdx,
                    splitIdx: csProjection ? csProjection.splitIdx : 0,
                    projection: csProjection ? csProjection.projection : undefined,
                };
            });
        })
            .flatMap(function (projections) { return projections; })
            .filter(function (obj) { return !!obj.projection; })
            // Reverse so that commands drawn with higher z-orders are preferred.
            .reverse()
            .reduce(function (prev, curr) {
            return prev && prev.projection.d < curr.projection.d ? prev : curr;
        }, undefined);
        if (!minProjectionResultInfo) {
            return undefined;
        }
        var spsIdx = minProjectionResultInfo.spsIdx, splitIdx = minProjectionResultInfo.splitIdx, projection = minProjectionResultInfo.projection;
        var cmdIdx = this.toCmdIdx(spsIdx, minProjectionResultInfo.csIdx, splitIdx);
        return { projection: projection, subIdx: this.subPathOrdering.indexOf(spsIdx), cmdIdx: cmdIdx };
    };
    PathState.prototype.hitTest = function (point, opts) {
        var _this = this;
        if (opts === void 0) { opts = {}; }
        var endPointHits = [];
        var segmentHits = [];
        var shapeHits = [];
        var defaultRestrictToSubIdx = this.subPaths.map(function (unused, i) { return i; });
        var restrictToSubIdxSet = new Set(opts.restrictToSubIdx || defaultRestrictToSubIdx);
        if (opts.isPointInRangeFn) {
            endPointHits.push.apply(endPointHits, _(this.subPaths)
                .map(function (subPath, subIdx) { return ({ subPath: subPath, subIdx: subIdx }); })
                .filter(function (obj) {
                var subPath = obj.subPath, subIdx = obj.subIdx;
                return !subPath.isCollapsing() && restrictToSubIdxSet.has(subIdx);
            })
                .map(function (obj) {
                var subPath = obj.subPath, subIdx = obj.subIdx;
                return subPath.getCommands().map(function (cmd, cmdIdx) {
                    var _a = cmd.end, x = _a.x, y = _a.y;
                    var d = MathUtil.distance(cmd.end, point);
                    var t = 1;
                    var projection = { x: x, y: y, d: d, t: t };
                    return { subIdx: subIdx, cmdIdx: cmdIdx, projection: projection, cmd: cmd };
                });
            })
                .flatMap(function (pointInfos) { return pointInfos; })
                .filter(function (pointInfo) { return opts.isPointInRangeFn(pointInfo.projection.d, pointInfo.cmd); })
                .map(function (pointInfo) {
                var subIdx = pointInfo.subIdx, cmdIdx = pointInfo.cmdIdx, projection = pointInfo.projection;
                return { subIdx: subIdx, cmdIdx: cmdIdx, projection: projection };
            })
                .value());
        }
        if (opts.isSegmentInRangeFn) {
            // TODO: also check to see if the hit occurred at a stroke-linejoin vertex
            // TODO: take stroke width scaling into account as well?
            segmentHits.push.apply(segmentHits, _(this.subPaths)
                .map(function (subPath, subIdx) { return ({ subPath: subPath, subIdx: subIdx }); })
                .filter(function (obj) {
                var subPath = obj.subPath, subIdx = obj.subIdx;
                return !subPath.isCollapsing() && restrictToSubIdxSet.has(subIdx);
            })
                .flatMap(function (obj) {
                var subIdx = obj.subIdx;
                var spsIdx = _this.subPathOrdering[subIdx];
                var sps = _this.findSubPathState(subIdx);
                // We iterate by csIdx here to improve performance (since cmdIdx
                // values can be split points).
                return _.flatMap(sps.getCommandStates(), function (cs, csIdx) {
                    var projectionWithSplitIdx = cs.project(point);
                    if (!projectionWithSplitIdx) {
                        return [];
                    }
                    var projection = projectionWithSplitIdx.projection, splitIdx = projectionWithSplitIdx.splitIdx;
                    if (sps.isReversed()) {
                        projection.t = 1 - projection.t;
                    }
                    var cmdIdx = _this.toCmdIdx(spsIdx, csIdx, splitIdx);
                    return [{ subIdx: subIdx, cmdIdx: cmdIdx, projection: projection }];
                });
            })
                .filter(function (obj) {
                var cmd = _this.subPaths[obj.subIdx].getCommands()[obj.cmdIdx];
                return opts.isSegmentInRangeFn(obj.projection.d, cmd);
            })
                .value());
        }
        if (opts.findShapesInRange) {
            shapeHits.push.apply(shapeHits, _(this.subPaths)
                .map(function (subPath, subIdx) { return ({ subPath: subPath, subIdx: subIdx }); })
                .filter(function (obj) {
                var subPath = obj.subPath, subIdx = obj.subIdx;
                return subPath.isClosed() && !subPath.isCollapsing() && restrictToSubIdxSet.has(subIdx);
            })
                .flatMap(function (obj) {
                var subIdx = obj.subIdx;
                var css = _this.findSubPathState(subIdx).getCommandStates();
                var bounds = createBoundingBox(css);
                if (!containsPoint(bounds, point)) {
                    // Nothing to see here. Check the next subpath.
                    return [];
                }
                // The point is inside the subpath's bounding box, so next, we will
                // use the 'even-odd rule' to determine if the filled path has been hit.
                // We create a line from the mouse point to a point we know that is not
                // inside the path (in this case, we use a coordinate outside the path's
                // bounded box). A hit has occured if and only if the number of
                // intersections between the line and the path is odd.
                var line = { p1: point, p2: { x: bounds.r + 1, y: bounds.b + 1 } };
                var intersectionResults = css.map(function (cs) { return cs.intersects(line); });
                var numIntersections = _.sumBy(intersectionResults, function (ts) { return ts.length; });
                if (numIntersections % 2 === 0) {
                    // Nothing to see here. Check the next subpath.
                    return [];
                }
                return [{ subIdx: subIdx }];
            })
                .value());
        }
        var isEndPointHit = !!endPointHits.length;
        var isSegmentHit = !!segmentHits.length;
        var isShapeHit = !!shapeHits.length;
        var isHit = isEndPointHit || isSegmentHit || isShapeHit;
        return {
            isHit: isHit,
            isEndPointHit: isEndPointHit,
            isSegmentHit: isSegmentHit,
            isShapeHit: isShapeHit,
            endPointHits: endPointHits,
            segmentHits: segmentHits,
            shapeHits: shapeHits,
        };
    };
    // TODO: move this math stuff into the calculators module
    // TODO: approximate bezier curves by splitting them up into line segments
    // TODO: write tests for this stuff
    PathState.prototype.getPoleOfInaccessibility = function (subIdx) {
        var subPathCmds = this.subPaths[subIdx].getCommands();
        if (subPathCmds.length === 1) {
            var end = subPathCmds[0].end;
            return { x: end.x, y: end.y };
        }
        var cmds = subPathCmds.slice(1);
        var polygon = _.flatMap(cmds, function (cmd) {
            var _a = cmd.start, p1x = _a.x, p1y = _a.y;
            var _b = cmd.end, p2x = _b.x, p2y = _b.y;
            return [[p1x, p1y], [p2x, p2y]];
        });
        if (cmds.length && !this.subPaths[subIdx].isClosed()) {
            var _a = cmds[0].start, p1x = _a.x, p1y = _a.y;
            var _b = _.last(cmds).end, p2x = _b.x, p2y = _b.y;
            polygon.push.apply(polygon, [[p1x, p1y], [p2x, p2y]]);
        }
        var pole = polylabel([polygon]);
        return { x: pole[0], y: pole[1] };
    };
    // TODO: cache this?
    PathState.prototype.getBoundingBox = function () {
        var css = _.flatMap(this.subPathStateMap, function (sps) { return sps.getCommandStates(); });
        return createBoundingBox(css);
    };
    PathState.prototype.isClockwise = function (subIdx) {
        var cmds = this.subPaths[subIdx].getCommands();
        return _.sumBy(cmds, function (cmd) { return getArea(cmd); }) >= 0;
    };
    PathState.prototype.findSubPathState = function (subIdx) {
        return findSubPathState(this.subPathStateMap, this.subPathOrdering[subIdx]);
    };
    PathState.prototype.findCommandStateInfo = function (subIdx, cmdIdx) {
        var sps = this.findSubPathState(subIdx);
        var numCommandsInSubPath = _.sumBy(sps.getCommandStates(), function (cs) { return cs.getCommands().length; });
        if (cmdIdx && sps.isReversed()) {
            cmdIdx = numCommandsInSubPath - cmdIdx;
        }
        cmdIdx += sps.getShiftOffset();
        if (cmdIdx >= numCommandsInSubPath) {
            // Note that subtracting numCommandsInSubPath is intentional here
            // (as opposed to subtracting numCommandsInSubPath - 1).
            cmdIdx -= numCommandsInSubPath;
        }
        var counter = 0;
        for (var _i = 0, _a = sps.getCommandStates(); _i < _a.length; _i++) {
            var cs = _a[_i];
            if (counter + cs.getCommands().length > cmdIdx) {
                return { sps: sps, cs: cs, splitIdx: cmdIdx - counter };
            }
            counter += cs.getCommands().length;
        }
        throw new Error('Error retrieving command mutation');
    };
    PathState.prototype.toCmdIdx = function (spsIdx, csIdx, splitIdx) {
        var sps = this.findSubPathState(this.subPathOrdering.indexOf(spsIdx));
        var commandStates = sps.getCommandStates();
        var numCmds = _.sumBy(commandStates, function (cs) { return cs.getCommands().length; });
        var cmdIdx = splitIdx + _.sum(commandStates.map(function (cs, i) { return (i < csIdx ? cs.getCommands().length : 0); }));
        var shiftOffset = sps.getShiftOffset();
        if (sps.isReversed()) {
            cmdIdx = numCmds - cmdIdx;
            shiftOffset *= -1;
            shiftOffset += numCmds - 1;
        }
        if (shiftOffset) {
            cmdIdx += numCmds - shiftOffset - 1;
            if (cmdIdx >= numCmds) {
                cmdIdx = cmdIdx - numCmds + 1;
            }
        }
        return cmdIdx;
    };
    return PathState;
}());
export { PathState };
// TODO: cache this?
function createBoundingBox(css) {
    var bounds = { l: Infinity, t: Infinity, r: -Infinity, b: -Infinity };
    var expandBoundsFn = function (x, y) {
        if (isNaN(x) || isNaN(y)) {
            return;
        }
        bounds.l = Math.min(x, bounds.l);
        bounds.t = Math.min(y, bounds.t);
        bounds.r = Math.max(x, bounds.r);
        bounds.b = Math.max(y, bounds.b);
    };
    var expandBoundsForCommandMutationFn = function (cs) {
        var bbox = cs.getBoundingBox();
        expandBoundsFn(bbox.x.min, bbox.y.min);
        expandBoundsFn(bbox.x.max, bbox.y.min);
        expandBoundsFn(bbox.x.min, bbox.y.max);
        expandBoundsFn(bbox.x.max, bbox.y.max);
    };
    css.forEach(function (cs) { return expandBoundsForCommandMutationFn(cs); });
    return bounds;
}
function isSubPathSplit(map, spsIdx) {
    return !!findSubPathState(map, spsIdx).getSplitSubPaths().length;
}
function containsPoint(rect, p) {
    return rect.l <= p.x && p.x < rect.r && rect.t <= p.y && p.y < rect.b;
}
function getArea(cmd) {
    if (cmd.type === 'M') {
        return 0;
    }
    var _a = cmd.start, x0 = _a.x, y0 = _a.y;
    var _b = cmd.end, x3 = _b.x, y3 = _b.y;
    var area = 0;
    switch (cmd.type) {
        case 'L':
        case 'Z':
            area = (x3 - x0) * (y3 - y0);
            break;
        case 'Q':
        case 'C':
            var x1 = void 0;
            var y1 = void 0;
            var x2 = void 0;
            var y2 = void 0;
            if (cmd.type === 'Q') {
                var cp = cmd.points[1];
                x1 = x0 + (2 / 3) * (cp.x - x0);
                y1 = y0 + (2 / 3) * (cp.y - y0);
                x2 = x3 + (2 / 3) * (cp.x - x3);
                y2 = y3 + (2 / 3) * (cp.y - y3);
            }
            else {
                x1 = cmd.points[1].x;
                y1 = cmd.points[1].y;
                x2 = cmd.points[2].x;
                y2 = cmd.points[2].y;
            }
            area =
                (3 *
                    ((y3 - y0) * (x1 + x2) -
                        (x3 - x0) * (y1 + y2) +
                        y1 * (x0 - x2) -
                        x1 * (y0 - y2) +
                        y3 * (x2 + x0 / 3) -
                        x3 * (y2 + y0 / 3))) /
                    20;
            break;
    }
    return area;
}
