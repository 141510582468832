/**
 * A property is used to decorate a model object so that it can be inspected
 * and/or animated. T is the value type that is stored inside the model object.
 *
 * Model objects decorated with a property are given the following:
 *
 * - A getter/setter that can be accessed using the property's name.
 * - An 'inspectableProperties' object mapping string property names
 *   to their corresponding inspectable property objects.
 * - An 'animatableProperties' object mapping string property names
 *   to their corresponding animatable property objects.
 */
var Property = /** @class */ (function () {
    function Property(propertyName, config) {
        if (config === void 0) { config = {}; }
        this.propertyName = propertyName;
        this.isAnimatable = !!config.isAnimatable;
    }
    /**
     * Builds a decorator factory for the specified properties.
     */
    Property.register = function () {
        var props = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            props[_i] = arguments[_i];
        }
        return function (cls) {
            props.forEach(function (prop) {
                // Create's a property with the specified property name.
                Object.defineProperty(cls.prototype, prop.propertyName, {
                    get: function () {
                        return prop.getter(this, prop.propertyName);
                    },
                    set: function (value) {
                        prop.setter(this, prop.propertyName, value);
                    },
                });
            });
            var animatableProperties = new Map();
            if (cls.prototype.animatableProperties) {
                animatableProperties = new Map(cls.prototype.animatableProperties);
            }
            var inspectableProperties = new Map();
            if (cls.prototype.inspectableProperties) {
                inspectableProperties = new Map(cls.prototype.inspectableProperties);
            }
            props.forEach(function (prop) {
                if (prop.isAnimatable) {
                    animatableProperties.set(prop.propertyName, prop);
                }
                inspectableProperties.set(prop.propertyName, prop);
            });
            Object.defineProperty(cls.prototype, 'animatableProperties', {
                get: function () { return new Map(animatableProperties); },
            });
            Object.defineProperty(cls.prototype, 'inspectableProperties', {
                get: function () { return new Map(inspectableProperties); },
            });
        };
    };
    Property.prototype.getEditableValue = function (model, propertyName) {
        return model[propertyName];
    };
    Property.prototype.setEditableValue = function (model, propertyName, value) {
        model[propertyName] = value;
    };
    Property.prototype.getter = function (model, propertyName) {
        return model[propertyName + "_"];
    };
    Property.prototype.setter = function (model, propertyName, value) {
        model[propertyName + "_"] = value;
    };
    Property.prototype.displayValueForValue = function (value) {
        return value;
    };
    /**
     * Returns an interpolated value between start and end using the specified fraction.
     * This method does not modify the property's internal state, but rather
     * returns a newly created object.
     */
    Property.prototype.interpolateValue = function (start, end, fraction) {
        return start;
    };
    /**
     * Returns a cloned instance of the value.
     */
    Property.prototype.cloneValue = function (value) {
        return value;
    };
    /**
     * Returns the animator value type for used in Android AnimatedVectorDrawable files.
     */
    Property.prototype.getAnimatorValueType = function () {
        return '';
    };
    return Property;
}());
export { Property };
