/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./splashscreen.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "./splashscreen.component";
var styles_SplashScreenComponent = [i0.styles];
var RenderType_SplashScreenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SplashScreenComponent, data: {} });
export { RenderType_SplashScreenComponent as RenderType_SplashScreenComponent };
export function View_SplashScreenComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i3.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-icon", [["class", "splashscreen-logo mat-icon"], ["role", "img"], ["svgIcon", "shapeshifter"]], [[2, "mat-icon-inline", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "splashscreen-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Shape Shifter is an icon animation tool designed for desktop browsers"]))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_3 = "shapeshifter"; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 4).inline; _ck(_v, 3, 0, currVal_2); }); }
export function View_SplashScreenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-splashscreen", [], null, null, null, View_SplashScreenComponent_0, RenderType_SplashScreenComponent)), i1.ɵdid(1, 49152, null, 0, i6.SplashScreenComponent, [], null, null)], null, null); }
var SplashScreenComponentNgFactory = i1.ɵccf("app-splashscreen", i6.SplashScreenComponent, View_SplashScreenComponent_Host_0, {}, {}, []);
export { SplashScreenComponentNgFactory as SplashScreenComponentNgFactory };
