import { EventEmitter, OnInit, } from '@angular/core';
import { ClipPathLayer, GroupLayer, Layer, PathLayer, VectorLayer } from 'app/modules/editor/model/layers';
import { PathAnimationBlock } from 'app/modules/editor/model/timeline';
import { ModelUtil } from 'app/modules/editor/scripts/common';
import { ActionModeService } from 'app/modules/editor/services';
import { Store } from 'app/modules/editor/store';
import { getLayerListTreeState } from 'app/modules/editor/store/common/selectors';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
var LayerListTreeComponent = /** @class */ (function () {
    function LayerListTreeComponent(store, actionModeService) {
        this.store = store;
        this.actionModeService = actionModeService;
        // MouseEvents from this layer (or children layers further down the tree)
        // are recursively handled by parent components until they reach
        // the LayerTimelineComponent.
        this.layerClick = new EventEmitter();
        this.layerDoubleClick = new EventEmitter();
        this.layerMouseDown = new EventEmitter();
        this.layerToggleExpanded = new EventEmitter();
        this.layerToggleVisibility = new EventEmitter();
        this.addTimelineBlockClick = new EventEmitter();
        this.convertToClipPathClick = new EventEmitter();
        this.convertToPathClick = new EventEmitter();
        this.flattenGroupClick = new EventEmitter();
    }
    LayerListTreeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.layerModel$ = this.store.select(getLayerListTreeState).pipe(map(function (_a) {
            var animation = _a.animation, selectedLayerIds = _a.selectedLayerIds, collapsedLayerIds = _a.collapsedLayerIds, hiddenLayerIds = _a.hiddenLayerIds, hoveredLayerId = _a.hoveredLayerId, isActionMode = _a.isActionMode;
            var isExpandable = _this.isLayerExpandable();
            var availablePropertyNames = Array.from(ModelUtil.getAvailablePropertyNamesForLayer(_this.layer, animation));
            var getExistingPropertyNamesFn = function (layerId) {
                return _.keys(ModelUtil.getOrderedBlocksByPropertyByLayer(animation)[layerId]);
            };
            var existingPropertyNames = getExistingPropertyNamesFn(_this.layer.id);
            var canBeConvertedToPath = _this.layer instanceof ClipPathLayer;
            // We can't convert a path into a clip path if it has incompatible animation blocks.
            var canBeConvertedToClipPath = _this.layer instanceof PathLayer &&
                // TODO: comparing the sets of all animatable properties for each layer type would be more robust
                !animation.blocks.some(function (b) { return !(b instanceof PathAnimationBlock); });
            var canBeFlattened = _this.layer instanceof GroupLayer &&
                _this.layer.children.length > 0 &&
                // TODO: allow merging groups w/ existing blocks in some cases?
                existingPropertyNames.length === 0 &&
                _this.layer.children.every(function (l) {
                    return (l instanceof PathLayer ||
                        l instanceof ClipPathLayer ||
                        // TODO: allow merging groups into groups w/ existing blocks in some cases?
                        getExistingPropertyNamesFn(l.id).length === 0);
                });
            return {
                animation: animation,
                isSelected: selectedLayerIds.has(_this.layer.id),
                isHovered: hoveredLayerId === _this.layer.id,
                isExpandable: isExpandable,
                isExpanded: !collapsedLayerIds.has(_this.layer.id),
                isVisible: !hiddenLayerIds.has(_this.layer.id),
                availablePropertyNames: availablePropertyNames,
                existingPropertyNames: existingPropertyNames,
                isActionMode: isActionMode,
                canBeConvertedToClipPath: canBeConvertedToClipPath,
                canBeConvertedToPath: canBeConvertedToPath,
                canBeFlattened: canBeFlattened,
            };
        }));
    };
    // @Override
    LayerListTreeComponent.prototype.onLayerClick = function (event, layer) {
        event.stopPropagation();
        if (!this.actionModeService.isActionMode()) {
            this.layerClick.emit({ event: event, layer: layer });
        }
    };
    // @Override
    LayerListTreeComponent.prototype.onLayerMouseDown = function (event, layer) {
        if (!this.actionModeService.isActionMode()) {
            this.layerMouseDown.emit({ event: event, layer: layer });
        }
    };
    // @Override
    LayerListTreeComponent.prototype.onLayerToggleExpanded = function (event, layer) {
        event.stopPropagation();
        if (this.isLayerExpandable()) {
            this.layerToggleExpanded.emit({ event: event, layer: layer });
        }
    };
    // @Override
    LayerListTreeComponent.prototype.onLayerToggleVisibility = function (event, layer) {
        event.stopPropagation();
        if (!this.actionModeService.isActionMode()) {
            this.layerToggleVisibility.emit({ event: event, layer: layer });
        }
    };
    // @Override
    LayerListTreeComponent.prototype.onAddTimelineBlockClick = function (event, layer, propertyName) {
        if (!this.actionModeService.isActionMode()) {
            this.addTimelineBlockClick.emit({ event: event, layer: layer, propertyName: propertyName });
        }
    };
    // @Override
    LayerListTreeComponent.prototype.onConvertToClipPathClick = function (event, layer) {
        if (!this.actionModeService.isActionMode()) {
            this.convertToClipPathClick.emit({ event: event, layer: layer });
        }
    };
    // @Override
    LayerListTreeComponent.prototype.onConvertToPathClick = function (event, layer) {
        if (!this.actionModeService.isActionMode()) {
            this.convertToPathClick.emit({ event: event, layer: layer });
        }
    };
    // @Override
    LayerListTreeComponent.prototype.onFlattenGroupClick = function (event, layer) {
        if (!this.actionModeService.isActionMode()) {
            this.flattenGroupClick.emit({ event: event, layer: layer });
        }
    };
    // Used by *ngFor loop.
    LayerListTreeComponent.prototype.trackLayerFn = function (index, layer) {
        return layer.id;
    };
    LayerListTreeComponent.prototype.isLayerExpandable = function () {
        return this.layer instanceof VectorLayer || this.layer instanceof GroupLayer;
    };
    return LayerListTreeComponent;
}());
export { LayerListTreeComponent };
