import * as tslib_1 from "tslib";
import { ClipPathLayer, GroupLayer, LayerUtil, PathLayer, } from 'app/modules/editor/model/layers';
import { ColorUtil } from 'app/modules/editor/scripts/common';
import { PaperUtil } from 'app/modules/editor/scripts/paper/util';
import * as _ from 'lodash';
import * as paper from 'paper';
import { EditPathRaster } from './EditPathRaster';
import { RotateItemsPivotRaster } from './RotateItemsPivotRaster';
import { SelectionBoundsRaster } from './SelectionBoundsRaster';
/**
 * The root layer used of our paper.js project. Note that this layer is
 * assigned a scale matrix that converts global project coordinates to
 * viewport coordinates.
 *
 * TODO: scaling rasters down causes their hit tolerances remain the same
 * TODO: when multiple items selected, show lightly outlined bounds for individual items?
 * TODO: explicitly set paths with no Z to closed? (i.e. M 1 1 h 6 v 6 h -6 v -6)
 * TODO: figure out if we can reduce stable bundle sizes (tree shake paper.js?)
 */
var PaperLayer = /** @class */ (function (_super) {
    tslib_1.__extends(PaperLayer, _super);
    function PaperLayer(ps) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.cssScaling = 1;
        _this.canvasColorRect = new paper.Path.Rectangle(new paper.Point(0, 0), new paper.Size(0, 0));
        _this.canvasColorRect.guide = true;
        _this.updateChildren();
        return _this;
    }
    Object.defineProperty(PaperLayer.prototype, "vectorLayer", {
        get: function () {
            return this.ps.getVectorLayer();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaperLayer.prototype, "selectedLayerIds", {
        get: function () {
            return this.ps.getSelectedLayerIds();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaperLayer.prototype, "hoveredLayerId", {
        get: function () {
            return this.ps.getHoveredLayerId();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaperLayer.prototype, "hiddenLayerIds", {
        get: function () {
            return this.ps.getHiddenLayerIds();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaperLayer.prototype, "editPathInfo", {
        get: function () {
            return this.ps.getEditPathInfo();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaperLayer.prototype, "rotateItemsInfo", {
        get: function () {
            return this.ps.getRotateItemsInfo();
        },
        enumerable: true,
        configurable: true
    });
    PaperLayer.prototype.hitTestVectorLayer = function (projPoint) {
        var _a = this.vectorLayer, vpWidth = _a.width, vpHeight = _a.height;
        var vpBounds = new paper.Rectangle(0, 0, vpWidth, vpHeight);
        var vpPoint = this.vectorLayerItem.globalToLocal(projPoint);
        if (!vpBounds.contains(vpPoint)) {
            return { hitItem: undefined, children: [] };
        }
        var hitResult = (function recurseFn(item) {
            var localPoint = item.globalToLocal(projPoint).transform(item.matrix);
            var hitItem;
            var children = [];
            if (item instanceof paper.Path) {
                // TODO: figure out what to do with compound paths?
                var res = item.hitTest(localPoint, { fill: true, stroke: true });
                if (res) {
                    hitItem = res.item;
                }
            }
            else if (item instanceof paper.Group) {
                var strokeBounds = item.strokeBounds;
                if (strokeBounds.contains(localPoint)) {
                    hitItem = item;
                    children = item.children.map(recurseFn).filter(function (r) { return !!r.hitItem; });
                }
            }
            return { hitItem: hitItem, children: children };
        })(this.vectorLayerItem);
        return {
            hitItem: this.vectorLayerItem,
            children: hitResult.children,
        };
    };
    PaperLayer.prototype.setDimensions = function (viewportWidth, viewportHeight, viewWidth, viewHeight) {
        // Note that viewWidth / viewportWidth === viewHeight / viewportHeight.
        this.cssScaling = viewWidth / viewportWidth;
        this.matrix = new paper.Matrix().scale(this.cssScaling);
        this.updatePixelGridItem(viewportWidth, viewportHeight);
    };
    PaperLayer.prototype.onVectorLayerChanged = function () {
        this.updateCanvasColorShape();
        this.updateVectorLayerItem();
        this.updateEditPathItem();
        this.updateSelectionBoundsItem();
        this.updateRotateItemsPivotItem();
        this.updateHoverPathItem();
    };
    PaperLayer.prototype.onSelectedLayerIdsChanged = function () {
        this.updateSelectionBoundsItem();
        this.updateRotateItemsPivotItem();
    };
    PaperLayer.prototype.onHiddenLayerIdsChanged = function () {
        this.updateHiddenLayers();
        // TODO: should we hide selection bounds, overlays, etc. for invisible layers?
    };
    PaperLayer.prototype.onHoveredLayerIdChanged = function () {
        this.updateHoverPathItem();
    };
    PaperLayer.prototype.onEditPathInfoChanged = function () {
        this.updateEditPathItem();
        this.updateSelectionBoundsItem();
        this.updateRotateItemsPivotItem();
    };
    PaperLayer.prototype.onRotateItemsInfoChanged = function () {
        this.updateRotateItemsPivotItem();
    };
    PaperLayer.prototype.setCreatePathInfo = function (info) {
        if (this.createPathItem) {
            this.createPathItem.remove();
            this.createPathItem = undefined;
        }
        if (info) {
            this.createPathItem = newCreatePathItem(info);
            this.updateChildren();
        }
    };
    PaperLayer.prototype.setSplitCurveInfo = function (info) {
        if (this.splitCurveItem) {
            this.splitCurveItem.remove();
            this.splitCurveItem = undefined;
        }
        if (info) {
            this.splitCurveItem = newSplitCurveItem(info, this.cssScaling);
            this.updateChildren();
        }
    };
    PaperLayer.prototype.setTooltipInfo = function (info) {
        if (this.tooltipItem) {
            this.tooltipItem.remove();
            this.tooltipItem = undefined;
        }
        if (info) {
            // TODO: re-enable tooltip when ready
            // this.tooltipItem = newTooltipItem(info, this.cssScaling);
            this.updateChildren();
        }
    };
    PaperLayer.prototype.setSnapGuideInfo = function (info) {
        if (this.snapGuideItem) {
            this.snapGuideItem.remove();
            this.snapGuideItem = undefined;
        }
        if (info) {
            this.snapGuideItem = newSnapGuideItem(info, this.cssScaling);
            this.updateChildren();
        }
    };
    PaperLayer.prototype.setSelectionBox = function (box) {
        if (this.selectionBoxItem) {
            this.selectionBoxItem.remove();
            this.selectionBoxItem = undefined;
        }
        if (box) {
            this.selectionBoxItem = newSelectionBoxItem(box.from, box.to);
            this.updateChildren();
        }
    };
    PaperLayer.prototype.updateCanvasColorShape = function () {
        this.canvasColorRect = new paper.Path.Rectangle(new paper.Point(0, 0), new paper.Size(this.vectorLayer.width, this.vectorLayer.height));
        this.canvasColorRect.guide = true;
        this.canvasColorRect.fillColor = parseAndroidColor(this.vectorLayer.canvasColor) || 'white';
        this.updateChildren();
    };
    PaperLayer.prototype.updateVectorLayerItem = function () {
        if (this.vectorLayerItem) {
            this.vectorLayerItem.remove();
        }
        this.vectorLayerItem = newVectorLayerItem(this.vectorLayer);
        this.updateHiddenLayers();
        this.updateChildren();
    };
    PaperLayer.prototype.updateSelectionBoundsItem = function () {
        if (this.selectionBoundsItem) {
            this.selectionBoundsItem.remove();
            this.selectionBoundsItem = undefined;
        }
        if (!this.editPathInfo) {
            var selectedItemBounds = this.getSelectedItemBounds();
            if (selectedItemBounds) {
                this.selectionBoundsItem = newSelectionBoundsItem(selectedItemBounds, this.cssScaling);
            }
        }
        this.updateChildren();
    };
    PaperLayer.prototype.updateRotateItemsPivotItem = function () {
        if (this.rotateItemsPivotItem) {
            this.rotateItemsPivotItem.remove();
            this.rotateItemsPivotItem = undefined;
        }
        var rii = this.rotateItemsInfo;
        if (rii) {
            var selectedItemBounds = this.getSelectedItemBounds();
            if (selectedItemBounds) {
                var vpPivot = rii.pivot ? new paper.Point(rii.pivot) : selectedItemBounds.center;
                this.rotateItemsPivotItem = newRotationPivotItem(vpPivot, this.cssScaling);
            }
        }
        this.updateChildren();
    };
    /**
     * Returns the bounds of the currently selected items in project coordinates.
     * Empty groups will be filtered out. Returns undefined if there are no selected
     * items left to compute.
     */
    PaperLayer.prototype.getSelectedItemBounds = function () {
        var _this = this;
        var selectedItems = Array.from(this.selectedLayerIds)
            .map(function (id) { return _this.findItemByLayerId(id); })
            // Filter out any selected empty groups.
            .filter(function (i) { return !(i instanceof paper.Group) || i.children.length; });
        if (selectedItems.length === 0) {
            return undefined;
        }
        return PaperUtil.transformRectangle(PaperUtil.computeBounds(selectedItems), this.matrix.inverted());
    };
    PaperLayer.prototype.updateHiddenLayers = function () {
        var hiddenLayerIds = this.hiddenLayerIds;
        (function recurseFn(item) {
            item.visible = !hiddenLayerIds.has(item.data.id);
            if (item.hasChildren()) {
                item.children.forEach(recurseFn);
            }
        })(this.vectorLayerItem);
    };
    PaperLayer.prototype.updateHoverPathItem = function () {
        if (this.hoverPathItem) {
            this.hoverPathItem.remove();
            this.hoverPathItem = undefined;
        }
        if (this.hoveredLayerId) {
            var item = this.findItemByLayerId(this.hoveredLayerId);
            this.hoverPathItem = newHoverPathItem(item);
        }
        this.updateChildren();
    };
    PaperLayer.prototype.updateEditPathItem = function () {
        if (this.editPathItem) {
            this.editPathItem.remove();
            this.editPathItem = undefined;
        }
        var epi = this.editPathInfo;
        var selectedLayerIds = this.selectedLayerIds;
        if (epi && selectedLayerIds.size) {
            // TODO: is it possible for pathData to be undefined?
            var path = this.findItemByLayerId(selectedLayerIds.values().next().value);
            this.editPathItem = newEditPathItem(path, epi, this.cssScaling);
            this.updateChildren();
        }
    };
    PaperLayer.prototype.updatePixelGridItem = function (viewportWidth, viewportHeight) {
        if (this.pixelGridItem) {
            this.pixelGridItem.remove();
            this.pixelGridItem = undefined;
        }
        if (this.cssScaling > 4) {
            this.pixelGridItem = newPixelGridItem(viewportWidth, viewportHeight);
            this.updateChildren();
        }
    };
    PaperLayer.prototype.updateChildren = function () {
        this.children = _.compact([
            this.canvasColorRect,
            this.vectorLayerItem,
            this.selectionBoundsItem,
            this.rotateItemsPivotItem,
            this.hoverPathItem,
            this.createPathItem,
            this.splitCurveItem,
            this.editPathItem,
            this.snapGuideItem,
            this.selectionBoxItem,
            this.pixelGridItem,
            this.tooltipItem,
        ]);
    };
    /** Finds the vector layer item with the given layer ID. */
    PaperLayer.prototype.findItemByLayerId = function (layerId) {
        if (!layerId) {
            return undefined;
        }
        if (this.vectorLayerItem.data.id === layerId) {
            return this.vectorLayerItem;
        }
        return _.first(this.vectorLayerItem.getItems({
            match: function (_a) {
                var id = _a.data.id;
                return layerId === id;
            },
        }));
    };
    /**
     * Finds all vector layer items that overlap with the specified bounds.
     * Note that the bounds must be in viewport coordinates.
     * @param includePartialOverlaps iff true, include items that partially overlap the bounds
     */
    PaperLayer.prototype.findItemsInBounds = function (vpBounds, includePartialOverlaps) {
        return this.vectorLayerItem.getItems({
            // TODO: figure out how to deal with groups and compound paths
            class: paper.Path,
            overlapping: includePartialOverlaps ? vpBounds : undefined,
            inside: includePartialOverlaps ? undefined : vpBounds,
        });
    };
    return PaperLayer;
}(paper.Layer));
export { PaperLayer };
function parseAndroidColor(androidColor, alpha) {
    if (alpha === void 0) { alpha = 1; }
    var color = ColorUtil.parseAndroidColor(androidColor);
    return color
        ? new paper.Color(color.r / 255, color.g / 255, color.b / 255, (color.a / 255) * alpha)
        : undefined;
}
function newVectorLayerItem(vl) {
    var item = new paper.Group();
    if (!vl) {
        return item;
    }
    var fromPathLayerFn = function (layer) {
        var fillColor = layer.fillColor, fillAlpha = layer.fillAlpha, strokeColor = layer.strokeColor, strokeAlpha = layer.strokeAlpha;
        var trimPathStart = layer.trimPathStart, trimPathEnd = layer.trimPathEnd, trimPathOffset = layer.trimPathOffset;
        // TODO: make sure this works with compound paths as well (Android behavior is different)
        var pathLength = layer.pathData ? layer.pathData.getPathLength() : 0;
        var dashArray = pathLength
            ? LayerUtil.toStrokeDashArray(trimPathStart, trimPathEnd, trimPathOffset, pathLength)
            : undefined;
        var dashOffset = pathLength
            ? LayerUtil.toStrokeDashOffset(trimPathStart, trimPathEnd, trimPathOffset, pathLength)
            : undefined;
        // TODO: import a compound path instead
        // Only paths with more than one command can be closed.
        var closed = layer.pathData && layer.pathData.isClosed() && layer.pathData.getCommands().length > 1;
        return new paper.Path({
            data: { id: layer.id },
            pathData: layer.pathData ? layer.pathData.getPathString() : '',
            fillColor: parseAndroidColor(fillColor, fillAlpha),
            strokeColor: parseAndroidColor(strokeColor, strokeAlpha),
            strokeWidth: layer.strokeWidth,
            miterLimit: layer.strokeMiterLimit,
            strokeJoin: layer.strokeLinejoin,
            strokeCap: layer.strokeLinecap,
            fillRule: layer.fillType === 'evenOdd' ? 'evenodd' : 'nonzero',
            dashArray: dashArray,
            dashOffset: dashOffset,
            closed: closed,
        });
    };
    var fromClipPathLayerFn = function (layer) {
        var pathData = layer.pathData ? layer.pathData.getPathString() : '';
        // Only paths with more than one command can be closed.
        var closed = layer.pathData && layer.pathData.isClosed() && layer.pathData.getCommands().length > 1;
        return new paper.Path({
            data: { id: layer.id },
            pathData: pathData,
            clipMask: true,
            closed: closed,
        });
    };
    var fromGroupLayerFn = function (layer) {
        var pivotX = layer.pivotX, pivotY = layer.pivotY, scaleX = layer.scaleX, scaleY = layer.scaleY, rotation = layer.rotation, translateX = layer.translateX, translateY = layer.translateY;
        var pivot = new paper.Matrix(1, 0, 0, 1, pivotX, pivotY);
        var scale = new paper.Matrix(scaleX, 0, 0, scaleY, 0, 0);
        var cosr = Math.cos((rotation * Math.PI) / 180);
        var sinr = Math.sin((rotation * Math.PI) / 180);
        var rotate = new paper.Matrix(cosr, sinr, -sinr, cosr, 0, 0);
        var translate = new paper.Matrix(1, 0, 0, 1, translateX, translateY);
        var matrix = new paper.Matrix()
            .prepend(pivot.inverted())
            .prepend(scale)
            .prepend(rotate)
            .prepend(translate)
            .prepend(pivot);
        return new paper.Group({ data: { id: layer.id }, matrix: matrix });
    };
    item.data.id = vl.id;
    item.opacity = vl.alpha;
    item.addChildren(vl.children.map(function recurseFn(layer) {
        if (layer instanceof PathLayer) {
            // TODO: return a compound path instead
            return fromPathLayerFn(layer);
        }
        if (layer instanceof ClipPathLayer) {
            // TODO: return a compound path instead
            return fromClipPathLayerFn(layer);
        }
        if (layer instanceof GroupLayer) {
            var groupItem = fromGroupLayerFn(layer);
            groupItem.addChildren(layer.children.map(function (l) { return recurseFn(l); }));
            return groupItem;
        }
        throw new TypeError('Unknown layer type: ' + layer);
    }));
    return item;
}
/** Creates a new hover path for the specified item. */
function newHoverPathItem(item) {
    var hoverPath;
    if (item instanceof paper.Group) {
        hoverPath = new paper.Path.Rectangle(item.bounds);
    }
    else if (item instanceof paper.Path) {
        hoverPath = new paper.Path(item.segments);
        hoverPath.closed = item.closed;
    }
    if (hoverPath) {
        hoverPath.strokeColor = '#009dec';
        hoverPath.guide = true;
        hoverPath.strokeScaling = false;
        hoverPath.strokeWidth = 2 / paper.view.zoom;
        // Transform the hover path from local coordinates to viewport coordinates.
        hoverPath.matrix = item.globalMatrix
            .prepended(paper.project.activeLayer.matrix.inverted())
            .prepend(item.matrix.inverted());
    }
    return hoverPath;
}
// TODO: reuse this code with SelectionBoundsRaster, etc.
var PIVOT_TYPES = [
    'topLeft',
    'topCenter',
    'topRight',
    'rightCenter',
    'bottomRight',
    'bottomCenter',
    'bottomLeft',
    'leftCenter',
];
/**
 * Creates a new selection bounds item for the specified selected items.
 */
function newSelectionBoundsItem(bounds, cssScaling) {
    var group = new paper.Group();
    // Draw an outline for the bounded box.
    var outlinePath = new paper.Path.Rectangle(bounds);
    outlinePath.strokeScaling = false;
    outlinePath.strokeWidth = 2 / paper.view.zoom;
    outlinePath.strokeColor = '#e8e8e8';
    outlinePath.guide = true;
    group.addChild(outlinePath);
    // Create segments for the bounded box.
    PIVOT_TYPES.forEach(function (pivotType) {
        // TODO: avoid creating rasters in a loop like this
        var center = bounds[pivotType];
        var handle = SelectionBoundsRaster.of(pivotType, center);
        var scaleFactor = getRasterScaleFactor(cssScaling);
        handle.scale(scaleFactor, scaleFactor);
        group.addChild(handle);
    });
    return group;
}
/**
 * Creates a rotation pivot point at the specified position.
 */
function newRotationPivotItem(position, cssScaling) {
    var pivot = RotateItemsPivotRaster.of(position);
    var scaleFactor = getRasterScaleFactor(cssScaling);
    pivot.scale(scaleFactor, scaleFactor);
    return pivot;
}
/**
 * Creates the overlay decorations for the given edit path.
 */
function newEditPathItem(path, info, cssScaling) {
    var group = new paper.Group();
    var scaleFactor = getRasterScaleFactor(cssScaling);
    var matrix = path.globalMatrix.prepended(new paper.Matrix(1 / cssScaling, 0, 0, 1 / cssScaling, 0, 0));
    var addRasterFn = function (raster) {
        raster.scale(scaleFactor, scaleFactor);
        group.addChild(raster);
        return raster;
    };
    var addLineFn = function (from, to) {
        var line = new paper.Path.Line(from, to);
        line.guide = true;
        line.strokeColor = '#aaaaaa';
        line.strokeWidth = 1 / paper.view.zoom;
        line.strokeScaling = false;
        // line.transform(matrix);
        group.addChild(line);
    };
    var selectedSegments = info.selectedSegments, visibleHandleIns = info.visibleHandleIns, selectedHandleIn = info.selectedHandleIn, visibleHandleOuts = info.visibleHandleOuts, selectedHandleOut = info.selectedHandleOut;
    // TODO: avoid creating rasters in a loop like this
    path.segments.forEach(function (_a, segmentIndex) {
        var point = _a.point, handleIn = _a.handleIn, handleOut = _a.handleOut;
        var center = point.transform(matrix);
        if (handleIn && visibleHandleIns.has(segmentIndex)) {
            handleIn = point.add(handleIn).transform(matrix);
            addLineFn(center, handleIn);
            addRasterFn(new EditPathRaster('handle-in', segmentIndex, selectedHandleIn === segmentIndex, handleIn));
        }
        if (handleOut && visibleHandleOuts.has(segmentIndex)) {
            handleOut = point.add(handleOut).transform(matrix);
            addLineFn(center, handleOut);
            addRasterFn(new EditPathRaster('handle-out', segmentIndex, selectedHandleOut === segmentIndex, handleOut));
        }
        addRasterFn(new EditPathRaster('segment', segmentIndex, selectedSegments.has(segmentIndex), center));
    });
    return group;
}
function getRasterScaleFactor(cssScaling) {
    return 1 / (1.8 * cssScaling * paper.view.zoom);
}
function newCreatePathItem(info) {
    var path = new paper.Path(info.pathData);
    path.guide = true;
    path.strokeScaling = false;
    path.strokeWidth = 1 / paper.view.zoom;
    path.strokeColor = info.strokeColor;
    return path;
}
function newSplitCurveItem(info, cssScaling) {
    var group = new paper.Group();
    group.guide = true;
    var splitPoint = info.splitPoint, segment1 = info.segment1, segment2 = info.segment2;
    var point1 = new paper.Point(segment1.point);
    var handleIn1 = new paper.Point(segment1.handleIn);
    var handleOut1 = new paper.Point(segment1.handleOut);
    var point2 = new paper.Point(segment2.point);
    var handleIn2 = new paper.Point(segment2.handleIn);
    var handleOut2 = new paper.Point(segment2.handleOut);
    var highlightedCurve = new paper.Path([
        new paper.Segment(point1, handleIn1, handleOut1),
        new paper.Segment(point2, handleIn2, handleOut2),
    ]);
    highlightedCurve.guide = true;
    highlightedCurve.strokeColor = '#3466A9';
    highlightedCurve.strokeScaling = false;
    highlightedCurve.strokeWidth = 2 / paper.view.zoom;
    group.addChild(highlightedCurve);
    var highlightedPoint = new paper.Path.Circle(new paper.Point(splitPoint), 4 / paper.view.zoom / cssScaling);
    highlightedPoint.guide = true;
    highlightedPoint.fillColor = '#3466A9';
    group.addChild(highlightedPoint);
    return group;
}
// TODO: add rounded rect background for tooltip
// TODO: ensure tooltip is justified correctly w/ respect to the active item
// function newTooltipItem(info: TooltipInfo, cssScaling: number) {
//   return new paper.PointText({
//     point: info.point,
//     content: info.label,
//     fillColor: 'red',
//     justification: 'left',
//     // TODO: text doesn't display when using font size of only 12?
//     fontSize: 14 / paper.view.zoom / cssScaling,
//     fontFamily: 'Roboto, Helvetica Neue, sans-serif',
//     guide: true,
//   });
// }
function newSnapGuideItem(info, cssScaling) {
    var group = new paper.Group({ guide: true });
    var newLineFn = function (from, to) {
        var line = new paper.Path.Line(from, to);
        line.guide = true;
        line.strokeScaling = false;
        line.strokeWidth = 1 / paper.view.zoom;
        line.strokeColor = 'red';
        return line;
    };
    info.guides.forEach(function (_a) {
        var from = _a.from, to = _a.to;
        group.addChild(newLineFn(new paper.Point(from), new paper.Point(to)));
    });
    var addToAngleFn = function (point, angle) {
        point = point.clone();
        point.angle += angle;
        return point;
    };
    var newHandleLineFn = function (endPoint, handle) {
        var from = endPoint.add(addToAngleFn(handle, 90));
        var to = endPoint.add(addToAngleFn(handle, -90));
        return newLineFn(from, to);
    };
    var newRulerLabelFn = function (point, content) {
        // TODO: use a better font (roboto?)
        // TODO: add padding above/to the side of the label
        return new paper.PointText({
            point: point,
            content: content,
            fillColor: 'red',
            // TODO: add justification so it displays to the bottom-left of the current point
            fontSize: 12 / paper.view.zoom / cssScaling,
            guide: true,
        });
    };
    var handleLengthPixels = 4;
    var matrix = new paper.Matrix(cssScaling, 0, 0, cssScaling, 0, 0);
    info.rulers.forEach(function (line) {
        var from = new paper.Point(line.from);
        var to = new paper.Point(line.to);
        var mid = from.add(to.subtract(from).multiply(0.5));
        var globalFrom = from.transform(matrix);
        var globalTo = to.transform(matrix);
        var rulerHandle = globalTo
            .subtract(globalFrom)
            .normalize()
            .multiply(handleLengthPixels)
            .transform(matrix.inverted());
        // TODO: make sure the rounded vs. actual values are equal!
        // TODO: only display decimals for small viewports
        var pointTextLabel = _.round(from.getDistance(to), 1).toString();
        group.addChildren([
            newLineFn(from, to),
            newHandleLineFn(from, rulerHandle),
            newHandleLineFn(to, rulerHandle),
            newRulerLabelFn(mid, pointTextLabel),
        ]);
    });
    return group;
}
function newSelectionBoxItem(from, to) {
    var path = new paper.Path.Rectangle(new paper.Rectangle(from, to));
    path.guide = true;
    path.strokeScaling = false;
    path.strokeWidth = 1 / paper.view.zoom;
    path.strokeColor = '#aaaaaa';
    path.dashArray = [3 / paper.view.zoom];
    return path;
}
function newPixelGridItem(viewportWidth, viewportHeight) {
    var group = new paper.Group({ guide: true });
    var newLineFn = function (from, to) {
        var line = new paper.Path.Rectangle(from, to);
        line.strokeColor = '#808080';
        line.opacity = 0.25;
        line.strokeScaling = false;
        line.strokeWidth = 1;
        line.guide = true;
        return line;
    };
    for (var x = 1; x < viewportWidth; x++) {
        group.addChild(newLineFn(new paper.Point(x, 0), new paper.Point(x, viewportHeight)));
    }
    for (var y = 1; y < viewportHeight; y++) {
        group.addChild(newLineFn(new paper.Point(0, y), new paper.Point(viewportWidth, y)));
    }
    return group;
}
