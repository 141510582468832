export var BatchActionTypes;
(function (BatchActionTypes) {
    BatchActionTypes["BatchAction"] = "__batch__BATCH";
})(BatchActionTypes || (BatchActionTypes = {}));
var BatchAction = /** @class */ (function () {
    function BatchAction() {
        var actions = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            actions[_i] = arguments[_i];
        }
        this.type = BatchActionTypes.BatchAction;
        this.payload = actions;
    }
    return BatchAction;
}());
export { BatchAction };
