import * as tslib_1 from "tslib";
import { MathUtil } from 'app/modules/editor/scripts/common';
import * as _ from 'lodash';
import { Property } from './Property';
var NumberProperty = /** @class */ (function (_super) {
    tslib_1.__extends(NumberProperty, _super);
    function NumberProperty(name, config) {
        if (config === void 0) { config = {}; }
        var _this = _super.call(this, name, config) || this;
        _this.min = config.min === undefined ? -Infinity : config.min;
        _this.max = config.max === undefined ? Infinity : config.max;
        _this.isInteger = !!config.isInteger;
        return _this;
    }
    // @Override
    NumberProperty.prototype.setEditableValue = function (model, propertyName, value) {
        if (typeof value !== 'number') {
            value = parseFloat(value);
        }
        if (isNaN(value)) {
            return;
        }
        value = _.clamp(value, this.min, this.max);
        if (this.isInteger) {
            value = Math.floor(value);
        }
        model[propertyName] = value;
    };
    // @Override
    NumberProperty.prototype.setter = function (model, propertyName, value) {
        if (typeof value === 'string') {
            value = Number(value);
        }
        if (typeof value === 'number') {
            if (!isNaN(value)) {
                value = _.clamp(value, this.min, this.max);
                if (this.isInteger) {
                    value = Math.floor(value);
                }
            }
        }
        model[propertyName + "_"] = value;
    };
    // @Override
    NumberProperty.prototype.interpolateValue = function (start, end, fraction) {
        return MathUtil.lerp(start, end, fraction);
    };
    // @Override
    NumberProperty.prototype.displayValueForValue = function (value) {
        if (typeof value === 'number') {
            return (Number.isInteger(value)
                ? value.toString()
                : Number(value.toFixed(3)).toString()).replace(/-/g, '\u2212');
        }
        return value;
    };
    // @Override
    NumberProperty.prototype.getAnimatorValueType = function () {
        return 'floatType';
    };
    // @Override
    NumberProperty.prototype.getTypeName = function () {
        return 'NumberProperty';
    };
    return NumberProperty;
}(Property));
export { NumberProperty };
