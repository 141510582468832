import * as tslib_1 from "tslib";
import { PaperLayer } from 'app/modules/editor/scripts/paper/item';
import { MasterToolPicker } from 'app/modules/editor/scripts/paper/tool';
import { getHiddenLayerIds, getSelectedLayerIds } from 'app/modules/editor/store/layers/selectors';
import { getCreatePathInfo, getEditPathInfo, getHoveredLayerId, getRotateItemsInfo, getSelectionBox, getSnapGuideInfo, getSplitCurveInfo, getToolMode, getTooltipInfo, getZoomPanInfo, } from 'app/modules/editor/store/paper/selectors';
import { getAnimatedVectorLayer } from 'app/modules/editor/store/playback/selectors';
import * as paper from 'paper';
var PaperProject = /** @class */ (function (_super) {
    tslib_1.__extends(PaperProject, _super);
    function PaperProject(canvas, ps, store) {
        var _this = _super.call(this, canvas) || this;
        _this.subscriptions = [];
        var pl = new PaperLayer(ps);
        paper.project.addLayer(pl);
        _this.paperLayer = pl;
        _this.masterToolPicker = new MasterToolPicker(ps);
        _this.subscriptions.push(store.select(getToolMode).subscribe(function () { return _this.masterToolPicker.onToolModeChanged(); }), 
        // TODO: dont allow the user to modify the vector layer when current time > 0
        store.select(getAnimatedVectorLayer).subscribe(function () { return pl.onVectorLayerChanged(); }), store.select(getSelectedLayerIds).subscribe(function () { return pl.onSelectedLayerIdsChanged(); }), store.select(getHoveredLayerId).subscribe(function () { return pl.onHoveredLayerIdChanged(); }), store.select(getHiddenLayerIds).subscribe(function () { return pl.onHiddenLayerIdsChanged(); }), store.select(getCreatePathInfo).subscribe(function (info) { return pl.setCreatePathInfo(info); }), store.select(getSplitCurveInfo).subscribe(function (info) { return pl.setSplitCurveInfo(info); }), store.select(getEditPathInfo).subscribe(function (info) { return pl.onEditPathInfoChanged(); }), store.select(getRotateItemsInfo).subscribe(function (info) { return pl.onRotateItemsInfoChanged(); }), store.select(getSnapGuideInfo).subscribe(function (info) { return pl.setSnapGuideInfo(info); }), store.select(getTooltipInfo).subscribe(function (info) { return pl.setTooltipInfo(info); }), store.select(getSelectionBox).subscribe(function (box) {
            if (box) {
                var from = new paper.Point(box.from);
                var to = new paper.Point(box.to);
                pl.setSelectionBox({ from: from, to: to });
            }
            else {
                pl.setSelectionBox(undefined);
            }
        }), store.select(getZoomPanInfo).subscribe(function (_a) {
            var zoom = _a.zoom, _b = _a.translation, tx = _b.tx, ty = _b.ty;
            _this.view.matrix = new paper.Matrix(zoom, 0, 0, zoom, tx, ty);
        }));
        return _this;
    }
    /**
     * Sets the project's dimensions with the new VectorLayer viewport and canvas element size (in CSS pixels).
     */
    PaperProject.prototype.setDimensions = function (viewportWidth, viewportHeight, viewWidth, viewHeight) {
        // The view size represents the actual size of the canvas in CSS pixels.
        // The viewport size represents the user-visible dimensions (i.e. the default 24x24).
        this.view.viewSize = new paper.Size(viewWidth, viewHeight);
        this.paperLayer.setDimensions(viewportWidth, viewportHeight, viewWidth, viewHeight);
    };
    // @Override
    PaperProject.prototype.remove = function () {
        _super.prototype.remove.call(this);
        while (this.subscriptions.length) {
            this.subscriptions.pop().unsubscribe();
        }
    };
    return PaperProject;
}(paper.Project));
export { PaperProject };
