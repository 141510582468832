import * as tslib_1 from "tslib";
import * as paper from 'paper';
var EditPathRaster = /** @class */ (function (_super) {
    tslib_1.__extends(EditPathRaster, _super);
    function EditPathRaster(type, segmentIndex, isSelected, center) {
        var _this = _super.call(this, type === 'segment'
            ? "/assets/paper/" + (isSelected ? 'vector-segment-selected' : 'vector-segment') + ".png"
            : "/assets/paper/" + (isSelected ? 'vector-handle-selected' : 'vector-handle') + ".png", center) || this;
        _this.type = type;
        _this.segmentIndex = segmentIndex;
        _this.isSelected = isSelected;
        return _this;
    }
    return EditPathRaster;
}(paper.Raster));
export { EditPathRaster };
