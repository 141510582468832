import { HttpClient } from '@angular/common/http';
import { ModelUtil } from 'app/modules/editor/scripts/common';
import { FileExportService } from 'app/modules/editor/services/fileexport.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProjectService = /** @class */ (function () {
    function ProjectService(http) {
        this.http = http;
    }
    /**
     * Fetches a shape shifter project via HTTP.
     * @param url the URL of the shape shifter project
     */
    ProjectService.prototype.getProject = function (url) {
        return this.http
            .get(url)
            .toPromise()
            .then(function (response) {
            var jsonObj = response;
            var _a = FileExportService.fromJSON(jsonObj), vectorLayer = _a.vectorLayer, animation = _a.animation, hiddenLayerIds = _a.hiddenLayerIds;
            return ModelUtil.regenerateModelIds(vectorLayer, animation, hiddenLayerIds);
        });
    };
    ProjectService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectService_Factory() { return new ProjectService(i0.inject(i1.HttpClient)); }, token: ProjectService, providedIn: "root" });
    return ProjectService;
}());
export { ProjectService };
