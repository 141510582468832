import * as tslib_1 from "tslib";
import { LayerUtil, VectorLayer } from 'app/modules/editor/model/layers';
import { Animation } from 'app/modules/editor/model/timeline';
import { AvdSerializer, SpriteSerializer, SvgSerializer } from 'app/modules/editor/scripts/export';
import { Store } from 'app/modules/editor/store';
import { getHiddenLayerIds, getVectorLayer } from 'app/modules/editor/store/layers/selectors';
import { getAnimation } from 'app/modules/editor/store/timeline/selectors';
import * as $ from 'jquery';
import * as JSZip from 'jszip';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
// Store a version number just in case we ever change the export format...
var IMPORT_EXPORT_VERSION = 1;
var EXPORTED_FPS = [30, 60];
/**
 * A simple service that exports vectors and animations.
 */
var FileExportService = /** @class */ (function () {
    function FileExportService(store) {
        this.store = store;
    }
    FileExportService.fromJSON = function (jsonObj) {
        var layers = jsonObj.layers, timeline = jsonObj.timeline;
        var vectorLayer = new VectorLayer(layers.vectorLayer);
        var hiddenLayerIds = new Set(layers.hiddenLayerIds);
        var animation = new Animation(timeline.animation);
        return { vectorLayer: vectorLayer, hiddenLayerIds: hiddenLayerIds, animation: animation };
    };
    FileExportService.prototype.exportJSON = function () {
        var vl = this.getVectorLayer();
        var anim = this.getAnimation();
        var jsonStr = JSON.stringify({
            version: IMPORT_EXPORT_VERSION,
            layers: {
                vectorLayer: vl.toJSON(),
                hiddenLayerIds: Array.from(this.getHiddenLayerIds()),
            },
            timeline: {
                animation: anim.toJSON(),
            },
        }, undefined, 2);
        downloadFile(jsonStr, vl.name + ".shapeshifter");
    };
    FileExportService.prototype.exportSvg = function () {
        // Export standalone SVG frames.
        var vl = this.getVectorLayerWithoutHiddenLayers();
        var anim = this.getAnimationWithoutHiddenBlocks();
        if (!anim.blocks.length) {
            // Just export an SVG if there are no animation blocks defined.
            var svg = SvgSerializer.toSvgString(vl);
            downloadFile(svg, vl.name + ".svg");
            return;
        }
        // TODO: figure out how to add better jszip typings
        var zip = new JSZip();
        EXPORTED_FPS.forEach(function (fps) {
            var numSteps = Math.ceil((anim.duration / 1000) * fps);
            var svgs = SpriteSerializer.createSvgFrames(vl, anim, numSteps);
            var length = (numSteps - 1).toString().length;
            var fpsFolder = zip.folder(fps + "fps");
            svgs.forEach(function (s, i) {
                fpsFolder.file("frame" + _.padStart(i.toString(), length, '0') + ".svg", s);
            });
        });
        zip.generateAsync({ type: 'blob' }).then(function (content) {
            downloadFile(content, "frames_" + vl.name + ".zip");
        });
    };
    // TODO: should we or should we not export hidden layers?
    FileExportService.prototype.exportVectorDrawable = function () {
        var vl = this.getVectorLayerWithoutHiddenLayers();
        var vd = AvdSerializer.toVectorDrawableXmlString(vl);
        var fileName = "vd_" + vl.name + ".xml";
        downloadFile(vd, fileName);
    };
    FileExportService.prototype.exportAnimatedVectorDrawable = function () {
        var vl = this.getVectorLayerWithoutHiddenLayers();
        var anim = this.getAnimationWithoutHiddenBlocks();
        var avd = AvdSerializer.toAnimatedVectorDrawableXmlString(vl, anim);
        var fileName = "avd_" + anim.name + ".xml";
        downloadFile(avd, fileName);
    };
    FileExportService.prototype.exportSvgSpritesheet = function () {
        var _this = this;
        // Create an svg sprite animation.
        var vl = this.getVectorLayerWithoutHiddenLayers();
        var anim = this.getAnimationWithoutHiddenBlocks();
        // TODO: figure out how to add better jszip typings
        var zip = new JSZip();
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, asyncForEach(EXPORTED_FPS, function (fps) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var numSteps, svgSprite, cssSprite, fileName, htmlSprite, spriteFolder;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        numSteps = Math.ceil((anim.duration / 1000) * fps);
                                        return [4 /*yield*/, SpriteSerializer.createSvgSprite(vl, anim, numSteps)];
                                    case 1:
                                        svgSprite = _a.sent();
                                        cssSprite = SpriteSerializer.createCss(vl.width, vl.height, anim.duration, numSteps);
                                        fileName = "sprite_" + fps + "fps";
                                        htmlSprite = SpriteSerializer.createHtml(fileName + ".svg", fileName + ".css");
                                        spriteFolder = zip.folder(fps + "fps");
                                        spriteFolder.file(fileName + ".html", htmlSprite);
                                        spriteFolder.file(fileName + ".css", cssSprite);
                                        spriteFolder.file(fileName + ".svg", svgSprite);
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        zip.generateAsync({ type: 'blob' }).then(function (content) {
                            downloadFile(content, "spritesheet_" + vl.name + ".zip");
                        });
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    FileExportService.prototype.exportCssKeyframes = function () {
        // TODO: implement this
    };
    FileExportService.prototype.getVectorLayer = function () {
        var vectorLayer;
        this.store
            .select(getVectorLayer)
            .pipe(first())
            .subscribe(function (vl) { return (vectorLayer = vl); });
        return vectorLayer;
    };
    FileExportService.prototype.getAnimation = function () {
        var animation;
        this.store
            .select(getAnimation)
            .pipe(first())
            .subscribe(function (anim) { return (animation = anim); });
        return animation;
    };
    FileExportService.prototype.getHiddenLayerIds = function () {
        var hiddenLayerIds;
        this.store
            .select(getHiddenLayerIds)
            .pipe(first())
            .subscribe(function (ids) { return (hiddenLayerIds = ids); });
        return hiddenLayerIds;
    };
    FileExportService.prototype.getVectorLayerWithoutHiddenLayers = function () {
        return LayerUtil.removeLayers.apply(LayerUtil, [this.getVectorLayer()].concat(Array.from(this.getHiddenLayerIds())));
    };
    FileExportService.prototype.getAnimationWithoutHiddenBlocks = function () {
        var anim = this.getAnimation().clone();
        var hiddenLayerIds = this.getHiddenLayerIds();
        anim.blocks = anim.blocks.filter(function (b) { return !hiddenLayerIds.has(b.layerId); });
        return anim;
    };
    FileExportService.ngInjectableDef = i0.defineInjectable({ factory: function FileExportService_Factory() { return new FileExportService(i0.inject(i1.Store)); }, token: FileExportService, providedIn: "root" });
    return FileExportService;
}());
export { FileExportService };
function downloadFile(content, fileName) {
    var anchor = $('<a>')
        .hide()
        .appendTo(document.body);
    var blob = content instanceof Blob ? content : new Blob([content], { type: 'octet/stream' });
    var url = window.URL.createObjectURL(blob);
    anchor.attr({ href: url, download: fileName });
    anchor.get(0).click();
    window.URL.revokeObjectURL(url);
}
function asyncForEach(array, callback) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var index;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    index = 0;
                    _a.label = 1;
                case 1:
                    if (!(index < array.length)) return [3 /*break*/, 4];
                    return [4 /*yield*/, callback(array[index], index, array)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    index++;
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/];
            }
        });
    });
}
