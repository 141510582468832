import { MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmDialogComponent } from 'app/modules/editor/components/dialogs/confirmdialog.component';
import { DemoDialogComponent } from 'app/modules/editor/components/dialogs/demodialog.component';
import { DropFilesDialogComponent, } from 'app/modules/editor/components/dialogs/dropfilesdialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var DialogService = /** @class */ (function () {
    function DialogService(dialog) {
        this.dialog = dialog;
    }
    DialogService.prototype.confirm = function (title, message) {
        var config = new MatDialogConfig();
        config.data = { title: title, message: message };
        return this.dialog.open(ConfirmDialogComponent, config).afterClosed();
    };
    DialogService.prototype.pickDemo = function () {
        return this.dialog.open(DemoDialogComponent, new MatDialogConfig()).afterClosed();
    };
    DialogService.prototype.dropFiles = function () {
        return this.dialog.open(DropFilesDialogComponent, new MatDialogConfig()).afterClosed();
    };
    DialogService.ngInjectableDef = i0.defineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.inject(i1.MatDialog)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
