import { LayerUtil } from 'app/modules/editor/model/layers';
import { ModelUtil } from 'app/modules/editor/scripts/common';
import { SvgLoader, VectorDrawableLoader } from 'app/modules/editor/scripts/import';
import { Store } from 'app/modules/editor/store';
import { getVectorLayer } from 'app/modules/editor/store/layers/selectors';
import { ResetWorkspace } from 'app/modules/editor/store/reset/actions';
import { first } from 'rxjs/operators';
import { FileExportService } from './fileexport.service';
import { LayerTimelineService } from './layertimeline.service';
import { Duration, SnackBarService } from './snackbar.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./snackbar.service";
import * as i3 from "./layertimeline.service";
var ImportType;
(function (ImportType) {
    ImportType[ImportType["Svg"] = 1] = "Svg";
    ImportType[ImportType["VectorDrawable"] = 2] = "VectorDrawable";
    ImportType[ImportType["Json"] = 3] = "Json";
})(ImportType || (ImportType = {}));
/**
 * A simple service that imports vector layers from files.
 */
var FileImportService = /** @class */ (function () {
    function FileImportService(store, snackBarService, layerTimelineService) {
        this.store = store;
        this.snackBarService = snackBarService;
        this.layerTimelineService = layerTimelineService;
    }
    Object.defineProperty(FileImportService.prototype, "vectorLayer", {
        get: function () {
            var vectorLayer;
            this.store
                .select(getVectorLayer)
                .pipe(first())
                .subscribe(function (vl) { return (vectorLayer = vl); });
            return vectorLayer;
        },
        enumerable: true,
        configurable: true
    });
    FileImportService.prototype.import = function (fileList, resetWorkspace) {
        var _this = this;
        if (resetWorkspace === void 0) { resetWorkspace = false; }
        if (!fileList || !fileList.length) {
            return;
        }
        var files = [];
        // tslint:disable-next-line: prefer-for-of
        for (var i = 0; i < fileList.length; i++) {
            files.push(fileList[i]);
        }
        var numCallbacks = 0;
        var numErrors = 0;
        var addedVls = [];
        var importType;
        var maybeAddVectorLayersFn = function () {
            numCallbacks++;
            if (numErrors === files.length) {
                _this.onFailure();
            }
            else if (numCallbacks === files.length) {
                _this.onSuccess(importType, resetWorkspace, addedVls);
            }
        };
        var existingVl = this.vectorLayer;
        var _loop_1 = function (file) {
            var fileReader = new FileReader();
            fileReader.onload = function (event) {
                var text = event.target.result;
                var callbackFn = function (vectorLayer) {
                    if (!vectorLayer) {
                        numErrors++;
                        maybeAddVectorLayersFn();
                        return;
                    }
                    addedVls.push(vectorLayer);
                    maybeAddVectorLayersFn();
                };
                var doesNameExistFn = function (name) {
                    return !!LayerUtil.findLayerByName([existingVl].concat(addedVls), name);
                };
                if (file.type.includes('svg')) {
                    importType = ImportType.Svg;
                    SvgLoader.loadVectorLayerFromSvgString(text, doesNameExistFn)
                        .then(function (vl) { return callbackFn(vl); })
                        .catch(function () {
                        console.warn('failed to import SVG');
                        callbackFn(undefined);
                    });
                }
                else if (file.type.includes('xml')) {
                    importType = ImportType.VectorDrawable;
                    var vl = void 0;
                    try {
                        vl = VectorDrawableLoader.loadVectorLayerFromXmlString(text, doesNameExistFn);
                        callbackFn(vl);
                    }
                    catch (e) {
                        console.warn('Failed to parse the file', e);
                        callbackFn(undefined);
                    }
                }
                else if (file.type === 'application/json' || file.name.match(/\.shapeshifter$/)) {
                    importType = ImportType.Json;
                    var vl = void 0;
                    var animation = void 0;
                    var hiddenLayerIds = void 0;
                    try {
                        var jsonObj = JSON.parse(text);
                        var parsedObj = FileExportService.fromJSON(jsonObj);
                        vl = parsedObj.vectorLayer;
                        animation = parsedObj.animation;
                        hiddenLayerIds = parsedObj.hiddenLayerIds;
                        var regeneratedModels = ModelUtil.regenerateModelIds(vl, animation, hiddenLayerIds);
                        vl = regeneratedModels.vectorLayer;
                        animation = regeneratedModels.animation;
                        hiddenLayerIds = regeneratedModels.hiddenLayerIds;
                    }
                    catch (e) {
                        console.warn('Failed to parse the file', e);
                        _this.onFailure();
                    }
                    _this.onSuccess(importType, resetWorkspace, [vl], animation, hiddenLayerIds);
                }
            };
            fileReader.onerror = function (event) {
                var target = event.target;
                switch (target.error.code) {
                    case target.error.NOT_FOUND_ERR:
                        alert('File not found');
                        break;
                    case target.error.NOT_READABLE_ERR:
                        alert('File is not readable');
                        break;
                    case target.error.ABORT_ERR:
                        break;
                    default:
                        alert('An error occurred reading this file');
                        break;
                }
                numErrors++;
                maybeAddVectorLayersFn();
            };
            fileReader.onabort = function (event) {
                alert('File read cancelled');
            };
            fileReader.readAsText(file);
        };
        for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
            var file = files_1[_i];
            _loop_1(file);
        }
    };
    FileImportService.prototype.onSuccess = function (importType, resetWorkspace, vls, animation, hiddenLayerIds) {
        if (importType === ImportType.Json) {
            ga('send', 'event', 'Import', 'JSON');
            this.store.dispatch(new ResetWorkspace(vls[0], animation, hiddenLayerIds));
        }
        else {
            if (importType === ImportType.Svg) {
                ga('send', 'event', 'Import', 'SVG');
            }
            else if (importType === ImportType.VectorDrawable) {
                ga('send', 'event', 'Import', 'Vector Drawable');
            }
            if (resetWorkspace) {
                this.store.dispatch(new ResetWorkspace());
            }
            this.layerTimelineService.importLayers(vls);
            // TODO: count number of individual layers?
            this.snackBarService.show("Imported " + vls.length + " layer" + (vls.length === 1 ? '' : 's'), 'Dismiss', Duration.Short);
        }
    };
    FileImportService.prototype.onFailure = function () {
        this.snackBarService.show("Couldn't import layers from file", 'Dismiss', Duration.Long);
    };
    FileImportService.ngInjectableDef = i0.defineInjectable({ factory: function FileImportService_Factory() { return new FileImportService(i0.inject(i1.Store), i0.inject(i2.SnackBarService), i0.inject(i3.LayerTimelineService)); }, token: FileImportService, providedIn: "root" });
    return FileImportService;
}());
export { FileImportService };
