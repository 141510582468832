import { OnInit } from '@angular/core';
import { ActionMode } from 'app/modules/editor/model/actionmode';
import { ClipPathLayer, GroupLayer, LayerUtil, PathLayer, VectorLayer, } from 'app/modules/editor/model/layers';
import { FractionProperty, NameProperty } from 'app/modules/editor/model/properties';
import { PathAnimationBlock } from 'app/modules/editor/model/timeline';
import { ColorUtil, ModelUtil } from 'app/modules/editor/scripts/common';
import { ActionModeService, LayerTimelineService, PlaybackService, ShortcutService, ThemeService, } from 'app/modules/editor/services';
import { Store } from 'app/modules/editor/store';
import { getPropertyInputState } from 'app/modules/editor/store/common/selectors';
import { SetAnimation } from 'app/modules/editor/store/timeline/actions';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { InspectedProperty } from './InspectedProperty';
// TODO: when you enter a 'start time' larger than 'end time', transform 'end time' correctly
var PropertyInputComponent = /** @class */ (function () {
    function PropertyInputComponent(store, actionModeService, playbackService, layerTimelineService, themeService) {
        this.store = store;
        this.actionModeService = actionModeService;
        this.playbackService = playbackService;
        this.layerTimelineService = layerTimelineService;
        this.themeService = themeService;
        // Map used to track user state that has been entered into textfields
        // but may not have been saved in the store.
        this.enteredValueMap = new Map();
    }
    PropertyInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        var prevThemeType;
        var currThemeType = this.themeService.getThemeType().themeType;
        this.propertyInputModel$ = this.store.select(getPropertyInputState).pipe(map(function (_a) {
            var animation = _a.animation, isAnimationSelected = _a.isAnimationSelected, selectedBlockIds = _a.selectedBlockIds, vectorLayer = _a.vectorLayer, selectedLayerIds = _a.selectedLayerIds;
            prevThemeType = currThemeType = _this.themeService.getThemeType().themeType;
            if (selectedLayerIds.size) {
                return _this.buildInspectedLayerProperties(vectorLayer, selectedLayerIds, animation);
            }
            else if (selectedBlockIds.size) {
                return _this.buildInspectedBlockProperties(vectorLayer, animation, selectedBlockIds);
            }
            else if (isAnimationSelected) {
                return _this.buildInspectedAnimationProperties(animation);
            }
            else {
                return {
                    numSelections: 0,
                    inspectedProperties: [],
                    availablePropertyNames: [],
                };
            }
        }));
        this.themeState$ = combineLatest(this.propertyInputModel$, this.themeService.asObservable()).pipe(map(function (_a) {
            var unused = _a[0], themeType = _a[1].themeType;
            prevThemeType = currThemeType;
            currThemeType = _this.themeService.getThemeType().themeType;
            return { prevThemeType: prevThemeType, currThemeType: currThemeType };
        }));
    };
    PropertyInputComponent.prototype.shouldShowStartActionModeButton = function (pim) {
        return pim.numSelections === 1 && pim.model instanceof PathAnimationBlock;
    };
    PropertyInputComponent.prototype.shouldDisableStartActionModeButton = function (pim) {
        if (!this.shouldShowStartActionModeButton(pim)) {
            return false;
        }
        var _a = pim.model, fromValue = _a.fromValue, toValue = _a.toValue;
        return !fromValue || !fromValue.getPathString() || !toValue || !toValue.getPathString();
    };
    PropertyInputComponent.prototype.onAutoFixPathsClick = function (pim) {
        this.actionModeService.autoFix();
    };
    PropertyInputComponent.prototype.onStartActionModeClick = function () {
        ga('send', 'event', 'Action mode', 'Started');
        this.actionModeService.setActionMode(ActionMode.Selection);
    };
    PropertyInputComponent.prototype.shouldShowAnimateLayerButton = function (pim) {
        return (pim.availablePropertyNames.length > 0 &&
            pim.numSelections === 1 &&
            (pim.model instanceof VectorLayer ||
                pim.model instanceof GroupLayer ||
                pim.model instanceof ClipPathLayer ||
                pim.model instanceof PathLayer));
    };
    PropertyInputComponent.prototype.onAnimateLayerClick = function (layer, propertyName) {
        var clonedValue = layer.inspectableProperties
            .get(propertyName)
            .cloneValue(layer[propertyName]);
        var currentTime = this.playbackService.getCurrentTime();
        this.layerTimelineService.addBlocks([
            {
                layerId: layer.id,
                propertyName: propertyName,
                fromValue: clonedValue,
                toValue: clonedValue,
                currentTime: currentTime,
            },
        ]);
    };
    PropertyInputComponent.prototype.shouldShowInvalidPathAnimationBlockMsg = function (pim) {
        return (pim.numSelections === 1 &&
            pim.model instanceof PathAnimationBlock &&
            !pim.model.isAnimatable());
    };
    PropertyInputComponent.prototype.isPathBlockFromValueEmpty = function (block) {
        return !block.fromValue || !block.fromValue.getPathString();
    };
    PropertyInputComponent.prototype.isPathBlockToValueEmpty = function (block) {
        return !block.toValue || !block.toValue.getPathString();
    };
    PropertyInputComponent.prototype.onValueEditorKeyDown = function (event, ip) {
        switch (event.keyCode) {
            // Up/down arrow buttons.
            case 38:
            case 40:
                ip.resolveEnteredValue();
                var $target_1 = $(event.target);
                var numberValue = Number($target_1.val());
                if (isNaN(numberValue)) {
                    break;
                }
                var delta = event.keyCode === 38 ? 1 : -1;
                if (ip.property instanceof FractionProperty) {
                    delta *= 0.1;
                }
                if (event.shiftKey) {
                    // TODO: make this more obvious somehow
                    delta *= 10;
                }
                else if (ShortcutService.isOsDependentModifierKey(event)) {
                    // TODO: make this more obvious somehow
                    delta /= 10;
                }
                ip.property.setEditableValue(ip, 'value', Number((numberValue + delta).toFixed(6)));
                setTimeout(function () { return $target_1.get(0).select(); }, 0);
                return false;
        }
        return undefined;
    };
    PropertyInputComponent.prototype.buildInspectedLayerProperties = function (vl, selectedLayerIds, animation) {
        var _this = this;
        var numSelections = selectedLayerIds.size;
        var selectedLayers = Array.from(selectedLayerIds).map(function (id) { return vl.findLayerById(id); });
        if (numSelections > 1) {
            return {
                numSelections: numSelections,
                icon: 'collection',
                description: numSelections + " layers",
                // TODO: implement batch editting
                inspectedProperties: [],
                availablePropertyNames: [],
            };
        }
        // Edit a single layer.
        var enteredValueMap = this.enteredValueMap;
        var layer = selectedLayers[0];
        var icon = layer.type;
        var description = layer.name;
        var inspectedProperties = [];
        layer.inspectableProperties.forEach(function (property, propertyName) {
            inspectedProperties.push(new InspectedProperty(layer, property, propertyName, enteredValueMap, function (value) {
                // TODO: avoid dispatching the action if the properties are equal
                var clonedLayer = layer.clone();
                clonedLayer[propertyName] = value;
                _this.layerTimelineService.updateLayer(clonedLayer);
            }, 
            // TODO: return the 'rendered' value if an animation is ongoing? (see AIA)
            undefined, function (enteredValue) {
                if (property instanceof NameProperty) {
                    return LayerUtil.getUniqueLayerName([vl], NameProperty.sanitize(enteredValue));
                }
                return enteredValue;
            }, 
            // TODO: copy AIA conditions to determine whether this should be editable
            undefined));
        });
        var availablePropertyNames = Array.from(ModelUtil.getAvailablePropertyNamesForLayer(layer, animation));
        return {
            model: layer,
            numSelections: numSelections,
            inspectedProperties: inspectedProperties,
            icon: icon,
            description: description,
            availablePropertyNames: availablePropertyNames,
        };
    };
    PropertyInputComponent.prototype.buildInspectedBlockProperties = function (vl, animation, selectedBlockIds) {
        var _this = this;
        var numSelections = selectedBlockIds.size;
        var selectedBlocks = Array.from(selectedBlockIds).map(function (id) {
            return _.find(animation.blocks, function (b) { return b.id === id; });
        });
        if (numSelections > 1) {
            return {
                numSelections: numSelections,
                icon: 'collection',
                // TODO: implement batch editting
                description: numSelections + " property animations",
                inspectedProperties: [],
                availablePropertyNames: [],
            };
        }
        var enteredValueMap = this.enteredValueMap;
        var block = selectedBlocks[0];
        var icon = 'animationblock';
        var description = block.propertyName;
        var blockLayer = vl.findLayerById(block.layerId);
        var subDescription = "for '" + blockLayer.name + "'";
        var inspectedProperties = [];
        block.inspectableProperties.forEach(function (property, propertyName) {
            inspectedProperties.push(new InspectedProperty(block, property, propertyName, enteredValueMap, function (value) {
                // TODO: avoid dispatching the action if the properties are equal
                var clonedBlock = block.clone();
                clonedBlock[propertyName] = value;
                _this.layerTimelineService.updateBlocks([clonedBlock]);
            }));
        });
        return {
            model: block,
            numSelections: numSelections,
            inspectedProperties: inspectedProperties,
            icon: icon,
            description: description,
            subDescription: subDescription,
            availablePropertyNames: [],
        };
    };
    PropertyInputComponent.prototype.buildInspectedAnimationProperties = function (animation) {
        var store = this.store;
        var enteredValueMap = this.enteredValueMap;
        var icon = 'animation';
        var description = animation.name;
        var inspectedProperties = [];
        animation.inspectableProperties.forEach(function (property, propertyName) {
            inspectedProperties.push(new InspectedProperty(animation, property, propertyName, enteredValueMap, function (value) {
                // TODO: avoid dispatching the action if the properties are equal
                var clonedAnimation = animation.clone();
                clonedAnimation[propertyName] = value;
                store.dispatch(new SetAnimation(clonedAnimation));
            }, undefined, undefined, undefined));
        });
        return {
            model: animation,
            numSelections: 1,
            inspectedProperties: inspectedProperties,
            icon: icon,
            description: description,
            availablePropertyNames: [],
        };
    };
    // Called from the HTML template.
    PropertyInputComponent.prototype.androidToCssColor = function (color) {
        return ColorUtil.androidToCssHexColor(color);
    };
    PropertyInputComponent.prototype.trackInspectedPropertyFn = function (index, ip) {
        return ip.propertyName;
    };
    PropertyInputComponent.prototype.trackEnumOptionFn = function (index, option) {
        return option.value;
    };
    return PropertyInputComponent;
}());
export { PropertyInputComponent };
