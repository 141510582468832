import * as tslib_1 from "tslib";
import { Path, PathUtil } from 'app/modules/editor/model/paths';
import { Property } from './Property';
var PathProperty = /** @class */ (function (_super) {
    tslib_1.__extends(PathProperty, _super);
    function PathProperty() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // @Override
    PathProperty.prototype.setEditableValue = function (model, propertyName, value) {
        var path;
        try {
            path = new Path(value);
        }
        catch (e) {
            // An error will be thrown if the user attempts to enter an invalid path,
            // which will occur frequently if they type the path out by hand.
            return;
        }
        model[propertyName] = path;
    };
    // @Override
    PathProperty.prototype.getEditableValue = function (model, propertyName) {
        return model[propertyName] ? model[propertyName].getPathString() : '';
    };
    // @Override
    PathProperty.prototype.getter = function (model, propertyName) {
        return model[propertyName + "_"];
    };
    // @Override
    PathProperty.prototype.setter = function (model, propertyName, value) {
        if (!value) {
            model[propertyName + "_"] = undefined;
            return;
        }
        model[propertyName + "_"] = typeof value === 'string' ? new Path(value) : value;
    };
    // @Override
    PathProperty.prototype.displayValueForValue = function (value) {
        return value ? value.getPathString() : '';
    };
    // @Override
    PathProperty.prototype.interpolateValue = function (start, end, fraction) {
        if (!start || !end || !start.isMorphableWith(end) || !fraction) {
            return start;
        }
        if (fraction === 1) {
            return end;
        }
        return PathUtil.interpolate(start, end, fraction);
    };
    // @Override
    PathProperty.prototype.cloneValue = function (value) {
        return value ? value.mutate().build() : undefined;
    };
    // @Override
    PathProperty.prototype.getAnimatorValueType = function () {
        return 'pathType';
    };
    // @Override
    PathProperty.prototype.getTypeName = function () {
        return 'PathProperty';
    };
    return PathProperty;
}(Property));
export { PathProperty };
