import * as tslib_1 from "tslib";
import { Property } from './Property';
var NameProperty = /** @class */ (function (_super) {
    tslib_1.__extends(NameProperty, _super);
    function NameProperty() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NameProperty.sanitize = function (value) {
        if (value === void 0) { value = ''; }
        return value
            .toLowerCase()
            .replace(/^\s+|\s+$/g, '')
            .replace(/[\s-]+/g, '_')
            .replace(/[^\w_]+/g, '');
    };
    // @Override
    NameProperty.prototype.setEditableValue = function (model, propertyName, value) {
        model[propertyName] = NameProperty.sanitize(value);
    };
    // @Override
    NameProperty.prototype.getTypeName = function () {
        return 'NameProperty';
    };
    return NameProperty;
}(Property));
export { NameProperty };
