import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit, QueryList, } from '@angular/core';
import { ActionSource } from 'app/modules/editor/model/actionmode';
import { MathUtil, Matrix } from 'app/modules/editor/scripts/common';
import { DestroyableMixin } from 'app/modules/editor/scripts/mixins';
import { ThemeService } from 'app/modules/editor/services';
import { Store } from 'app/modules/editor/store';
import { isActionMode } from 'app/modules/editor/store/actionmode/selectors';
import { getVectorLayer } from 'app/modules/editor/store/layers/selectors';
import { getZoomPanInfo } from 'app/modules/editor/store/paper/selectors';
import { environment } from 'environments/environment';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CanvasContainerDirective } from './canvascontainer.directive';
import { CanvasLayersDirective } from './canvaslayers.directive';
import { CanvasLayoutMixin } from './CanvasLayoutMixin';
import { CanvasOverlayDirective } from './canvasoverlay.directive';
import { CanvasPaperDirective } from './canvaspaper.directive';
import { CanvasRulerDirective } from './canvasruler.directive';
// Canvas margin in css pixels.
var CANVAS_MARGIN = 36;
var CanvasComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CanvasComponent, _super);
    function CanvasComponent(elementRef, store, themeService) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.themeService = themeService;
        _this.IS_BETA = environment.beta;
        _this.$element = $(elementRef.nativeElement);
        return _this;
    }
    CanvasComponent.prototype.ngOnInit = function () {
        this.isActionMode$ = this.store.select(isActionMode);
    };
    CanvasComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var activeViewport$ = this.store.select(getVectorLayer).pipe(map(function (vl) { return ({ w: vl.width, h: vl.height }); }), distinctUntilChanged(_.isEqual));
        this.registerSubscription(combineLatest(this.canvasBounds$, activeViewport$).subscribe(function (_a) {
            var bounds = _a[0], viewport = _a[1];
            var w = Math.max(1, bounds.w - CANVAS_MARGIN * 2);
            var h = Math.max(1, bounds.h - CANVAS_MARGIN * 2);
            _this.setDimensions({ w: w, h: h }, viewport);
        }));
        this.registerSubscription(this.store.select(getZoomPanInfo).subscribe(function (info) {
            _this.setZoomPan(info.zoom, info.translation);
        }));
    };
    // @Override
    CanvasComponent.prototype.onDimensionsChanged = function (bounds, viewport) {
        var directives = this.canvasContainer.toArray().concat(this.canvasLayers.toArray(), this.canvasOverlay.toArray(), this.canvasPaper.toArray(), this.canvasRulers.toArray());
        directives.forEach(function (d) { return d.setDimensions(bounds, viewport); });
    };
    // @Override
    CanvasComponent.prototype.onZoomPanChanged = function (zoom, translation) {
        var directives = this.canvasContainer.toArray().concat(this.canvasLayers.toArray(), this.canvasOverlay.toArray(), this.canvasPaper.toArray(), this.canvasRulers.toArray());
        directives.forEach(function (d) { return d.setZoomPan(zoom, translation); });
    };
    CanvasComponent.prototype.onMouseDown = function (event) {
        this.canvasOverlay.forEach(function (c) { return c.onMouseDown(event); });
        this.showRuler(event);
    };
    CanvasComponent.prototype.onMouseMove = function (event) {
        this.canvasOverlay.forEach(function (c) { return c.onMouseMove(event); });
        this.showRuler(event);
    };
    CanvasComponent.prototype.onMouseUp = function (event) {
        this.canvasOverlay.forEach(function (c) { return c.onMouseUp(event); });
        this.showRuler(event);
    };
    CanvasComponent.prototype.onMouseLeave = function (event) {
        this.canvasOverlay.forEach(function (c) { return c.onMouseLeave(event); });
        this.hideRuler();
    };
    CanvasComponent.prototype.showRuler = function (event) {
        var canvasOffset = this.$element.offset();
        var zoom = this.getZoom();
        var _a = this.getTranslation(), tx = _a.tx, ty = _a.ty;
        var point = MathUtil.transformPoint({ x: event.pageX - canvasOffset.left, y: event.pageY - canvasOffset.top }, new Matrix(zoom, 0, 0, zoom, tx, ty).invert());
        var x = point.x / Math.max(1, this.cssScale);
        var y = point.y / Math.max(1, this.cssScale);
        this.canvasRulers.forEach(function (r) { return r.showMouse({ x: _.round(x), y: _.round(y) }); });
    };
    CanvasComponent.prototype.hideRuler = function () {
        this.canvasRulers.forEach(function (r) { return r.hideMouse(); });
    };
    return CanvasComponent;
}(CanvasLayoutMixin(DestroyableMixin())));
export { CanvasComponent };
