/**
 * Stores information about an inspected property.
 * V is the property value type (number, string, or path).
 */
var InspectedProperty = /** @class */ (function () {
    function InspectedProperty(
    // The model object being inspected (a layer, animation, or animation block).
    model, 
    // The model object's inspected property.
    property, 
    // The model object's inspected property name.
    propertyName, 
    // The in-memory entered value map.
    enteredValueMap, 
    // Stores the model's entered value for the given property name in the application store.
    setValueFn, 
    // Returns the value associated with this model's property name.
    getValueFn, 
    // Provides an opportunity to edit the value before it is set.
    transformEditedValueFn, 
    // Returns whether or not this property name is editable.
    isEditable) {
        if (getValueFn === void 0) { getValueFn = function () { return model[propertyName]; }; }
        if (transformEditedValueFn === void 0) { transformEditedValueFn = function (enteredValue) { return enteredValue; }; }
        if (isEditable === void 0) { isEditable = function () { return true; }; }
        this.property = property;
        this.propertyName = propertyName;
        this.enteredValueMap = enteredValueMap;
        this.setValueFn = setValueFn;
        this.getValueFn = getValueFn;
        this.transformEditedValueFn = transformEditedValueFn;
        this.isEditable = isEditable;
        this.typeName = this.property.getTypeName();
    }
    Object.defineProperty(InspectedProperty.prototype, "value", {
        get: function () {
            return this.getValueFn();
        },
        set: function (value) {
            this.setValueFn(value);
        },
        enumerable: true,
        configurable: true
    });
    InspectedProperty.prototype.getDisplayValue = function () {
        return this.property.displayValueForValue(this.value);
    };
    Object.defineProperty(InspectedProperty.prototype, "editableValue", {
        get: function () {
            var enteredValue = this.getEnteredValue();
            return enteredValue === undefined
                ? this.property.getEditableValue(this, 'value')
                : enteredValue;
        },
        set: function (enteredValue) {
            this.setEnteredValue(enteredValue);
            enteredValue = this.transformEditedValueFn(enteredValue);
            this.property.setEditableValue(this, 'value', enteredValue);
        },
        enumerable: true,
        configurable: true
    });
    InspectedProperty.prototype.resolveEnteredValue = function () {
        this.setEnteredValue(undefined);
    };
    InspectedProperty.prototype.getEnteredValue = function () {
        if (this.enteredValueMap.has(this.propertyName)) {
            return this.enteredValueMap.get(this.propertyName);
        }
        return undefined;
    };
    InspectedProperty.prototype.setEnteredValue = function (value) {
        if (value === undefined) {
            this.enteredValueMap.delete(this.propertyName);
        }
        else {
            this.enteredValueMap.set(this.propertyName, value);
        }
    };
    return InspectedProperty;
}());
export { InspectedProperty };
