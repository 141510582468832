import * as tslib_1 from "tslib";
import { INTERPOLATORS } from 'app/modules/editor/model/interpolators';
import { GroupLayer, LayerUtil, PathLayer, VectorLayer, } from 'app/modules/editor/model/layers';
import { Animation, AnimationBlock, PathAnimationBlock } from 'app/modules/editor/model/timeline';
import { MathUtil, Matrix, ModelUtil } from 'app/modules/editor/scripts/common';
import { Store } from 'app/modules/editor/store';
import { BatchAction } from 'app/modules/editor/store/batch/actions';
import { SetCollapsedLayers, SetHiddenLayers, SetSelectedLayers, SetVectorLayer, } from 'app/modules/editor/store/layers/actions';
import { getCollapsedLayerIds, getHiddenLayerIds, getSelectedLayerIds, getVectorLayer, } from 'app/modules/editor/store/layers/selectors';
import { SelectAnimation, SetAnimation, SetSelectedBlocks, } from 'app/modules/editor/store/timeline/actions';
import { getAnimation, getSelectedBlockIds, isAnimationSelected, } from 'app/modules/editor/store/timeline/selectors';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import * as StoreUtil from './StoreUtil';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * A simple service that provides an interface for making layer/timeline changes.
 */
var LayerTimelineService = /** @class */ (function () {
    function LayerTimelineService(store) {
        this.store = store;
    }
    /**
     * Selects or deselects the animation.
     */
    LayerTimelineService.prototype.selectAnimation = function (isSelected) {
        this.updateSelections(isSelected, new Set(), new Set());
    };
    /**
     * Selects or deselects the specified block ID.
     */
    LayerTimelineService.prototype.selectBlock = function (blockId, clearExisting) {
        var selectedBlockIds = this.getSelectedBlockIds();
        if (clearExisting) {
            selectedBlockIds.forEach(function (id) {
                if (id !== blockId) {
                    selectedBlockIds.delete(id);
                }
            });
        }
        if (!clearExisting && selectedBlockIds.has(blockId)) {
            selectedBlockIds.delete(blockId);
        }
        else {
            selectedBlockIds.add(blockId);
        }
        this.updateSelections(false, selectedBlockIds, new Set());
    };
    /**
     * Selects or deselects the specified layer ID.
     */
    LayerTimelineService.prototype.selectLayer = function (layerId, clearExisting) {
        var selectedLayerIds = this.getSelectedLayerIds();
        if (clearExisting) {
            selectedLayerIds.forEach(function (id) {
                if (id !== layerId) {
                    selectedLayerIds.delete(id);
                }
            });
        }
        if (!clearExisting && selectedLayerIds.has(layerId)) {
            selectedLayerIds.delete(layerId);
        }
        else {
            selectedLayerIds.add(layerId);
        }
        this.updateSelections(false, new Set(), selectedLayerIds);
    };
    LayerTimelineService.prototype.setSelectedLayers = function (layerIds) {
        this.updateSelections(false, new Set(), new Set(layerIds));
    };
    /**
     * Clears all animation/block/layer selections.
     */
    LayerTimelineService.prototype.clearSelections = function () {
        var actions = this.getClearSelectionsActions();
        if (actions.length) {
            this.store.dispatch(new (BatchAction.bind.apply(BatchAction, [void 0].concat(actions)))());
        }
    };
    LayerTimelineService.prototype.getClearSelectionsActions = function () {
        return this.getUpdateSelectionsActions(false, new Set(), new Set());
    };
    LayerTimelineService.prototype.updateSelections = function (isAnimSelected, selectedBlockIds, selectedLayerIds) {
        var actions = this.getUpdateSelectionsActions(isAnimSelected, selectedBlockIds, selectedLayerIds);
        if (actions.length) {
            this.store.dispatch(new (BatchAction.bind.apply(BatchAction, [void 0].concat(actions)))());
        }
    };
    LayerTimelineService.prototype.getUpdateSelectionsActions = function (isAnimSelected, selectedBlockIds, selectedLayerIds) {
        var actions = [];
        if (this.isAnimationSelected() !== isAnimSelected) {
            actions.push(new SelectAnimation(isAnimSelected));
        }
        if (!_.isEqual(this.getSelectedBlockIds(), selectedBlockIds)) {
            actions.push(new SetSelectedBlocks(selectedBlockIds));
        }
        if (!_.isEqual(this.getSelectedLayerIds(), selectedLayerIds)) {
            actions.push(new SetSelectedLayers(selectedLayerIds));
            // TODO: improve this design somehow (probably best not to have this service depend on paper ops?)
            // TODO: figure out which selection-changed cases should force you into default mode
            // TODO: i.e. should selecting a new layer in edit path mode trigger edit path mode for the new layer?
            if (environment.beta) {
                actions.push.apply(actions, StoreUtil.getEnterDefaultModeActions());
            }
        }
        return actions;
    };
    /**
     * Toggles the specified layer's expanded state.
     */
    LayerTimelineService.prototype.toggleExpandedLayer = function (layerId, recursive) {
        var layerIds = new Set([layerId]);
        if (recursive) {
            var layer = this.getVectorLayer().findLayerById(layerId);
            if (layer) {
                layer.walk(function (l) { return layerIds.add(l.id); });
            }
        }
        var collapsedLayerIds = this.getCollapsedLayerIds();
        if (collapsedLayerIds.has(layerId)) {
            layerIds.forEach(function (id) { return collapsedLayerIds.delete(id); });
        }
        else {
            layerIds.forEach(function (id) { return collapsedLayerIds.add(id); });
        }
        this.store.dispatch(new SetCollapsedLayers(collapsedLayerIds));
    };
    /**
     * Toggles the specified layer's visibility.
     */
    LayerTimelineService.prototype.toggleVisibleLayer = function (layerId) {
        var layerIds = this.getHiddenLayerIds();
        if (layerIds.has(layerId)) {
            layerIds.delete(layerId);
        }
        else {
            layerIds.add(layerId);
        }
        this.store.dispatch(new SetHiddenLayers(layerIds));
    };
    /**
     * Imports a list of vector layers into the workspace.
     */
    LayerTimelineService.prototype.importLayers = function (vls) {
        if (!vls.length) {
            return;
        }
        var importedVls = vls.slice();
        var vectorLayer = this.getVectorLayer();
        var vectorLayers = [vectorLayer];
        if (!vectorLayer.children.length) {
            // Simply replace the empty vector layer rather than merging with it.
            var vl = importedVls[0].clone();
            vl.name = vectorLayer.name;
            importedVls[0] = vl;
            vectorLayers = [];
        }
        var newVectorLayers = vectorLayers.concat(importedVls);
        var newVl = newVectorLayers.length === 1
            ? newVectorLayers[0]
            : newVectorLayers.reduce(LayerUtil.mergeVectorLayers);
        this.store.dispatch(new (BatchAction.bind.apply(BatchAction, [void 0].concat(this.getClearSelectionsActions(), [new SetVectorLayer(newVl)])))());
    };
    /**
     * Adds a layer to the vector tree.
     */
    LayerTimelineService.prototype.addLayer = function (layer) {
        var vl = this.getVectorLayer();
        var selectedLayers = this.getSelectedLayers();
        if (selectedLayers.length === 1) {
            var selectedLayer = selectedLayers[0];
            if (!(selectedLayer instanceof VectorLayer)) {
                // Add the new layer as a sibling to the currently selected layer.
                var parent_1 = LayerUtil.findParent(vl, selectedLayer.id).clone();
                parent_1.children = parent_1.children.concat([layer]);
                this.updateLayer(parent_1);
                return;
            }
        }
        var vectorLayer = vl.clone();
        vectorLayer.children = vectorLayer.children.concat([layer]);
        this.updateLayer(vectorLayer);
    };
    /**
     * Sets the current vector layer.
     */
    LayerTimelineService.prototype.setVectorLayer = function (vl) {
        this.store.dispatch(new SetVectorLayer(vl));
    };
    /**
     * Updates an existing layer in the tree.
     */
    LayerTimelineService.prototype.updateLayer = function (layer) {
        this.store.dispatch(new SetVectorLayer(LayerUtil.updateLayer(this.getVectorLayer(), layer)));
    };
    /**
     * Replaces an existing layer in the tree with a new layer. Note that
     * this method assumes that both layers still have the same children layers.
     */
    LayerTimelineService.prototype.swapLayers = function (layerId, newLayer) {
        if (layerId === newLayer.id) {
            this.updateLayer(newLayer);
            return;
        }
        var vl = this.getVectorLayer();
        var parent = LayerUtil.findParent(vl, layerId).clone();
        var layerIndex = _.findIndex(parent.children, function (l) { return l.id === layerId; });
        var children = parent.children.slice();
        children.splice(layerIndex, 1, newLayer);
        parent.children = children;
        var actions = [
            new SetVectorLayer(LayerUtil.updateLayer(vl, parent))
        ].concat(this.buildCleanupLayerIdActions(layerId));
        var animation = this.getAnimation();
        var oldLayerBlocks = animation.blocks.filter(function (b) { return b.layerId === layerId; });
        var newAnimatableProperties = new Set(newLayer.animatableProperties.keys());
        // Preserve any blocks that are still animatable with the new layer.
        var newLayerBlocks = oldLayerBlocks
            .filter(function (b) { return newAnimatableProperties.has(b.propertyName); })
            .map(function (b) {
            b = b.clone();
            b.layerId = newLayer.id;
            return b;
        });
        var newAnimation = animation.clone();
        newAnimation.blocks = animation.blocks.filter(function (b) { return b.layerId !== layerId; }).concat(newLayerBlocks);
        actions.push(new SetAnimation(newAnimation));
        this.store.dispatch(new (BatchAction.bind.apply(BatchAction, [void 0].concat(actions)))());
    };
    /**
     * Merges the specified group layer into its children layers.
     * TODO: make it possible to merge groups that contain animation blocks?
     */
    LayerTimelineService.prototype.flattenGroupLayer = function (layerId) {
        var vl = this.getVectorLayer();
        var layer = vl.findLayerById(layerId);
        if (!layer.children.length) {
            return;
        }
        var layerTransform = Matrix.flatten(LayerUtil.getCanvasTransformsForGroupLayer(layer));
        var layerChildren = layer.children.map(function (l) {
            if (l instanceof GroupLayer) {
                var flattenedTransform = Matrix.flatten([
                    layerTransform
                ].concat(LayerUtil.getCanvasTransformsForGroupLayer(l)));
                var _a = flattenedTransform.getScaling(), sx = _a.sx, sy = _a.sy;
                var degrees = flattenedTransform.getRotation();
                var _b = flattenedTransform.getTranslation(), tx = _b.tx, ty = _b.ty;
                l = l.clone();
                l.pivotX = 0;
                l.pivotY = 0;
                l.translateX = tx;
                l.translateY = ty;
                l.rotation = degrees;
                l.scaleX = sx;
                l.scaleY = sy;
                return l;
            }
            l = l.clone();
            if (l instanceof PathLayer && l.strokeWidth) {
                var scaleFactor = layerTransform.getScaleFactor();
                var newStrokeWidth = l.strokeWidth * scaleFactor ? 1 / scaleFactor : 0;
                l.strokeWidth = MathUtil.round(newStrokeWidth);
            }
            var path = l.pathData;
            if (!path || !l.pathData.getPathString()) {
                return l;
            }
            l.pathData = path
                .mutate()
                .transform(layerTransform)
                .build();
            return l;
        });
        var layerChildrenIds = new Set(layerChildren.map(function (l) { return l.id; }));
        var parent = LayerUtil.findParent(vl, layerId).clone();
        var children = parent.children.slice();
        children.splice.apply(children, [_.findIndex(parent.children, function (l) { return l.id === layerId; }), 1].concat(layerChildren));
        parent.children = children;
        var actions = [
            new SetVectorLayer(LayerUtil.updateLayer(vl, parent))
        ].concat(this.buildCleanupLayerIdActions(layerId));
        var newAnimation = this.getAnimation().clone();
        // TODO: show a dialog if the user is about to unknowingly delete any blocks?
        newAnimation.blocks = newAnimation.blocks.filter(function (b) { return b.layerId !== layerId; });
        // TODO: also attempt to merge children group animation blocks?
        newAnimation.blocks = newAnimation.blocks.map(function (b) {
            if (!(b instanceof PathAnimationBlock) || !layerChildrenIds.has(b.layerId)) {
                return b;
            }
            var block = b.clone();
            if (block.fromValue) {
                block.fromValue = block.fromValue
                    .mutate()
                    .transform(layerTransform)
                    .build();
            }
            if (block.toValue) {
                block.toValue = block.toValue
                    .mutate()
                    .transform(layerTransform)
                    .build();
            }
            return block;
        });
        actions.push(new SetAnimation(newAnimation));
        this.store.dispatch(new (BatchAction.bind.apply(BatchAction, [void 0].concat(actions)))());
    };
    LayerTimelineService.prototype.buildCleanupLayerIdActions = function () {
        var deletedLayerIds = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            deletedLayerIds[_i] = arguments[_i];
        }
        var collapsedLayerIds = this.getCollapsedLayerIds();
        var hiddenLayerIds = this.getHiddenLayerIds();
        var selectedLayerIds = this.getSelectedLayerIds();
        var differenceFn = function (s, a) {
            return new Set(_.difference(Array.from(s), a));
        };
        var actions = [];
        if (deletedLayerIds.some(function (id) { return collapsedLayerIds.has(id); })) {
            actions.push(new SetCollapsedLayers(differenceFn(collapsedLayerIds, deletedLayerIds)));
        }
        if (deletedLayerIds.some(function (id) { return hiddenLayerIds.has(id); })) {
            actions.push(new SetHiddenLayers(differenceFn(hiddenLayerIds, deletedLayerIds)));
        }
        if (deletedLayerIds.some(function (id) { return selectedLayerIds.has(id); })) {
            actions.push(new SetSelectedLayers(differenceFn(selectedLayerIds, deletedLayerIds)));
        }
        return actions;
    };
    /**
     * Groups or ungroups the selected layers.
     */
    LayerTimelineService.prototype.groupOrUngroupSelectedLayers = function (shouldGroup) {
        var selectedLayerIds = this.getSelectedLayerIds();
        if (!selectedLayerIds.size) {
            return;
        }
        var vl = this.getVectorLayer();
        // Sort selected layers by order they appear in tree.
        var tempSelLayers = Array.from(selectedLayerIds).map(function (id) { return vl.findLayerById(id); });
        var selLayerOrdersMap = {};
        var n = 0;
        vl.walk(function (layer) {
            if (_.find(tempSelLayers, function (l) { return l.id === layer.id; })) {
                selLayerOrdersMap[layer.id] = n;
                n++;
            }
        });
        tempSelLayers.sort(function (a, b) { return selLayerOrdersMap[a.id] - selLayerOrdersMap[b.id]; });
        if (shouldGroup) {
            // Remove any layers that are descendants of other selected layers,
            // and remove the vectorLayer itself if selected.
            tempSelLayers = tempSelLayers.filter(function (layer) {
                if (layer instanceof VectorLayer) {
                    return false;
                }
                var p = LayerUtil.findParent(vl, layer.id);
                while (p) {
                    if (_.find(tempSelLayers, function (l) { return l.id === p.id; })) {
                        return false;
                    }
                    p = LayerUtil.findParent(vl, p.id);
                }
                return true;
            });
            if (!tempSelLayers.length) {
                return;
            }
            // Find destination parent and insertion point.
            var firstSelectedLayerParent = LayerUtil.findParent(vl, tempSelLayers[0].id).clone();
            var firstSelectedLayerIndexInParent = _.findIndex(firstSelectedLayerParent.children, function (l) { return l.id === tempSelLayers[0].id; });
            // Remove all selected items from their parents and move them into a new parent.
            var newGroup = new GroupLayer({
                name: LayerUtil.getUniqueLayerName([vl], 'group'),
                children: tempSelLayers,
            });
            var parentChildren = firstSelectedLayerParent.children.slice();
            parentChildren.splice(firstSelectedLayerIndexInParent, 0, newGroup);
            _.remove(parentChildren, function (child) {
                return _.find(tempSelLayers, function (selectedLayer) { return selectedLayer.id === child.id; });
            });
            firstSelectedLayerParent.children = parentChildren;
            vl = LayerUtil.updateLayer(vl, firstSelectedLayerParent);
            selectedLayerIds = new Set([newGroup.id]);
        }
        else {
            // Ungroup selected groups layers.
            var newSelectedLayers_1 = [];
            tempSelLayers.filter(function (layer) { return layer instanceof GroupLayer; }).forEach(function (groupLayer) {
                // Move children into parent.
                var parent = LayerUtil.findParent(vl, groupLayer.id).clone();
                var indexInParent = Math.max(0, _.findIndex(parent.children, function (l) { return l.id === groupLayer.id; }));
                var newChildren = parent.children.slice();
                newChildren.splice.apply(newChildren, [indexInParent, 0].concat(groupLayer.children));
                parent.children = newChildren;
                vl = LayerUtil.updateLayer(vl, parent);
                newSelectedLayers_1.splice.apply(newSelectedLayers_1, [0, 0].concat(groupLayer.children));
                // Delete the parent.
                vl = LayerUtil.removeLayers(vl, groupLayer.id);
            });
            selectedLayerIds = new Set(newSelectedLayers_1.map(function (l) { return l.id; }));
        }
        this.store.dispatch(new BatchAction(new SetVectorLayer(vl), new SetSelectedLayers(selectedLayerIds)));
    };
    LayerTimelineService.prototype.deleteSelectedModels = function () {
        return this.store.dispatch(new (BatchAction.bind.apply(BatchAction, [void 0].concat(this.getDeleteSelectedModelsActions())))());
    };
    LayerTimelineService.prototype.getDeleteSelectedModelsActions = function () {
        var collapsedLayerIds = this.getCollapsedLayerIds();
        var hiddenLayerIds = this.getHiddenLayerIds();
        var selectedLayerIds = this.getSelectedLayerIds();
        var vl = this.getVectorLayer();
        if (selectedLayerIds.has(vl.id)) {
            vl = new VectorLayer();
            collapsedLayerIds.clear();
            hiddenLayerIds.clear();
        }
        else {
            selectedLayerIds.forEach(function (layerId) {
                vl = LayerUtil.removeLayers(vl, layerId);
                collapsedLayerIds.delete(layerId);
                hiddenLayerIds.delete(layerId);
            });
        }
        var animation = this.getAnimation();
        if (this.isAnimationSelected()) {
            animation = new Animation();
        }
        var selectedBlockIds = this.getSelectedBlockIds();
        if (selectedBlockIds.size) {
            animation = animation.clone();
            animation.blocks = animation.blocks.filter(function (b) { return !selectedBlockIds.has(b.id); });
        }
        // Remove any blocks corresponding to deleted layers.
        var filteredBlocks = animation.blocks.filter(function (b) { return !!vl.findLayerById(b.layerId); });
        if (filteredBlocks.length !== animation.blocks.length) {
            animation = animation.clone();
            animation.blocks = filteredBlocks;
        }
        return [
            new SetVectorLayer(vl),
            new SetCollapsedLayers(collapsedLayerIds),
            new SetHiddenLayers(hiddenLayerIds),
            new SetSelectedLayers(new Set()),
            new SelectAnimation(false),
            new SetAnimation(animation),
            new SetSelectedBlocks(new Set()),
        ];
    };
    LayerTimelineService.prototype.updateBlocks = function (blocks) {
        if (!blocks.length) {
            return;
        }
        var animation = this.getAnimation().clone();
        animation.blocks = animation.blocks.map(function (block) {
            var newBlock = _.find(blocks, function (b) { return block.id === b.id; });
            return newBlock ? newBlock : block;
        });
        this.store.dispatch(new SetAnimation(animation));
    };
    LayerTimelineService.prototype.addBlocks = function (blocks, autoSelectBlocks) {
        if (autoSelectBlocks === void 0) { autoSelectBlocks = true; }
        blocks.forEach(function (b) {
            if (!b.id) {
                b.id = _.uniqueId();
            }
        });
        var animation = this.getAnimation();
        var addedBlocks = [];
        for (var _i = 0, blocks_1 = blocks; _i < blocks_1.length; _i++) {
            var block = blocks_1[_i];
            var anim = this.addBlockToAnimation(animation, block);
            if (animation !== anim) {
                animation = anim;
                addedBlocks.push(block);
            }
        }
        this.store.dispatch(new BatchAction(new SetAnimation(animation), new SelectAnimation(false), new SetSelectedBlocks(new Set(addedBlocks.map(function (b) { return b.id; }))), new SetSelectedLayers(new Set())));
    };
    LayerTimelineService.prototype.addBlockToAnimation = function (animation, block) {
        var layer = this.getVectorLayer().findLayerById(block.layerId);
        if (!layer) {
            return animation;
        }
        var newBlockDuration = block.duration || 100;
        var interpolator = block.interpolator || INTERPOLATORS[0].value;
        var propertyName = block.propertyName;
        var currentTime = block.currentTime;
        // Find the right start time for the block, which should be a gap between
        // neighboring blocks closest to the active time cursor, of a minimum size.
        var blocksByLayerId = ModelUtil.getOrderedBlocksByPropertyByLayer(animation);
        var blockNeighbors = (blocksByLayerId[layer.id] || {})[propertyName] || [];
        var gaps = [];
        for (var i = 0; i < blockNeighbors.length; i++) {
            gaps.push({
                start: i === 0 ? 0 : blockNeighbors[i - 1].endTime,
                end: blockNeighbors[i].startTime,
            });
        }
        gaps.push({
            start: blockNeighbors.length ? blockNeighbors[blockNeighbors.length - 1].endTime : 0,
            end: animation.duration,
        });
        gaps = gaps
            .filter(function (gap) { return gap.end - gap.start >= newBlockDuration; })
            .map(function (gap) {
            var dist = Math.min(Math.abs(gap.end - currentTime), Math.abs(gap.start - currentTime));
            return tslib_1.__assign({}, gap, { dist: dist });
        })
            .sort(function (a, b) { return a.dist - b.dist; });
        if (!gaps.length) {
            // No available gaps, cancel.
            // TODO: show a disabled button to prevent this case?
            console.warn('Ignoring failed attempt to add animation block');
            return animation;
        }
        var startTime = Math.max(currentTime, gaps[0].start);
        var endTime = Math.min(startTime + newBlockDuration, gaps[0].end);
        if (endTime - startTime < newBlockDuration) {
            startTime = endTime - newBlockDuration;
        }
        // Generate the new block.
        var property = layer.animatableProperties.get(propertyName);
        var type;
        if (property.getTypeName() === 'PathProperty') {
            type = 'path';
        }
        else if (property.getTypeName() === 'ColorProperty') {
            type = 'color';
        }
        else {
            type = 'number';
        }
        // TODO: clone the current rendered property value and set the from/to values appropriately
        // const valueAtCurrentTime =
        //   this.studioState_.animationRenderer
        //     .getLayerPropertyValue(layer.id, propertyName);
        var newBlock = AnimationBlock.from({
            id: block.id ? block.id : undefined,
            layerId: layer.id,
            propertyName: propertyName,
            startTime: startTime,
            endTime: endTime,
            fromValue: block.fromValue,
            toValue: block.toValue,
            interpolator: interpolator,
            type: type,
        });
        animation = animation.clone();
        animation.blocks = animation.blocks.concat([newBlock]);
        return animation;
    };
    LayerTimelineService.prototype.getVectorLayer = function () {
        return this.queryStore(getVectorLayer);
    };
    LayerTimelineService.prototype.getSelectedLayerIds = function () {
        return new Set(this.queryStore(getSelectedLayerIds));
    };
    LayerTimelineService.prototype.getSelectedLayers = function () {
        var vl = this.getVectorLayer();
        return Array.from(this.getSelectedLayerIds()).map(function (id) { return vl.findLayerById(id); });
    };
    LayerTimelineService.prototype.getHiddenLayerIds = function () {
        return new Set(this.queryStore(getHiddenLayerIds));
    };
    LayerTimelineService.prototype.getCollapsedLayerIds = function () {
        return new Set(this.queryStore(getCollapsedLayerIds));
    };
    LayerTimelineService.prototype.getSelectedBlockIds = function () {
        return new Set(this.queryStore(getSelectedBlockIds));
    };
    LayerTimelineService.prototype.getSelectedBlocks = function () {
        var anim = this.getAnimation();
        var blockIds = this.getSelectedBlockIds();
        return Array.from(blockIds).map(function (id) { return _.find(anim.blocks, function (b) { return b.id === id; }); });
    };
    LayerTimelineService.prototype.getAnimation = function () {
        return this.queryStore(getAnimation);
    };
    LayerTimelineService.prototype.isAnimationSelected = function () {
        return this.queryStore(isAnimationSelected);
    };
    LayerTimelineService.prototype.queryStore = function (selector) {
        var obj;
        this.store
            .select(selector)
            .pipe(first())
            .subscribe(function (o) { return (obj = o); });
        return obj;
    };
    LayerTimelineService.ngInjectableDef = i0.defineInjectable({ factory: function LayerTimelineService_Factory() { return new LayerTimelineService(i0.inject(i1.Store)); }, token: LayerTimelineService, providedIn: "root" });
    return LayerTimelineService;
}());
export { LayerTimelineService };
