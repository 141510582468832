import * as tslib_1 from "tslib";
import { ToolMode } from 'app/modules/editor/model/paper';
import { Gesture } from 'app/modules/editor/scripts/paper/gesture';
import { HoverItemsGesture } from './HoverItemsGesture';
import { HoverSegmentsCurvesGesture } from './HoverSegmentsCurvesGesture';
/**
 * A gesture that handles mouse move hover events.
 */
var HoverGesture = /** @class */ (function (_super) {
    tslib_1.__extends(HoverGesture, _super);
    function HoverGesture(ps) {
        var _this = _super.call(this) || this;
        _this.ps = ps;
        _this.hoverItemsGesture = new HoverItemsGesture(_this.ps);
        _this.hoverSegmentsCurvesGesture = new HoverSegmentsCurvesGesture(_this.ps);
        return _this;
    }
    // @Override
    HoverGesture.prototype.onMouseMove = function (event) {
        var gesture = this.getCurrentGesture();
        if (gesture) {
            gesture.onMouseMove(event);
        }
    };
    // @Override
    HoverGesture.prototype.onKeyDown = function (event) {
        var gesture = this.getCurrentGesture();
        if (gesture) {
            gesture.onKeyDown(event);
        }
    };
    HoverGesture.prototype.getCurrentGesture = function () {
        if (this.ps.getToolMode() !== ToolMode.Default) {
            return undefined;
        }
        var epi = this.ps.getEditPathInfo();
        if (!epi) {
            return this.hoverItemsGesture;
        }
        if (!this.ps.getSelectedLayerIds().size) {
            // If we are in edit path mode but there is no selected layer ID, then
            // the user is using the 'vector' tool and hasn't yet started to create
            // a path. In this case we do not want to show any hovers.
            return undefined;
        }
        return this.hoverSegmentsCurvesGesture;
    };
    return HoverGesture;
}(Gesture));
export { HoverGesture };
